import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import FormPage from "../../_components/FormPage";
import CredentialContext from "../../../config/CredentialContext";
import { Button, Modal } from "react-bootstrap";
import { getReturnJson, postReturnJson } from "../../../utils/dataFetching";
import { processDataFromCapServer } from "../../../utils/dataProcessing";
import { useCruiseLine, useLocalize, useQuery, useTheme, useHeaderTitle } from "../../../utils/hooks";
import LoadingWrapper from "../../_components/Wrappers/LoadingWrapper";
import PassengerList from "../PassengerList";
import TripSummary from "../../_components/TripSummary/TripSummary";
import BookingErrorWrapper from "./BookingErrorWrapper";

const INFO_DETAILS = {
    embarkationDate: "Embarkation Date",
    shipCode: "Ship Code",
    shipName: "Ship Name",
    voyageNumber: "Voyage Number",
    durationDays: "Duration Days",
};

export default function ManageCruiseTrip() {
    const { submissionHref, userId, processInstance, setProcessInstance, setError } = useContext(CredentialContext);
    const query = useQuery();
    const theme = useTheme(query.get("theme"));
    const location = useLocation();
    const { localize } = useLocalize();

    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [loading, setLoading] = useState(true);
    const [showRemovedGuestsModal, setShowRemovedGuestsModal] = useState(false);
    const [getGuests, setGetGuests] = useState(false);
    const [cruiseInfoDiff, setCruiseInfoDiff] = useState([]);
    const [generalCruiseInfoDiff, setGeneralCruiseInfoDiff] = useState(false);
    const [deletedGuests, setDeletedGuests] = useState([]);
    const [isBookingError, setIsBookingError] = useState(false);
    const [bookingErrorCode, setBookingErrorCode] = useState();
    const [bookingErrorTitle, setBookingErrorTitle] = useState();
    const [bookingErrorMessage, setBookingErrorMessage] = useState();

    const { fetchCarnivalBookingDetailsFromProcessInstance, getBookingErrorContent } = useCruiseLine();

    const closeModal = () => {
        setShowModal(false);
    };

    const closeRemovedGuestsModal = () => {
        setShowRemovedGuestsModal(false);
    };

    const renderDetailsInfo = (infoCode, oldInfo, newInfo) => {
        return oldInfo
            ? `${infoCode} has changed from ${oldInfo} to ${newInfo}`
            : `${infoCode} was updated to ${newInfo}`;
    };

    const tripSummaryDiff = (cruiseInfo, cruiseDetails) => {
        let isDiff = false;
        let showModal = false;
        const newCruiseInfo = [];

        const {
            durationDays: carnevalDurationDays,
            sailDate,
            shipCode: carnevalShipCode,
            shipName: carnevalShipName,
            voyageNumber: carnevalVoyageNumber,
            embarkPortCode: carnevalEmbarkPortCode,
            inPortTesting: carnevalInPortTesting
        } = cruiseInfo;
        const {
            durationDays: capDurationDays,
            embarkationDate,
            shipCode: capShipCode,
            shipName: capShipName,
            voyageNumber: capVoyageNumber,
            embarkPortCode: capEmbarkPortCode,
            inPortTesting: capInPortTesting
        } = cruiseDetails;

        const areDifferent = (prop1, prop2) => {
            if ((prop1 === null || prop1 === undefined) && (prop2 === null || prop2 === undefined)) return false;
            return prop1 !== prop2;
        };

        if (areDifferent(sailDate, embarkationDate)) {
            processInstance.userData.sharedData.cruiseDetails.embarkationDate = sailDate;
            newCruiseInfo.push(renderDetailsInfo(INFO_DETAILS.embarkationDate, embarkationDate, sailDate));
            isDiff = true;
            showModal = true;
        }

        if (areDifferent(carnevalShipCode, capShipCode)) {
            processInstance.userData.sharedData.cruiseDetails.shipCode = carnevalShipCode;
            setGeneralCruiseInfoDiff(true);
            isDiff = true;
            showModal = true;
        }

        if (areDifferent(carnevalShipName, capShipName)) {
            processInstance.userData.sharedData.cruiseDetails.shipName = carnevalShipName;
            newCruiseInfo.push(renderDetailsInfo(INFO_DETAILS.shipName, capShipName, carnevalShipName));
            isDiff = true;
            showModal = true;
        }

        if (areDifferent(carnevalVoyageNumber, capVoyageNumber)) {
            processInstance.userData.sharedData.cruiseDetails.voyageNumber = carnevalVoyageNumber;
            setGeneralCruiseInfoDiff(true);
            isDiff = true;
            showModal = true;
        }

        if (areDifferent(carnevalDurationDays, capDurationDays)) {
            processInstance.userData.sharedData.cruiseDetails.durationDays = carnevalDurationDays;
            newCruiseInfo.push(renderDetailsInfo(INFO_DETAILS.durationDays, capDurationDays, carnevalDurationDays));
            isDiff = true;
            showModal = true;
        }
        
        if (areDifferent(carnevalEmbarkPortCode, capEmbarkPortCode)) {
            processInstance.userData.sharedData.cruiseDetails.embarkPortCode = carnevalEmbarkPortCode;
            setGeneralCruiseInfoDiff(true);
            isDiff = true;
            showModal = true;
        }

        if (areDifferent(carnevalInPortTesting, capInPortTesting)) {
            processInstance.userData.sharedData.cruiseDetails.inPortTesting = carnevalInPortTesting;
            setGeneralCruiseInfoDiff(true);
            isDiff = true;
            showModal = true;
        }

        if (areDifferent(carnevalEmbarkPortCode, capEmbarkPortCode)) {
            processInstance.userData.sharedData.cruiseDetails.embarkPortCode = carnevalEmbarkPortCode;
            setGeneralCruiseInfoDiff(true);
            isDiff = true;
            showModal = true;
        }

        if (areDifferent(carnevalInPortTesting, capInPortTesting)) {
            processInstance.userData.sharedData.cruiseDetails.inPortTesting = carnevalInPortTesting;
            setGeneralCruiseInfoDiff(true);
            isDiff = true;
            showModal = true;
        }

        setCruiseInfoDiff(newCruiseInfo);
        return { isDiff, showModal };
    };

    const matchGuest = (guest, existingUserInfo) =>
        guest.firstName?.toUpperCase() === existingUserInfo?.firstName?.toUpperCase() &&
        guest.lastName?.toUpperCase() === existingUserInfo?.lastName?.toUpperCase();

    const processGuestListResponse = guestListResponse => {
        if (guestListResponse.text) {
            setError({ message: guestListResponse.text });
        }
        const { guests, ...cruiseInfo } = guestListResponse;
        const allGuestIds = guests.map(g => g.patientId);

        const { userSpecificData, sharedData } = processInstance.userData;
        const { cruiseDetails } = sharedData;
        const delGuest = [];
        let updatedPatientIds = false;

        Object.keys(userSpecificData).forEach(user => {
            const existingUserInfo = userSpecificData[user];
            if (!existingUserInfo.userPatientId) {
                const newGuestInfo = guests.find(g => matchGuest(g, existingUserInfo));
                if (newGuestInfo && newGuestInfo.patientId) {
                    processInstance.userData.userSpecificData[user].userPatientId = newGuestInfo.patientId;
                    updatedPatientIds = true;
                }
            } else if (!allGuestIds.includes(existingUserInfo.userPatientId)) {
                delGuest.push(`${existingUserInfo.firstName} ${existingUserInfo.lastName}`);
                delete processInstance.userData.userSpecificData[user];
            }
        });

        setDeletedGuests(delGuest);

        const { showModal, isDiff } = tripSummaryDiff(cruiseInfo, cruiseDetails);

        const shouldShowModal = delGuest.length !== 0 || showModal;
        if (isDiff || updatedPatientIds) {
            const url = `${submissionHref}/processInstances/${processInstance.id}/persons/${userId}`;

            const processCapResponse = capResponse => {
                setProcessInstance({ ...capResponse, carneval: true });
                processDataFromCapServer(setError)(capResponse);
                setShowRemovedGuestsModal(shouldShowModal);
                setLoading(false);
            };

            postReturnJson(url, processInstance, processCapResponse, setError);
        } else {
            setLoading(false);
        }
    };

    const getGuestList = () => {
        const handleBookingError = error => {
            const { title, message } = getBookingErrorContent(error);
            setBookingErrorCode(error?.code);
            setBookingErrorTitle(title);
            setBookingErrorMessage(message);
            setIsBookingError(true);
            setLoading(false);
        };
        fetchCarnivalBookingDetailsFromProcessInstance(processInstance, processGuestListResponse, handleBookingError);
    };

    useEffect(() => {
        if (getGuests) {
            getGuestList();
        }
    }, [getGuests]);

    const getProcessInstance = () => {
        const processInstanceId = query.get("extId");
        const instanceUrl = `${submissionHref}/processInstances/${processInstanceId}/persons/${userId}`;

        const processProcessInstanceResponse = response => {
            setProcessInstance(response);
            processDataFromCapServer(setError)(response);
            setGetGuests(true);
        };

        getReturnJson(instanceUrl, null, processProcessInstanceResponse, setError);
    };

    useEffect(() => {
        getProcessInstance();
    }, []);

    const cancelTrip = () => {
        setShowModal(false);

        const processInstanceId = query.get("extId");
        const cancellationUrl = `${submissionHref}/processInstances/${processInstanceId}/persons/${userId}/cancel`;

        postReturnJson(cancellationUrl, processInstance, processDataFromCapServer(setError, true), setError);
    };

    const showCancelConfirmation = () => {
        const { eventDetails } = processInstance?.userData.sharedData;
        const messageId = eventDetails
            ? "manageTrip.cancelEvent.confirmMessage"
            : "manageTrip.cancelTrip.confirmMessage";
        setModalMessage(localize(messageId));
        setShowModal(true);
    };

    const getModalBgColor = () => {
        return { backgroundColor: theme.forms.backgroundSolid };
    };

    const Menu = () => {
        const { eventDetails } = processInstance?.userData.sharedData;
        const messageId = eventDetails ? "global.cancel" : "global.cancelTrip";

        return <div onClick={showCancelConfirmation}>{localize(messageId)}</div>;
    };

    const RemovedGuestsModal = () => (
        <Modal show={showRemovedGuestsModal} onHide={closeRemovedGuestsModal}>
            <Modal.Header style={getModalBgColor()}>
                <Modal.Title>{localize("carnivalcruise.manageTrip.modalTitle")}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={getModalBgColor()}>
                {deletedGuests.length !== 0 ? (
                    <div>
                        <div className="justify-content-center">
                            {localize("carnivalcruise.manageTrip.modal.guestRemoved")}
                        </div>
                        {deletedGuests.map(guest => (
                            <div>{guest}</div>
                        ))}
                    </div>
                ) : (
                    ""
                )}
                {cruiseInfoDiff.length !== 0 ? (
                    <div>
                        <div className="justify-content-center">
                            {localize("carnivalcruise.manageTrip.modal.cruiseInfoUpdate")}
                        </div>
                        {cruiseInfoDiff.map(info => (
                            <div key={info}>{info}</div>
                        ))}
                    </div>
                ) : (
                    ""
                )}
                {generalCruiseInfoDiff ? (
                    <div>
                        <div className="justify-content-center">
                            {localize("carnivalcruise.manageTrip.modal.cruiseInfoGeneralUpdate")}
                        </div>
                    </div>
                ) : (
                    ""
                )}
            </Modal.Body>
            <Modal.Footer style={getModalBgColor()}>
                <Button variant="secondary" onClick={closeRemovedGuestsModal}>
                    {localize("global.close")}
                </Button>
            </Modal.Footer>
        </Modal>
    );

    const ConfirmationModal = ({ title, message }) => {
        return (
            <Modal show={showModal} onHide={closeModal}>
                {title && (
                    <Modal.Header style={getModalBgColor()}>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                )}
                <Modal.Body style={getModalBgColor()}>
                    <p>{message}</p>
                </Modal.Body>
                <Modal.Footer style={getModalBgColor()}>
                    <Button variant="secondary" onClick={closeModal}>
                        {localize("global.cancel")}
                    </Button>
                    <Button variant="primary" onClick={cancelTrip}>
                        {localize("global.confirm")}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };

    return (
        <LoadingWrapper fullPage isLoading={loading}>
            <div className="manage-trip">
                <ConfirmationModal message={modalMessage} />
                <RemovedGuestsModal />
                <BookingErrorWrapper
                    show={isBookingError}
                    errorCode={bookingErrorCode}
                    title={bookingErrorTitle}
                    message={bookingErrorMessage}
                >
                    <FormPage header={useHeaderTitle()} headerMenu={Menu}>
                        <TripSummary />
                        <PassengerList />
                        <Link to={`/manageguests${location.search}`}>
                            <Button type="button" className="w-100">
                                {localize("carnivalcruise.manageTrip.guestList")}
                            </Button>
                        </Link>
                    </FormPage>
                </BookingErrorWrapper>
            </div>
        </LoadingWrapper>
    );
}
