import { useContext, useEffect, useState } from "react";
import CredentialContext from "../../config/CredentialContext";
import { getReturnJson } from "../dataFetching";

const useFormConfig = () => {

    const { submissionHref, updateLoading } = useContext(CredentialContext);

    const [config, setConfig] = useState({});

    useEffect(() => {
        updateLoading("config", true);
        const url = `${submissionHref}/config`;
        const handleResponse = c => {
            setConfig(c || {});
            updateLoading("config", false);
        }
        const handleError = () => updateLoading("config", false);
        getReturnJson(url, null, handleResponse, handleError);
    }, [])

    return config;
};

export default useFormConfig;