import { countries } from "../config/countries";
import { worldCountries } from "../config/world-countries";

export const getCountry = view => {
    const parts = view.split(":");
    return parts.length <= 1 ? "" : Object.keys(countries).find(key => countries[key].code === parts[1].toUpperCase());
};

export const getFullCountryNameFrom3LetterCode = (letter3Code, language) =>
    worldCountries.find(worldCountry => worldCountry?.alpha3 === letter3Code)?.name[language];

export const getFullCountryNameFrom2LetterCode = (letter2Code, language) =>
    worldCountries.find(worldCountry => worldCountry?.alpha2 === letter2Code)?.name[language];

export const getTwoLeterCodeWithThreeLetterCode = (letter3Code) => {
    return worldCountries.find((wC) => wC.alpha3 === letter3Code)?.alpha2;
}
export const getThreeLeterCodeWithTwoLetterCode = (letter2Code) => {
    return worldCountries.find((wC) => wC.alpha2 === letter2Code)?.alpha3;
}