import { Button } from "react-bootstrap";
import { AddIcon } from "../../icons/AddIcon";
import CrossedEyeIcon from "../../icons/CrossedEyeIcon";
import { ErrorIcon } from "../../icons/ErrorIcon";
import EyeIcon from "../../icons/EyeIcon";
import { InfoIcon } from "../../icons/InfoIcon";
import RemoveIcon2 from "../../icons/RemoveIcon2";

const IconButton = ({ onClick, children, btnClassName = "", iconType = "info" }) => {
    const renderIcon = () => {
        switch (iconType) {
            case "warning":
                return <ErrorIcon customClass="mr-2" size="20" />;
            case "eye":
                return <EyeIcon customClass="mr-2" size="15" colorClass="secondary-info-icon" />;
            case "crossedEye":
                return <CrossedEyeIcon customClass="mr-2" width="22" colorClass="secondary-info-icon" />;
            case "add":
                return <AddIcon customClass="mr-2" size="18" colorClass="secondary-info-icon-stroke" />;
            case "remove":
                return <RemoveIcon2 customClass="mr-2" size="22" colorClass="error-icon" />;
            default:
                return <InfoIcon customClass="mr-2" size="20px" colorClass="secondary-info-icon" />;
        }
    };

    return (
        <Button
            className={`w-100 d-flex justify-content-center align-items-center btn ${
                iconType === "warning" || iconType === "remove" ? "btn-outline-danger" : "btn-outline-info-secondary"
            } mb-2 text-90 ${btnClassName}`}
            onClick={onClick}
        >
            {renderIcon()}
            {children}
        </Button>
    );
};

export default IconButton;
