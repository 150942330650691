import React, { useContext, useEffect, useState } from "react";
import { Card, Modal, Button } from "react-bootstrap";
import FormPage from "../../_components/FormPage";
import LoadingWrapper from "../../_components/Wrappers/LoadingWrapper";
import CredentialContext from "../../../config/CredentialContext";
import { useLocalize, useCap, useQuery, useHeaderTitle, useUser, useLanguage } from "../../../utils/hooks";
import { getReturnJson, postReturnJson } from "../../../utils/dataFetching";
import { getBaseUrl } from "../../../utils/textUtils";
import { closeForm, processDataFromCapServer } from "../../../utils/dataProcessing";
import AddSavedCompanionForm from "../AddSavedCompanionForm"
import AddCompanions from "../AddCompanions";
import FlightBlockItem from "./FlightBlockItem";
import CloseIcon from "../../../icons/CloseIcon";
import DeleteCompanionModal from "./DeleteCompanionModal";
import CompanionCountryOfResidenceModal from '../CompanionCountryOfResidenceModal';
import Advert from '../../_components/Advert';
import { datadogRum } from '@datadog/browser-rum';
import { REDIRECT } from '../../../utils/hooks/useCap';

const ManageInterlineTrip = () => {
    const query = useQuery();
    const { localize } = useLocalize();
    const { localizeField } = useLanguage();
    const { fetchProcessInstance, updateProcessInstance } = useCap(REDIRECT.NONE);
    const {
        processInstance,
        credentialTypeHref,
        userId,
        loading,
        setError, 
        submissionHref,
    } = useContext(CredentialContext);
    const { checkAndUpdateUser, isUserDataBeingFetched } = useUser(userId, setError);

    const [savedCompanions, setSavedCompanions] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setDeleteShowModal] = useState(false);
    const [deleteCompanionId, setDeleteCompanionId] = useState('');
    const [showModalForCountryOfResidence, setShowModalForCountryOfResidence] = useState(false);
    const [areCompanionsLoading, setAreCompanionsLoading] = useState(true);

    const shouldDisableAddCompanion = () => {
        if (processInstance) {
            const maxPassengers = (processInstance.instructions?.maxCompanions ?? 9) + 1;
            const passengerCount = Object.keys(processInstance.userData.userSpecificData).length;

            return passengerCount >= maxPassengers;
        } else {
            return false;
        }
    };

    const allowCompanions = processInstance?.instructions?.allowCompanions ?? false;

    useEffect(() => {
        fetchProcessInstance();

        const getCompanionsUrl = getBaseUrl(credentialTypeHref) + "/api/persons/" + userId + "/companions";

        const handleResponse = response => {
            setSavedCompanions(response);
            setAreCompanionsLoading(false);
        };

        getReturnJson(getCompanionsUrl, null, handleResponse, () => setError("Error fetching companions"));
    }, []);

    const mainUserData = processInstance?.userData?.userSpecificData?.[userId];

    const mainUserCountryOfResidenceSubmit = async (enteredCountryOfResidence) => {
        await checkAndUpdateUser({ countryOfResidence: enteredCountryOfResidence })

        mainUserData.countryOfResidence = enteredCountryOfResidence;

        await updateProcessInstance(processInstance);
    };

    useEffect(() => {
        if (mainUserData && !mainUserData?.countryOfResidence) {
            setShowModalForCountryOfResidence(true);
        }
    }, [mainUserData]);

    useEffect(() => {
        if (processInstance) {
            const { bookingIdentifier, carrierCode } = processInstance.userData.sharedData.interlineFlightDetails;
            datadogRum.setUser({ bookingIdentifier, carrierCode });
        }
    }, [processInstance]);

    const renderFlightBlocks = (companionId) => {
        const flightBlock = processInstance.userData.sharedData.interlineFlightDetails.flightBlocks;

        return (
            flightBlock.map((block, i) => (
                <Card.Body key={i}>
                    <FlightBlockItem
                        blockId={block.id}
                        companionId={companionId}
                    />
                </Card.Body>
            ))
        );
    };

    const showDeleteCompanionModal = (key) => {
        setDeleteCompanionId(key)
        setDeleteShowModal(true);
    };

    const closeDeleteCompanionModal = () => {
        setDeleteCompanionId('');
        setDeleteShowModal(false);
    };

    const showConfirmationModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const cancelTrip = () => {
        const processInstanceId = query.get("extId");
        const cancellationUrl = `${submissionHref}/processInstances/${processInstanceId}/persons/${userId}/cancel`;

        postReturnJson(cancellationUrl, processInstance, processDataFromCapServer(setError, true), setError);
        setShowModal(false);
    };

    const ConfirmationModal = () => {
        return (
            <Modal centered show={showModal} onHide={closeModal}>
                <Modal.Body>
                    <p>{localize("manageTrip.cancelTrip.confirmMessage")}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
                        {localize("global.cancel")}
                    </Button>
                    <Button variant="primary" onClick={cancelTrip}>
                        {localize("global.confirm")}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };

    const headerMenu = () => <div onClick={showConfirmationModal}>{localize("global.cancelTrip")}</div>

    const userSpecificData = processInstance?.userData?.userSpecificData;

    return (
        <>
            <ConfirmationModal />
            <DeleteCompanionModal
                companionId={deleteCompanionId}
                showModal={showDeleteModal}
                closeModal={closeDeleteCompanionModal}
            />
            <CompanionCountryOfResidenceModal
                onShowModal={showModalForCountryOfResidence}
                firstName={mainUserData?.firstName}
                lastName={mainUserData?.lastName}
                onCloseModal={setShowModalForCountryOfResidence}
                handleSubmit={mainUserCountryOfResidenceSubmit}
                exitApp={closeForm}
                userType="main"
                loadingProp="submit"
            />
            <LoadingWrapper fullPage isLoading={!processInstance || isUserDataBeingFetched || loading.submit || areCompanionsLoading}>
                <div className="manage-interlie-trip layout-with-at">
                    <div>
                        <FormPage header={useHeaderTitle()} headerMenu={headerMenu}>
                            {processInstance && Object.entries(userSpecificData)?.map(([key, value]) => (
                                <Card key={key}>
                                    <div className="d-flex flex-row justify-content-center px-2 pt-3 pb-2 h5">
                                        <div className="m-auto text-break">{`${value.firstName} ${value.lastName}`}</div>
                                        {key !== userId
                                            ? <div onClick={() => showDeleteCompanionModal(key)}>
                                                <CloseIcon />
                                            </div>
                                            : ''
                                        }
                                    </div>
                                    {renderFlightBlocks(key)}
                                </Card>
                            ))}
                            {allowCompanions && (
                                <>
                                    <AddCompanions
                                        disabled={shouldDisableAddCompanion()}
                                        addCompanionText={localizeField(processInstance?.instructions?.companionText?.body) || localize("manageTrip.addCompanion.text")}
                                    />
                                    {shouldDisableAddCompanion() && (
                                        <p className="pt-3 mb-0 text-90 font-weight-bold">
                                            {localize("manageTrip.addCompanion.maximumNumber.text")}
                                        </p>
                                    )}
                                </>
                            )}
                        </FormPage>
                        {allowCompanions && (
                            <FormPage>
                                <AddSavedCompanionForm companions={savedCompanions} disabled={shouldDisableAddCompanion()} />
                            </FormPage>
                        )}
                    </div>
                    <Advert />
                </div>
            </LoadingWrapper>
        </>
    );
};

export default ManageInterlineTrip;
