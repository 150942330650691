import BookingDateField from "./BookingDateField";
import BookingTextField from "./BookingTextField";

const CustomBookingField = props => {
    switch (props.type) {
        case "date":
            return <BookingDateField {...props} />;
        case "text":
            return <BookingTextField {...props} />;
        default:
            return <BookingTextField {...props} />;
    }
};

export default CustomBookingField;
