import { DateTime } from "luxon";
import { REVIEW_STATUS } from "../constants";
import useUserDataPath from "./useUserDataPath";

const useDocumentReview = processInstance => {
    const { getUserDataPath } = useUserDataPath(processInstance);
    const userDataPath = getUserDataPath();

    const userSubmittedDocuments = userDataPath?.submittedDocuments;

    const getStepDocumentType = step => userSubmittedDocuments?.[step?.view?.parameters?.documentType];

    const getDocumentSubmissionStatus = step => {
        const documentSubmissionStatus = getStepDocumentType(step)?.submissionStatus?.toUpperCase();

        return documentSubmissionStatus;
    };

    const getReviewData = docType => {
        if (docType) {
            const typeReviews = userSubmittedDocuments?.[docType]?.submissions;
            const typeReviewExists = typeReviews?.length > 0;
            const activeTypeReview = typeReviewExists
                ? typeReviews?.find((typeReview, _, array) => {
                      return DateTime.fromISO(typeReview?.submitted)?.equals(
                          DateTime.max(...array.map(tr => DateTime.fromISO(tr?.submitted)))
                      );
                  })
                : undefined;
            const activeTypeReviewStatus = typeReviewExists
                ? activeTypeReview?.review?.status || REVIEW_STATUS.PENDING
                : undefined;
            const activeTypeReviewDate = activeTypeReview?.submitted;
            const activeTypeReviewNote = activeTypeReview?.review?.note?.message || "";
            return {
                typeReviewExists,
                activeTypeReview,
                activeTypeReviewStatus,
                activeTypeReviewDate,
                activeTypeReviewNote,
            };
        }
        return {};
    };

    const documentSubmissionStatus = userDataPath?.documentSubmissionStatus;

    return {
        userSubmittedDocuments,
        getStepDocumentType,
        getDocumentSubmissionStatus,
        getReviewData,
        documentSubmissionStatus,
    };
};

export default useDocumentReview;
