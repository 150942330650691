import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { useForm, FormProvider } from "react-hook-form";
import { useCap, useLanguage, useLocalize, useQuery, useUser } from "../../utils/hooks";
import { Button, Form, Row } from "react-bootstrap";
import CredentialContext from "../../config/CredentialContext";
import UnapprovedPoliciesWrapper from "../_components/Wrappers/PrivacyPolicyWrapper/UnapprovedPoliciesWrapper";
import FormPage from "../_components/FormPage";
import MarkdownText from "../_components/MarkdownText";
import LoadingWrapper from "../_components/Wrappers/LoadingWrapper";
import EventDetailsInputs from "./EventDetailsInputs";
import { setDateTimeZone } from "../../utils/dateTimeUtils";
import { REDIRECT } from "../../utils/hooks/useCap";
import { getThreeLeterCodeWithTwoLetterCode } from "../../utils/countryUtils";

const EventDetails = () => {
    const methods = useForm();
    const { userId, appVersion, globalConfig, loading, updateLoading, setError } = useContext(CredentialContext);
    const { createProcessInstance } = useCap(REDIRECT.MANAGE_TRIP);
    const query = useQuery();
    const { localize } = useLocalize();
    const { localizeFieldOrDefault } = useLanguage();

    const {
        extendedBookingFields = [],
        policies: configPolicies = null,
        eventDetailsHeader = {},
        eventDetailsNote = {},
        eventDetailsLegend = {},
    } = globalConfig;

    const {
        user,
        glideNationality,
        glidePassportCountry,
        glideCountryOfResidence,
        glideReferenceNumbers,
        checkAndUpdateUser,
    } = useUser(userId, setError);

    const eventCode = query.get("eventCode");

    const getPolicies = () => {
        if (configPolicies) {
            return configPolicies;
        }
    };

    const trimValues = (obj, prop) => (obj[prop] = obj[prop]?.trim());
    const convertStringToBoolean = (obj, prop) =>
        (obj[prop] = ["yes", "true"].includes(obj[prop].toString().toLowerCase()));
    const formatDate = (obj, prop) => (obj[prop] = setDateTimeZone(obj[prop]));
    const convertStringToNumber = (obj, prop) => (obj[prop] = parseInt(obj[prop], 10));

    const onSubmit = event => {
        const submittedValues = { ...event };

        extendedBookingFields
            .filter(bF => submittedValues.hasOwnProperty(bF.name))
            .forEach(bF => trimValues(submittedValues, bF.name));

        extendedBookingFields
            .filter(bF => submittedValues.hasOwnProperty(bF.name) && bF.convertToBoolean)
            .forEach(bF => convertStringToBoolean(submittedValues, bF.name));

        extendedBookingFields
            .filter(bF => submittedValues.hasOwnProperty(bF.name) && bF.type === "date")
            .forEach(bF => formatDate(submittedValues, bF.name));

        extendedBookingFields
            .filter(bF => submittedValues.hasOwnProperty(bF.name) && bF.type === "number")
            .forEach(bF => convertStringToNumber(submittedValues, bF.name));

        const eventDetailsInputs = extendedBookingFields?.reduce((acc, curr) => {
            if (curr.saveTo === "eventDetails" && (submittedValues[curr.name] || submittedValues[curr.name] === 0)) {
                acc[curr.name] = submittedValues[curr.name];
            }

            return acc;
        }, {});

        const eventDetailsDetailsInputs = extendedBookingFields?.reduce((acc, curr) => {
            if (
                curr.saveTo === "eventDetails.details" &&
                (submittedValues[curr.name] || submittedValues[curr.name] === 0)
            ) {
                acc[curr.name] = submittedValues[curr.name];
            }

            return acc;
        }, {});

        const userSpecificDataInputs = extendedBookingFields?.reduce((acc, curr) => {
            if (
                curr.saveTo === "userSpecificData" &&
                (submittedValues[curr.name] || submittedValues[curr.name] === 0)
            ) {
                acc[curr.name] = submittedValues[curr.name];
            }

            return acc;
        }, {});

        const userSpecificDataParametersInputs = extendedBookingFields?.reduce((acc, curr) => {
            if (
                curr.saveTo === "userSpecificData.parameters" &&
                (submittedValues[curr.name] || submittedValues[curr.name] === 0)
            ) {
                acc[curr.name] = submittedValues[curr.name];
            }

            return acc;
        }, {});

        const capPayload = {
            userData: {
                sharedData: {
                    appVersion,
                    eventDetails: {
                        ...(eventCode ? { eventCode } : {}),
                        ...eventDetailsInputs,
                        ...(Object.keys(eventDetailsDetailsInputs).length
                            ? {
                                  details: { ...eventDetailsDetailsInputs },
                              }
                            : {}),
                    },
                },
                userSpecificData: {
                    [userId]: {
                        ...userSpecificDataInputs,
                        passportCountry:
                            userSpecificDataInputs?.passportCountry ||
                            getThreeLeterCodeWithTwoLetterCode(userSpecificDataInputs?.nationality),
                        ...(Object.keys(userSpecificDataParametersInputs).length
                            ? {
                                  parameters: {
                                      ...userSpecificDataParametersInputs,
                                  },
                              }
                            : {}),
                    },
                },
            },
        };

        const reference = submittedValues?.referenceNumber
            ? `${eventCode}:${submittedValues.referenceNumber}`
            : eventCode;

        updateLoading("submit", true);
        checkAndUpdateUser({
            firstName: submittedValues?.firstName,
            lastName: submittedValues?.lastName,
            birthDate: submittedValues?.dateOfBirth,
            nationality: submittedValues?.nationality,
            passportCountry:
                submittedValues?.passportCountry || getThreeLeterCodeWithTwoLetterCode(submittedValues?.nationality),
            countryOfResidence: submittedValues?.countryOfResidence,
            reference,
        });
        createProcessInstance(capPayload);
    };

    return (
        <UnapprovedPoliciesWrapper policies={getPolicies()}>
            <FormPage header={localizeFieldOrDefault(eventDetailsHeader, localize("eventDetails.header"))}>
                <FormProvider {...methods}>
                    <Form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
                        <MarkdownText>
                            {localizeFieldOrDefault(eventDetailsNote, localize("eventDetails.note"))}
                        </MarkdownText>
                        <fieldset disabled={loading.submit}>
                            <legend>
                                {localizeFieldOrDefault(eventDetailsLegend, localize("eventDetails.trip.legend"))}
                            </legend>
                            <EventDetailsInputs
                                bookingFields={extendedBookingFields}
                                methods={methods}
                                user={user}
                                glideReferenceNumbers={glideReferenceNumbers}
                                glideNationality={glideNationality}
                                glidePassportCountry={glidePassportCountry}
                                glideCountryOfResidence={glideCountryOfResidence}
                            />
                        </fieldset>
                        <Row className="justify-content-center">
                            <Button type="submit" className="w-100 mx-3" disabled={loading.submit}>
                                <LoadingWrapper isLoading={loading.submit}>
                                    <FormattedMessage id="global.submit" />
                                </LoadingWrapper>
                            </Button>
                        </Row>
                    </Form>
                </FormProvider>
            </FormPage>
        </UnapprovedPoliciesWrapper>
    );
};

export default EventDetails;
