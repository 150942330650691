import { useFormContext, Controller } from "react-hook-form";
import { Form } from "react-bootstrap";
import React from "react";
import { resolveObjectPath } from "../../../utils/formUtils";
import CustomSwitch from "../CustomSwitch";
import { useLanguage } from "../../../utils/hooks";

const CustomSwitchInput = ({ input, isComplete }) => {
    const { name, label, switchLabel, info, infoHtml, required, invalidMessage } = input;

    const { errors, control } = useFormContext();
    const getError = resolveObjectPath(errors);

    const { localizeField } = useLanguage();

    return (
        <Form.Group>
            <Controller
                id={name}
                control={control}
                name={name}
                defaultValue={false}
                required={required}
                rules={{ validate: value => !required || value }}
                render={({ onChange, ref }) => (
                    <CustomSwitch
                        control={name}
                        switchName={name}
                        label={localizeField(label)}
                        switchLabel={localizeField(switchLabel)}
                        switchInfo={localizeField(info)}
                        switchInfoHtml={localizeField(infoHtml)}
                        onChecked={e => onChange(e.target.checked)}
                        switchRef={ref}
                        disabled={isComplete}
                    />
                )}
            />
            {getError(name)?.type === "validate" && (
                <Form.Text className="text-required">{localizeField(invalidMessage)}</Form.Text>
            )}
        </Form.Group>
    );
};

export default CustomSwitchInput;
