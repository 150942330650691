export const airlineActions = {
    SET_AIRLINES: "setAirlines",
    SET_AIRLINE: "setAirline",
};

export const personalActions = {
    SET_FIRST_NAME: "setFirstName",
    SET_MIDDLE_NAME: "setMiddleName",
    SET_LAST_NAME: "setLastName",
    SET_BIRTH_DATE: "setBirthDate",
    SET_NATIONALITY: "setNationality",
    SET_COUNTRY_OF_RESIDENCE: "setCountryOfResidence",
    SET_VISA_PURPOSE: "setVisaPurpose",
};

export const flightActions = {
    SET_BOOKING_REF: "setBookingRef",
    SET_SUPPORTED_COUNTRIES: "setSupportedCountries",
    SET_FLIGHT_SEGMENTS: "setFlightSegments",
    RESET_FLIGHT_DETAILS: "resetFlightDetails",
    ADD_FLIGHT_SEGMENT: "addFlightSegment",
    REMOVE_FLIGHT_SEGMENT: "removeFlightSegment",
};

export const bookingRefRules = {
    NO_PNR: {
        AIRLINES_WITH_REQUIRED_BOOKING_REF: ["EI", "AA"],
        AIRLINES_NOT_USING_BOOKING_REF: ["TJ"],
    },
};

export const bookingRefPatterns = {
    EI: "((?![019Oo])[0-9a-zA-Z]){6}",
    AA: "[a-zA-Z]{6}",
    BA: "((?![01])[0-9a-zA-Z]){6}",
};

export const INVALID_REASON = {
    defaultMessage: "noAcceptedFlightSegments",
    AIRLINE_NOT_SUPPORTED: "arlineNotSupported",
    ROUTE_NOT_SUPPORTED: "routeNotSupported",
    TRIP_NOT_SUPPORTED: "tripNotSupported",
    "24": "noSupportedPastFlight",
    "283": "noAcceptedFlightSegments",
    "508": "noSupportedFlightSegments",
    "700": "noSupportedFlight",
};
