export const REVIEW_STATUS = {
    ACCEPTED: "ACCEPTED",
    PENDING: "PENDING",
    REJECTED: "REJECTED",
    NO_LONGER_ACCEPTABLE: "NO_LONGER_ACCEPTABLE",
    SUBMITTED: "SUBMITTED",
    VISA_NOT_REQUIRED: "VISA_NOT_REQUIRED",
    VISA_CONDITIONAL: "VISA_CONDITIONAL",
};

export const STEP_STATUS = {
    REQUIRED: "REQUIRED",
    OPTIONAL: "OPTIONAL",
    PENDING: "PENDING",
    COMPLETE: "COMPLETE",
    REJECTED: "REJECTED"
};

export const STATUS_FOR = {
    COVID_TEST: "covidTests",
    COVID19_VACCINE: "covidVaccines",
    VISA: ['visas', 'passports'],
    PASSPORT: "passports"
};

export const HEALTH_PASS_TYPE = {
    VACCINE: "VACCINE",
    TEST: "TEST",
    RECOVERY: "RECOVERY",
};

export const PRESENTATION_TYPES = {
    DIGITAL_QR: "DIGITAL_QR",
    DIGITAL_UPLOAD: "DIGITAL_UPLOAD",
    PAPER: "PAPER",
    VERIFLY_ISSUED_CREDENTIAL_BASED_ON_PAPER: "VERIFLY_ISSUED_CREDENTIAL_BASED_ON_PAPER",
};

export const MANUAL_PRESENTATION_TYPES = [
    PRESENTATION_TYPES.PAPER,
    PRESENTATION_TYPES.VERIFLY_ISSUED_CREDENTIAL_BASED_ON_PAPER,
];

export const MOCKED_ARRIVAL_DATA = {
    none: "XX",
    airportCode: "NON",
    flightNo: "9999",
    bookRef: "arrivals",
};

export const submissionHref = "http://submissionHref";
export const credentialTypeHref = "http://credentialTypeHref";
export const medicalHref = "http://medicalHref";
export const language = "en";
export const email = "ndra@mdra.com";
export const firstName = "Petar";
export const lastName = "Dragi%C4%87";
export const personId = "GUID1";
export const companionId = "GUID1";
export const correlationId = "KVYEHWBGU6NONYVTIMLQ6UCWOA";
export const devReturnTrue = process.env.NODE_ENV === "development";
export const tripHost = "http://localhost:3000";
