export const BallotBoxIcon = ({ size, customClass }) => (
    <svg
        className={"svg-icon " + (customClass ? customClass : "")}
        viewBox="0 0 24 24"
        width={size ? size : "24px"}
        height={size ? size : "24px"}
        fill="none"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
    >
        <g>
            <polyline className="ballot-box-checked" points="9 11 12 14 22 4" />
            <path className="ballot-box-checked" d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11" />
            <rect className="ballot-box-unchecked" x="3" y="3" width="18" height="18" rx="2" ry="2" />
        </g>
    </svg>
);
