import { DateTime } from "luxon";
import { useContext, useEffect, useState } from "react";
import CredentialContext from "../../config/CredentialContext";
import { getReturnJson } from "../dataFetching";
import { getBaseUrl } from "../textUtils";
import useQuery from "./useQuery";

const usePerson = (personId, departure, onError) => {
    const query = useQuery();
    const { userId, processInstance } = useContext(CredentialContext);

    const [person, setPerson] = useState({});

    const getGlideUrl = () => {
        const cth = query.get("credentialTypeHref");
        return `${getBaseUrl(cth)}/api/persons/${userId}`;
    };

    const getCompanionsUrl = () => `${getGlideUrl()}/companions`;

    const getAge = dateOfBirth => {
        const departureDateTime = DateTime.fromISO(departure);
        const birthDateTime = DateTime.fromISO(dateOfBirth);
        const userAge = departureDateTime.diff(birthDateTime, "years").years;
        return { yearsOfAge: userAge, isMinor: userAge < 18 };
    };
    useEffect(() => {
        if (personId) {
            const isPrimaryPassenger = userId === personId;
            const updatePerson = (fName, lName, dateOfBirth, nationality) =>
                setPerson({
                    fName,
                    lName,
                    fullName: `${fName} ${lName}`,
                    dateOfBirth,
                    nationality,
                    isPrimaryPassenger,
                    ...getAge(dateOfBirth),
                });
            if (isPrimaryPassenger) {
                const handleResponse = ({ firstName, lastName, extendedAttributes }) => {
                    const { dateOfBirth, nationality } = extendedAttributes;
                    updatePerson(firstName, lastName, dateOfBirth, nationality);
                };
                getReturnJson(getGlideUrl(), null, handleResponse, onError);
            } else {
                const handleResponse = companions => {
                    const companion = companions.find(c => c.id === personId);
                    if (companion) {
                        const {
                            firstName,
                            lastName,
                            privateInfo: {
                                extendedAttributes: { dateOfBirth, nationality },
                            },
                        } = companion;
                        updatePerson(firstName, lastName, dateOfBirth, nationality);
                    } else {
                        const {
                            firstName,
                            lastName,
                            dateOfBirth,
                            nationality,
                        } = processInstance?.userData.userSpecificData[personId];
                        updatePerson(firstName, lastName, dateOfBirth, nationality);
                    }
                };
                getReturnJson(getCompanionsUrl(), null, handleResponse, onError);
            }
        }
    }, [personId]);

    return person;
};

export default usePerson;
