import {
    CUSTOM_FORM_CONFIG_KEY,
    CUSTOM_INSTRUCTIONS_KEY, INTERLINE_KEY, PI_KEY,
    USE_CUSTOM_FORM_CONFIG_KEY,
    USE_CUSTOM_INSTRUCTIONS_KEY
} from "../../pages/Navigation/constants";
import FormConfigResponse from "../mocks/FormConfigResponse";
import PersonResponse from "../mocks/PersonResponse";
import { personId } from "../../utils/constants";
import FlightResponse from "../mocks/FlightResponse";
import { airlines, airports, generateFlights, airlinesAll, supportedCountries } from "../mocks/FlightScheduleData";
import { processInterlineSteps, setInterlineReview } from "./processInterlineInstance";
import { getLocal, setLocal } from "./utils";
import { processSteps, setReview } from "./processInstance";
import { flightBlock, flightSpecificData } from "../mocks/FlightResponseInterline";
import { v4 as uuidv4 } from "uuid";

const PERSON_KEY = "user";

// const personId = 'VN7B4GP3ZTP57JAJJFKVHQ6TQI';

export const airlinesGet = (req, res, ctx) => {
    return res(ctx.delay(), ctx.status(200), ctx.json(airlines));
};

export const airlinesAllGet = (req, res, ctx) => {
    return res(ctx.delay(), ctx.status(200), ctx.json(airlinesAll));
};

export const getSupportedCountries = (req, res, ctx) => {
    return res(ctx.delay(), ctx.status(200), ctx.json(supportedCountries));
};

export const airportsGet = (req, res, ctx) => {
    return res(ctx.delay(), ctx.status(200), ctx.json({ items: airports }));
};

export const departuresGet = (req, res, ctx) => {
    const countryCode = req.url.searchParams.get("countryCode");
    const airlineCode = req.url.searchParams.get("airlineCode");
    const mod = ["AA", "BA", "EI", "TJ" ].indexOf(airlineCode);
    return res(ctx.delay(), ctx.status(200), ctx.json(airports.filter(a => a.country_iso !== countryCode).filter((a, i) => i % 4 === mod )));
};

export const arrivalsGet = (req, res, ctx) => {
    const countryCode = req.url.searchParams.get("arrivalCountry");
    if (countryCode) {
        return res(ctx.delay(), ctx.status(200), ctx.json(airports.filter(a => a.country_iso === countryCode)));
    } else {
        return res(ctx.delay(), ctx.status(200), ctx.json(airports.slice(0, 4)));
    }
};

export const flightsGet = (req, res, ctx) => {
    const airlineCode = req.url.searchParams.get("airlineCode");
    const departureCode = req.url.searchParams.get("departureCode");
    const arrivalCode = req.url.searchParams.get("arrivalCode");
    return res(ctx.delay(), ctx.status(200), ctx.json(generateFlights(airlineCode, departureCode, arrivalCode)));
};

export const flightGetPerson = (req, res, ctx) => {
    const existingPerson = getLocal(PERSON_KEY);
    if (!existingPerson) {
        setLocal(PERSON_KEY, PersonResponse);
    }
    const response = existingPerson || PersonResponse;
    return res(ctx.delay(), ctx.status(200), ctx.json(response));
};

export const flightPostPerson = (req, res, ctx) => {
    const existing = getLocal(PERSON_KEY);
    const response  = {...existing, ...req.body};
    setLocal(PERSON_KEY, response);
    return res(ctx.delay(), ctx.status(200), ctx.json(response));
};

const getInstructionKeys = body => {
    const arrayOfUsers = Object.keys(body.userData.userSpecificData);
    const { interlineFlightDetails } = body?.userData?.sharedData || {};
    if (interlineFlightDetails) {
        const keysArray = [];
        interlineFlightDetails.flightBlocks.forEach(fb => fb.flightSegments.forEach(fs => arrayOfUsers.forEach(u => keysArray.push(`${fb.id}:${fs.id}:${u}`))));
        return keysArray;
    } else {
        return arrayOfUsers;
    }
}

const getSegmentIds = interlineFlightDetails => interlineFlightDetails.flightBlocks.flatMap(fb => fb.flightSegments.map(fs => `${fb.id}:${fs.id}`));

const fillFlightSpecificData = (userSpecificData, interlineFlightDetails) => {
    const segments = getSegmentIds(interlineFlightDetails)
    for(const u in userSpecificData) {
        if(!userSpecificData[u].flightSpecificData) {
            segments.forEach(s => {
                userSpecificData[u].flightSpecificData = {
                    ...userSpecificData[u].flightSpecificData,
                    [s]: flightSpecificData
                };
            })
        }
    }
    return userSpecificData;
}

const getInstructions = () => {
    const useCustomInstructions = getLocal(USE_CUSTOM_INSTRUCTIONS_KEY);
    return useCustomInstructions ? getLocal(CUSTOM_INSTRUCTIONS_KEY) : { ...FlightResponse.instructions.userProcesses[personId] };
}

export const flightPost = (req, res, ctx) => {
    const instructionsForUser = getInstructions();
    const keysArray = getInstructionKeys(req.body);
    const newUserProcesses = {};
    keysArray.forEach(user => {
        newUserProcesses[user] = { ...instructionsForUser, userId: user };
    });

    const newInstance = {
        ...req.body,
        id: uuidv4(),
        instructions: { ...FlightResponse.instructions, userProcesses: newUserProcesses },
    };

    const { interlineFlightDetails } = req.body?.userData?.sharedData || {};

    const key = interlineFlightDetails ? INTERLINE_KEY : PI_KEY;

    const response = { ...FlightResponse, ...newInstance };
    if(interlineFlightDetails) {
        response.userData.userSpecificData = fillFlightSpecificData(req.body.userData.userSpecificData, interlineFlightDetails);
    }
    const existingPis = getLocal(key) ||[];
    setLocal(key, [...existingPis, response]);

    return res(ctx.delay(), ctx.status(201), ctx.json(response));
};

// GET
export const flightGet = (req, res, ctx) => {
    const existingPis = getLocal(PI_KEY) || [];
    const existingInterlines = getLocal(INTERLINE_KEY) || [];
    const allTrips = [...existingPis, ...existingInterlines];
    const id = req.params.id;
    const response = allTrips.find(pi => pi.id === id);
    return res(ctx.status(200), ctx.json(response));
};

// POST
export const flightUpdate = (req, res, ctx) => {
    const requestBody = req.body;
    const { userSpecificData, sharedData } = requestBody.userData;
    const instructionsForUser = getInstructions();
    const isInterline = Boolean(sharedData?.interlineFlightDetails);
    if (isInterline) {
        requestBody.userData.userSpecificData = fillFlightSpecificData(userSpecificData, sharedData.interlineFlightDetails)
        getInstructionKeys(req.body).forEach(k => {
            if(!req.body.instructions.userProcesses[k]) {
                req.body.instructions.userProcesses[k] = {...instructionsForUser};
            }
        })
    } else {
        for (const id in userSpecificData) {
            requestBody.instructions.userProcesses[id] = requestBody.instructions.userProcesses[id] ?? {
                ...instructionsForUser,
            };
        }
    }
    const processInstance = isInterline ? processInterlineSteps(requestBody) : processSteps(requestBody);
    const key = isInterline ? INTERLINE_KEY : PI_KEY;
    const existingPis = getLocal(key) ||[];
    const updated = existingPis.map(pi => pi.id === processInstance.id ? processInstance : pi );
    setLocal(key, updated);
    return res(ctx.status(200), ctx.json(processInstance));
};

export const acceptReviews = (req, res, ctx) => {
    const extId = req.url.searchParams.get("extId");
    const fsd = req.url.searchParams.get("fsd");
    const companionId = req.url.searchParams.get("companionId");
    const isInterline = req.url.searchParams.get("interline");
    const stepName = req.url.searchParams.get("stepName");
    const accept = req.url.searchParams.get("accept");
    const key = isInterline ? INTERLINE_KEY : PI_KEY
    const flights = getLocal(key);
    const pi = flights.find(f => f.id === extId);
    const updatedPi = isInterline ? setInterlineReview(pi, companionId, fsd, stepName, accept) : setReview(pi, companionId, stepName, accept);
    const updatedFlights = flights.map(pi => pi.id === updatedPi.id ? updatedPi : pi );
    setLocal(key, updatedFlights);
    return res(ctx.status(200), ctx.json(updatedFlights));
};

// Flight blocks
export const flightResponseInterlineGet = (req, res, ctx) => {
    const bookingNumber = req.url.searchParams.get("bookingNumber");
    const response = flightBlock("BA", bookingNumber);
    return res(ctx.status(200), ctx.json(response));
}

export const config = (req, res, ctx) => {
    const useCustom = getLocal(USE_CUSTOM_FORM_CONFIG_KEY);
    const response = useCustom ? getLocal(CUSTOM_FORM_CONFIG_KEY) : FormConfigResponse;
    return res(ctx.status(200), ctx.json(response));
}

export const presignedUrls = (req, res, ctx) => {
    const response = req.body.map(item => ({...item, preSignedUrl: "/presignedurl", s3key: uuidv4()}));
    return res(ctx.status(200), ctx.json(response));
}

export const putToPresignedUrl = (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({}));
}
