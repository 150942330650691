import React from "react";
import { Form } from "react-bootstrap";
import { getBegginingOf1900, getEndOfNextYear } from "../../../utils/dateTimeUtils";
import { useLanguage, useLocalize } from "../../../utils/hooks";
import DateInput from "../../_components/DateInput";
import { DateTime } from "luxon";

const BookingDateField = ({ field, onChange }) => {
    const { localize } = useLocalize();
    const { localizeField } = useLanguage();

    const getLabel = f =>
        f.label ? localizeField(f.label) : localize(`carnivalcruise.retrievebooking.${f.name}.label`);

    const { dateType, allowToday, min, max, minDate, maxDate, dateOnly, missingMessage, outOfRangeMessage } = field;

    const getDatePart = val => DateTime.fromISO(val).setZone("UTC", { keepLocalTime: true }).toISODate();
    const handleChange = val => onChange({ target: { value: dateOnly ? getDatePart(val) : val } });

    return (
        <>
            <DateInput
                dateSelected={handleChange}
                label={getLabel(field)}
                type={dateType}
                allowToday={allowToday}
                min={min || getBegginingOf1900()}
                max={max || getEndOfNextYear()}
                maxDate={maxDate}
                minDate={minDate}
                missingMessage={localizeField(missingMessage)}
                outOfRangeMessage={localizeField(outOfRangeMessage)}
            />
            {field.note && <Form.Text muted>{localizeField(field.note)}</Form.Text>}
        </>
    );
};

export default BookingDateField;
