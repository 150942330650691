import { v4 as uuidv4 } from "uuid";
import { DateTime } from "luxon";

// POST user feedback
export const addFeedbackPost = (req, res, ctx) => {
    const feedbackId = uuidv4();
    const question = {
        id: uuidv4(),
        userFeedbackId: feedbackId,
        createdDtm: DateTime.local().toISO(),
        ...req.body.questions[0],
    };

    const feedback = {
        id: feedbackId,
        accountHolderId: req.body.accountHolderId,
        subjectId: req.body.subjectId,
        procDefnId: "PPDDS2NW6R3Q6XSOIFBP3JMY2E",
        procInstanceId: req.body.procInstanceId,
        createdDtm: DateTime.local().toISO(),
        questions: [question],
    };

    return res(ctx.status(201), ctx.json(feedback));
};

// POST medical MRZ read
export const medicalServicePost = (req, res, ctx) => {
    let payload = {};

    if (req?.body?.documentType === "PASSPORT") {
        payload = {
            documentNumber: "321123321123",
            issuingState: "USA",
            firstName: "Nikola",
            lastName: "Dragic",
            dateOfBirth: "1977-07-07",
            dateOfExpiration: "2023-02-01",
            gender: "MALE",
            /* code: 283,
            message: "Valid MRZ not found" */
        };
    }

    if (req?.body?.documentType === "VISA") {
        payload = {
            documentNumber: "11111111111",
            issuingState: "USA",
            firstName: "Nikola",
            lastName: "Dragic",
            dateOfBirth: "1977-07-07",
            dateOfExpiration: "2023-02-01"
        };
    }

    return res(ctx.status(201), ctx.json(payload));
};
