import { useEffect, useState } from "react";
import { getStep, isStepName } from "../steps";
import useQuery from "./useQuery";

const useStep = (processInstance, predicate, usePredicate) => {
    const [step, setStep] = useState();
    const [parameters, setParameters] = useState();
    const [isComplete, setIsComplete] = useState(false);

    const query = useQuery();
    const stepName = query.get("stepName");
    const stepId = query.get("stepId");

    const getParameterTextValue = (parameter, language, value) => {
        const values = step?.view.parameters[parameter]?.values;
        const languageSpecificValues = values?.[language.substr(0, 2)] || values?.en;
        return languageSpecificValues?.[value] || "";
    };

    const getParameterBoolValue = parameter => Boolean(step?.view.parameters[parameter]);

    useEffect(() => {
        if (processInstance && stepId && predicate) {
            if (usePredicate) {
                setStep(getStep(processInstance, stepId, predicate));
            } else {
                setStep(getStep(processInstance, stepId, isStepName(stepName)));
            }
        }
    }, [processInstance, stepId, predicate, usePredicate, stepName]);

    useEffect(() => {
        if (step) {
            setIsComplete(step.complete);
            step?.view?.parameters && setParameters(step.view.parameters)
        }
    }, [step]);

    return { step, isComplete, stepViewParameters: parameters, getParameterTextValue, getParameterBoolValue };
};

export default useStep;
