import { DateTime } from "luxon";
import { countries } from "../config/countries";
import { REVIEW_STATUS, STATUS_FOR, STEP_STATUS } from "./constants";

export const COVID_TEST_STEP_NAME = "COVID_TEST";
export const TRAVEL_AUTHORIZATION_STEP_NAME = "TRAVEL_AUTHORIZATION";
export const BEFORE_YOU_GO_TO_THE_AIRPORT_STEP_NAME = "BEFORE_YOU_GO_TO_THE_AIRPORT";
export const TRAVEL_ATTESTATION_STEP_NAME = "TRAVEL_ATTESTATION";
export const TRAVEL_INSURANCE_STEP_NAME = "TRAVEL_INSURANCE";
export const COMPANION_PHOTO_STEP_NAME = "COMPANION_PHOTO";
export const VACCINATION_STEP_NAME = "COVID19_VACCINE";
export const COVID_REVIEW_STEP_NAME = "COVID_REVIEW";
export const QUESTIONNAIRE_STEP_NAME = "QUESTIONNAIRE";
export const STEP_GROUP_STEP_NAME = "STEP_GROUP";
export const HEALTH_REVIEW_STEP_NAME = "HEALTH_REVIEW";
export const VISA = "VISA";
export const PASSPORT = "PASSPORT";
export const PROOF_OF_VISA_STEP_NAME = "PROOF_OF_VISA";
export const DOCUMENT_VALIDATION_STEP_NAME = "DOCUMENT_VALIDATION";
export const PHOTO_IDENTIFICATION_STEP_NAME = "PHOTO_IDENTIFICATION";
export const BIRTH_CERTIFICATE_STEP_NAME = "BIRTH_CERTIFICATE";
export const NAME_CHANGE_STEP_NAME = "NAME_CHANGE";
export const GENERIC_DOCUMENT_STEP_NAME = "GENERIC_DOCUMENT";


export const isStepName = name => s => s.name === name;
export const isStepType = name => s => getStepType(s) === name;

export const isCovidTestStep = isStepType(COVID_TEST_STEP_NAME);
export const isTravelAuthorizationStep = isStepName(TRAVEL_AUTHORIZATION_STEP_NAME);
export const isBeforeYouGoToTheAirportStep = isStepType(BEFORE_YOU_GO_TO_THE_AIRPORT_STEP_NAME);
export const isTravelAttestationStep = isStepType(TRAVEL_ATTESTATION_STEP_NAME);
export const isTravelInsuranceStep = isStepName(TRAVEL_INSURANCE_STEP_NAME);
export const isCompanionPhotoStep = isStepName(COMPANION_PHOTO_STEP_NAME);
export const isVaccinationStep = isStepType(VACCINATION_STEP_NAME);
export const isCovidReviewStep = isStepName(COVID_REVIEW_STEP_NAME);
export const isQuestionnaire = isStepName(QUESTIONNAIRE_STEP_NAME);
export const isStepGroup = isStepType(STEP_GROUP_STEP_NAME);
export const isHealthReview = isStepType(HEALTH_REVIEW_STEP_NAME);
export const isDocumentValidation = isStepType(DOCUMENT_VALIDATION_STEP_NAME);
export const isPhotoIdentificationStep = isStepType(PHOTO_IDENTIFICATION_STEP_NAME);
export const isBirthCertificateStep = isStepType(BIRTH_CERTIFICATE_STEP_NAME);
export const isNameChangeStep = isStepType(NAME_CHANGE_STEP_NAME);
export const isGenericDocumentStep = isStepType(GENERIC_DOCUMENT_STEP_NAME);

export const getSteps = (processInstance, stepId) => processInstance?.instructions.userProcesses[stepId]?.steps || [];

export const getStep = (processInstance, stepId, predicate) => getSteps(processInstance, stepId).find(predicate);

export const getInterlineStepsByType = predicate => (processInstance, companionId) => {
    const instructionIds = Object.keys(processInstance?.instructions.userProcesses).filter(s => s.includes(companionId));
    return instructionIds.map(id => processInstance?.instructions.userProcesses[id].steps.find(predicate));
}

export const getCombinedVaccineParameters = (processInstance, companionId) => {
    const steps = getInterlineStepsByType(isVaccinationStep)(processInstance, companionId);
    const allStepsParameters = steps.map(s => s.view.parameters);
    const allStepsVaccineTypes = allStepsParameters.flatMap(p => p.vaccineTypes);
    const vaccineTypeNames = new Set([
        ...allStepsVaccineTypes
            .filter(vt => !allStepsParameters.some(p => !p.vaccineTypes.find(v => v.name === vt.name)))
            .map(vt => vt.name)
    ]);
    const vaccineTypes = [...vaccineTypeNames].map(name => ({name}));
    const allVaccineTypesByName = name => allStepsVaccineTypes.filter(vt => vt.name === name);
    const getPropValuesForVaccineType = (name, propName) => allVaccineTypesByName(name).map(vt => vt[propName]);
    const getMinProp = (type, propName) => type[propName] = Math.min(...getPropValuesForVaccineType(type.name, propName));
    const getMaxProp = (type, propName) => type[propName] = Math.min(...getPropValuesForVaccineType(type.name, propName));
    vaccineTypes.forEach(vt => {
        getMaxProp(vt, "numDoses");
        getMaxProp(vt, "fullyVaccinatedAfterDays");
        getMaxProp(vt, "boosterActiveAfterDays");
        getMinProp(vt, "boosterRequiredAfterDays");
    })
    return vaccineTypeNames;
};

export const stepExists = (processInstance, stepId, predicate) => getSteps(processInstance, stepId).includes(predicate);

export const isStepCompleted = (processInstance, stepId, predicate) =>
    getStep(processInstance, stepId, predicate)?.completed;

export const isStepAvailable = (processInstance, stepId, predicate) =>
    getStep(processInstance, stepId, predicate)?.available;

export const getBodyText = (step, language) => {
    const lang = language.substr(0, 2);
    const values = step?.view.parameters.bodyText?.values;
    return values?.[lang] || values?.en || "";
};

export const getStepInternalUrl = step => step.view.view.split(":")[0];

export const getStepCountry = step => {
    const parts = step.view.view.split(":");
    return parts.length <= 1 ? "" : Object.keys(countries).find(key => countries[key].code === parts[1].toUpperCase());
};

export const getStepUrl = (step, query, companionId) => {
    query.set("companionId", companionId);
    query.set("fromDate", step.notBeforeDtm);
    query.set("toDate", step.notAfterDtm);
    query.set("stepName", step.name);
    if (step.name.includes(DOCUMENT_VALIDATION_STEP_NAME)) {
        query.set("referencedUnder", step.name);
    }
    return `${getStepInternalUrl(step)}/${getStepCountry(step)}?${query.toString()}`;
};

export const reviewStatus = (statusFor, processInstance, companionId, fsdReference) => {
    const companionData = fsdReference
        ? processInstance.userData.userSpecificData[companionId]?.flightSpecificData[fsdReference]
        : processInstance.userData.userSpecificData[companionId];

    const isStatusForArray = Array.isArray(statusFor)
    const checkIfStatusExists = isStatusForArray
        ? statusFor.every((curr) => companionData?.[curr]?.length > 0 )
        :  companionData?.[statusFor]?.length > 0;

    if (!checkIfStatusExists) {
        return undefined;
    }

    if (isStatusForArray) {

        const statusStep = statusFor.map((s) => {
            return companionData?.[s].find((status, _, array) => {
                return DateTime.fromISO(status.submitted).equals(
                  DateTime.max(...array.map(t => DateTime.fromISO(t.submitted)))
              );
          })?.review?.status || REVIEW_STATUS.PENDING;
        })

        return statusStep.includes(REVIEW_STATUS.REJECTED) ? REVIEW_STATUS.REJECTED : REVIEW_STATUS.PENDING;
    }

    return companionData?.[statusFor]?.find((status, _, array) => {
            return DateTime.fromISO(status.submitted).equals(
              DateTime.max(...array.map(t => DateTime.fromISO(t.submitted)))
          );
      })?.review?.status || REVIEW_STATUS.PENDING;
};

export const getStepType = step => {
    if (step?.name && step.name.includes("COVID") && step.name.includes("TEST")) return COVID_TEST_STEP_NAME;
    if (step?.name && step.name.includes("VACCINE")) return VACCINATION_STEP_NAME;
    if (step?.name && step.name.includes("HEALTH_REVIEW")) return HEALTH_REVIEW_STEP_NAME;
    if (step?.name && step.name.includes("STEP_GROUP")) return STEP_GROUP_STEP_NAME;
    if (step?.name && (step.name.includes(BEFORE_YOU_GO_TO_THE_AIRPORT_STEP_NAME) || step.name.includes("BYG"))) return BEFORE_YOU_GO_TO_THE_AIRPORT_STEP_NAME;
    if (step?.name && step.name.includes(TRAVEL_ATTESTATION_STEP_NAME)) return TRAVEL_ATTESTATION_STEP_NAME;
    if (step?.name && step.name.includes("PASSPORT") && !step.name.includes("DOCUMENT_VALIDATION")) return PASSPORT;
    if (step?.name && step.name === VISA && step.view.parameters.options.length !== 1) return VISA;
    if (step?.name && step.name === VISA && step.view.parameters.options.length === 1) return PASSPORT;
    if (step?.name && step.name.includes(PROOF_OF_VISA_STEP_NAME)) return PROOF_OF_VISA_STEP_NAME;
    if (step?.name && step.name.includes("DOCUMENT_VALIDATION")) return DOCUMENT_VALIDATION_STEP_NAME;
    if (step?.name && step.name.includes("PHOTO_IDENTIFICATION")) return PHOTO_IDENTIFICATION_STEP_NAME;
    if (step?.name && step.name.includes("BIRTH_CERTIFICATE")) return BIRTH_CERTIFICATE_STEP_NAME;
    if (step?.name && step.name.includes("NAME_CHANGE")) return NAME_CHANGE_STEP_NAME;
    if (step?.name && step.name.includes("GENERIC_DOCUMENT")) return GENERIC_DOCUMENT_STEP_NAME;
    return undefined;
};

export const showStatusIcon = (status, step, processInstance, companionId, fsdReference) =>
    !!STATUS_FOR[getStepType(step)] &&
    reviewStatus(STATUS_FOR[getStepType(step)], processInstance, companionId, fsdReference) === status;

export const isPendingStep = (step, processInstance, companionId, fsdReference) =>
    showStatusIcon(REVIEW_STATUS.PENDING, step, processInstance, companionId, fsdReference);

export const isRejectedStep = (step, processInstance, companionId, fsdReference) =>
    showStatusIcon(REVIEW_STATUS.REJECTED, step, processInstance, companionId, fsdReference);

export const isNoLongerAcceptedStep = (step, processInstance, companionId, fsdReference) =>
    showStatusIcon(REVIEW_STATUS.NO_LONGER_ACCEPTABLE, step, processInstance, companionId, fsdReference);

export const isAcceptedStep = (step, processInstance, companionId, fsdReference) =>
    showStatusIcon(REVIEW_STATUS.ACCEPTED, step, processInstance, companionId, fsdReference);

export const isFlaggedStep = (step, processInstance, companionId, fsdReference) => {
    const flaggedQuestionnaireInfo = fsdReference
        ? processInstance.userData.userSpecificData[companionId]?.flightSpecificData[fsdReference]
              .flaggedQuestionnaireInfo
        : processInstance.userData.userSpecificData[companionId]?.flaggedQuestionnaireInfo;

    const flaggedQuestionnaires = flaggedQuestionnaireInfo ? Object.keys(flaggedQuestionnaireInfo) : [];
    const type = step?.view?.parameters?.type;

    return Boolean(flaggedQuestionnaires && type && flaggedQuestionnaires.includes(type));
};

export const isFlaggedOrRejectedStep = (step, processInstance, companionId, fsdReference) =>
    isFlaggedStep(step, processInstance, companionId, fsdReference) ||
    isRejectedStep(step, processInstance, companionId, fsdReference) ||
    isNoLongerAcceptedStep(step, processInstance, companionId, fsdReference);

export const getStepStatus = (step, processInstance, companionId) => {
    if (step.complete) return STEP_STATUS.COMPLETE;
    if (isPendingStep(step, processInstance, companionId)) return STEP_STATUS.PENDING;
    if (step.optional) return STEP_STATUS.OPTIONAL;
    return STEP_STATUS.REQUIRED;
};

export const OPT_OUT_TYPES = {
    [COVID_TEST_STEP_NAME]: "notSubmittingTest",
    [VACCINATION_STEP_NAME]: "notSubmittingVaccine",
};

export const isOptedOut = (step, processInstance, companionId, fsdReference) => {
    if (!step.optional) {
        return false;
    }

    const stepType = getStepType(step);

    if (fsdReference) {
        return processInstance.userData.userSpecificData[companionId].flightSpecificData[fsdReference].healthReview?.[
            OPT_OUT_TYPES[stepType]
        ];
    }

    return processInstance.userData.userSpecificData[companionId].healthReview?.[OPT_OUT_TYPES[stepType]];
};

export const isPassportStep = (step) => step?.name && step.name.includes("PASSPORT") && !step.name.includes("DOCUMENT_VALIDATION");

export const isFirstOrOnlyStepOfKind = (processInstance, stepId, predicate, stepInQuestion) => {
    if (!processInstance || !stepId || !predicate || !stepInQuestion) return false;

    const steps = getSteps(processInstance, stepId);

    if (Array.isArray(steps)) {
        const stepsOfKind = steps
            .filter(predicate)
            .map(s => s?.name);
        if (stepsOfKind.length === 1) {
            return true;
        }
        return stepsOfKind.indexOf(stepInQuestion.name) === 0;
    }

    return true;
};

//************************* Step options *************************

export const getOptions = step => step.view.parameters.options || [];
export const getOption = (step, predicate) => getOptions(step).find(predicate);
export const getCurrentOption = (step, currentLocation) =>
    getOption(step, o => currentLocation.toString().includes(o.view));

export const viewStartsWith = urlPart => o => o?.view?.startsWith(urlPart);

export const isTestReviewOption = viewStartsWith("/addcovidtestresults");
export const isVaccineReviewOption = viewStartsWith("/addvaccination");
export const isDeferOption = viewStartsWith("/defer");

export const shouldDisplayOption = opt => (opt.hasOwnProperty("display") ? opt.display : true);
