import React, { lazy, Suspense, useEffect, useLayoutEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import { Route, Switch, useHistory, useLocation } from "react-router";
import matchPath from "react-router/modules/matchPath";
import AddInterlineFlightDetails from "./pages/AddInterlineFlightDetails";
import FormConfigurationResolver from "./pages/ConfigurableForm/FormConfigurationResolver";
import { Theme } from "./theme/Theme";
import "./App.scss";
import { fetchAirlinesToCountry, fetchTests, fetchTrips, fetchConfig } from "./utils/dataFetching";
import fetch from "./utils/fetch";
import { closeForm } from "./utils/dataProcessing";
import { useLanguage, useQuery, useTheme } from "./utils/hooks";
import { ThemeProvider } from "styled-components";
import { countries } from "./config/countries";
import CredentialContext from "./config/CredentialContext";
import ErrorBoundary from "./pages/_components/Wrappers/ErrorBoundary";
import ErrorMessage from "./pages/_components/ErrorMessage";
import FlightDetailsWithFetching from "./pages/AddFlightDetails/FlightDetailsWithFetching";
import ManageTrip from "./pages/ManageTrip/";
import CapCruiseDetails from "./pages/AddCruiseDetails/";
import ManageInterlineTrip from "./pages/ManageTrip/ManageInterlineTrip/";
import EventDetails from './pages/AddEventDetails/EventDetails';
import LoadingWrapper from "./pages/_components/Wrappers/LoadingWrapper";
import ActionModals from "./pages/_components/ActionModals";
import { isCovidTestStep, isPhotoIdentificationStep, isVaccinationStep } from "./utils/steps";
import { HEALTH_PASS_TYPE } from "./utils/constants";

const Navigation = lazy(() => import("./pages/Navigation/Navigation"));
const ManageCruiseTripRouter = lazy(() => import("./pages/ManageTrip/ManageCruiseTripRouter"));
const ManageTripRouter = lazy(() => import("./pages//ManageTrip/ManageTripsRouter"));
const ManageNonInterlineTrip = lazy(() => import("./pages/ManageTrip/ManageNonInterlineTrip/"));
const ManageInterlineTripBlock = lazy(() => import("./pages/ManageTrip/ManageInterlineTrip/ManageInterlineTripBlock/"));
const CapCovidTestResults = lazy(() => import("./pages/AddCovidTestResults/"));
const CapLegacyVaccinationReview = lazy(() => import("./pages/AddVaccination/Legacy/"));
const CapVaccinationReview = lazy(() => import("./pages/AddVaccination/"));
const CapTravelAuthorization = lazy(() => import("./pages/AddPreregistration/CapTravelAuthorization"));
const CapBeforeAirport = lazy(() => import("./pages/AddChecklist/CapBeforeAirport"));
const CapCustomBeforeAirport = lazy(() => import("./pages/AddChecklist/CapCustomBeforeAirport"));
const CapHealthInsurance = lazy(() => import("./pages/AddHealthInsurance/CapHealthInsurance"));
const CapAttestation = lazy(() => import("./pages/AddAttestation/CapAttestation"));
const CanadaAttestation = lazy(() => import("./pages/AddAttestation/CanadaAttestation"));
const CapLegacyAttestation = lazy(() => import("./pages/AddAttestation/CapLegacyAttestation"));
const AddCompanion = lazy(() => import("./pages/AddCompanion/AddCompanion"));
const AddCompanionPhoto = lazy(() => import("./pages/AddCompanionPhoto/AddCompanionPhoto"));
const TripCredentials = lazy(() => import("./pages/TripChecklist/TripCredentials"));
const TestTypesScreenWrapper = lazy(() => import("./pages/Wrappers/TestTypesScreenWrapper"));
const MapScreenWrapper = lazy(() => import("./pages/Wrappers/MapScreenWrapper"));
const CapDetailsForm = lazy(() => import("./pages/AddDetails/CapDetailsForm"));
const CapQuestionnaire = lazy(() => import("./pages/AddQuestionnaire/CapQuestionnaire"));
const CapCovidReviewProcess = lazy(() => import("./pages/AddCovidReview/CapCovidReviewProcess"));
const AddTestCenterQrCode = lazy(() => import("./pages/AddDigitalHealthPass/AddTestCenterQrCode"));
const AddDigitalCertificate = lazy(() => import("./pages/AddDigitalHealthPass/AddDigitalCertificate"));
const PartnerTestCenters = lazy(() => import("./pages/PartnerTestCenters/PartnerTestCenters"));
const SupportedDigitalCertificates = lazy(() => import("./pages/PartnerTestCenters/SupportedDigitalCertificates"));
const DeferredConfirmation = lazy(() => import("./pages/AddCovidReview/DeferredConfirmation"));
const AddArrival = lazy(() => import("./pages/AddArrival/AddArrival"));
const AddMultiStepProcess = lazy(() => import("./pages/AddMultiStepProcess/AddMultiStepProcess"));
const MultiStepProcessSubStep = lazy(() => import("./pages/AddMultiStepProcess/MultiStepProcessSubStep"));
const CarnivalCruiseAddGuests = lazy(() => import("./pages/ManageTrip/ManageCruiseTrip/CarnivalCruiseAddGuests/"));
const AddVisa = lazy(() => import("./pages/AddVisa/"));
const PassportDetails = lazy(() => import("./pages/AddVisa/PassportDetails/"));
const VisaDetails = lazy(() => import("./pages/AddVisa/VisaDetails/"));
const AddDocumentValidation = lazy(() => import("./pages/AddDocumentValidation"));
const AddDocument = lazy(() => import("./pages/AddDocuments"));
const AddBirthCertificate = lazy(() => import("./pages/AddBirthCertificate"));
const AddNameChange = lazy(() => import("./pages/AddNameChange"));
const AddGenericDocument = lazy(() => import("./pages/AddGenericDocument"));

function App() {
    const [error, setError] = useState();
    const [trips, setTrips] = useState([]);
    const [tests, setTests] = useState([]);
    const [airlines, setAirlines] = useState([]);
    const [processInstance, setProcessInstance] = useState();
    const [globalConfig, setGlobalConfig] = useState({});
    const [loading, setLoading] = useState({
        trips: true,
        tests: true,
        airports: true,
        companion: false,
        config: true,
    });

    const query = useQuery();

    const submissionHref = query.get("submissionHref");
    const credentialTypeHref = query.get("credentialTypeHref");
    const medicalHref = query.get("medicalHref");
    const appVersion = query.get("appVersion");

    const onTripsFetched = response => {
        setTrips(response.items);
        updateLoading("trips", false);
    };

    const onTestsFetched = response => {
        setTests(response.items);
        updateLoading("tests", false);
    };

    const onAirlinesFetched = response => {
        setAirlines(response);
        updateLoading("airlines", false);
    };

    const onLoadingError = prop => err => {
        setError(err);
        updateLoading(prop, false);
    };

    const onFetchCongig = config => {
        setGlobalConfig(config);
        updateLoading("config", false);
    };

    const updateLoading = (prop, value) => setLoading(current => ({ ...current, [prop]: value }));
    const loadingAny = (...args) => [...args].map(p => loading[p]).reduce((a, c) => !!a || !!c, false);

    const history = useHistory();
    const location = useLocation();
    const theme = useTheme(query.get("theme"));
    const { locale, translations } = useLanguage();

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    useEffect(() => {
        let pathname = history.location.pathname;
        const match = matchPath(pathname, {
            path: [
                "/addflightdetails/:country",
                "/addflightdetails/cap/:country",
                "/addcovidtestresults/:country",
                "/addcovidtestresults/cap/:country",
                "/addvaccination/cap/:country",
                "/addpreregistration/:country",
                "/addpreregistration/cap/:country",
                "/addchecklist/:country",
                "/addchecklist/cap/:country",
                "/addhealthinsurance/:country",
                "/addhealthinsurance/cap/:country",
                "/addtravelauthcheck/:country",
                "/addtravelauthcheck/cap/:country",
                "/attestations/:country",
                "/attestations/cap/:country",
                "/managetrip",
                "/addcompanion",
                "/addcompanionphoto",
                "/tripchecklist",
                "/addinterlineflightdetails/cap/:country",
            ],
            exact: true,
            strict: false,
        });
        let countryCode;

        if (match && match.params.country) {
            const country = match.params.country;
            countryCode = countries[country]?.code;
        }
        locale && fetch.setLanguage(locale);

        countryCode && fetchTrips(countryCode, onTripsFetched, onLoadingError("trips"));
        countryCode && fetchTests(countryCode, onTestsFetched, onLoadingError("tests"));
        countryCode && fetchAirlinesToCountry(countryCode, onAirlinesFetched, onLoadingError("airlines"));

        fetchConfig(submissionHref, onFetchCongig, onLoadingError("config"));
        // eslint-disable-next-line
    }, []);

    return (
        <div className="app-container">
            <ThemeProvider theme={theme}>
                <Theme />
                <IntlProvider locale={locale} messages={translations}>
                    <ErrorBoundary>
                        {error ? (
                            <ErrorMessage error={error} close={closeForm} />
                        ) : (
                            <CredentialContext.Provider
                                value={{
                                    trips,
                                    tests,
                                    airlines,
                                    processInstance,
                                    setProcessInstance,
                                    loading,
                                    loadingAny,
                                    updateLoading,
                                    error,
                                    setError,
                                    globalConfig,
                                    firstName: query.get("firstName"),
                                    lastName: query.get("lastName"),
                                    userEmail: query.get("email"),
                                    userId: query.get("personId"),
                                    language: query.get("language"),
                                    submissionHref,
                                    credentialTypeHref,
                                    medicalHref,
                                    extId: query.get("extId"),
                                    appVersion,
                                }}
                            >
                                <Suspense fallback={<LoadingWrapper isLoading={true} fullPage />}>
                                    <ActionModals />
                                    <Switch>
                                        <Route exact path="/">
                                            <Navigation />
                                        </Route>
                                        {/* Not lazy loaded */}
                                        <Route exact path="/addflightdetails/cap/:country">
                                            <FlightDetailsWithFetching />
                                        </Route>
                                        {/* Not lazy loaded */}
                                        <Route exact path="/addinterlineflightdetails">
                                            <AddInterlineFlightDetails />
                                        </Route>
                                        <Route exact path="/managecruisetriprouter">
                                            <ManageCruiseTripRouter />
                                        </Route>
                                        <Route exact path="/managetriprouter">
                                            <ManageTripRouter />
                                        </Route>
                                        <Route exact path="/managenoninterlinetrip">
                                            <ManageNonInterlineTrip />
                                        </Route>
                                        {/* Not lazy loaded */}
                                        <Route exact path="/manageinterlinetrip">
                                            <ManageInterlineTrip />
                                        </Route>
                                        <Route exact path="/manageinterlinetripblock/:flightBlock">
                                            <ManageInterlineTripBlock />
                                        </Route>
                                        <Route exact path="/addcovidtestresults/cap/:country">
                                            <CapCovidTestResults />
                                        </Route>
                                        <Route exact path="/addvaccination/cap/:country">
                                            <CapLegacyVaccinationReview />
                                        </Route>
                                        <Route exact path="/addvaccinationdoses/cap/:country">
                                            <CapVaccinationReview />
                                        </Route>
                                        <Route exact path="/addpreregistration/cap/:country">
                                            <CapTravelAuthorization />
                                        </Route>
                                        <Route exact path="/addchecklist/cap/:country">
                                            <CapBeforeAirport />
                                        </Route>
                                        <Route exact path="/addcustomchecklist/cap/:country">
                                            <CapCustomBeforeAirport />
                                        </Route>
                                        <Route exact path="/addhealthinsurance/cap/:country">
                                            <CapHealthInsurance />
                                        </Route>
                                        <Route exact path="/attestations/:version/cap/:country">
                                            <CapAttestation />
                                        </Route>
                                        <Route
                                            exact
                                            path="/attestations/cap/:country"
                                            render={props =>
                                                props.match.params.country.toLowerCase() === "canada" ? (
                                                    <CanadaAttestation />
                                                ) : (
                                                    <CapLegacyAttestation />
                                                )
                                            }
                                        />
                                        {/* Not lazy loaded */}
                                        <Route exact path="/managetrip">
                                            <ManageTrip />
                                        </Route>
                                        <Route exact path="/addcompanion">
                                            <AddCompanion />
                                        </Route>
                                        <Route exact path="/addcompanionphoto">
                                            <AddCompanionPhoto />
                                        </Route>
                                        <Route exact path="/tripchecklist">
                                            <TripCredentials />
                                        </Route>
                                        <Route exact path="/testcenters">
                                            <TestTypesScreenWrapper />
                                        </Route>
                                        <Route exact path="/map">
                                            <MapScreenWrapper />
                                        </Route>
                                        {/* Not lazy loaded */}
                                        <Route exact path="/addcruisedetails/:cruiseFormType">
                                            <CapCruiseDetails />
                                        </Route>
                                        <Route exact path="/addeventdetails/:event">
                                            <EventDetails />
                                        </Route>
                                        <Route exact path="/addevent/">
                                            <EventDetails />
                                        </Route>
                                        <Route exact path="/add/:type">
                                            <CapDetailsForm />
                                        </Route>
                                        <Route exact path="/addquestionnaire">
                                            <CapQuestionnaire />
                                        </Route>
                                        <Route exact path="/addcovidreview">
                                            <CapCovidReviewProcess stepType={isCovidTestStep} />
                                        </Route>
                                        <Route exact path="/addcovidvaccinationreview">
                                            <CapCovidReviewProcess stepType={isVaccinationStep} />
                                        </Route>
                                        <Route exact path="/addtestcenterqrcode">
                                            <AddTestCenterQrCode />
                                        </Route>
                                        <Route exact path="/adddigitalcertificate">
                                            <AddDigitalCertificate healthPassType={HEALTH_PASS_TYPE.VACCINE} />
                                        </Route>
                                        <Route exact path="/addtestdigitalcertificate">
                                            <AddDigitalCertificate healthPassType={HEALTH_PASS_TYPE.TEST} />
                                        </Route>
                                        <Route exact path="/addrecoverydigitalcertificate">
                                            <AddDigitalCertificate healthPassType={HEALTH_PASS_TYPE.RECOVERY} />
                                        </Route>
                                        <Route exact path="/partnertestcenters">
                                            <PartnerTestCenters />
                                        </Route>
                                        <Route exact path="/supporteddigitalcerts">
                                            <SupportedDigitalCertificates />
                                        </Route>
                                        <Route exact path="/deferredcovidtest">
                                            <DeferredConfirmation />
                                        </Route>
                                        <Route exact path="/addarrival/:country">
                                            <AddArrival />
                                        </Route>
                                        <Route exact path="/addhealthreview">
                                            <AddMultiStepProcess />
                                        </Route>
                                        <Route exact path="/healthreviewtest">
                                            <MultiStepProcessSubStep stepType={isCovidTestStep} />
                                        </Route>
                                        <Route exact path="/healthreviewvaccine">
                                            <MultiStepProcessSubStep stepType={isVaccinationStep} />
                                        </Route>
                                        <Route exact path="/manageguests">
                                            <CarnivalCruiseAddGuests />
                                        </Route>
                                        {/* Not lazy loaded */}
                                        <Route exact path="/create/cruiseDetails">
                                            <FormConfigurationResolver />
                                        </Route>

                                        <Route exact path="/addvisa">
                                            <AddVisa />
                                        </Route>
                                        <Route exact path="/addpassportdetails">
                                            <PassportDetails />
                                        </Route>
                                        <Route exact path="/addvisadetails">
                                            <VisaDetails />
                                        </Route>

                                        <Route exact path="/documentvalidation">
                                            <AddDocumentValidation />
                                        </Route>
                                        <Route exact path="/photoidentification">
                                            <AddDocument stepType={isPhotoIdentificationStep} />
                                        </Route>
                                        <Route exact path="/birthcertificate">
                                            <AddBirthCertificate />
                                        </Route>
                                        <Route exact path="/namechange">
                                            <AddNameChange />
                                        </Route>
                                        <Route exact path="/genericdocument">
                                            <AddGenericDocument />
                                        </Route>
                                    </Switch>
                                </Suspense>
                            </CredentialContext.Provider>
                        )}
                    </ErrorBoundary>
                </IntlProvider>
            </ThemeProvider>
        </div>
    );
}

export default App;
