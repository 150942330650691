const FormConfigResponse = {
    // policies: ["halPrivacyConsent", "halHealthCheckConsent", "halStorageConsent"],
    policies: ["amnPrivacyConsent", "amnHealthCheckConsent", "amnStorageConsent"],
    eventDetailsHeader: {
        values: {
            en: "AMN Healthcare header"
        }
    },
    eventDetailsNote: {
        values: {
            en: "Please enter required event details"
        }
    },
    eventDetailsLegend: {
        values: {
            en: "AMN Healthcare form legend"
        }
    },
    extendedBookingFields: [
        /* {
            type: "text",
            name: "referenceNumber",
            saveTo: "eventDetails",
            label: {
                values: {
                    en: "Reference Number",
                },
            },
            required: true,
            pattern:
                "[A-Za-z0-9 /\n]+[A-Za-z0-9 /\n]*|^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$",
            minLength: 1,
            maxLength: 20,
            missingMessage: {
                values: {
                    en: "Missing information",
                },
            },
            invalidMessage: {
                values: {
                    en: "Invalid information",
                },
            },
            invalidLengthMessage: {
                values: {
                    en: "Must be between 1 and 20 chars",
                },
            },
            note: {
                values: {
                    en: "Your reference number should match...",
                },
            },
        }, */
        {
            type: "text",
            name: "firstName",
            saveTo: "userSpecificData",
            label: {
                values: {
                    en: "First Name",
                },
            },
            required: true,
            pattern: "",
            minLength: 1,
            maxLength: 40,
            missingMessage: {
                values: {
                    en: "Missing information",
                },
            },
            invalidMessage: {
                values: {
                    en: "Invalid information",
                },
            },
            invalidLengthMessage: {
                values: {
                    en: "Must be between 1 and 20 chars",
                },
            },
            note: {
                values: {
                    en: "Your first name should match...",
                },
            },
        },
        {
            type: "text",
            name: "lastName",
            saveTo: "userSpecificData",
            label: {
                values: {
                    en: "Last Name",
                },
            },
            required: true,
            pattern: "",
            minLength: 1,
            maxLength: 40,
            missingMessage: {
                values: {
                    en: "Missing information",
                },
            },
            invalidMessage: {
                values: {
                    en: "Invalid information",
                },
            },
            invalidLengthMessage: {
                values: {
                    en: "Must be between 1 and 20 chars",
                },
            },
            note: {
                values: {
                    en: "Your last name should match...",
                },
            },
        },
        {
            type: "date",
            name: "dateOfBirth",
            saveTo: "userSpecificData",
            label: {
                values: {
                    en: "Date of Birth",
                },
            },
            required: true,
            // "min" has to be the start of the year
            min: "1920-01-01",
            // "max" has to be the end of the year, if "min" is specified, "max" must be too
            max: "2023-12-31",
            dateType: "pastOnly",
            allowToday: true,
            missingMessage: {
                values: {
                    en: "Missing information",
                },
            },
            invalidMessage: {
                values: {
                    en: "Invalid date",
                },
            },
            note: {
                values: {
                    en: "Your date of birth should match...",
                },
            },
        },
        {
            type: "countrySelect",
            // nationality || passportCountry || countryOfResidence
            name: "nationality",
            saveTo: "userSpecificData",
            label: {
                values: {
                    en: "Passport Country",
                },
            },
            missingMessage: {
                values: {
                    en: "Missing information",
                },
            },
            invalidMessage: {
                values: {
                    en: "Invalid nationatlity",
                },
            },
            note: {
                values: {
                    en: "Your nationality should match...",
                },
            },
        },
        /* {
            type: "countrySelect",
            // nationality || passportCountry || countryOfResidence
            name: "passportCountry",
            saveTo: "userSpecificData",
            label: {
                values: {
                    en: "Passport Country",
                },
            },
            missingMessage: {
                values: {
                    en: "Missing information",
                },
            },
            invalidMessage: {
                values: {
                    en: "Invalid passport country",
                },
            },
            note: {
                values: {
                    en: "Your passport country should match...",
                },
            },
        }, */
        {
            type: "countrySelect",
            // nationality || passportCountry || countryOfResidence
            name: "countryOfResidence",
            saveTo: "userSpecificData",
            label: {
                values: {
                    en: "Country of Residence",
                },
            },
            missingMessage: {
                values: {
                    en: "Missing information",
                },
            },
            invalidMessage: {
                values: {
                    en: "Invalid country of residence",
                },
            },
            note: {
                values: {
                    en: "Your country of residence should match...",
                },
            },
        },
        {
            type: "email",
            name: "email",
            saveTo: "userSpecificData.parameters",
            label: {
                values: {
                    en: "Email Address",
                },
            },
            required: true,
            pattern: "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$",
            minLength: 1,
            maxLength: 40,
            missingMessage: {
                values: {
                    en: "Missing information",
                },
            },
            invalidMessage: {
                values: {
                    en: "Invalid information",
                },
            },
            invalidLengthMessage: {
                values: {
                    en: "Must be between 1 and 20 chars",
                },
            },
            note: {
                values: {
                    en: "Your email should match...",
                },
            },
        },
        {
            type: "select",
            name: "discipline",
            saveTo: "eventDetails.details",
            label: {
                values: {
                    en: "Discipline",
                },
            },
            required: true,
            options: [
                {
                    label: {
                        values: {
                            en: "Doctor",
                        },
                    },
                    value: "Doctor",
                },
                {
                    label: {
                        values: {
                            en: "Nurse",
                        },
                    },
                    value: "Nurse",
                },
                {
                    label: {
                        values: {
                            en: "Technician",
                        },
                    },
                    value: "Technician",
                },
            ],
            placeholder: {
                values: {
                    en: "Select discipline",
                },
            },
            missingMessage: {
                values: {
                    en: "Missing information",
                },
            },
        },
        {
            type: "phone",
            name: "phone",
            saveTo: "eventDetails.details",
            label: {
                values: {
                    en: "Phone",
                },
            },
            required: true,
            // Make sure to escape the character like "\"
            pattern: "^(\\+\\d{1,2}\\s?)?1?\\-?\\.?\\s?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$",
            minLength: 1,
            maxLength: 20,
            missingMessage: {
                values: {
                    en: "Missing information",
                },
            },
            invalidMessage: {
                values: {
                    en: "Invalid information",
                },
            },
            invalidLengthMessage: {
                values: {
                    en: "Must be between 1 and 20 chars",
                },
            },
            note: {
                values: {
                    en: "Your phone should match...",
                },
            },
        },
    ],
    env: "dev",
    passType: "carnival",
    header: {
        values: {
            en: "Add Cruise Details",
        },
    },
    inputs: [
        {
            name: "optionalNote",
            type: "note",
            customClass: "text-small",
            htmlText: {
                values: {
                    en:
                        "<p>Note paragraph 1 text bla bla bla</p>Lorem **bacon** ipsum dolor <em>ham</em> amet<p></p><p>blaksldkasdlksa dcjnshjad jsdkajd <strong>djhcfdskhfkdhskfds</strong> jsdbakjdsahdlasdlsaj <a href='#'>dskhvfdslfhdusilhfljdsh</a> dkfls fdklsj fsldkfldsk ldsfkjflkj flsdl</strong></p>",
                },
            },
            showIf: [
                {
                    field: "select",
                    values: ["opt1", "opt2"],
                },
            ],
        },
        {
            name: "userData.sharedData.cruiseDetails.cruiseLineCode",
            label: {
                values: {
                    en: "Cruise Line Code",
                },
            },
            type: "text",
            required: true,
            pattern: "^[A-Za-z]*$",
            minLength: 2,
            maxLength: 20,
            missingMessage: {
                values: {
                    en: "This field is required.",
                },
            },
            invalidMessage: {
                values: {
                    en: "Please enter a valid value",
                },
            },
            invalidLengthMessage: {
                values: {
                    en: "Must be between 2 and 20 characters long.",
                },
            },
        },
        {
            name: "userData.sharedData.cruiseDetails.bookingNumber",
            label: {
                values: {
                    en: "Booking Number",
                },
            },
            type: "text",
            required: true,
            pattern: "[0-9]{7}",
            minLength: 7,
            maxLength: 7,
            missingMessage: {
                values: {
                    en: "This field is required.",
                },
            },
            invalidMessage: {
                values: {
                    en: "Please enter a valid booking number",
                },
            },
            invalidLengthMessage: {
                values: {
                    en: "Must contain 7 digits.",
                },
            },
        },
        {
            name: "userData.sharedData.cruiseDetails.embarkationDate",
            type: "date",
            label: {
                values: {
                    en: "EmbarkationDate",
                },
            },
            required: true,
            min: "2022-01-01",
            max: "2022-12-31",
            dateType: "futureOnly",
            missingMessage: {
                values: {
                    en: "Please select embarcation date",
                },
            },
            allowToday: true,
            invalidMessage: {
                values: {
                    en: "Date must not be in the past",
                },
            },
        },
    ],
    advert: {
        link: "https://cibtvisas.com/visas",
        image: "https://myverifly.com/assets/adverts/cibt_visa.png",
    },
    disableGuestEdit: true,
    requirePassportCountry: true,
    passportCountryLabel: {
        values: {
            en: "Country of Passport",
        },
    },
    allowedEmbarkationDaysInPast: 30,
    visaIssuanceDaysInFutureAllowed: 45,
    disablePassportCountryCheckOnPassportForm: true,
    /* "airlineNoPNRmessage": {
        "values": {
            "en": "Airline selected is not currently enabled for PNR Lookup..."
        }
    },
    "flightModalInfo": {
        "values": {
            "en": "Please enter your trip deatials which will determine..."
        }
    },
    "connectingFlightModalInfo": {
        "values": {
            "en": "You can add <b>connecting</b> flights..."
        }
    }, */
    // passportVisaUploadOptionFirst: false,
    /* companionFormConfiguration: {
        instructions: {
            values: {
                en: "To avoid any issues during check-in, it is crucial to ensure that the information you provide <b>exactly matches</b> the details on your booking.<br>It is important to <b>avoid abbreviations or any variations</b> different from the information on your booking to ensure smooth travel arrangements."
            }
        },
        firstName: {
            note: {
                values: {
                    en: "For the passenger's <u>first name</u>, enter the given name(s) exactly as written in the passport they'll use for the journey. If they have a <u>middle name</u>, add it after their first name.<br>Make sure these details match the information you’ve provided in your booking."
                }
            }
        },
        lastName: {
            note: {
                values: {
                    en: "For the <u>last name</u> (surname), please enter it exactly as it appears in the companion's passport they're using for the journey. For example, if the last name is \"OZORA\" enter it exactly as \"OZORA\".<br>Make sure these details match the information you’ve provided in your booking."
                }
            }
        },
        dateOfBirth: {
            note: {
                values: {
                    en: "Before proceeding, please double-check that the date of birth (DOB) you provide here matches the one you have on your booking."
                }
            }
        }
    } */
};

export default FormConfigResponse;
