import { Form } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import Required from "./Required";

const AutoSelect = ({
    name,
    options,
    disabled,
    placeholder,
    onChange,
    requiredMessage,
    availableOptionsOnly,
    defaultInputValue,
    clearButton = true,
    ...remainingProps
}) => {
    const [selectedValue, setSelectedValue] = useState("");
    const [defaultValue, setDefaultValue] = useState("");

    const selectOption = e => {
        onChange({ value: e.target.value });
        setSelectedValue(e.target.value);
    };

    const pattern = options.map(o => o.label).join("|");

    const handleChange = o => (o[0]?.value ? onChange(o[0].value) : onChange(""));
    const onBlur = e => {
        const val = options.find(o => o.label === e.target.value)?.value;
        onChange(val);
    };

    useEffect(() => {
        if (options.length === 0) {
            onChange("");
            setDefaultValue("");
            setSelectedValue("");
        } else if (options.length === 1) {
            onChange(options[0].value);
            setDefaultValue(options[0].value);
        }
        // eslint-disable-next-line
    }, [options]);

    const sorter = (a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0);

    return options.length <= 1 ? (
        <Form.Control
            as="select"
            value={selectedValue || defaultValue}
            disabled={disabled}
            onChange={selectOption}
            {...remainingProps}
        >
            <option value="" disabled hidden>
                {placeholder}
            </option>
            {options.sort(sorter).map(({ value, label }, i) => (
                <option key={i} value={value}>
                    {label}
                </option>
            ))}
        </Form.Control>
    ) : (
        <Typeahead
            clearButton={clearButton}
            id={name}
            defaultInputValue={defaultInputValue}
            onChange={handleChange}
            onBlur={onBlur}
            placeholder={placeholder}
            options={options.sort(sorter)}
            renderInput={({ inputRef, referenceElementRef, ...inputProps }) => (
                <>
                    <Form.Control
                        required
                        disabled={disabled}
                        pattern={availableOptionsOnly && pattern}
                        {...inputProps}
                        ref={input => {
                            inputRef(input);
                            referenceElementRef(input);
                        }}
                    />
                    <Required message={requiredMessage} />
                </>
            )}
        ></Typeahead>
    );
};

export default AutoSelect;
