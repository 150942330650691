export const countries = {
    afghanistan: {
        code: "AF",
        fullName: "Afghanistan",
    },
    albania: {
        code: "AL",
        fullName: "Albania",
    },
    algeria: {
        code: "DZ",
        fullName: "Algeria",
    },
    americanSamoa: {
        code: "AS",
        fullName: "American Samoa",
    },
    andorra: {
        code: "AD",
        fullName: "Andorra",
    },
    angola: {
        code: "AO",
        fullName: "Angola",
    },
    anguilla: {
        code: "AI",
        fullName: "Anguilla",
    },
    antarctica: {
        code: "AQ",
        fullName: "Antarctica",
    },
    antigua: {
        code: "AG",
        fullName: "Antigua and Barbuda",
    },
    argentina: {
        code: "AR",
        fullName: "Argentina",
    },
    armenia: {
        code: "AM",
        fullName: "Armenia",
    },
    aruba: {
        code: "AW",
        fullName: "Aruba",
    },
    australia: {
        code: "AU",
        fullName: "Australia",
    },
    austria: {
        code: "AT",
        fullName: "Austria",
    },
    azerbaijan: {
        code: "AZ",
        fullName: "Azerbaijan",
    },
    bahamas: {
        code: "BS",
        fullName: "Bahamas",
        testWithinDaysDisplay: 5,
        testWithinHours: 120,
        testTypes: ["PCR"],
        testRequiredAfter: "",
        testAgeLimit: 11,
        travelAuth: true,
        travelPreAuth: false,
        travelAuthUrl: "https://travel.gov.bs/",
        travelAuthResidentsUrl: "",
        travelAuthNonResidentsUrl: "",
        travelReferenceNumber: true,
        travelReferenceNumberInput: false,
        beforeAirportFormWithinHours: 0,
    },
    bahrain: {
        code: "BH",
        fullName: "Bahrain",
    },
    bangladesh: {
        code: "BD",
        fullName: "Bangladesh",
    },
    barbados: {
        code: "BB",
        fullName: "Barbados",
    },
    belarus: {
        code: "BY",
        fullName: "Belarus",
    },
    belgium: {
        code: "BE",
        fullName: "Belgium",
    },
    belize: {
        code: "BZ",
        fullName: "Belize",
    },
    benin: {
        code: "BJ",
        fullName: "Benin",
    },
    bermuda: {
        code: "BM",
        fullName: "Bermuda",
    },
    bhutan: {
        code: "BT",
        fullName: "Bhutan",
    },
    bolivia: {
        code: "BO",
        fullName: "Plurinational State of Bolivia",
    },
    bonaire: {
        code: "BQ",
        fullName: "Bonaire, Sint Eustatius and Saba",
    },
    bosniaAndHerzegovina: {
        code: "BA",
        fullName: "Bosnia and Herzegovina",
    },
    Botswana: {
        code: "BW",
        fullName: "Botswana",
    },
    bouvetIsland: {
        code: "BV",
        fullName: "Bouvet Island",
    },
    brazil: {
        code: "BR",
        fullName: "Brazil",
        airports: ["GIG", "GRU", "MAO"],
        testWithinDaysDisplay: 3,
        testWithinHours: 72,
        testTypes: ["PCR"],
        testRequiredAfter: "",
        travelAuth: true,
        travelPreAuth: true,
        travelAuthUrl: "https://formulario.anvisa.gov.br/",
        travelAuthResidentsUrl: "",
        travelAuthNonResidentsUrl: "",
        travelReferenceNumber: false,
        travelReferenceNumberInput: false,
        travelReferenceNumberInputOnAuth: true,
        travelReferenceNumberInputLabel: "travelAuth.passportNumber.label",
        beforeAirportFormWithinHours: 0,
    },
    britishIndianOceanTerritory: {
        code: "IO",
        fullName: "British Indian Ocean Territory",
    },
    brunei: {
        code: "BN",
        fullName: "Brunei Darussalam",
    },
    bulgaria: {
        code: "BG",
        fullName: "Bulgaria",
    },
    burkinaFaso: {
        code: "BF",
        fullName: "Burkina Faso",
    },
    burundi: {
        code: "BI",
        fullName: "Burundi",
    },
    caboVerde: {
        code: "CV",
        fullName: "Cabo Verde",
    },
    cambodia: {
        code: "KH",
        fullName: "Cambodia",
    },
    cameroon: {
        code: "CM",
        fullName: "Cameroon",
    },
    canada: {
        code: "CA",
        fullName: "Canada",
        airports: ["YYZ", "YVR", "YYC", "YUL"],
        testWithinDaysDisplay: 3,
        testWithinHours: 72,
        testTypes: ["PCR", "LAMP", "NAA"],
        testRequiredAfter: "",
        testAgeLimit: 5,
        travelAuth: true,
        travelPreAuth: false,
        travelAuthUrl: "https://arrivecan.cbsa-asfc.cloud-nuage.canada.ca/privacy",
        travelAuthResidentsUrl: "",
        travelAuthNonResidentsUrl: "",
        travelReferenceNumber: false,
        travelReferenceNumberInput: true,
        travelReferenceNumberInputLabel: "travelAuth.referenceNumber.label",
        beforeAirportFormWithinHours: 0,
        refused: true,
    },
    caymanIslands: {
        code: "KY",
        fullName: "Cayman Islands",
    },
    centralAfricanRepublic: {
        code: "CF",
        fullName: "Central African Republic",
    },
    chad: {
        code: "TD",
        fullName: "Chad",
    },
    chile: {
        code: "CL",
        fullName: "Chile",
        airports: ["SCL"],
        testWithinDaysDisplay: 3,
        testWithinHours: 72,
        testTypes: ["LABPCR"],
        testRequiredAfter: "",
        travelAuth: true,
        travelPreAuth: false,
        travelAuthUrl: "https://www.c19.cl/",
        travelAuthResidentsUrl: "",
        travelAuthNonResidentsUrl: "",
        travelReferenceNumber: true,
        travelReferenceNumberInput: false,
        beforeAirportFormWithinHours: 0,
    },
    china: {
        code: "CN",
        fullName: "China",
    },
    christmasIsland: {
        code: "CX",
        fullName: "Christmas Island",
    },
    cocosIslands: {
        code: "CC",
        fullName: "Cocos (Keeling) Islands",
    },
    colombia: {
        code: "CO",
        fullName: "Colombia",
        airports: ["BOG", "BAQ", "CLO", "CTG", "MDE"],
        testWithinDaysDisplay: 4,
        testWithinHours: 96,
        testTypes: ["PCR"],
        testRequiredAfter: "01/01/2021",
        travelAuth: true,
        travelPreAuth: true,
        travelAuthUrl: "https://apps.migracioncolombia.gov.co/pre-registro/public/preregistro.jsf",
        travelAuthResidentsUrl: "",
        travelAuthNonResidentsUrl: "",
        travelReferenceNumber: false,
        travelReferenceNumberInput: false,
        beforeAirportFormWithinHours: 0,
    },
    comoros: {
        code: "KM",
        fullName: "Comoros",
    },
    congoDemoctraticRepublic: {
        code: "CD",
        fullName: "Democratic Republic of the Congo",
    },
    congo: {
        code: "CG",
        fullName: "Congo",
    },
    cookIslands: {
        code: "CK",
        fullName: "Cook Islands",
    },
    costaRica: {
        code: "CR",
        fullName: "Costa Rica",
    },
    croatia: {
        code: "HR",
        fullName: "Croatia",
    },
    cuba: {
        code: "CU",
        fullName: "Cuba",
    },
    curacao: {
        code: "CW",
        fullName: "Curaçao",
    },
    cyprus: {
        code: "CY",
        fullName: "Cyprus",
    },
    czechia: {
        code: "CZ",
        fullName: "Czechia",
    },
    coteDivoire: {
        code: "CI",
        fullName: "Côte d'Ivoire",
    },
    denmark: {
        code: "DK",
        fullName: "Denmark",
    },
    djibouti: {
        code: "DJ",
        fullName: "Djibouti",
    },
    dominica: {
        code: "DM",
        fullName: "Dominica",
    },
    dominicanRepublic: {
        code: "DO",
        fullName: "Dominican Republic",
    },
    ecuador: {
        code: "EC",
        fullName: "Ecuador",
    },
    egypt: {
        code: "EG",
        fullName: "Egypt",
    },
    elSalvador: {
        code: "SV",
        fullName: "El Salvador",
        airports: ["SAL"],
        testWithinDaysDisplay: 3,
        testWithinHours: 72,
        testTypes: ["PCR"],
        testRequiredAfter: "",
        travelAuth: false,
        travelPreAuth: false,
        travelAuthUrl: "",
        travelAuthResidentsUrl: "",
        travelAuthNonResidentsUrl: "",
        travelReferenceNumber: true,
        travelReferenceNumberInput: false,
        beforeAirportFormWithinHours: 0,
    },
    equatorialGuinea: {
        code: "GQ",
        fullName: "Equatorial Guinea",
    },
    eritrea: {
        code: "ER",
        fullName: "Eritrea",
    },
    estonia: {
        code: "EE",
        fullName: "Estonia",
    },
    eswatini: {
        code: "SZ",
        fullName: "Eswatini",
    },
    ethiopia: {
        code: "ET",
        fullName: "Ethiopia",
    },
    falklandIslands: {
        code: "FK",
        fullName: "Falkland Islands",
    },
    faroeIslands: {
        code: "FO",
        fullName: "Faroe Islands",
    },
    fiji: {
        code: "FJ",
        fullName: "Fiji",
    },
    finland: {
        code: "FI",
        fullName: "Finland",
    },
    france: {
        code: "FR",
        fullName: "France",
        testWithinDaysDisplay: 5,
        testWithinHours: 72,
        testTypes: [],
        testRequiredAfter: "",
        testAgeLimit: 11,
        travelAuth: false,
        travelPreAuth: true,
        travelAuthUrl: "",
        travelAuthResidentsUrl: "",
        travelAuthNonResidentsUrl: "",
        travelReferenceNumber: false,
        travelReferenceNumberInput: false,
        beforeAirportFormWithinHours: 0,
    },
    frenchGuiana: {
        code: "GF",
        fullName: "French Guiana",
    },
    frenchPolynesia: {
        code: "PF",
        fullName: "French Polynesia",
    },
    frenchSouthernTerritories: {
        code: "TF",
        fullName: "French Southern Territories",
    },
    gabon: {
        code: "GA",
        fullName: "Gabon",
    },
    gambia: {
        code: "GM",
        fullName: "Gambia",
    },
    georgia: {
        code: "GE",
        fullName: "Georgia",
    },
    germany: {
        code: "DE",
        fullName: "Germany",
    },
    ghana: {
        code: "GH",
        fullName: "Ghana",
    },
    gibraltar: {
        code: "GI",
        fullName: "Gibraltar",
    },
    greece: {
        code: "GR",
        fullName: "Greece",
    },
    greenland: {
        code: "GL",
        fullName: "Greenland",
    },
    grenada: {
        code: "GD",
        fullName: "Grenada",
    },
    guadeloupe: {
        code: "GP",
        fullName: "Guadeloupe",
    },
    guam: {
        code: "GU",
        fullName: "Guam",
    },
    guatemala: {
        code: "GT",
        fullName: "Guatemala",
        airports: ["GUA"],
        testWithinDaysDisplay: 3,
        testWithinHours: 72,
        testWithinDays: 3,
        testTypes: ["PCR", "ANTIGEN"],
        testRequiredAfter: "",
        travelAuth: true,
        travelPreAuth: false,
        travelAuthUrl: "https://servicios.igm.gob.gt/pasedesalud/",
        travelAuthResidentsUrl: "",
        travelAuthNonResidentsUrl: "",
        travelReferenceNumber: true,
        travelReferenceNumberInput: false,
        beforeAirportFormWithinHours: 0,
    },
    guernsey: {
        code: "GG",
        fullName: "Guernsey",
    },
    guinea: {
        code: "GN",
        fullName: "Guinea",
    },
    guineaBissau: {
        code: "GW",
        fullName: "Guinea-Bissau",
    },
    guyana: {
        code: "GY",
        fullName: "Guyana",
    },
    haiti: {
        code: "HT",
        fullName: "Haiti",
    },
    hawaii: {
        code: "US-HI",
        fullName: "Hawaii",
    },
    heardIsland: {
        code: "HM",
        fullName: "Heard Island and McDonald Islands",
    },
    holySee: {
        code: "VA",
        fullName: "Holy See",
    },
    honduras: {
        code: "HN",
        fullName: "Honduras",
        airports: ["RTB", "SAP", "TGU"],
        testWithinDaysDisplay: 3,
        testWithinHours: 72,
        testTypes: ["PCR", "ANTIGEN", "RAPID"],
        testRequiredAfter: "",
        travelAuth: true,
        travelPreAuth: false,
        travelAuthUrl: "https://prechequeo.inm.gob.hn/",
        travelAuthResidentsUrl: "",
        travelAuthNonResidentsUrl: "",
        travelReferenceNumber: true,
        travelReferenceNumberInput: false,
        beforeAirportFormWithinHours: 0,
    },
    hongKong: {
        code: "HK",
        fullName: "Hong Kong",
    },
    hungary: {
        code: "HU",
        fullName: "Hungary",
    },
    iceland: {
        code: "IS",
        fullName: "Iceland",
    },
    india: {
        code: "IN",
        fullName: "India",
    },
    indonesia: {
        code: "ID",
        fullName: "Indonesia",
    },
    iran: {
        code: "IR",
        fullName: "Iran (Islamic Republic of)",
    },
    iraq: {
        code: "IQ",
        fullName: "Iraq",
    },
    ireland: {
        code: "IE",
        fullName: "Ireland",
        testWithinDaysDisplay: 3,
        testWithinHours: 72,
        testTypes: [],
        testRequiredAfter: "",
        testAgeLimit: 7,
        travelAuth: false,
        travelPreAuth: true,
        travelAuthUrl: "",
        travelAuthResidentsUrl: "",
        travelAuthNonResidentsUrl: "",
        travelReferenceNumber: false,
        travelReferenceNumberInput: false,
        beforeAirportFormWithinHours: 0,
        redCountryCheck: true,
    },
    isleOfMan: {
        code: "IM",
        fullName: "Isle of Man",
    },
    israel: {
        code: "IL",
        fullName: "Israel",
    },
    italy: {
        code: "IT",
        fullName: "Italy",
    },
    jamaica: {
        code: "JM",
        fullName: "Jamaica",
        airports: ["KIN", "MBJ"],
        testWithinDaysDisplay: 3,
        testWithinHours: 72,
        testWithinDays: 3,
        testTypes: ["PCR", "NAA", "RNA", "ANTIGEN"],
        testRequiredAfter: "",
        testAgeLimit: 12,
        travelAuth: true,
        travelPreAuth: false,
        travelAuthUrl: "",
        travelAuthResidentsUrl: "https://jamcovid19.moh.gov.jm/immigration.html",
        travelAuthNonResidentsUrl: "https://www.visitjamaica.com/travelauthorization/",
        travelReferenceNumber: true,
        travelReferenceNumberInput: false,
        beforeAirportFormWithinHours: 0,
    },
    japan: {
        code: "JP",
        fullName: "Japan",
    },
    jersey: {
        code: "JE",
        fullName: "Jersey",
    },
    jordan: {
        code: "JO",
        fullName: "Jordan",
    },
    kazakhstan: {
        code: "KZ",
        fullName: "Kazakhstan",
    },
    kenya: {
        code: "KE",
        fullName: "Kenya",
    },
    kiribati: {
        code: "KI",
        fullName: "Kiribati",
    },
    koreaDpr: {
        code: "KP",
        fullName: "Democratic People's Republic of Korea",
    },
    korea: {
        code: "KR",
        fullName: "Republic of Korea",
    },
    kuwait: {
        code: "KW",
        fullName: "Kuwait",
    },
    kyrgyzstan: {
        code: "KG",
        fullName: "Kyrgyzstan",
    },
    lao: {
        code: "LA",
        fullName: "Lao People's Democratic Republic",
    },
    latvia: {
        code: "LV",
        fullName: "Latvia",
    },
    lebanon: {
        code: "LB",
        fullName: "Lebanon",
    },
    lesotho: {
        code: "LS",
        fullName: "Lesotho",
    },
    liberia: {
        code: "LR",
        fullName: "Liberia",
    },
    libya: {
        code: "LY",
        fullName: "Libya",
    },
    liechtenstein: {
        code: "LI",
        fullName: "Liechtenstein",
    },
    lithuania: {
        code: "LT",
        fullName: "Lithuania",
    },
    luxembourg: {
        code: "LU",
        fullName: "Luxembourg",
    },
    macao: {
        code: "MO",
        fullName: "Macao",
    },
    madagascar: {
        code: "MG",
        fullName: "Madagascar",
    },
    malawi: {
        code: "MW",
        fullName: "Malawi",
    },
    malaysia: {
        code: "MY",
        fullName: "Malaysia",
    },
    maldives: {
        code: "MV",
        fullName: "Maldives",
    },
    mali: {
        code: "ML",
        fullName: "Mali",
    },
    malta: {
        code: "MT",
        fullName: "Malta",
        arrivalTimeZone: "CET",
    },
    marshallIslands: {
        code: "MH",
        fullName: "Marshall Islands",
    },
    martinique: {
        code: "MQ",
        fullName: "Martinique",
    },
    mauritania: {
        code: "MR",
        fullName: "Mauritania",
    },
    mauritius: {
        code: "MU",
        fullName: "Mauritius",
    },
    mayotte: {
        code: "YT",
        fullName: "Mayotte",
    },
    mexico: {
        code: "MX",
        fullName: "Mexico",
    },
    micronesia: {
        code: "FM",
        fullName: "Federated States of Micronesia",
    },
    moldova: {
        code: "MD",
        fullName: "Republic of Moldova",
    },
    monaco: {
        code: "MC",
        fullName: "Monaco",
    },
    mongolia: {
        code: "MN",
        fullName: "Mongolia",
    },
    montenegro: {
        code: "ME",
        fullName: "Montenegro",
    },
    montserrat: {
        code: "MS",
        fullName: "Montserrat",
    },
    morocco: {
        code: "MA",
        fullName: "Morocco"
    },
    mozambique: {
        code: "	MZ",
        fullName: "Mozambique",
    },
    myanmar: {
        code: "MM",
        fullName: "Myanmar",
    },
    namibia: {
        code: "NA",
        fullName: "Namibia",
    },
    nauru: {
        code: "NR",
        fullName: "Nauru",
    },
    nepal: {
        code: "NP",
        fullName: "Nepal",
    },
    netherlands: {
        code: "NL",
        fullName: "Netherlands",
    },
    newCaledonia: {
        code: "NC",
        fullName: "New Caledonia",
    },
    newZealand: {
        code: "NZ",
        fullName: "New Zealand",
    },
    nicaragua: {
        code: "NI",
        fullName: "Nicaragua",
    },
    niger: {
        code: "NE",
        fullName: "Niger",
    },
    nigeria: {
        code: "NG",
        fullName: "Nigeria",
    },
    niue: {
        code: "NU",
        fullName: "Niue",
    },
    norfolkIsland: {
        code: "NF",
        fullName: "Norfolk Island",
    },
    northernMarianaIslands: {
        code: "MP",
        fullName: "Northern Mariana Islands",
    },
    norway: {
        code: "NO",
        fullName: "Norway",
    },
    oman: {
        code: "OM",
        fullName: "Oman",
    },
    pakistan: {
        code: "PK",
        fullName: "Pakistan",
    },
    palau: {
        code: "PW",
        fullName: "Palau",
    },
    palestine: {
        code: "PS",
        fullName: "State of Palestine",
    },
    panama: {
        code: "PA",
        fullName: "Panama",
    },
    papuaNewGuinea: {
        code: "PG",
        fullName: "Papua New Guinea",
    },
    paraguay: {
        code: "PY",
        fullName: "Paraguay",
    },
    peru: {
        code: "PE",
        fullName: "Peru",
    },
    philippines: {
        code: "PH",
        fullName: "Philippines",
    },
    pitcairn: {
        code: "PN",
        fullName: "Pitcairn",
    },
    poland: {
        code: "PL",
        fullName: "Poland",
    },
    portugal: {
        code: "PT",
        fullName: "Portugal",
    },
    puertoRico: {
        code: "PR",
        fullName: "Puerto Rico",
    },
    qatar: {
        code: "QA",
        fullName: "Qatar",
    },
    republicOfNorthMacedonia: {
        code: "MK",
        fullName: "Republic of North Macedonia",
    },
    romania: {
        code: "RO",
        fullName: "Romania",
    },
    russianFederation: {
        code: "RU",
        fullName: "Russian Federation",
    },
    rwanda: {
        code: "RW",
        fullName: "Rwanda",
    },
    reunion: {
        code: "RE",
        fullName: "Réunion",
    },
    samoa: {
        code: "WS",
        fullName: "Samoa",
    },
    sanMarino: {
        code: "SM",
        fullName: "San Marino",
    },
    saoTomeAndPrincipe: {
        code: "ST",
        fullName: "Sao Tome and Principe",
    },
    saudiArabia: {
        code: "SA",
        fullName: "Saudi Arabia",
    },
    senegal: {
        code: "SN",
        fullName: "Senegal",
    },
    serbia: {
        code: "RS",
        fullName: "Serbia",
    },
    seychelles: {
        code: "SC",
        fullName: "Seychelles",
    },
    sierraLeone: {
        code: "SL",
        fullName: "Sierra Leone",
    },
    singapore: {
        code: "SG",
        fullName: "Singapore",
    },
    slovakia: {
        code: "SK",
        fullName: "Slovakia",
    },
    slovenia: {
        code: "SI",
        fullName: "Slovenia",
    },
    solomonIslands: {
        code: "SB",
        fullName: "Solomon Islands",
    },
    somalia: {
        code: "SO",
        fullName: "Somalia",
    },
    southAfrica: {
        code: "ZA",
        fullName: "South Africa",
    },
    southGeorgiaAndTheSouthSandwichIslands: {
        code: "GS",
        fullName: "South Georgia and the South Sandwich Islands",
    },
    southSudan: {
        code: "SS",
        fullName: "South Sudan",
    },
    spain: {
        code: "ES",
        fullName: "Spain",
    },
    sriLanka: {
        code: "	LK",
        fullName: "Sri Lanka",
    },
    stBarts: {
        code: "BL",
        fullName: "Saint Barthélemy",
    },
    stHelena: {
        code: "SH",
        fullName: "Saint Helena, Ascension and Tristan da Cunha",
    },
    stKitts: {
        code: "KN",
        fullName: "Saint Kitts and Nevis",
    },
    stLucia: {
        code: "LC",
        fullName: "Saint Lucia",
    },
    stMaarten: {
        code: "SX",
        fullName: "Sint Maarten",
    },
    stMartin: {
        code: "MF",
        fullName: "Saint Martin",
    },
    stPierre: {
        code: "PM",
        fullName: "Saint Pierre and Miquelon",
    },
    stVincent: {
        code: "VC",
        fullName: "Saint Vincent and the Grenadines",
    },
    sudan: {
        code: "SD",
        fullName: "Sudan",
    },
    suriname: {
        code: "SR",
        fullName: "Suriname",
    },
    svalbardAndJanMayen: {
        code: "SJ",
        fullName: "Svalbard and Jan Mayen",
    },
    sweden: {
        code: "SE",
        fullName: "Sweden",
    },
    switzerland: {
        code: "CH",
        fullName: "Switzerland",
    },
    syrianArabRepublic: {
        code: "SY",
        fullName: "Syrian Arab Republic",
    },
    taiwan: {
        code: "TW",
        fullName: "Taiwan",
    },
    tajikistan: {
        code: "TJ",
        fullName: "Tajikistan",
    },
    tanzania: {
        code: "TZ",
        fullName: "Tanzania",
    },
    thailand: {
        code: "TH",
        fullName: "Thailand",
    },
    timorLeste: {
        code: "TL",
        fullName: "Timor-Leste",
    },
    togo: {
        code: "TG",
        fullName: "Togo",
    },
    tokelau: {
        code: "TK",
        fullName: "Tokelau",
    },
    tonga: {
        code: "TO",
        fullName: "Tonga",
    },
    trinidadAndTobago: {
        code: "TT",
        fullName: "Trinidad and Tobago",
    },
    tunisia: {
        code: "TN",
        fullName: "Tunisia",
    },
    turkey: {
        code: "TR",
        fullName: "Turkey",
    },
    turkmenistan: {
        code: "TM",
        fullName: "Turkmenistan",
    },
    turksAndCaicos: {
        code: "TC",
        fullName: "Turks and Caicos Islands",
    },
    tuvalu: {
        code: "TV",
        fullName: "Tuvalu",
    },
    uganda: {
        code: "UG",
        fullName: "Uganda",
    },
    ukraine: {
        code: "UA",
        fullName: "Ukraine",
    },
    uae: {
        code: "AE",
        fullName: "United Arab Emirates",
    },
    uk: {
        code: "GB",
        fullName: "the United Kingdom",
        testWithinDaysDisplay: 3,
        testWithinHours: 72,
        testWithinDays: 3,
        testTypes: ["PCR", "LAMP", "ANTIGEN", "NAA"],
        testRequiredAfter: "",
        testAgeLimit: 11,
        travelAuth: true,
        travelPreAuth: false,
        travelAuthUrl: "https://www.gov.uk/provide-journey-contact-details-before-travel-uk",
        travelAuthResidentsUrl: "",
        travelAuthNonResidentsUrl: "",
        travelReferenceNumber: true,
        travelReferenceNumberInput: false,
        beforeAirportFormWithinHours: 0,
        attestation: false,
        uploadNote: true,
        redCountryCheck: true,
    },
    unitedStatesMinorOutlyingIslands: {
        code: "UM",
        fullName: "United States Minor Outlying Islands",
    },
    usa: {
        code: "US",
        fullName: "United States of America",
        airports: [
            "AUS",
            "BOS",
            "CLT",
            "CMH",
            "DCA",
            "DFW",
            "IND",
            "JFK",
            "LAX",
            "LGA",
            "MCI",
            "MIA",
            "ORD",
            "PHL",
            "PHX",
            "RDU",
            "SMF",
            "STL",
        ],
        symptomsThresholdDays: "5",
        testWithinDaysDisplay: 3,
        testWithinDays: 3,
        testWithinHours: 72,
        testTypes: ["PCR", "ANTIGEN", "LAMP", "NAA", "TMA"],
        testRequiredAfter: "",
        testAgeLimit: 2,
        travelAuth: false,
        travelPreAuth: true,
        travelAuthUrl: "https://",
        travelAuthResidentsUrl: "",
        travelAuthNonResidentsUrl: "",
        travelReferenceNumber: false,
        beforeAirportFormWithinHours: 0,
        travelReferenceNumberInput: false,
        attestation: true,
    },
    uruguay: {
        code: "UY",
        fullName: "Uruguay",
    },
    uzbekistan: {
        code: "UZ",
        fullName: "Uzbekistan",
    },
    vanuatu: {
        code: "VU",
        fullName: "Vanuatu",
    },
    venezuela: {
        code: "VE",
        fullName: "Venezuela (Bolivarian Republic of)",
    },
    vietNam: {
        code: "VN",
        fullName: "Viet Nam",
    },
    virginIslandsBritish: {
        code: "VG",
        fullName: "Virgin Islands (British)",
    },
    virginIslandsUS: {
        code: "VI",
        fullName: "Virgin Islands (U.S.)",
    },
    wallisAndFutuna: {
        code: "WF",
        fullName: "Wallis and Futuna",
    },
    westernSahara: {
        code: "EH",
        fullName: "Western Sahara",
    },
    yemen: {
        code: "YE",
        fullName: "Yemen",
    },
    zambia: {
        code: "ZM",
        fullName: "Zambia",
    },
    zimbabwe: {
        code: "ZW",
        fullName: "Zimbabwe",
    },
    alandIslands: {
        code: "AX",
        fullName: "Åland Islands",
    },
};
