/**
 * Js api representing a wrapper around the fetch functionality
 */

const _apiHost = '';

let language = 'en-US';

async function request(url, token, params, method = 'GET') {

    // options passed to the fetch request
    const options = {
        method,
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json', // we will be sending JSON
            'Authorization': "Bearer " + token,
            'Accept-Language': language
        }
    };

    // if params exists and method is GET, add query string to url
    // otherwise, just add params as a "body" property to the options object
    if (params) {
        if (method === 'GET') {
            url += '?' + objectToQueryString(params);
        } else {
            options.body = JSON.stringify(params); // body should match Content-Type in headers option
        }
    }

    // fetch returns a promise, so we add keyword await to wait until the promise settles
    // returns a single Promise object
    return await fetch(_apiHost + url, options);
}

function objectToQueryString(obj) {
    return Object.keys(obj).map(key => key + '=' + obj[key]).join('&');
}

function get(url, token, params) {
    return request(url, token, params);
}

function getStatusOnly(url, token, params) {
    return request(url, token, params, 'GET', false);
}

function create(url, token, params) {
    return request(url, token, params, 'POST');
}

function createStatusOnly(url, token, params) {
    return request(url, token, params, 'POST', false);
}

function update(url, token, params) {
    return request(url, token, params, 'PUT');
}

function remove(url, token, params) {
    return request(url, token, params, 'DELETE');
}

function setLanguage(lang) {
    language = lang;
}

const f = {
    get,
    getStatusOnly,
    create,
    createStatusOnly,
    update,
    remove,
    setLanguage
};

export default f;