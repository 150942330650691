import { getLocal, setLocal } from "./utils";

const APPROVALS_KEY = "approvals";

const approvalsObject = {
    cruiseHealthCheckConsentApproved: false,
    cruisePrivacyConsentApproved: false,
    cruiseStorageConsentApproved: false,
    dataStorageConsentApproved: false,
    healthCheckConsentApproved: false,
    privacyPolicyApproved: false,
    usagParticipantHealthCheckConsentApproved: false,
    usagParticipantPrivacyPolicyConsentApproved: false,
    usagSpectatorHealthCheckConsentApproved: false,
    usagSpectatorPrivacyPolicyConsentApproved: false,
    eventPrivacyConsentApproved: false,
    eventHealthCheckConsentApproved: false,
    eventStorageConsentApproved: false,
    workReturnPrivacyConsentApproved: false,
    workReturnHealthCheckConsentApproved: false,
    workReturnStorageConsentApproved: false,
    paymentPolicyConsentApproved: false,
    biometricPolicyConsentApproved: false
};

// POST
// TO DO, apply changes for new consent management
export const approvalsPost = (req, res, ctx) => {
    const approvals = getLocal(APPROVALS_KEY) || approvalsObject;
    const approvalsUpdate = { ...approvals, ...req.body };
    setLocal(APPROVALS_KEY, approvalsUpdate);
    return res(ctx.status(200), ctx.delay(), ctx.json(approvalsUpdate));
};
