import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { getCountry } from "../countryUtils";
import { getCurrentOption, getOptions } from "../steps";
import { useLanguage, useQuery, useStep } from "./index";

const useStepOption = (processInstance, companionId, predicate, usePredicate) => {
    const [options, setOptions] = useState([]);
    const [option, setOption] = useState();
    const [loaded, setLoaded] = useState(false);

    const { step, isComplete } = useStep(processInstance, predicate, usePredicate);
    const { localizeField } = useLanguage();
    const location = useLocation();
    const query = useQuery();
    const history = useHistory();

    const getLocalizedField = fieldName => option && option[fieldName] && localizeField(option[fieldName]);

    const findOption = pred => options.find(pred);

    const getOptionUrl = (view, updateQuery, isHealthReview) => {
        if (updateQuery) {
            query.set("reviewProcess", "true");
        }
        if (isHealthReview) {
            query.set('healthReview', true);
        }
        return `${view.split(":")[0]}/${getCountry(view)}?${query.toString()}`;
    };

    const redirectToOption = (pred, isHealthReview) => {
        const opt = findOption(pred);
        const view = opt?.view;
        view && history.replace(getOptionUrl(view, false, isHealthReview));
    }

    useEffect(() => {
        if (step) {
            const opts = getOptions(step);
            setOptions(opts);
            const opt = getCurrentOption(step, location.pathname);
            setOption(opt);
            setLoaded(true);
        }
    }, [step]);

    return { option, getLocalizedField, getOptionUrl, redirectToOption, options, isComplete, loaded };
};

export default useStepOption;
