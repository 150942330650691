// box shadow settings
const shadow = "0 0 8px 0.2rem ";

const borderBase = "1px solid ";

const white = "rgba(255, 255, 255, 1)";
const black = "rgba(0, 0, 0, 1)";

const getInvalidIcon = color => {
    return (
        "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23" +
        color +
        "' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' " +
        "d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23" +
        color +
        "' stroke='none'/%3e%3c/svg%3e"
    );
};

const getValidIcon = color => {
    return (
        "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23" +
        color +
        "' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e"
    );
};

// light theme colors that repeat
const lightColors = {
    text: "#212529",
    background: "#e9ecef",
    container: "#F8F9FA",
    accent: "#007bff",
    border: "#CED4DA",
    info: "#AAAAAA",
    pending: "#F27316",
    invalid: "#dc3545",
    valid: "#28a745",
    black25: "rgba(0, 0, 0, 0.25)",
    muted: "#6c757d",
    invalidIcon: getInvalidIcon("dc3545"),
    validIcon: getValidIcon("28a745"),
};

// dark theme colors that repeat
const darkColors = {
    backgroundSolid: "#42505D",
    background: "linear-gradient(180deg, rgba(23,34,45,1) 0%, rgba(45,65,83,1) 50%, rgba(23,34,45,1) 100%)",
    accent: "#D2EAFF",
    white: "rgba(255, 255, 255, 1)",
    white5: "rgba(255, 255, 255, 0.05)",
    white10: "rgba(255, 255, 255, 0.05)",
    white25: "rgba(255, 255, 255, 0.25)",
    white50: "rgba(255, 255, 255, 0.50)",
    pending: "#E7CB6B",
    invalid: "#FFC79C",
    valid: "#8DDD9E",
    muted: "#526B8B",
    invalidIcon: getInvalidIcon("FFC79C"),
    validIcon: getValidIcon("8DDD9E"),
};

export const Themes = {
    light: {
        global: {
            text: lightColors.text,
            background: lightColors.background,
            container: lightColors.container,
            accent: lightColors.accent,
            transparent: "transparent",
        },
        forms: {
            background: lightColors.container,
            backgroundSolid: lightColors.container,
            text: lightColors.text,
            border: borderBase + lightColors.border,
            borderInput: borderBase + lightColors.border,
            shadowInput: "0 0 0.4em 0.1em #e4e4e4",
            disabledText: "#495057",
            disabledBackground: lightColors.background,
            shadow: shadow + lightColors.accent + "20",
            invalid: lightColors.invalid,
            valid: lightColors.valid,
            info: lightColors.info,
            secondaryInfo: lightColors.muted,
            buttonInfo: white,
            pending: lightColors.pending,
            optional: lightColors.text,
            invalidShadow: shadow + lightColors.invalid + "40",
            validShadow: shadow + lightColors.valid + "40",
            validIcon: lightColors.validIcon,
            invalidIcon: lightColors.invalidIcon,
            mutedText: lightColors.muted,
            textAccented: lightColors.text,
            kebabMenu: black,
            credentialStep: lightColors.black25,
            timePicker: "invert(0)",
            fieldOpacity: "0.5",
            option: lightColors.container,
            radioButtonBorder: lightColors.black25,
        },
        buttons: {
            primary: {
                background: lightColors.accent,
                shadow: shadow + lightColors.accent + "20",
                text: white,
            },
            secondary: {
                background: lightColors.muted,
                shadow: shadow + lightColors.muted + "20",
                text: white,
            },
            light: {
                background: "transparent",
                shadow: shadow + lightColors.accent + "20",
                text: lightColors.accent,
            },
            success: {
                background: lightColors.valid,
                shadow: shadow + lightColors.valid + "20",
                text: white,
            },
            danger: {
                background: lightColors.invalid,
                shadow: shadow + lightColors.invalid + "20",
                text: white,
            },
            toggle: {
                background: "transparent",
                border: borderBase + lightColors.accent,
                text: lightColors.accent,
            },
            outlinePrimary: {
                background: "transparent",
                border: lightColors.accent,
                text: lightColors.accent,
            },
            outlineSecondary: {
                background: "transparent",
                border: lightColors.muted,
                text: lightColors.muted,
            },
            outlineInfo: {
                background: lightColors.info,
                border: white,
                text: white,
            },
            outlineInfoSecondary: {
                background: "transparent",
                border: lightColors.muted,
                text: lightColors.muted,
            },
            outlineDanger: {
                background: "transparent",
                border: lightColors.invalid,
                text: lightColors.invalid,
            },
        },
        alerts: {
            warning: {
                text: "#856404",
                background: "#fff3cd",
                border: borderBase + "#ffeeba",
            },
            danger: {
                text: "#721c24",
                background: "#f8d7da",
                border: borderBase + "#f5c6cb",
            },
        },
        stepper: {
            active: {
                text: lightColors.text,
                background: lightColors.info,
            },
            inactive: {
                text: lightColors.text,
                background: lightColors.background,
            },
        },
        switch: {
            background: white,
            outline: lightColors.border,
            checked: lightColors.muted,
            unchecked: lightColors.muted,
        },
        accordion: {
            header: {
                text: lightColors.text,
                border: lightColors.background,
            },
            body: {
                text: lightColors.text,
            }
        }
    },
    dark: {
        global: {
            text: white,
            background: darkColors.background,
            container: darkColors.white5,
            accent: darkColors.accent,
            transparent: "transparent",
        },
        forms: {
            background: darkColors.white10,
            backgroundSolid: darkColors.backgroundSolid,
            text: white,
            border: borderBase + darkColors.white10,
            borderInput: borderBase + darkColors.backgroundSolid,
            shadowInput: "0 0 0.4em 0.1em #202f3e",
            disabledText: darkColors.white25,
            disabledBackground: darkColors.white5,
            shadow: shadow + darkColors.accent + "20",
            invalid: darkColors.invalid,
            valid: darkColors.valid,
            info: darkColors.accent,
            secondaryInfo: darkColors.accent,
            buttonInfo: darkColors.accent,
            pending: darkColors.pending,
            optional: darkColors.white50,
            invalidShadow: shadow + darkColors.invalid + "40",
            validShadow: shadow + darkColors.valid + "40",
            validIcon: darkColors.validIcon,
            invalidIcon: darkColors.invalidIcon,
            mutedText: darkColors.accent,
            textAccented: darkColors.accent,
            kebabMenu: white,
            credentialStep: darkColors.white25,
            timePicker: "invert(1)",
            fieldOpacity: "0.5",
            option: darkColors.white5,
            radioButtonBorder: darkColors.white25,
        },
        buttons: {
            primary: {
                background: "#526B8B",
                shadow: shadow + darkColors.accent + "20",
                text: white,
            },
            secondary: {
                background: "#526777",
                shadow: shadow + darkColors.accent + "20",
                text: white,
            },
            light: {
                background: "transparent",
                shadow: shadow + darkColors.accent + "20",
                text: white,
            },
            success: {
                background: darkColors.valid,
                shadow: shadow + darkColors.valid + "20",
                text: darkColors.backgroundSolid,
            },
            danger: {
                background: darkColors.invalid,
                shadow: shadow + darkColors.invalid + "20",
                text: darkColors.backgroundSolid,
            },
            toggle: {
                background: "transparent",
                border: borderBase + darkColors.accent + "20",
                text: white,
            },
            outlinePrimary: {
                background: "transparent",
                border: darkColors.accent,
                text: darkColors.accent,
            },
            outlineSecondary: {
                background: "transparent",
                border: darkColors.muted,
                text: darkColors.muted,
            },
            outlineInfo: {
                background: "transparent",
                border: darkColors.accent,
                text: darkColors.accent,
            },
            outlineInfoSecondary: {
                background: "transparent",
                border: darkColors.accent,
                text: darkColors.accent,
            },
            outlineDanger: {
                background: "transparent",
                border: darkColors.invalid,
                text: darkColors.invalid,
            },
        },
        alerts: {
            warning: {
                text: "#2d4153",
                background: darkColors.accent,
                border: borderBase + "#a5bed3",
            },
            danger: {
                text: "#754b2a",
                background: darkColors.invalid,
                border: borderBase + darkColors.invalid,
            },
        },
        stepper: {
            active: {
                text: white,
                background: darkColors.backgroundMetalic,
            },
            inactive: {
                text: white,
                background: darkColors.backgroundSolid,
            },
        },
        switch: {
            background: white,
            outline: darkColors.backgroundSolid,
            checked: darkColors.backgroundSolid,
            unchecked: darkColors.backgroundSolid,
        },
        accordion: {
            header: {
                text: darkColors.accent,
                border: darkColors.white5,
            },
            body: {
                text: white,
            }
        }
    },
};
