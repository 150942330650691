import React from "react";
import { Form, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import { useFormContext, Controller } from "react-hook-form";
import { BallotBoxIcon } from "../../../icons/BallotBoxIcon";
import { resolveObjectPath } from "../../../utils/formUtils";
import { useLanguage, useLocalize } from "../../../utils/hooks";
import MarkdownText from "../MarkdownText";

const CustomToggle = ({ input, isComplete, selectedValue }) => {
    const {
        name,
        label,
        defaultValue = "yes",
        trueValue = "yes",
        falseValue = "no",
        required,
        validValues,
        missingMessage,
        invalidMessage,
    } = input;

    const { errors, control } = useFormContext();
    const getError = resolveObjectPath(errors);

    const { localize } = useLocalize();
    const { localizeField } = useLanguage();

    const handleChange = callback => value => {
        callback(value);
    };

    const validate = value => {
        if (validValues) {
            return validValues.includes(value);
        }
    };

    const getErrorMessage = type => localizeField(type === "required" ? missingMessage : invalidMessage);

    const getDefaultValue = () => {
        if (typeof selectedValue === "boolean") {
            return selectedValue ? trueValue : falseValue;
        }
        return selectedValue || defaultValue;
    };

    return (
        <Form.Group>
            <Controller
                id={name}
                control={control}
                name={name}
                defaultValue={getDefaultValue()}
                rules={{ required, validate }}
                render={({ onChange }) => (
                    <>
                        <Form.Label>
                            <MarkdownText>{localizeField(label)}</MarkdownText>
                        </Form.Label>
                        <ToggleButtonGroup
                            defaultValue={getDefaultValue()}
                            name="options"
                            className="w-100"
                            onChange={handleChange(onChange)}
                        >
                            <ToggleButton
                                className="mr-2 rounded btn-toggle"
                                type="radio"
                                value={trueValue}
                                disabled={isComplete}
                            >
                                <BallotBoxIcon size={16} />
                                {localize("global.yes")}
                            </ToggleButton>
                            <ToggleButton
                                active
                                className="ml-2 rounded btn-toggle"
                                type="radio"
                                value={falseValue}
                                disabled={isComplete}
                            >
                                <BallotBoxIcon size={16} />
                                {localize("global.no")}
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </>
                )}
            />
            {getError(name) && <Form.Text className="text-required">{getErrorMessage(getError(name).type)}</Form.Text>}
        </Form.Group>
    );
};

export default CustomToggle;
