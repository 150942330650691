export const PRIVACY_POLICY = "privacyPolicy";
export const HEALTH_CHECK_CONSENT = "healthCheckConsent";
export const DATA_STORAGE_CONSENT = "dataStorageConsent";
export const USAG_PARTICIPANT_PRIVACY_POLICY = "usagParticipantPrivacyPolicyConsent";
export const USAG_PARTICIPANT_HEALTH = "usagParticipantHealthCheckConsent";
export const USAG_SPECTATOR_PRIVACY_POLICY = "usagSpectatorPrivacyPolicyConsent";
export const USAG_SPECTATOR_HEALTH = "usagSpectatorHealthCheckConsent";
export const CRUISE_PRIVACY = "cruisePrivacyConsent";
export const CRUISE_HEALTH = "cruiseHealthCheckConsent";
export const CRUISE_STORAGE = "cruiseStorageConsent";
export const CARNIVAL_PRIVACY = "carnivalPrivacyConsent";
export const CARNIVAL_HEALTH = "carnivalHealthCheckConsent";
export const CARNIVAL_STORAGE = "carnivalStorageConsent";
export const HAL_PRIVACY = "halPrivacyConsent";
export const HAL_HEALTH = "halHealthCheckConsent";
export const HAL_STORAGE = "halStorageConsent";
export const MALTA_PRIVACY = "maltaPrivacyConsent";
export const MALTA_HEALTH = "maltaHealthCheckConsent";
export const MALTA_STORAGE = "maltaStorageConsent";
export const PAYMENT_POLICY = "paymentPolicyConsent";
export const BIOMETRIC_POLICY = "biometricPolicyConsent";
export const LLC_CONSENT = "llcConsent";

export const APPROVED_SUFFIX = "Approved";

export const INITIAL_CONSENT = [PRIVACY_POLICY, HEALTH_CHECK_CONSENT, DATA_STORAGE_CONSENT];
export const PARTICIPANT_CONSENT = [USAG_PARTICIPANT_PRIVACY_POLICY, USAG_PARTICIPANT_HEALTH];
export const SPECTATOR_CONSENT = [USAG_SPECTATOR_PRIVACY_POLICY, USAG_SPECTATOR_HEALTH];
export const COUNTRIES_CONSENT = {
    malta: [MALTA_PRIVACY, MALTA_HEALTH, MALTA_STORAGE]
};

// These will be configured in the process definition. To be removed once that is implemented
export const EVENT_CONSENT = {
    usagparticipants: PARTICIPANT_CONSENT,
    usagspectators: SPECTATOR_CONSENT
}

export const CRUISE_CONSENT = [CRUISE_PRIVACY, CRUISE_HEALTH, CRUISE_STORAGE]
export const CARNIVAL_CONSENT = {
    CCL: [CARNIVAL_PRIVACY, CARNIVAL_HEALTH, CARNIVAL_STORAGE],
    HAL: [HAL_PRIVACY, HAL_HEALTH, HAL_STORAGE]
}