import React, { useContext } from "react";
import { useHistory } from "react-router";
import { Button, Form } from "react-bootstrap";
import { doNothing } from "../../../utils/dataProcessing";
import { useLanguage, useLocalize } from "../../../utils/hooks";
import CredentialContext from "../../../config/CredentialContext";
import FormPage from "../../_components/FormPage";
import MarkdownText from "../../_components/MarkdownText";
import LoadingWrapper from "../../_components/Wrappers/LoadingWrapper";
import CarnevalCruiseGuestListItem from "./CarnevalCruiseGuestListItem";

const CarnivalCruiseGuestList = ({ guests, onOpen }) => {
    const { localize } = useLocalize();
    const { localizeField, localizeFieldOrDefault } = useLanguage();
    const history = useHistory();
    const { processInstance = {}, loadingAny, globalConfig } = useContext(CredentialContext);
    const { guestListHeader, guestListText, guestListTitle, guestListCompleteLabel } = globalConfig;

    const onComplete = () => {
        const { id } = processInstance;
        history.replace(`/managetrip/${history.location.search}&extId=${id}&passType=carnival`);
    };

    const noGuests = <div className="pb-2">{localize("carnivalcruise.guest.noGuests")}</div>;

    const renderGuests = () =>
        guests.map((guest, i) => (
            <CarnevalCruiseGuestListItem
                added={guest.added}
                key={i}
                firstName={guest.firstName}
                lastName={guest.lastName}
                birthDate={guest.birthDate}
                onOpen={onOpen}
                item={guest}
            />
        ));

    return (
        <LoadingWrapper fullPage isLoading={loadingAny("config")}>
            <FormPage header={localizeFieldOrDefault(guestListHeader, localize("carnivalcruise.guest.title-1"))}>
                <Form>
                    <Form.Group>
                        {guestListText ? (
                            <MarkdownText>{localizeField(guestListText)}</MarkdownText>
                        ) : (
                            <>
                                <p>{localize("carnivalcruise.guest.text-1")}</p>
                                <p>{localize("carnivalcruise.guest.text-2")}</p>
                                {!processInstance?.id && <p>{localize("carnivalcruise.guest.text-3")}</p>}
                            </>
                        )}
                    </Form.Group>
                    <Form.Group className="text-center pb-0">
                        <h5 className="card-header p-0">
                            {localizeFieldOrDefault(guestListTitle, localize("carnivalcruise.guest.title-2"))}
                        </h5>
                    </Form.Group>
                    <div className="w-100">{guests.length === 0 ? noGuests : renderGuests()}</div>
                    <Button
                        type="submit"
                        onClick={onComplete}
                        className="w-100 mt-3"
                        disabled={!processInstance?.id || guests.length === 0}
                    >
                        {localizeFieldOrDefault(guestListCompleteLabel, localize("carnivalcruise.guest.submit.label"))}
                    </Button>
                </Form>
            </FormPage>
        </LoadingWrapper>
    );
};

CarnivalCruiseGuestList.defaultProps = {
    guests: [],
    onOpen: doNothing,
};

export default CarnivalCruiseGuestList;
