import { useContext, useState } from "react";
import { Button } from "react-bootstrap";
import CredentialContext from "../../config/CredentialContext";
import { ErrorIcon } from "../../icons/ErrorIcon";
import { postReturnJson } from "../../utils/dataFetching";
import { doNothing, processDataFromCapServer } from "../../utils/dataProcessing";
import { useLocalize, useQuery } from "../../utils/hooks";
import FormPage from "../_components/FormPage";
import MarkdownText from "../_components/MarkdownText";
import LoadingWrapper from "../_components/Wrappers/LoadingWrapper";

const CarnivalCruiseRetrieveBookingError = ({ onNext, errorCode, title, message, buttonLabel }) => {
    const { processInstance, submissionHref, userId, setError } = useContext(CredentialContext);
    const query = useQuery();
    const { localize } = useLocalize();
    const processInstanceId = query.get("extId");

    const [isLoading, setIsLoading] = useState(false);

    const removeTripErrorCodes = ["21", "5166"];
    const showRemoveTripButton =
        processInstance &&
        submissionHref &&
        processInstanceId &&
        userId &&
        removeTripErrorCodes.includes(errorCode?.toString());

    const backToForm = () => {
        onNext();
    };

    const cancelTrip = () => {
        setIsLoading(true);
        const cancellationUrl =
            submissionHref + "/processInstances/" + processInstanceId + "/persons/" + userId + "/cancel";
        postReturnJson(cancellationUrl, processInstance, processDataFromCapServer(setError, true), setError);
    };

    const renderRemoveTripButton = () => {
        if (showRemoveTripButton) {
            return (
                <Button onClick={cancelTrip} className="btn-primary w-100 mt-3" disabled={isLoading}>
                    <LoadingWrapper isLoading={isLoading}>{localize("global.removeTrip")}</LoadingWrapper>
                </Button>
            );
        }
        return null;
    };

    return (
        <FormPage header={localize("carnivalcruise.retrievebooking.title")} cardBodyClassName="text-center">
            <ErrorIcon size="72px" customClass="mb-4" />
            <div className="align-items-center mb-4 h2 text-danger">
                {title || localize("carnivalcruise.retrievebooking.error.title")}
            </div>
            <div className="text-justify mb-4">
                <MarkdownText>{message || localize("global.error.defaultMessage")}</MarkdownText>
            </div>
            {renderRemoveTripButton()}
            <Button onClick={backToForm} className="btn-secondary w-100 mt-3" disabled={isLoading}>
                {buttonLabel || localize("carnivalcruise.retrievebooking.error.button")}
            </Button>
        </FormPage>
    );
};

CarnivalCruiseRetrieveBookingError.defaultProps = {
    onNext: doNothing,
};

export default CarnivalCruiseRetrieveBookingError;
