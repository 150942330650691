import React, { useState, useEffect } from "react";
import { doNothing } from "../../../utils/dataProcessing";
import CarnivalCruiseEditGuest from "./CarnivalCruiseEditGuest";
import CarnivalCruiseGuestList from "../CarnevalCruiseGuestList";
import { useCruiseLine } from "../../../utils/hooks";
import LoadingWrapper from "../../_components/Wrappers/LoadingWrapper";
import { useContext } from "react";
import CredentialContext from "../../../config/CredentialContext";

const CarnivalCruiseAddGuests = ({ onError }) => {
    const { globalConfig } = useContext(CredentialContext);
    const { disableGuestEdit } = globalConfig;
    const { fetchCustomBookingDetails, getBookingErrorContent } = useCruiseLine();

    const [showGuest, setShowGuest] = useState(false);
    const [guest, setGuest] = useState({});
    const [cruiseInfo, setCruiseInfo] = useState({});
    const [allGuests, setAllGuests] = useState([]);
    const [firstPassenger, setFirstPassenger] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const processResponse = response => {
        const { guests, ...cruiseDetails } = response;
        setAllGuests(guests);
        setCruiseInfo(cruiseDetails);
        setIsLoading(false);
    };

    const setError = error => {
        const {title, message} = getBookingErrorContent(error);
        onError(title, message, error?.code);
        setIsLoading(false);
    };

    useEffect(() => {
        fetchCustomBookingDetails(processResponse, setError);
    }, []);

    const onOpen = item => {
        setShowGuest(true);
        setGuest(item);
    };

    const backToList = () => {
        setShowGuest(false);
    };

    const onSave = newGuest => {
        const newAllGuests = allGuests.map(guest => {
            const personalInfoToCompare = disableGuestEdit
                ? guest.firstName !== newGuest.firstName ||
                  guest.lastName !== newGuest.lastName ||
                  guest.birthDate !== newGuest.birthDate
                : guest.firstName !== newGuest.firstName || guest.lastName !== newGuest.lastName;

            if (
                guest.patientId && newGuest.patientId
                    ? guest.patientId !== newGuest.patientId
                    : personalInfoToCompare
            ) {
                return guest;
            }
            return newGuest;
        });

        if (!firstPassenger) {
            setFirstPassenger(true);
        }

        setAllGuests(newAllGuests);
        setShowGuest(false);
    };

    return (
        <LoadingWrapper container isLoading={isLoading}>
            {showGuest ? (
                <CarnivalCruiseEditGuest
                    onSave={onSave}
                    guest={guest}
                    firstPassanger={firstPassenger}
                    cruiseInfo={cruiseInfo}
                    backToList={backToList}
                />
            ) : (
                <CarnivalCruiseGuestList onOpen={onOpen} guests={allGuests} />
            )}
        </LoadingWrapper>
    );
};

CarnivalCruiseAddGuests.defaultProps = {
    onNext: doNothing,
};

export default CarnivalCruiseAddGuests;
