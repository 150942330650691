import { approvalsHandlers } from "./approvalsHandlers";
import { cruiseHandlers } from "./cruiseHandlers";
import { flightsHandlers } from "./flightsHandlers";
import { glidePersonHandlers } from "./glidePersonHandlers";
import { processInstanceHandlers } from "./processInstanceHandlers";
import { legacyHandlers } from "./legacyHandlers";
import { glideServerTimeHandlers } from "./glideServerTimeHandlers";
import { carnevalHandler } from "./carnevalHandler";
import { rumHandlers } from "./rumHandler";
import { medicalServiceHandlers } from './medicalServiceHandlers';

export const handlers = [
    ...legacyHandlers,
    ...approvalsHandlers,
    ...glidePersonHandlers,
    ...flightsHandlers,
    ...cruiseHandlers,
    ...processInstanceHandlers,
    ...glideServerTimeHandlers,
    ...carnevalHandler,
    ...medicalServiceHandlers,
    ...rumHandlers,
];
