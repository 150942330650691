import React from "react";
import { Col, Row } from "react-bootstrap";
import { useIntl } from "react-intl";
import { DateTime } from "luxon";

const ArrivalDetailsForSummary = ({ details }) => {
    const { originCountry, arrivalDateTime } = details;

    const intl = useIntl();
    const localize = id => intl.formatMessage({ id });

    return (
        <>
            <Row>
                <Col className="pl-0 col-7">{localize("flightDetails.flight.origin.label")}</Col>
                <Col className="text-right pr-0 col-5">{originCountry}</Col>
            </Row>
            <Row>
                <Col className="pl-0 col-7">{localize("arrivalDetails.arrivalDate.label")}</Col>
                <Col className="text-right pr-0 col-5">
                    {DateTime.fromISO(arrivalDateTime, { setZone: true })
                        .setLocale(intl.locale)
                        .toFormat("dd MMM yyyy")}
                </Col>
            </Row>
        </>
    );
};

export default ArrivalDetailsForSummary;
