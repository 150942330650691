import React from "react";
import { useFormContext } from "react-hook-form";
import { resolveObjectPath } from "../../../utils/formUtils";
import { useLanguage } from "../../../utils/hooks";
import { Form } from "react-bootstrap";

const CustomSelect = ({ input, isComplete }) => {
    const { name, label, required, options = [], placeholder, missingMessage } = input;

    const { register, errors } = useFormContext();
    const getError = resolveObjectPath(errors);

    const { localizeField } = useLanguage();

    return (
        <Form.Group>
            <Form.Label>{localizeField(label)}</Form.Label>
            <Form.Control id={name} as="select" name={name} ref={register({ required })} disabled={isComplete}>
                <option value="">{localizeField(placeholder)}</option>
                {options.map(o => (
                    <option key={o.value} value={o.value}>
                        {localizeField(o.label)}
                    </option>
                ))}
            </Form.Control>
            {getError(name)?.type === "required" && (
                <Form.Text className="text-required">{localizeField(missingMessage)}</Form.Text>
            )}
        </Form.Group>
    );
};

export default CustomSelect;
