import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { testingCentersReducer } from "./reducers/testingCentersReducer";
import { singleTestingCenterReducer } from "./reducers/singleTestingCenterReducer";
import { locationReducer } from "./reducers/locationReducer";
import { testTypesReducer } from "./reducers/testTypesReducer";
import { fetchApiKeyReducer } from "./reducers/fetchApiKeyReducer";

const reducer = combineReducers({
    markers: testingCentersReducer,
    testingCenter: singleTestingCenterReducer,
    location: locationReducer,
    testTypes: testTypesReducer,
    apiKey: fetchApiKeyReducer,
});

const initialState = {};

const middleware = [thunk];

const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)));

export default store;
