import React, { useContext } from "react";
import { DateTime } from "luxon";
import { useIntl } from "react-intl";
import { ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ChevronRight } from "react-bootstrap-icons";
import CredentialContext from "../../config/CredentialContext";
import { useLocalize, useQuery } from "../../utils/hooks";

export default function PassengerList() {
    const {
        processInstance: { id, userData: { userSpecificData } = {}, instructions: { userProcesses } = {} },
    } = useContext(CredentialContext);

    const query = useQuery();

    const intl = useIntl();
    const { localize } = useLocalize();

    const getCompanionChecklistUrl = companionId => {
        query.set("companionId", companionId);
        query.set("stepId", companionId);
        query.set("correlationId", id);
        query.set("manageTrip", "true");

        return `/tripchecklist?${query.toString()}`;
    };

    const allStepsComplete = steps => steps?.reduce((acc, curr) => acc && curr.complete, true);

    const isChecklistComplete = userId => allStepsComplete(userProcesses[userId]?.steps);

    const SideText = ({ userId }) => {
        const messageId = `manageTrip.passenger.checklist.${
            isChecklistComplete(userId) ? "complete" : "manageChecklist"
        }`;
        const className = `manage-checklist${isChecklistComplete(userId) ? " complete" : ""}`;
        return <div className={className}>{localize(messageId)}</div>;
    };

    return (
        <ListGroup className="passenger-list">
            {userSpecificData &&
                Object.entries(userSpecificData).map(([key, value], index) => (
                    <ListGroup.Item key={`passenger_${index}`} className="passenger-link">
                        <Link to={getCompanionChecklistUrl(key)}>
                            <div className="passenger-name">{value.firstName + " " + value.lastName}</div>
                            <small>
                                {localize("manageTrip.passenger.dateOfBirth.abbreviated")}{" "}
                                {DateTime.fromISO(value.dateOfBirth, { setZone: true })
                                    .setLocale(intl.locale)
                                    .toFormat("dd MMM yyyy")}
                            </small>
                            <SideText userId={key} />
                            <ChevronRight className="float-right" />
                        </Link>
                    </ListGroup.Item>
                ))}
        </ListGroup>
    );
}
