import { USE_PREMIER_REVIEW_KEY } from "../../pages/Navigation/constants";
import {
    BEFORE_YOU_GO_TO_THE_AIRPORT_STEP_NAME, COMPANION_PHOTO_STEP_NAME,
    COVID_TEST_STEP_NAME,
    isCovidTestStep,
    isVaccinationStep,
    QUESTIONNAIRE_STEP_NAME,
    TRAVEL_ATTESTATION_STEP_NAME,
    TRAVEL_AUTHORIZATION_STEP_NAME,
    VACCINATION_STEP_NAME
} from "../../utils/steps";
import { DateTime } from "luxon";
import { PRESENTATION_TYPES, REVIEW_STATUS, STATUS_FOR } from "../../utils/constants";
import { getLocal } from "./utils";

const hasNoReviewStatus = item => !item.review?.status

const completeStep = (data, userId, stepName, predicate) => {
    console.log("Completing step %s", stepName);
    const findStepByName = s => s.name === stepName;
    const findStep = predicate || findStepByName;
    const step = data.instructions.userProcesses[userId].steps.find(findStep);
    if (step) {
        step.complete = true;
    }
    return data;
};

const setAvailable = (data, userId, stepName, predicate, value) => {
    console.log("Processing step %s", stepName);
    const findStepByName = s => s.name === stepName;
    const findStep = predicate || findStepByName;
    const step = data.instructions.userProcesses[userId].steps.find(findStep);
    if (step) {
        step.available = value;
    }
    return data;
};

const setPending = (data, userId, stepName) => {
    const arrayName = STATUS_FOR[stepName];
    console.log("Setting review status %s for %s", REVIEW_STATUS.PENDING, arrayName);
    for (const item of data.userData.userSpecificData[userId][arrayName].filter(hasNoReviewStatus)) {
        item.submitted = DateTime.utc().toISO();
        item.review = {
            presentation: PRESENTATION_TYPES.PAPER,
            submitted: DateTime.utc().toString(),
            status: REVIEW_STATUS.PENDING,
        };
    }
    return data;
};

const setAccepted = (data, userId, stepName) => {
    const arrayName = STATUS_FOR[stepName];
    console.log("Setting review status %s for %s", REVIEW_STATUS.ACCEPTED, arrayName);
    if(!data.userData.userSpecificData[userId][arrayName]) {
        data.userData.userSpecificData[userId][arrayName] = [{}]
    }
    for (const item of data.userData.userSpecificData[userId][arrayName]) {
        item.submitted = DateTime.utc().toISO();
        item.review = {
            presentation: PRESENTATION_TYPES.DIGITAL_UPLOAD,
            submitted: DateTime.utc().toString(),
            status: REVIEW_STATUS.ACCEPTED,
        };
    }
    const otherStepName = stepName === COVID_TEST_STEP_NAME ? VACCINATION_STEP_NAME : COVID_TEST_STEP_NAME;
    data = setAvailable(data, userId, otherStepName, null,false);
    data = completeStep(data, userId, COVID_TEST_STEP_NAME, isCovidTestStep);
    data = completeStep(data, userId, VACCINATION_STEP_NAME, isVaccinationStep);
    return data;
}

const processTravelAuthRequest = (data, userId) => {
    data.userData.userSpecificData[userId].travelAuthorizations = [{
        auditData: {rawData: "1234abcd"}
    }]
    delete(data.userData.userSpecificData[userId].travelAuthorizationRequests)
    return data;
}

export const setReview = (data, userId, stepName, accepted) => {
    const status = accepted ? REVIEW_STATUS.ACCEPTED : REVIEW_STATUS.REJECTED;
    const arrayName = STATUS_FOR[stepName];
    for (const item of data.userData.userSpecificData[userId][arrayName]) {
        item.review = { ...item.review, completed: DateTime.utc().toString(), status};
    }
    data = completeStep(data, userId, stepName);
    return data;
};

export const processSteps = (data, review) => {
    const {
        userData: { userSpecificData },
    } = data;
    console.log("Processing instance");
    for (const userId in userSpecificData) {
        console.log("Processing steps for user %s", userId);

        // COMPANION_PHOTO_STEP
        if (userSpecificData[userId].photo?.completed) {
            data = completeStep(data, userId, COMPANION_PHOTO_STEP_NAME);
        }

        // BEFORE_YOU_GO_TO_THE_AIRPORT_STEP
        if (userSpecificData[userId].checklist?.completed) {
            data = completeStep(data, userId, BEFORE_YOU_GO_TO_THE_AIRPORT_STEP_NAME);
        }

        // ATTESTATION
        if (userSpecificData[userId].attestation?.completed) {
            data = completeStep(data, userId, TRAVEL_ATTESTATION_STEP_NAME);
        }

        // TRAVEL_AUTHORIZATION
        if (userSpecificData[userId].travelAuthorizations?.length > 0) {
            data = completeStep(data, userId, TRAVEL_AUTHORIZATION_STEP_NAME);
        }
        if (userSpecificData[userId].travelAuthorizationRequests?.length > 0) {
            data = processTravelAuthRequest(data, userId)
        }

        // COVID_TEST
        if (userSpecificData[userId].covidTests?.filter(hasNoReviewStatus).length > 0) {
            data = setPending(data, userId, COVID_TEST_STEP_NAME);
        }

        if (userSpecificData[userId].deferCovidTest) {
            data = completeStep(data, userId, COVID_TEST_STEP_NAME);
        }

        // COVID19_VACCINE
        if (userSpecificData[userId].covidVaccines?.filter(hasNoReviewStatus).length > 0) {
            data = setPending(data, userId, VACCINATION_STEP_NAME);
        }

        // DIGITAL_CERTIFICATE
        const healthPasses = userSpecificData[userId].healthPasses;
        if (healthPasses?.filter(hp => !hp.processed).length > 0) {
            data.userData.userSpecificData[userId].healthPasses = healthPasses.map(hp => ({...hp, processed: true}));
            data = setAccepted(data, userId, COVID_TEST_STEP_NAME);
        }

        // QUESTIONNAIRE
        const questionnaires = userSpecificData[userId].questionnaires;
        if (questionnaires?.filter(hp => !hp.processed).length > 0) {
            data = completeStep(data, userId, QUESTIONNAIRE_STEP_NAME);
        }

        // QUESTIONNAIRE
        if (questionnaires?.filter(hp => hp.type === "HQ0009").length > 0) {
            data = completeStep(data, userId, "POST_DEPARTURE_QUESTIONNAIRE");
        }

        // QUESTIONNAIRE
        if (questionnaires?.filter(hp => hp.type === "HQ0012").length > 0) {
            data = completeStep(data, userId, "VACCINATION_QUESTIONNAIRE");
        }

        // PAYMENT
        if (userSpecificData[userId].checkForPayment && getLocal(USE_PREMIER_REVIEW_KEY)) {
            data.userData.userSpecificData[userId].paymentInfo = {
                "PREMIUM_REVIEW_SERVICE_GROUP": {
                    "USD": {
                        "stripePriceId": "price_1K4U5UCjzFTdzQ0pX9kfiufg",
                        "amount": 1499
                    }
                },
                "PREMIUM_REVIEW_SERVICE_SINGLE_TRAVELER": {
                    "USD": {
                        "stripePriceId": "price_1K4U5ECjzFTdzQ0pSEpP8zHB",
                        "amount": 899
                    }
                }
            };
        }
    }
    return data;
};
