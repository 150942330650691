import React, { useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { ChevronDown } from "react-bootstrap-icons";
import CredentialContext from "../../config/CredentialContext";
import { useCap, useLocalize } from "../../utils/hooks";
import { REDIRECT } from "../../utils/hooks/useCap";
import LoadingWrapper from "../_components/Wrappers/LoadingWrapper";
import CompanionCountryOfResidenceModal from "./CompanionCountryOfResidenceModal";
import { putReturnJson } from "../../utils/dataFetching";
import { getBaseUrl } from "../../utils/textUtils";
import { getThreeLeterCodeWithTwoLetterCode } from '../../utils/countryUtils';

export default function AddSavedCompanionForm({ companions = [], disabled = false }) {
    const {
        register,
        handleSubmit,
        reset,
        formState: { isSubmitSuccessful },
    } = useForm();

    const { processInstance, loading, updateLoading, credentialTypeHref, userId, setError } =
        useContext(CredentialContext);
    const { updateProcessInstance } = useCap(REDIRECT.NONE, "companion");

    const { localize } = useLocalize();

    const [showModalForCountryOfResidence, setShowModalForCountryOfResidence] = useState(false);
    const [companionData, setCompanionData] = useState();

    const onSubmit = data => {
        updateLoading("companion", true);

        const selectedCompanion = companions.find(c => c.id === data.companionId);

        if (!selectedCompanion?.privateInfo?.extendedAttributes?.countryOfResidence) {
            updateLoading("companion", false);
            setCompanionData(selectedCompanion);
            setShowModalForCountryOfResidence(true);
        } else {
            processInstance.userData.userSpecificData[data.companionId] = {
                companionId: selectedCompanion.id,
                firstName: selectedCompanion.firstName,
                lastName: selectedCompanion.lastName,
                dateOfBirth: selectedCompanion.privateInfo.extendedAttributes.dateOfBirth,
                nationality: selectedCompanion.privateInfo.extendedAttributes.nationality || "US",
                passportCountry: selectedCompanion.privateInfo.extendedAttributes.passportCountry || getThreeLeterCodeWithTwoLetterCode(selectedCompanion.privateInfo.extendedAttributes.nationality) || "USA",
                countryOfResidence: selectedCompanion.privateInfo.extendedAttributes.countryOfResidence
            };
            updateProcessInstance(processInstance);
        }
    };

    const filteredCompanions = companions.filter(c => {
        return !processInstance.userData.userSpecificData[c.id];
    });

    useEffect(() => {
        if (isSubmitSuccessful) {
            reset();
        }
    }, [isSubmitSuccessful, reset]);

    const companionCountryOfResidenceSubmit = enteredCountryOfResidence => {
        const createGlideCompanionUrl = getBaseUrl(credentialTypeHref) + "/api/persons/" + userId + "/companions/" + companionData.id;

        const updateCompanion = updatedCompanionData => {
            processInstance.userData.userSpecificData[updatedCompanionData.id] = {
                companionId: updatedCompanionData.id,
                firstName: updatedCompanionData.firstName,
                lastName: updatedCompanionData.lastName,
                dateOfBirth: updatedCompanionData.privateInfo.extendedAttributes.dateOfBirth,
                nationality: updatedCompanionData.privateInfo.extendedAttributes.nationality,
                passportCountry: updatedCompanionData.privateInfo.extendedAttributes.passportCountry || getThreeLeterCodeWithTwoLetterCode(updatedCompanionData.privateInfo.extendedAttributes.nationality),
                countryOfResidence: updatedCompanionData.privateInfo.extendedAttributes.countryOfResidence,
            };
            updateProcessInstance(processInstance);
        };

        companionData.privateInfo.extendedAttributes.passportCountry = companionData.privateInfo.extendedAttributes.passportCountry || getThreeLeterCodeWithTwoLetterCode(companionData.privateInfo.extendedAttributes.nationality);
        companionData.privateInfo.extendedAttributes.countryOfResidence = enteredCountryOfResidence;

        putReturnJson(createGlideCompanionUrl, companionData, updateCompanion, setError);
    };

    return (
        <Form>
            <CompanionCountryOfResidenceModal
                onShowModal={showModalForCountryOfResidence}
                onCloseModal={setShowModalForCountryOfResidence}
                firstName={companionData?.firstName}
                lastName={companionData?.lastName}
                handleSubmit={companionCountryOfResidenceSubmit}
                loadingProp="companion"
            />
            <legend>
                <FormattedMessage id="manageTrip.savedCompanionLegend" />
            </legend>
            <LoadingWrapper row isLoading={loading.companion}>
                <Form.Group className="input-group">
                    <Form.Control
                        as="select"
                        name="companionId"
                        ref={register({ required: true })}
                        defaultValue=""
                        disabled={(filteredCompanions && !filteredCompanions.length) || disabled}
                        onChange={handleSubmit(onSubmit)}
                    >
                        <option value="" disabled>
                            {localize("addCompanion.selectCompanion")}
                        </option>
                        {filteredCompanions.map((companion, index) => (
                            <option key={`comp_${index}`} value={companion.id}>
                                {`${companion.firstName} ${companion.lastName}`}
                            </option>
                        ))}
                    </Form.Control>
                    <ChevronDown className="input-group-btn" />
                </Form.Group>
            </LoadingWrapper>
        </Form>
    );
}
