import { DateTime } from "luxon";
import React, { useContext } from "react";
import { Button, Form, Row } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router";
import CredentialContext from "../../config/CredentialContext";
import { useCapHandler, useCompanion, useLanguage, useLocalize, useStep, useUser } from "../../utils/hooks";
import { REDIRECT } from "../../utils/hooks/useCap";
import { getBodyText, isQuestionnaire } from "../../utils/steps";
import CustomInput from "../_components/CustomInput/CustomInput";
import FormPage from "../_components/FormPage";
import MarkdownText from "../_components/MarkdownText";
import DateRangeChecker from "../_components/Wrappers/DateRangeChecker";
import LoadingWrapper from "../_components/Wrappers/LoadingWrapper";
import UnapprovedPoliciesWrapper from '../_components/Wrappers/PrivacyPolicyWrapper/UnapprovedPoliciesWrapper';

const ConfigurableForm = ({ config, action }) => {
    const { header, inputs, policies, redirect, propName, arrayPropName } = config || {};

    const { loading, updateLoading, processInstance, userId, setError } = useContext(CredentialContext);

    const { capHandler } = useCapHandler();

    const { companionId } = useCompanion();
    const history = useHistory();

    const { localizeField, language } = useLanguage();
    const { localize } = useLocalize();
    const { step, isComplete } = useStep(processInstance, companionId, isQuestionnaire);
    const { addUserSpecificData } = useUser(userId, setError);

    const methods = useForm();

    const convertStringToBoolean = (obj, prop) =>
        (obj[prop] = ["yes", "true"].includes(obj[prop].toString().toLowerCase()));
    const trimDate = (obj, prop) => (obj[prop] = DateTime.fromISO(obj[prop]).toISODate());

    const onSubmit = values => {
        updateLoading("submit", true);
        const submittedValues = { ...values };
        inputs
            .filter(i => submittedValues.hasOwnProperty(i.name) && i.convertToBoolean)
            .forEach(i => convertStringToBoolean(submittedValues, i.name));
        inputs
            .filter(i => submittedValues.hasOwnProperty(i.name) && i.type === "date")
            .forEach(i => trimDate(submittedValues, i.name));

        if (action === "create") {
            capHandler
                .setRedirectBehavior(REDIRECT.MANAGE_TRIP)
                .createProcessInstance(addUserSpecificData(submittedValues));
        } else {
            capHandler.setRedirectBehavior(redirect || REDIRECT.BACK);
            arrayPropName
                ? capHandler.addToArray(arrayPropName, submittedValues)
                : capHandler.updateCompanionData(propName, submittedValues);
            capHandler.updateProcessInstance();
        }
    };

    const isSubmitted = () => isComplete && !step.expired;

    return (
        <FormPage header={(header && localizeField(header)) || step?.displayName}>
            <UnapprovedPoliciesWrapper policies={policies}>
                <DateRangeChecker>
                    <LoadingWrapper row isLoading={!config}>
                        <FormProvider {...methods}>
                            <Form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
                                <MarkdownText className="form-text form-group mb-3">
                                    {getBodyText(step, language)}
                                </MarkdownText>
                                {inputs &&
                                    inputs.map((input, index) => (
                                        <CustomInput key={index} input={input} isComplete={isSubmitted()} />
                                    ))}
                                {!isSubmitted() && (
                                    <Row className="justify-content-center">
                                        <LoadingWrapper isLoading={loading.submit}>
                                            <Button type="submit" className="w-100 mx-3">
                                                <FormattedMessage id="global.submit" />
                                            </Button>
                                        </LoadingWrapper>
                                    </Row>
                                )}
                                <Row className="justify-content-center">
                                    <Button onClick={history.goBack} className="w-100 mx-3 my-3">
                                        {localize("global.back")}
                                    </Button>
                                </Row>
                            </Form>
                        </FormProvider>
                    </LoadingWrapper>
                </DateRangeChecker>
            </UnapprovedPoliciesWrapper>
        </FormPage>
    );
};

export default ConfigurableForm;
