import { useContext, useEffect, useState } from "react";
import CredentialContext from "../../config/CredentialContext";
import { INVALID_REASON } from "../../pages/AddInterlineFlightDetails/constants";
import { fetchInterlineFlight, postReturnJson } from "../dataFetching";
import { closeForm, processDataFromCapServer } from "../dataProcessing";
import { validateFligthBlocksResponse, validateInterlineTrip } from "../flightUtils";
import useLocalize from "./useLocalize";
import useQuery from "./useQuery";

const GET_ITINERARY = "GET_ITINERARY";

const useItineraryChange = () => {
    const {
        userId,
        submissionHref,
        processInstance,
        setProcessInstance,
        globalConfig = {},
        updateLoading,
        setError,
    } = useContext(CredentialContext);
    const query = useQuery();
    const { localize, localizeOrDefault } = useLocalize();
    const { additionalPnrLookupFields } = globalConfig;

    const [isBlocksAPIloading, setIsBlocksAPIloading] = useState(false);
    const [flightUpdateModalData, setFlightUpdateModalData] = useState([]);
    const [showFlightBlocksErrorModal, setShowFlightBlocksErrorModal] = useState(false);

    const closeUpdateInfoModal = () => {
        setFlightUpdateModalData([]);
        closeForm();
    };

    const closeFlightBlocksErrorModal = () => {
        setShowFlightBlocksErrorModal(false);
        closeForm();
    };

    const env = query.get("env");
    const { carrierCode, bookingIdentifier } =
        processInstance?.userData?.sharedData?.interlineFlightDetails || {};
    const { firstName, lastName, dateOfBirth, nationality, countryOfResidence } =
        processInstance?.userData?.userSpecificData?.[userId] || {};

    const flightDetails = {
        env,
        lastName,
        bookingNumber: bookingIdentifier,
    };
    const additionalFields = additionalPnrLookupFields?.[carrierCode] || [];
    if (additionalFields.includes("dateOfBirth") || additionalFields.includes("birthDate")) {
        flightDetails.dateOfBirth = dateOfBirth.split("T")[0];
    }

    const getErrorMessage = messageCode => {
        return localizeOrDefault(
            `addInterlineFlightDetails.interlineModalInfo.${INVALID_REASON[messageCode]}`,
            null,
            localize("global.error.defaultMessage")
        );
    };

    const capErrorHandler = e => {
        const { code } = e;
        const codeErrors = ["508", "700"];

        const messageCode = code && codeErrors.includes(code?.toString()) ? e.code.toString() : "defaultMessage";

        updateLoading("submit", false);
        setError(getErrorMessage(messageCode));
    };

    const setErrorResponse = e => {
        setIsBlocksAPIloading(false);

        const { code } = e;
        const codeErrors = ["24", "283", "508"];

        if (code && codeErrors.includes(code?.toString())) {
            setError(getErrorMessage(e.code.toString()));
        } else {
            setShowFlightBlocksErrorModal(true);
        }
    };

    const getFlightBlocksChanges = (
        interlineFlightDetails,
        responseInterlineFlightDetails,
        responseInterlineFlightDetailsChanges
    ) => {
        if (
            !interlineFlightDetails || !responseInterlineFlightDetails || !responseInterlineFlightDetailsChanges
        )
            return;

        const { blocksUpdated, blocksReset, blocksAdded, blocksDeleted } = responseInterlineFlightDetailsChanges;

        const messages = [];

        if (Array.isArray(blocksUpdated) && blocksUpdated.length > 0) {
            blocksUpdated.forEach(bU => {
                const { flightSegments = [] } = interlineFlightDetails?.flightBlocks?.find(fB => fB.id === bU) || {};

                if (flightSegments.length > 0) {
                    const departure = flightSegments?.[0]?.departure || {};
                    const arrival = flightSegments?.[flightSegments.length - 1]?.arrival || {};
                    const { airportName: startAirportName, airportCode: startAirportCode } = departure;
                    const { airportName: endAirportName, airportCode: endAirportCode } = arrival;

                    messages.push(
                        localize("manageTrip.blocksUpdated.message", {
                            departure: `${startAirportName}(${startAirportCode})`,
                            arrival: `${endAirportName}(${endAirportCode})`,
                        })
                    );
                }
            });
        }
        if (Array.isArray(blocksReset) && blocksReset.length > 0) {
            blocksReset.forEach(bR => {
                const { flightSegments = [] } = interlineFlightDetails?.flightBlocks?.find(fB => fB.id === bR) || {};

                if (flightSegments.length > 0) {
                    const departure = flightSegments?.[0]?.departure || {};
                    const arrival = flightSegments?.[flightSegments.length - 1]?.arrival || {};
                    const { airportName: startAirportName, airportCode: startAirportCode } = departure;
                    const { airportName: endAirportName, airportCode: endAirportCode } = arrival;

                    messages.push(
                        localize("manageTrip.blocksReset.message", {
                            departure: `${startAirportName}(${startAirportCode})`,
                            arrival: `${endAirportName}(${endAirportCode})`,
                        })
                    );
                }
            });
        }
        if (Array.isArray(blocksAdded) && blocksAdded.length > 0) {
            blocksAdded.forEach(bA => {
                const { flightSegments = [] } =
                    responseInterlineFlightDetails?.flightBlocks?.find(fB => fB.id === bA) || {};

                if (flightSegments.length > 0) {
                    const departure = flightSegments?.[0]?.departure || {};
                    const arrival = flightSegments?.[flightSegments.length - 1]?.arrival || {};
                    const { airportName: startAirportName, airportCode: startAirportCode } = departure;
                    const { airportName: endAirportName, airportCode: endAirportCode } = arrival;

                    messages.push(
                        localize("manageTrip.blocksAdded.message", {
                            departure: `${startAirportName}(${startAirportCode})`,
                            arrival: `${endAirportName}(${endAirportCode})`,
                        })
                    );
                }
            });
        }
        if (Array.isArray(blocksDeleted) && blocksDeleted.length > 0) {
            blocksDeleted.forEach(bA => {
                const { flightSegments = [] } = interlineFlightDetails?.flightBlocks?.find(fB => fB.id === bA) || {};

                if (flightSegments.length > 0) {
                    const departure = flightSegments?.[0]?.departure || {};
                    const arrival = flightSegments?.[flightSegments.length - 1]?.arrival || {};
                    const { airportName: startAirportName, airportCode: startAirportCode } = departure;
                    const { airportName: endAirportName, airportCode: endAirportCode } = arrival;

                    messages.push(
                        localize("manageTrip.blocksDeleted.message", {
                            departure: `${startAirportName}(${startAirportCode})`,
                            arrival: `${endAirportName}(${endAirportCode})`,
                        })
                    );
                }
            });
        }

        return messages;
    };

    const updateFlightInstance = (flightResponse) => {
        const updatedInterlineFlightDetails = {
            carrierCode,
            env,
            ...flightResponse,
        };

        processInstance.userData.sharedData.updatedInterlineFlightDetails = updatedInterlineFlightDetails;

        const handleCapResponse = response => {
            const interlineFlightDetails = processInstance?.userData?.sharedData?.interlineFlightDetails;
            const responseInterlineFlightDetails = response?.userData?.sharedData?.interlineFlightDetails;
            const responseInterlineFlightDetailsChanges = response?.userData?.sharedData?.interlineFlightDetailsChanges;
            setFlightUpdateModalData(
                getFlightBlocksChanges(
                    interlineFlightDetails,
                    responseInterlineFlightDetails,
                    responseInterlineFlightDetailsChanges
                )
            );

            setProcessInstance(response);
            processDataFromCapServer(setError)(response);
            updateLoading("submit", false);
        };

        const getUpdateUrl = `${submissionHref}/processInstances/${processInstance.id}/persons/${userId}`;
        postReturnJson(getUpdateUrl, processInstance, handleCapResponse, capErrorHandler);
    };

    useEffect(() => {
        if (processInstance && (processInstance?.userActions?.[userId]?.type === GET_ITINERARY || processInstance?.userData?.sharedData?.pnrSubmitRequestInProgress === true)) {
            setIsBlocksAPIloading(true);

            const processFlightBlocksResponse = response => {
                setIsBlocksAPIloading(false);
                const messageCodes = validateInterlineTrip(response);

                if (messageCodes.length !== 0) {
                    setError(getErrorMessage(messageCodes[0]));
                } else {
                    const flightResponse = validateFligthBlocksResponse(response);
                    
                    updateFlightInstance(flightResponse);
                }
            };

            fetchInterlineFlight(carrierCode, flightDetails, processFlightBlocksResponse, setErrorResponse);
        }
    }, [processInstance?.userActions?.[userId]?.type, processInstance?.userData?.sharedData?.pnrSubmitRequestInProgress]);

    const cancelTrip = () => {
        const processInstanceId = query.get("extId");
        const cancellationUrl = `${submissionHref}/processInstances/${processInstanceId}/persons/${userId}/cancel`;

        postReturnJson(cancellationUrl, processInstance, processDataFromCapServer(setError, true), setError);
    };

    return {
        isBlocksAPIloading,
        flightUpdateModal: {
            flightUpdateModalData,
            closeUpdateInfoModal,
        },
        flightBlocksErrorModal: {
            showFlightBlocksErrorModal,
            bookingIdentifier,
            firstName,
            lastName,
            carrierCode,
            dateOfBirth,
            nationality,
            countryOfResidence,
            closeFlightBlocksErrorModal,
            cancelTrip,
        },
    };
};

export default useItineraryChange;
