import { useContext, useEffect, useState } from "react";
import { DateTime } from "luxon";
import CredentialContext from "../../config/CredentialContext";
import useQuery from "./useQuery";
import { getBaseUrl } from "../textUtils";

import { getReturnJson } from "../dataFetching";

const useCurrentTime = () => {
    const query = useQuery();
    const { setError } = useContext(CredentialContext);

    const [currentTime, setCurrentTime] = useState(DateTime.local());

    const getGlideUrl = () => {
        const cth = query.get("credentialTypeHref");
        return `${getBaseUrl(cth)}/serverTime`;
    };

    useEffect(() => {
        const handleResponse = ({ currentTime }) => {
            setCurrentTime(currentTime);
        };
        getReturnJson(getGlideUrl(), null, handleResponse, setError );
    }, []);

    return currentTime;
}

export default useCurrentTime;
