const AirplaneHoriIcon = ({ width, height, className }) => (
    <svg 
        width={width}
        height={height}
        className={`svg-icon ${className}`}
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path className="info-icon" d="M13.3333 35.0002H16.6666L25 21.6668H34.1666C35.55 21.6668 36.6666 20.5502 36.6666 19.1668C36.6666 17.7835 35.55 16.6668 34.1666 16.6668H25L16.6666 3.3335L13.3333 3.3335L17.5 16.6668L8.33329 16.6668L5.83329 13.3335H3.33329L4.99996 19.1668L3.33329 25.0002H5.83329L8.33329 21.6668H17.5L13.3333 35.0002Z" />
    </svg>
);

AirplaneHoriIcon.defaultProps = {
    width: '60',
    height: '60',
    className: ''
}

export default AirplaneHoriIcon;
