import React, { useContext } from "react";
import CredentialContext from "../../config/CredentialContext";

const Advert = ({ containerClasses, imgClasses }) => {
    const { globalConfig } = useContext(CredentialContext);

    const configWithAdvertExists = !!(
        globalConfig &&
        globalConfig?.advert &&
        globalConfig?.advert?.image &&
        globalConfig.advert?.link
    );

    if (configWithAdvertExists) {
        return (
            <div className={`at-container ${containerClasses}`}>
                <div className="at-img-container">
                    <a href={globalConfig.advert.link}>
                        <img
                            src={globalConfig.advert.image}
                            className={`at-img ${imgClasses}`}
                            alt="Advert"
                        />
                    </a>
                </div>
            </div>
        );
    } else {
        return null;
    }
};

Advert.defaultProps = {
    containerClasses: "",
    imgClasses: "",
};

export default Advert;
