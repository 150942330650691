import {DateTime} from "luxon";

export const airlines = [
    {
        id: 1,
        name: "American Airlines",
        iataCode: "AA",
        icaoCode: "AAL",
        countryCode: "US",
        countryName: "United States",
        pnrLookupEnabled: true,
    },
    {
        id: 2,
        name: "British Airways",
        iataCode: "BA",
        icaoCode: "BAW",
        countryCode: "UK",
        countryName: "United Kingdom",
        pnrLookupEnabled: true,
    },
    {
        id: 3,
        name: "Aer Lingus",
        iataCode: "EI",
        icaoCode: "EIN",
        countryCode: "IE",
        countryName: "Ireland",
        pnrLookupEnabled: false,
    },
    {
        id: 4,
        name: "Tradewind",
        iataCode: "TJ",
        icaoCode: "GPD",
        countryCode: "US",
        countryName: "United States",
        pnrLookupEnabled: false,
    },
];

export const airlinesAll = {
    "content":[
        {"id":22,"name":"AerLingus","iataCode":"EI","icaoCode":"EIN","countryCode":"IE","countryName":"Ireland","iconUrl":"https://myverifly.com/assets/icons/ei.png","pnrLookupEnabled":null},
        {"id":25,"name":"AIR SERBIA","iataCode":"YU","icaoCode":"ALA","countryCode":"RS","countryName":"Serbia","iconUrl":"https://myverifly.com/assets/icons/jal.png","pnrLookupEnabled":null},
        {"id":24,"name":"AIR1 SERBIA","iataCode":"JQ","icaoCode":"CSL","countryCode":"RS","countryName":"Serbia","iconUrl":"https://myverifly.com/assets/icons/jal.png","pnrLookupEnabled":true},
        {"id":1,"name":"American Airlines","iataCode":"AA","icaoCode":"AAL","countryCode":"US","countryName":"United States","iconUrl":"https://myverifly.com/assets/icons/aa.png","pnrLookupEnabled":true},
        {"id":14,"name":"Bahamas Air","iataCode":"UP","icaoCode":"BHS","countryCode":"BS","countryName":"Bahamas","iconUrl":null,"pnrLookupEnabled":null},
        {"id":2,"name":"British Airways","iataCode":"BA","icaoCode":"BAW","countryCode":"GB","countryName":"United Kingdom","iconUrl":"https://myverifly.com/assets/icons/ba.png","pnrLookupEnabled":true},
        {"id":13,"name":"Iberia Airlines","iataCode":"IB","icaoCode":"IBE","countryCode":"ES","countryName":"Spain","iconUrl":null,"pnrLookupEnabled":null},
        {"id":15,"name":"Japan Airlines","iataCode":"JL","icaoCode":"JAL","countryCode":"JP","countryName":"Japan","iconUrl":"https://myverifly.com/assets/icons/jal.png","pnrLookupEnabled":null},
        {"id":17,"name":"Qantas","iataCode":"QF","icaoCode":"QFA","countryCode":"AU","countryName":"Australia","iconUrl":null,"pnrLookupEnabled":null},
        {"id":18,"name":"Royal Air Maroco","iataCode":"AT","icaoCode":"RAM","countryCode":"MA","countryName":"Morocco","iconUrl":null,"pnrLookupEnabled":null,"embeddedOnly": true}
    ],
    "pageable":{"sort":{"unsorted":false,"sorted":true,"empty":false},"offset":0,"pageNumber":0,"pageSize":10,"unpaged":false,"paged":true},
    "last":false,"totalPages":2,"totalElements":13,"size":10,"number":0,"sort":{"unsorted":false,"sorted":true,"empty":false},"first":true,"numberOfElements":10,"empty":false
}

export const airports = [
    {
        name: "Jesús Terán Paredo International Airport",
        iata_code: "AGU",
        timezone: "America/Mexico_City",
        country_iso: "MX",
    },
    { name: "V.C. Bird International Airport", iata_code: "ANU", timezone: "America/Antigua", country_iso: "AG" },
    { name: "Queen Beatrix International Airport", iata_code: "AUA", timezone: "America/Aruba", country_iso: "AW", minConnectionTime: "PT30M" },
    { name: "Del Bajío International Airport", iata_code: "BJX", timezone: "America/Mexico_City", country_iso: "MX" },
    { name: "El Dorado International Airport", iata_code: "BOG", timezone: "America/Bogota", country_iso: "CO" },
    {
        name: "Philip S. W. Goldson International Airport",
        iata_code: "BZE",
        timezone: "America/Belize",
        country_iso: "BZ",
    },
    {
        name: "Alfonso Bonilla Aragon International Airport",
        iata_code: "CLO",
        timezone: "America/Bogota",
        country_iso: "CO",
    },
    { name: "Cancún International Airport", iata_code: "CUN", timezone: "America/Cancun", country_iso: "MX" },
    {
        name: "General Roberto Fierro Villalobos International Airport",
        iata_code: "CUU",
        timezone: "America/Mazatlan",
        country_iso: "MX",
    },
    { name: "Cozumel International Airport", iata_code: "CZM", timezone: "America/Cancun", country_iso: "MX" },
    {
        name: "General Guadalupe Victoria International Airport",
        iata_code: "DGO",
        timezone: "America/Mexico_City",
        country_iso: "MX",
    },
    { name: "North Eleuthera Airport", iata_code: "ELH", timezone: "America/Nassau", country_iso: "BS" },
    {
        name: "Ministro Pistarini International Airport",
        iata_code: "EZE",
        timezone: "America/Buenos_Aires",
        country_iso: "AR",
    },
    { name: "Grand Bahama International Airport", iata_code: "FPO", timezone: "America/Nassau", country_iso: "BS" },
    { name: "Frankfurt am Main Airport", iata_code: "FRA", timezone: "Europe/Berlin", country_iso: "DE" },
    {
        name: "Don Miguel Hidalgo Y Costilla International Airport",
        iata_code: "GDL",
        timezone: "America/Mexico_City",
        country_iso: "MX",
    },
    { name: "Cheddi Jagan International Airport", iata_code: "GEO", timezone: "America/Guyana", country_iso: "GY" },
    { name: "Exuma International Airport", iata_code: "GGT", timezone: "America/Nassau", country_iso: "BS" },
    {
        name: "Rio Galeão – Tom Jobim International Airport",
        iata_code: "GIG",
        timezone: "America/Sao_Paulo",
        country_iso: "BR",
    },
    { name: "Point Salines International Airport", iata_code: "GND", timezone: "America/Grenada", country_iso: "GD" },
    {
        name: "Guarulhos - Governador André Franco Montoro International Airport",
        iata_code: "GRU",
        timezone: "America/Sao_Paulo",
        country_iso: "BR",
    },
    { name: "La Aurora Airport", iata_code: "GUA", timezone: "America/Guatemala", country_iso: "GT" },
    {
        name: "José Joaquín de Olmedo International Airport",
        iata_code: "GYE",
        timezone: "America/Guayaquil",
        country_iso: "EC",
    },
    { name: "José Martí International Airport", iata_code: "HAV", timezone: "America/Havana", country_iso: "CU" },
    {
        name: "General Ignacio P. Garcia International Airport",
        iata_code: "HMO",
        timezone: "America/Hermosillo",
        country_iso: "MX",
    },
    {
        name: "Bahías de Huatulco International Airport",
        iata_code: "HUX",
        timezone: "America/Mexico_City",
        country_iso: "MX",
    },
    { name: "Incheon International Airport", iata_code: "ICN", timezone: "Asia/Seoul", country_iso: "KR" },
    {
        name: "Manuel Márquez de León International Airport",
        iata_code: "LAP",
        timezone: "America/Mazatlan",
        country_iso: "MX",
    },
    { name: "London Heathrow Airport", iata_code: "LHR", timezone: "Europe/London", country_iso: "GB" },
    { name: "Jorge Chávez International Airport", iata_code: "LIM", timezone: "America/Lima", country_iso: "PE" },
    { name: "Sangster International Airport", iata_code: "MBJ", timezone: "America/Jamaica", country_iso: "JM" },
    {
        name: "Jose Maria Córdova International Airport",
        iata_code: "MDE",
        timezone: "America/Bogota",
        country_iso: "CO",
    },
    {
        name: "Licenciado Benito Juarez International Airport",
        iata_code: "MEX",
        timezone: "America/Mexico_City",
        country_iso: "MX",
    },
    {
        name: "Licenciado Manuel Crescencio Rejon Int Airport",
        iata_code: "MID",
        timezone: "America/Mexico_City",
        country_iso: "MX",
    },
    {
        name: "General Francisco J. Mujica International Airport",
        iata_code: "MLM",
        timezone: "America/Mexico_City",
        country_iso: "MX",
    },
    {
        name: "General Mariano Escobedo International Airport",
        iata_code: "MTY",
        timezone: "America/Mexico_City",
        country_iso: "MX",
    },
    {
        name: "General Rafael Buelna International Airport",
        iata_code: "MZT",
        timezone: "America/Mazatlan",
        country_iso: "MX",
    },
    { name: "Lynden Pindling International Airport", iata_code: "NAS", timezone: "America/Nassau", country_iso: "BS" },
    { name: "Narita International Airport", iata_code: "NRT", timezone: "Asia/Tokyo", country_iso: "JP" },
    { name: "Xoxocotlán International Airport", iata_code: "OAX", timezone: "America/Mexico_City", country_iso: "MX" },
    {
        name: "Toussaint Louverture International Airport",
        iata_code: "PAP",
        timezone: "America/Port-au-Prince",
        country_iso: "HT",
    },
    { name: "Providenciales Airport", iata_code: "PLS", timezone: "America/Grand_Turk", country_iso: "TC" },
    {
        name: "Gregorio Luperon International Airport",
        iata_code: "POP",
        timezone: "America/Santo_Domingo",
        country_iso: "DO",
    },
    { name: "Tocumen International Airport", iata_code: "PTY", timezone: "America/Panama", country_iso: "PA" },
    {
        name: "Punta Cana International Airport",
        iata_code: "PUJ",
        timezone: "America/Santo_Domingo",
        country_iso: "DO",
    },
    {
        name: "Licenciado Gustavo Díaz Ordaz International Airport",
        iata_code: "PVR",
        timezone: "America/Mexico_City",
        country_iso: "MX",
    },
    {
        name: "Querétaro Intercontinental Airport",
        iata_code: "QRO",
        timezone: "America/Mexico_City",
        country_iso: "MX",
    },
    {
        name: "Juan Manuel Galvez International Airport",
        iata_code: "RTB",
        timezone: "America/Tegucigalpa",
        country_iso: "HN",
    },
    {
        name: "Monseñor Óscar Arnulfo Romero International Airport",
        iata_code: "SAL",
        timezone: "America/El_Salvador",
        country_iso: "SV",
    },
    {
        name: "Ramón Villeda Morales International Airport",
        iata_code: "SAP",
        timezone: "America/Tegucigalpa",
        country_iso: "HN",
    },
    {
        name: "Comodoro Arturo Merino Benítez International Airport",
        iata_code: "SCL",
        timezone: "America/Santiago",
        country_iso: "CL",
    },
    {
        name: "Las Américas International Airport",
        iata_code: "SDQ",
        timezone: "America/Santo_Domingo",
        country_iso: "DO",
    },
    { name: "Los Cabos International Airport", iata_code: "SJD", timezone: "America/Mazatlan", country_iso: "MX" },
    {
        name: "Juan Santamaria International Airport",
        iata_code: "SJO",
        timezone: "America/Costa_Rica",
        country_iso: "CR",
    },
    {
        name: "Ponciano Arriaga International Airport",
        iata_code: "SLP",
        timezone: "America/Mexico_City",
        country_iso: "MX",
    },
    { name: "Cibao International Airport", iata_code: "STI", timezone: "America/Santo_Domingo", country_iso: "DO" },
    {
        name: "Princess Juliana International Airport",
        iata_code: "SXM",
        timezone: "America/Curacao",
        country_iso: "AN",
    },
    {
        name: "Sydney Kingsford Smith International Airport",
        iata_code: "SYD",
        timezone: "Australia/Sydney",
        country_iso: "AU",
    },
    { name: "Toncontín International Airport", iata_code: "TGU", timezone: "America/Tegucigalpa", country_iso: "HN" },
    {
        name: "Francisco Sarabia International Airport",
        iata_code: "TRC",
        timezone: "America/Mexico_City",
        country_iso: "MX",
    },
    {
        name: "Mariscal Sucre International Airport",
        iata_code: "UIO",
        timezone: "America/Guayaquil",
        country_iso: "EC",
    },
    { name: "Hewanorra International Airport", iata_code: "UVF", timezone: "America/St_Lucia", country_iso: "#N" },
    { name: "Vancouver International Airport", iata_code: "YVR", timezone: "America/Vancouver", country_iso: "CA" },
    { name: "Calgary International Airport", iata_code: "YYC", timezone: "America/Edmonton", country_iso: "CA" },
    {
        name: "Lester B. Pearson International Airport",
        iata_code: "YYZ",
        timezone: "America/Toronto",
        country_iso: "CA",
    },
    { name: "Eduardo Gomes International Airport", iata_code: "MAO", timezone: "America/Boa_Vista", country_iso: "BR" },
    { name: "Matecaña International Airport", iata_code: "PEI", timezone: "America/Bogota", country_iso: "CO" },
    {
        name: "Robert L. Bradshaw International Airport",
        iata_code: "SKB",
        timezone: "America/St_Kitts",
        country_iso: "#N",
    },
    { name: "Argyle International Airport", iata_code: "SVD", timezone: "America/St_Vincent", country_iso: "#N" },
    {
        name: "Ixtapa Zihuatanejo International Airport",
        iata_code: "ZIH",
        timezone: "America/Mexico_City",
        country_iso: "MX",
    },
    {
        name: "Playa De Oro International Airport",
        iata_code: "ZLO",
        timezone: "America/Mexico_City",
        country_iso: "MX",
    },
    {
        name: "Ernesto Cortissoz International Airport",
        iata_code: "BAQ",
        timezone: "America/Bogota",
        country_iso: "CO",
    },
    {
        name: "Sir Grantley Adams International Airport",
        iata_code: "BGI",
        timezone: "America/Barbados",
        country_iso: "BB",
    },
    { name: "Rafael Nuñez International Airport", iata_code: "CTG", timezone: "America/Bogota", country_iso: "CO" },
    { name: "Hato International Airport", iata_code: "CUR", timezone: "America/Curacao", country_iso: "AN" },
    { name: "Norman Manley International Airport", iata_code: "KIN", timezone: "America/Jamaica", country_iso: "JM" },
    {
        name: "Daniel Oduber Quiros International Airport",
        iata_code: "LIR",
        timezone: "America/Costa_Rica",
        country_iso: "CR",
    },
    { name: "Loreto International Airport", iata_code: "LTO", timezone: "America/Mazatlan", country_iso: "MX" },
    {
        name: "Leonard M Thompson International Airport",
        iata_code: "MHH",
        timezone: "America/Nassau",
        country_iso: "BS",
    },
    {
        name: "Carrasco International /General C L Berisso Airport",
        iata_code: "MVD",
        timezone: "America/Montevideo",
        country_iso: "UY",
    },
    {
        name: "Montreal / Pierre Elliott Trudeau International Airport",
        iata_code: "YUL",
        timezone: "America/Toronto",
        country_iso: "CA",
    },
    {
        name: "L.F. Wade International International Airport",
        iata_code: "BDA",
        timezone: "Atlantic/Bermuda",
        country_iso: "BM",
    },
    {
        name: "General Juan N Alvarez International Airport",
        iata_code: "ACA",
        timezone: "America/Mexico_City",
        country_iso: "MX",
    },
    {
        name: "General Leobardo C. Ruiz International Airport",
        iata_code: "ZCL",
        timezone: "America/Mexico_City",
        country_iso: "MX",
    },
    { name: "Owen Roberts International Airport", iata_code: "GCM", timezone: "America/Cayman", country_iso: "KY" },
    {
        name: "Augusto C. Sandino (Managua) International Airport",
        iata_code: "MGA",
        timezone: "America/Managua",
        country_iso: "NI",
    },
    { name: "Charles de Gaulle International Airport", iata_code: "CDG", timezone: "Europe/Paris", country_iso: "FR" },
    { name: "Piarco International Airport", iata_code: "POS", timezone: "America/Port_of_Spain", country_iso: "TT" },
    { name: "Flamingo International Airport", iata_code: "BON", timezone: "America/Curacao", country_iso: "AN" },
    {
        name: "Martinique Aimé Césaire International Airport",
        iata_code: "FDF",
        timezone: "America/Martinique",
        country_iso: "MQ",
    },
    { name: "Pointe-à-Pitre Le Raizet", iata_code: "PTP", timezone: "America/Guadeloupe", country_iso: "GP" },
    { name: "Chicago O'Hare International Airport", iata_code: "ORD", timezone: "America/Chicago", country_iso: "US" },
    {
        name: "Charlotte Douglas International Airport",
        iata_code: "CLT",
        timezone: "America/New_York",
        country_iso: "US",
    },
    {
        name: "Dallas Fort Worth International Airport",
        iata_code: "DFW",
        timezone: "America/Chicago",
        country_iso: "US",
    },
    { name: "John F Kennedy International Airport", iata_code: "JFK", timezone: "America/New_York", country_iso: "US" },
];

export const generateFlights = (airlineCode, departureCode, arrivalCode) => {
    if (airlineCode === "TJ") {
        return [{
            airlineCode,
            arrivalFlightTime: "",
            departureFlightTime: "",
            flightDate: "",
            flightNumber: ""
        }]
    }
    const indexedAirports = airports.map((a, i) => ({...a, index: i + 1}));
    const {index: part1, timezone: departureTz} = indexedAirports.find(a => a.iata_code === departureCode);
    const {index: part2, timezone: arrivalTz} = indexedAirports.find(a => a.iata_code === arrivalCode);
    const flightNumber = "" + part1 + part2;
    return generateDates().map(flightDate => ({
        airlineCode,
        arrivalFlightTime: DateTime.fromISO(flightDate).plus({hours: 14}).setZone(arrivalTz).toString(),
        departureFlightTime: DateTime.fromISO(flightDate).plus({hours: 12}).setZone(departureTz).toString(),
        flightDate,
        flightNumber
    }))
};

const generateDates = () => {
    return new Array(14).fill(0).map((el, i) => DateTime.local().plus({days: i}).toISODate());
}

export const supportedCountries = [
   {
      "id":12,
      "name":"Argentina",
      "code":"AR",
      "supportedDestination":true
   },
   {
      "id":13,
      "name":"Aruba",
      "code":"AW",
      "supportedDestination":true,
      "visaRequired": true
   },
   {
      "id":14,
      "name":"Australia",
      "code":"AU",
      "supportedDestination":true
   },
   {
      "id":15,
      "name":"Austria",
      "code":"AT",
      "supportedDestination":true
   },
   {
      "id":16,
      "name":"Bahamas",
      "code":"BS",
      "supportedDestination":true
   },
   {
      "id":17,
      "name":"Bahrain",
      "code":"BH",
      "supportedDestination":true
   },
   {
      "id":18,
      "name":"Barbados",
      "code":"BB",
      "supportedDestination":true
   },
   {
      "id":19,
      "name":"Belgium",
      "code":"BE",
      "supportedDestination":true
   },
   {
      "id":20,
      "name":"Belize",
      "code":"BZ",
      "supportedDestination":true
   },
   {
      "id":21,
      "name":"Bermuda",
      "code":"BM",
      "supportedDestination":true
   },
   {
      "id":22,
      "name":"Brazil",
      "code":"BR",
      "supportedDestination":true
   },
   {
      "id":23,
      "name":"Bulgaria",
      "code":"BG",
      "supportedDestination":true
   },
   {
      "id":24,
      "name":"Canada",
      "code":"CA",
      "supportedDestination":true
   },
   {
      "id":25,
      "name":"Cayman Islands",
      "code":"KY",
      "supportedDestination":true
   },
   {
      "id":26,
      "name":"Chile",
      "code":"CL",
      "supportedDestination":true
   },
   {
      "id":27,
      "name":"Colombia",
      "code":"CO",
      "supportedDestination":true
   },
   {
      "id":28,
      "name":"Costa Rica",
      "code":"CR",
      "supportedDestination":true
   },
   {
      "id":29,
      "name":"Croatia",
      "code":"HR",
      "supportedDestination":true
   },
   {
      "id":30,
      "name":"Curacao",
      "code":"CW",
      "supportedDestination":true
   },
   {
      "id":31,
      "name":"Czech",
      "code":"CZ",
      "supportedDestination":true
   },
   {
      "id":32,
      "name":"Dominican Republic",
      "code":"DO",
      "supportedDestination":true
   },
   {
      "id":33,
      "name":"Ecuador",
      "code":"EC",
      "supportedDestination":true
   },
   {
      "id":34,
      "name":"Egypt",
      "code":"EG",
      "supportedDestination":true
   },
   {
      "id":35,
      "name":"El Salvador",
      "code":"SV",
      "supportedDestination":true
   },
   {
      "id":36,
      "name":"Finlandr",
      "code":"FI",
      "supportedDestination":true
   },
   {
      "id":37,
      "name":"France",
      "code":"FR",
      "supportedDestination":true
   },
   {
      "id":38,
      "name":"Germany",
      "code":"DE",
      "supportedDestination":true
   },
   {
      "id":39,
      "name":"Greece",
      "code":"GR",
      "supportedDestination":true
   },
   {
      "id":40,
      "name":"Grenada",
      "code":"GD",
      "supportedDestination":true
   },
   {
      "id":41,
      "name":"Guatemala",
      "code":"GT",
      "supportedDestination":true
   },
   {
      "id":42,
      "name":"Guyana",
      "code":"GY",
      "supportedDestination":true
   },
   {
      "id":43,
      "name":"Hawaii",
      "code":"HI",
      "supportedDestination":true
   },
   {
      "id":45,
      "name":"Honduras",
      "code":"HN",
      "supportedDestination":true
   },
   {
      "id":46,
      "name":"Hungary",
      "code":"HU",
      "supportedDestination":true
   },
   {
      "id":47,
      "name":"India",
      "code":"IN",
      "supportedDestination":true
   },
   {
      "id":48,
      "name":"Ireland",
      "code":"IE",
      "supportedDestination":true
   },
   {
      "id":49,
      "name":"Israel",
      "code":"IL",
      "supportedDestination":true
   },
   {
      "id":50,
      "name":"Italy",
      "code":"IT",
      "supportedDestination":true
   },
   {
      "id":51,
      "name":"Jamaica",
      "code":"JM",
      "supportedDestination":true
   },
   {
      "id":52,
      "name":"Japan",
      "code":"JP",
      "supportedDestination":true
   },
   {
      "id":53,
      "name":"Jordan",
      "code":"JO",
      "supportedDestination":true
   },
   {
      "id":54,
      "name":"Kuwait",
      "code":"KV",
      "supportedDestination":true
   },
   {
      "id":55,
      "name":"Malta",
      "code":"MT",
      "supportedDestination":true
   },
   {
      "id":56,
      "name":"Mauritius",
      "code":"MU",
      "supportedDestination":true
   },
   {
      "id":58,
      "name":"Morocco",
      "code":"MA",
      "supportedDestination":true
   },
   {
      "id":59,
      "name":"Netherlands",
      "code":"NL",
      "supportedDestination":true
   },
   {
      "id":60,
      "name":"Panama",
      "code":"PA",
      "supportedDestination":true
   },
   {
      "id":62,
      "name":"Peru",
      "code":"PE",
      "supportedDestination":true
   },
   {
      "id":63,
      "name":"Poland",
      "code":"PL",
      "supportedDestination":true
   },
   {
      "id":64,
      "name":"Portugal",
      "code":"PT",
      "supportedDestination":true
   },
   {
      "id":65,
      "name":"Puerto Rico",
      "code":"PR",
      "supportedDestination":true
   },
   {
      "id":67,
      "name":"Qatar",
      "code":"QA",
      "supportedDestination":true
   },
   {
      "id":69,
      "name":"Saint Barthelemytar",
      "code":"BL",
      "supportedDestination":true
   },
   {
      "id":70,
      "name":"Saint Kitts & Nevis",
      "code":"KN",
      "supportedDestination":true
   },
   {
      "id":71,
      "name":"Saudi Arabia",
      "code":"SA",
      "supportedDestination":true
   },
   {
      "id":11,
      "name":"Serbia",
      "code":"RS",
      "supportedDestination":true
   },
   {
      "id":72,
      "name":"Singapore",
      "code":"SG",
      "supportedDestination":true
   },
   {
      "id":73,
      "name":"Sint Maarten",
      "code":"SX",
      "supportedDestination":true
   },
   {
      "id":74,
      "name":"South Africa",
      "code":"ZA",
      "supportedDestination":true
   },
   {
      "id":75,
      "name":"Spain",
      "code":"ES",
      "supportedDestination":true
   },
   {
      "id":77,
      "name":"St Lucia",
      "code":"IC",
      "supportedDestination":true
   },
   {
      "id":78,
      "name":"Switzerland",
      "code":"CH",
      "supportedDestination":true
   },
   {
      "id":79,
      "name":"Trinidad and Tobago",
      "code":"TT",
      "supportedDestination":true
   },
   {
      "id":44,
      "name":"Turkey",
      "code":"TR",
      "supportedDestination":true
   },
   {
      "id":76,
      "name":"Turks and Caicos",
      "code":"TC",
      "supportedDestination":true
   },
   {
      "id":66,
      "name":"United Arab Emirates",
      "code":"AE",
      "supportedDestination":true
   },
   {
      "id":61,
      "name":"United Kingdom",
      "code":"GB",
      "supportedDestination":true
   },
   {
      "id":57,
      "name":"United States",
      "code":"US",
      "supportedDestination":true
   },
   {
      "id": 175,
      "name": "Vietnam",
      "code": "VN",
      "supportedDestination": true,
      "visaRequired": true
  }
]
