import { useHistory } from 'react-router';

const usePayment = () => {
    const history = useHistory();

    const openStripeWindow = (instance, userId, onNext, onBack) => {
        if(!instance) {
          return onNext();
        }
        const [paymentRequests] = instance.userData.userSpecificData[userId].paymentRequests?.slice(-1);
        const redirectUrl = paymentRequests?.paymentInfo?.params?.redirectUrl;

        if (!redirectUrl && !window.openNewWindow) {
            return onNext();
        }

        try {
            window.openNewWindow(redirectUrl).then(data => {
                if (!data.result) {
                    return history.goBack();
                }

                const stripeQueryParams = new URLSearchParams(data.result);
                const paymentStatus = stripeQueryParams.get("paymentStatus");

                if (paymentStatus === "true") {
                    onNext();
                } else {
                    onBack();
                }
            });
        } catch (error) {
            console.error(error);
            onNext();
        }
    };

    return { openStripeWindow };
}

export default usePayment