import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import LockIcon from "../../../../icons/lock-icon.png";
import CustomSwitch from "../../CustomSwitch";
import FormPage from "../../FormPage";
import { useLocalize } from "../../../../utils/hooks";
import { constructPolicyText } from '../../../../utils/textUtils';

const UnnapprovedPolicy = ({ unapprovedPolicies, onAccept }) => {
    const { localize } = useLocalize();

    const [accepted, setAccepted] = useState({});
    const [toggle, setToggle] = useState({});

    const policyNames = unapprovedPolicies?.map(uP => uP?.name);

    const allAccepted = () => policyNames?.reduce((acc, curr) => acc && accepted[curr], true);

    useEffect(() => {
        setAccepted(policyNames?.reduce((acc, curr) => ({ ...acc, [curr]: false }), {}));
        setToggle(
            policyNames?.reduce(
                (acc, curr) => ({ ...acc, [curr]: () => setAccepted(state => ({ ...state, [curr]: !state[curr] })) }),
                {}
            )
        );
    }, [unapprovedPolicies]);

    return (
        <FormPage>
            <div className="container d-flex justify-content-center flex-column text-center align-items-center px-2 mb-3">
                <img src={LockIcon} alt="alt-icon" width="60px" />
                <h5 className="mt-3">
                    <FormattedMessage id="privacyPolicy.header.title" />
                </h5>
                <FormattedMessage id="privacyPolicy.header.text" />
            </div>
            <div className="consent-switches">
                {unapprovedPolicies?.map(policy => (
                    <CustomSwitch
                        key={policy.name}
                        control={policy.name}
                        switchName={policy.name}
                        title={policy.title || localize("privacyPolicy." + policy.name + ".title")}
                        label={constructPolicyText(policy)}
                        onChecked={toggle[policy.name]}
                    />
                ))}
            </div>
            <Button type="button" variant="primary" className="w-100 my-2" disabled={!allAccepted()} onClick={onAccept}>
                <FormattedMessage id="global.continue" />
            </Button>
        </FormPage>
    );
};

export default UnnapprovedPolicy;
