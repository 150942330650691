import { DateTime } from "luxon";
import moment from "moment";

/**
 * Set of utility functions for handling date and time
 * and creating formatted strings from the js Date object
 */

/**
 * @param: javaScript Date object
 * @returns: string containing only hours and minutes of the input date,
 * formatted as hh:mm, e.g. 06:00 PM
 */
export function getTimeString(date, timeZone) {
    let options = { hour: "2-digit", minute: "2-digit", timeZone: timeZone };
    return date.toLocaleDateString("en-US", options);
}

export function getLocalDateString(date, timeZone, locale) {
    let options = { year: "numeric", month: "short", day: "numeric", timeZone: timeZone };
    return date.toLocaleDateString(locale || "en-US", options);
}

/**
 * @param: javaScript Date object
 * @returns: string containing date portion of the input,
 * in ISO format, e.g. 2020-07-14
 */
export function getDateString(date = new Date()) {
    if (!date instanceof Date) {
        try {
            date = new Date(date);
        } catch {
            date = new Date();
        }
    }
    // return date.toISOString().substr(0, 10)
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    if (month < 10) month = "0" + month;
    let day = date.getDate();
    if (day < 10) day = "0" + day;
    return year + "-" + month + "-" + day;
}

/**
 * @param: javaScript Date object
 * @returns: string representing local date and time but in ISO format
 * as javaScript built in toISOString method returns UTC instead of local time
 * e.g.: 2020-07-14T11-15-00
 */
export function getIsoFormatLocalDateTimeString(date) {
    const offset = date.getTimezoneOffset() * 60 * 1000;
    const dateLocal = new Date(date - offset);
    let iso = dateLocal.toISOString();
    iso = iso.slice(0, 19);
    return iso;
}

/**
 * @param: date javaScript Date object
 * @param: durationInMillis duration of time slot in milliseconds
 * @param: timeZone string representing time zone
 * @returns: string containing time slot
 * start time (hours and minutes) and end time
 * separated by a dash, e.g. 06:00 AM - 06:15 AM
 */
export function getTimeSlotStartEndString(date, durationInMillis, timeZone) {
    if (!(date instanceof Date)) return "";

    let end = new Date(date.getTime() + durationInMillis);
    return `${getTimeString(date, timeZone)} - ${getTimeString(end, timeZone)}`;
}

/**
 * @param: javaScript Date object
 * @returns: object representing the start of business
 * hours for the given day, and number of slots as received
 * from the configuration
 */
export function getTimeSlotStartAndCount(config, dateString) {
    let startTime;
    let countOffset = 0;
    const { startHours, timeZoneOffsetInHours, defaultTimeZoneOffset, timeSlotDurationInMinutes } = config;
    const values = dateString.split(/\D/);
    let date = new Date(values[0], values[1] - 1, values[2], 3, 0, 0, 0);
    const timeZoneOffsetInHour = timeZoneOffsetInHours[dateString] || defaultTimeZoneOffset;
    date.setHours(startHours - (timeZoneOffsetInHour + date.getTimezoneOffset() / 60));
    startTime = date.getTime();

    const now = new Date().getTime();
    if (startTime < now) {
        const timeSlotDuration = timeSlotDurationInMinutes * 60 * 1000;
        countOffset = Math.floor((now - startTime) / timeSlotDuration);
        startTime = now - (now % timeSlotDuration);
    }

    return { startTime, countOffset };
}

export function getDaysInMonth(date = new Date()) {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
}

export function addMonth(date = new Date()) {
    return new Date(date.getFullYear(), date.getMonth() + 1, date.getDate());
}

/**
 * @param {string} zone - timezone where center is
 * @returns {string} - current day of the week in uppercase
 */

export const getCurrentDay = zone => {
    const timeZone = zone || "UTC";
    return DateTime.local().setZone(timeZone).toLocaleString({ weekday: "long" }).toUpperCase();
};

export const setDateTimeZone = date => DateTime.fromISO(date).toLocal().setZone("UTC", { keepLocalTime: true }).toISODate();

/**
 * @param {Object[]} workingHoursArray - array of objects that comes from backend
 * @param {string} workingHoursArray.openDay - day of the week in uppercase
 * @param {string} zone - timezone where center is
 * @returns {Object[]} - sorted array of objects, starting from the current day of the week
 */

export const sortWorkingHours = (workingHoursArray, zone) => {
    const timeZone = zone || "UTC";
    const currentDate = DateTime.local().setZone(timeZone).toLocaleString({ weekday: "long" }).toUpperCase();
    const sortedWorkingHoursArray = [...workingHoursArray];
    if (workingHoursArray?.length > 0) {
        for (let i = 0; i < workingHoursArray?.length; i++) {
            if (workingHoursArray[i]?.openDay !== currentDate) {
                sortedWorkingHoursArray.shift();
                sortedWorkingHoursArray.push(workingHoursArray[i]);
            } else {
                break;
            }
        }
    }
    return sortedWorkingHoursArray;
};

export const getHoursWithoutSeconds = hours => {
    return moment(`${hours}`, "HH:mm:ss").format("HH:mm");
};

const currentYear = DateTime.local().year;

const getIsoDate = (year, month, day) => {
    return DateTime.local(year, month, day).toISODate();
};

export const getBegginingOf1900 = () => getIsoDate(1900, 1, 1);

export const getBegginingOf2020 = () => getIsoDate(2020, 1, 1);

export const getBegginingOfCurrentYear = () => getIsoDate(currentYear, 1, 1);

export const getBegginingOfLastYear = () => getIsoDate(currentYear - 1, 1, 1);

export const getEndOCurrentYear = () => getIsoDate(currentYear, 12, 31);

export const getEndOfNextYear = () => getIsoDate(currentYear + 1, 12, 31);

export const getBeginningOf30YearsAgo = () => getIsoDate(currentYear - 30, 1, 1);

export const getEndOf30YearsInFuture = () => getIsoDate(currentYear + 30, 12, 31);

export const getDatePart = dt => DateTime.fromISO(dt).setZone("UTC", { keepLocalTime: true }).toISODate();

export const isUnderAge = dateTime => {
    const now = DateTime.local();
    const diff = now.diff(DateTime.fromISO(dateTime), "years");
    return diff.years < 18;
};
