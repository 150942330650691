import React from "react";
import { Form } from "react-bootstrap";
import { useLanguage, useLocalize } from "../../../utils/hooks";
import Required from "../../_components/Required";

const BookingTextField = ({ field, value, onChange, maxLength }) => {
    const { localize } = useLocalize();
    const { localizeField } = useLanguage();

    const defaultMissingMessage = localize("global.required");
    const getMissingMessage = f => (f.missingMessage ? localizeField(f.missingMessage) : defaultMissingMessage);
    const getLabel = f =>
        f.label ? localizeField(f.label) : localize(`carnivalcruise.retrievebooking.${f.name}.label`);

    return (
        <Form.Group controlId={field.name} key={field.name}>
            <Form.Label>{getLabel(field)}</Form.Label>
            <Form.Control
                value={value}
                type="text"
                minLength="1"
                maxLength={maxLength}
                pattern={field.pattern}
                onChange={onChange}
                required
            />
            {field.note && <Form.Text muted>{localizeField(field.note)}</Form.Text>}
            <Required message={getMissingMessage(field)} />
        </Form.Group>
    );
};

export default BookingTextField;
