import React from "react";

const RemoveIcon2 = ({ size, customClass, colorClass = "black" }) => {
    return (
        <svg
            className={"svg-icon " + (customClass ? customClass : "")}
            width={size ? size : "26px"}
            height={size ? size : "26px"}
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16 26.5C21.799 26.5 26.5 21.799 26.5 16C26.5 10.201 21.799 5.5 16 5.5C10.201 5.5 5.5 10.201 5.5 16C5.5 21.799 10.201 26.5 16 26.5ZM16 29C23.1797 29 29 23.1797 29 16C29 8.8203 23.1797 3 16 3C8.8203 3 3 8.8203 3 16C3 23.1797 8.8203 29 16 29Z"
                className={colorClass}
            />
            <path
                d="M22 11.2086L20.7914 10L16 14.7914L11.2086 10L10 11.2086L14.7914 16L10 20.7914L11.2086 22L16 17.2086L20.7914 22L22 20.7914L17.2086 16L22 11.2086Z"
                className={colorClass}
            />
        </svg>
    );
};

export default RemoveIcon2;
