import { Container, Row, Spinner } from "react-bootstrap";
import React, { useEffect, useState } from "react";

const SpinnerAnimation = ({ message, messageDelay = 0, ...otherProps }) => {
    const [showMessage, setShowMessage] = useState(false);

    useEffect(() => {
        if (message) {
            const to = setTimeout(() => setShowMessage(true), messageDelay);
            return () => clearTimeout(to);
        }
    }, [])
    return (
        <>
            <div id={"spinner-container"}>
                <Spinner animation="border" {...otherProps} />
            </div>
            {showMessage && <>
                <div className="break-row"/>
                <div className="alert text-warning mt-3">{message}</div>
            </>}
        </>
    );
};

const CustomSpinner = ({ row, container, ...otherProps }) => {
    if (row)
        return (
            <Row className="justify-content-center flex-wrap mx-0">
                <SpinnerAnimation {...otherProps} />
            </Row>
        );
    if (container)
        return (
            <Container className="d-flex flex-row flex-wrap justify-content-center align-items-center">
                <SpinnerAnimation {...otherProps} />
            </Container>
        );
    return <SpinnerAnimation {...otherProps} />;
};

export default CustomSpinner;
