import { TEST_TYPES_REQUEST, TEST_TYPES_SUCCESS, TEST_TYPES_FAIL } from "../constants/testTypesConstants";

export const testTypesReducer = (state = { testTypesArray: [] }, action) => {
    switch (action.type) {
        case TEST_TYPES_REQUEST:
            return { loading: true, testTypesArray: [] };
        case TEST_TYPES_SUCCESS:
            return { loading: false, testTypesArray: action.payload };
        case TEST_TYPES_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};
