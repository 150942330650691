export async function putToS3(fileObject, presignedUrl) {
    const requestOptions = {
        method: "PUT",
        headers: {
            "Content-Type": fileObject.type,
        },
        body: fileObject,
    };
    const response = await fetch(presignedUrl, requestOptions);
    return await response;
}