import ReactMarkdown from "react-markdown";
import raw from "rehype-raw";
import sanitize from "rehype-sanitize";
import React from "react";

const tagNames = [
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "h7",
    "h8",
    "br",
    "b",
    "i",
    "strong",
    "em",
    "a",
    "pre",
    "code",
    "img",
    "tt",
    "div",
    "ins",
    "del",
    "sup",
    "sub",
    "p",
    "ol",
    "ul",
    "table",
    "thead",
    "tbody",
    "tfoot",
    "blockquote",
    "dl",
    "dt",
    "dd",
    "kbd",
    "q",
    "samp",
    "var",
    "hr",
    "ruby",
    "rt",
    "rp",
    "li",
    "tr",
    "td",
    "th",
    "s",
    "strike",
    "header",
    "summary",
    "section",
    "details",
    "caption",
    "figure",
    "figcaption",
    "abbr",
    "bdo",
    "cite",
    "dfn",
    "mark",
    "small",
    "span",
    "time",
    "wbr",
    "u"
];

const MarkdownText = ({ className, children }) => {
    return <ReactMarkdown className={className} rehypePlugins={[raw, [sanitize, { tagNames }]]} children={children} />;
};

export default MarkdownText;
