import React, { useState } from "react";
import { Row, Modal, Button } from "react-bootstrap";
import { DateTime } from "luxon";
import { useLocalize } from "../../utils/hooks";
import { ErrorIcon } from "../../icons/ErrorIcon";

const InterlineInfoModal = ({
    showModal,
    bookingRef,
    airlineName,
    firstName,
    lastName,
    birthDate,
    nationality,
    countryOfResidence,
    closeModal,
    isUserActionsCheck = false,
    cancelTrip
}) => {
    const { localize } = useLocalize();
    const formatedBirthDate = DateTime.fromISO(birthDate, { setZone: true }).toLocaleString({ ...DateTime.DATE_MED });

    const [isCancelConfirmed, setIsCancelConfirmed] = useState(false);

    const handleCancelTrip = () => {
        if (isCancelConfirmed) {
            cancelTrip();
        } else {
            setIsCancelConfirmed(true);
        }
    }

    return (
        <Modal show={showModal} onHide={closeModal} centered dialogClassName="border-modal" className="px-1">
            <Modal.Body>
                <div className="text-center pt-3">
                    <ErrorIcon size="50px" />
                </div>
                <div className="text-center h4 py-4">
                    {localize("addInterlineFlightDetails.interlineModalInfo.title")}
                </div>
                <div className="px-3">
                    <div className="pb-2">{localize("addInterlineFlightDetails.interlineModalInfo.text-1")}</div>
                    <div className="pb-2">
                        {localize("addInterlineFlightDetails.interlineModalInfo.recordLocator")}: {bookingRef}
                    </div>
                    <div className="pb-2">
                        {localize("addInterlineFlightDetails.interlineModalInfo.airline")}: {airlineName}
                    </div>
                    <div className="pb-2">
                        {localize("addInterlineFlightDetails.interlineModalInfo.firstAndMiddleName")}: {firstName}
                    </div>
                    <div className="pb-2">
                        {localize("addInterlineFlightDetails.interlineModalInfo.lastName")}: {lastName}
                    </div>
                    <div className="pb-2">
                        {localize("addInterlineFlightDetails.interlineModalInfo.dateOfBirth")}: {formatedBirthDate}
                    </div>
                    <div className="pb-2">
                        {localize("addInterlineFlightDetails.interlineModalInfo.nationality")}: {nationality}
                    </div>
                    {countryOfResidence && (
                        <div className="pb-2">
                            {localize("addInterlineFlightDetails.interlineModalInfo.countryOfResidence")}: {countryOfResidence}
                        </div>
                    )}
                    {isUserActionsCheck ? null : <div className="pb-2">{localize("addInterlineFlightDetails.interlineModalInfo.text-2")}</div>}
                </div>
                <div className="px-3 py-4">
                    <Row className="justify-content-center">
                        <Button className="w-100" onClick={closeModal}>
                            {isUserActionsCheck ? localize("global.back") : localize("addInterlineFlightDetails.interlineModalInfo.button")}
                        </Button>
                        {isUserActionsCheck && <Button className="w-100 mt-3" variant="secondary" onClick={handleCancelTrip}>
                            {isCancelConfirmed ? localize("global.cancelTrip.confirm") : localize("global.cancelTrip")}
                        </Button>}
                    </Row>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default InterlineInfoModal;
