import { flightPost, flightResponseInterlineGet, presignedUrls, putToPresignedUrl } from "../responseResolvers/flights";
import { rest } from "msw";
import { submissionHref } from "../../utils/constants";
import { localHost } from "./constants";

export const processInstanceHandlers = [
    // POST submissionHref/processInstances/persons/:personId`
    rest.post(`${submissionHref}/processInstances/persons/:personId`, flightPost),

    rest.get(`${localHost}/api/AA/flightBlocks`, flightResponseInterlineGet),

    rest.post(`${submissionHref}/api/persons/:personId/presignedUrls`, presignedUrls),

    rest.put(`${localHost}/presignedUrl`, putToPresignedUrl)
];
