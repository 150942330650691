import { useEffect, useState } from "react";
import useQuery from "./useQuery";

const useUserDataPath = processInstance => {
    const [data, setData] = useState({});

    const query = useQuery();
    const fsdReference = query.get("fsdReference");
    const companionId = query.get("companionId");

    const getUserSpecificData = (companion = companionId) => {
        if (!processInstance) return null;

        if (!companion) return processInstance.userData.userSpecificData;

        return processInstance.userData.userSpecificData[companion];
    };

    const deleteUserSpecificData = (companion = companionId) => {
        if (!processInstance) return null;

        delete processInstance.userData.userSpecificData[companion];

        return processInstance;
    };

    const getUserDataPath = () => {
        if (!processInstance) {
            return null;
        }

        if (fsdReference) {
            return processInstance.userData.userSpecificData[companionId]?.flightSpecificData[fsdReference];
        }

        return processInstance.userData.userSpecificData[companionId];
    };

    const setUserData = (key, value) => {
        if (fsdReference) {
            processInstance.userData.userSpecificData[companionId].fsdReference = fsdReference;
            processInstance.userData.userSpecificData[companionId].flightSpecificData[fsdReference][key] = value;
        } else {
            processInstance.userData.userSpecificData[companionId][key] = value;
        }

        return processInstance;
    };

    const setUserDataWithInstance = (key, value, instance = processInstance) => {
        if (fsdReference) {
            instance.userData.userSpecificData[companionId].fsdReference = fsdReference;
            instance.userData.userSpecificData[companionId].flightSpecificData[fsdReference][key] = value;
        } else {
            instance.userData.userSpecificData[companionId][key] = value;
        }

        return instance;
    };

    useEffect(() => {
        if (processInstance) {
            setData(getUserDataPath());
        }
    }, [processInstance]);

    return {
        fsdReference,
        userData: data,
        getUserSpecificData,
        deleteUserSpecificData,
        getUserDataPath,
        setUserData,
        setUserDataWithInstance
    };
};

export default useUserDataPath;
