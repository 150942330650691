export const ErrorIcon = ({ size, customClass, fill='', colorClass = "error-icon" }) => (
    <svg
        className={"svg-icon " + (customClass ? customClass : "")}
        viewBox="0 0 24 24" width={size ? size : "24px"}
        height={size ? size : "24px"}
    >
        <g transform="translate(-101.01093,-144.51093)">
            <path
                className={colorClass}
                id="path4-0"
                d="m 111.53375,160.94246 h 2.95436 v 2.95429 h -2.95436 z m 0,-11.81736 h 2.95436 v 8.86301 h -2.95436 z" />
            <path
                className={colorClass}
                style={{ strokeWidth: "1.2" }}
                id="path4"
                d="m 112.99893,144.51093 c -6.62398,0 -11.988,5.37602 -11.988,12.00003 0,6.62398 5.36402,11.99997 11.988,11.99997 6.63601,0 12.012,-5.37599 12.012,-11.99997 0,-6.62401 -5.37599,-12.00003 -12.012,-12.00003 z m 0.015,21.59998 c -5.304,0 -9.60001,-4.29601 -9.60001,-9.59995 0,-5.304 4.29601,-9.60001 9.60001,-9.60001 5.30394,0 9.59995,4.29601 9.59995,9.60001 0,5.30394 -4.29601,9.59995 -9.59995,9.59995 z" />
        </g>
    </svg>
);