import React, { useContext, useEffect } from "react";
import { DateTime } from "luxon";
import { Card, Form } from "react-bootstrap";
import CredentialContext from "../../../config/CredentialContext";
import {
    fetchAirportsFromOrigin,
    fetchAirportsFromOriginWithoutArrivalCountry,
    fetchAirportsToCountry,
    fetchFlights,
} from "../../../utils/dataFetching";
import { mapAirportsToOptions, mapCountriesToOptions } from "../../../utils/flightUtils";
import { useLanguage, useLocalize } from "../../../utils/hooks";
import FlightDateInput from "../../AddFlightDetails/FlightDateInput";
import AutoSelect from "../../_components/AutoSelect";
import Required from "../../_components/Required";
import IconButton from "../../_components/IconButton";
import FlightInfo from "../../AddFlightDetails/FlightInfo";

const FlightSegmentForm = ({
    airline,
    supportedCountries,
    segmentNumber,
    flightSegments,
    flightSegment,
    flightActionsConfig,
}) => {
    const { updateFlightSegment, addFlightSegment, removeFlightSegment } = flightActionsConfig;

    const { loading, setError } = useContext(CredentialContext);
    const { localize } = useLocalize();
    const { language } = useLanguage();

    const getFlightNumberClassName = () => (airline === "TJ" ? "d-none" : "");
    const getAvailableFlights = () => {
        return [...new Set(flightSegment.flights.map(f => f.airlineCode + (f.flightNumber || "")))];
    };
    const getDepartureTimeZone = () =>
        flightSegment.departureAirports?.find(dA => dA.iata_code === flightSegment.departureAirport)?.timezone || "UTC";
    const updateFlightTime = (flightDetails, time) => {
        const [hours, minutes] = time.split(":");
        const chosenFlight = { ...flightDetails };
        const departureTz = getDepartureTimeZone();
        chosenFlight.departureFlightTime = DateTime.fromISO(chosenFlight.flightDate)
            .plus({ hours })
            .plus({ minutes })
            .setZone(departureTz, { keepLocalTime: true })
            .toISO();
        return chosenFlight;
    };

    const selectDestinationCountry = value => {
        if (value === flightSegment.destinationCountry) return;

        const newFlightSegments = [...flightSegments];
        const flightSegmentToUpdate = newFlightSegments[segmentNumber];
        flightSegmentToUpdate.destinationCountry = value;

        updateFlightSegment(newFlightSegments);
    };

    useEffect(() => {
        const newFlightSegments = [...flightSegments];
        const flightSegmentToUpdate = newFlightSegments[segmentNumber];
        flightSegmentToUpdate.departureAirports = [];
        updateFlightSegment(newFlightSegments);

        if (segmentNumber === 0) {
            const handleResponse = response => {
                flightSegmentToUpdate.departureAirports = response;
                flightSegmentToUpdate.arrivalAirports = [];
                flightSegmentToUpdate.arrivalAirport = undefined;
                updateFlightSegment(newFlightSegments);
            };

            flightSegment.destinationCountry &&
                fetchAirportsToCountry(airline, flightSegment.destinationCountry, handleResponse, setError);
        } else {
            flightSegmentToUpdate.departureAirports = [
                ...flightSegments[segmentNumber - 1].connectingFlightDepartureAirport,
            ];
            updateFlightSegment(newFlightSegments);
        }
    }, [flightSegment.destinationCountry]);

    const selectDepartureAirport = value => {
        if (value === flightSegment.departureAirport) return;

        const newFlightSegments = [...flightSegments];
        const flightSegmentToUpdate = newFlightSegments[segmentNumber];
        flightSegmentToUpdate.departureAirport = value;

        updateFlightSegment(newFlightSegments);
    };

    useEffect(() => {
        const newFlightSegments = [...flightSegments];
        const flightSegmentToUpdate = newFlightSegments[segmentNumber];
        flightSegmentToUpdate.arrivalAirports = [];
        updateFlightSegment(newFlightSegments);

        const handleResponse = response => {
            flightSegmentToUpdate.arrivalAirports = response;
            updateFlightSegment(newFlightSegments);
        };

        airline &&
            flightSegment.departureAirport &&
            flightSegment.destinationCountry &&
            fetchAirportsFromOrigin(
                airline,
                flightSegment.departureAirport,
                flightSegment.destinationCountry,
                handleResponse,
                setError
            );
    }, [flightSegment.destinationCountry, flightSegment.departureAirport]);

    const selectArrivalAirport = value => {
        if (value === flightSegment.arrivalAirport) return;

        const newFlightSegments = [...flightSegments];
        const flightSegmentToUpdate = newFlightSegments[segmentNumber];
        flightSegmentToUpdate.arrivalAirport = value;
        flightSegmentToUpdate.connectingFlightDepartureAirport = flightSegment.arrivalAirports.filter(
            aA => aA.iata_code === value
        );
        updateFlightSegment(newFlightSegments);
    };

    useEffect(() => {
        const newFlightSegments = [...flightSegments];
        const flightSegmentToUpdate = newFlightSegments[segmentNumber];
        flightSegmentToUpdate.flights = [];
        flightSegmentToUpdate.flight = undefined;
        flightSegmentToUpdate.flightNumber = undefined;
        flightSegmentToUpdate.flightDate = "";
        updateFlightSegment(newFlightSegments);

        const handleResponse = response => {
            flightSegmentToUpdate.flights = response;
            updateFlightSegment(newFlightSegments);
        };

        airline &&
            flightSegment.arrivalAirport &&
            flightSegment.departureAirport &&
            fetchFlights(
                airline,
                flightSegment.departureAirport,
                flightSegment.arrivalAirport,
                handleResponse,
                setError
            );
    }, [flightSegment.arrivalAirport]);

    const selectFlightNumber = value => {
        if (value === flightSegment.flightNumber) return;

        const newFlightSegments = [...flightSegments];
        const flightSegmentToUpdate = newFlightSegments[segmentNumber];
        flightSegmentToUpdate.flightNumber = value;

        updateFlightSegment(newFlightSegments);
    };

    const selectFlightDate = event => {
        const newFlightSegments = [...flightSegments];
        const flightSegmentToUpdate = newFlightSegments[segmentNumber];
        flightSegmentToUpdate.flightDate = event.target.value;

        updateFlightSegment(newFlightSegments);
    };

    const selectFlightTime = event => {
        const newFlightSegments = [...flightSegments];
        const flightSegmentToUpdate = newFlightSegments[segmentNumber];
        flightSegmentToUpdate.flightTime = event.target.value;

        updateFlightSegment(newFlightSegments);
    };

    useEffect(() => {
        if (flightSegment.arrivalAirport && flightSegment.flightNumber && flightSegment.flightDate) {
            const chosenFlight = {
                ...flightSegment.flights.find(
                    f =>
                        f.airlineCode === flightSegment.flightNumber ||
                        (f.airlineCode + f.flightNumber === flightSegment.flightNumber &&
                            f.departureFlightTime === flightSegment.flightDate)
                ),
            };
            const selectedDate = DateTime.fromISO(flightSegment.flightDate).toISODate();
            chosenFlight.flightDate = chosenFlight.flightDate || selectedDate;
            chosenFlight.flightNumber = chosenFlight.flightNumber || flightSegment.arrivalAirport;

            const newFlightSegments = [...flightSegments];
            const flightSegmentToUpdate = newFlightSegments[segmentNumber];
            flightSegmentToUpdate.flight = flightSegment.flightTime
                ? updateFlightTime(chosenFlight, flightSegment.flightTime)
                : chosenFlight;

            updateFlightSegment(newFlightSegments);
        }
    }, [flightSegment.arrivalAirport, flightSegment.flightNumber, flightSegment.flightDate]);

    useEffect(() => {
        if (flightSegment.flightTime && flightSegment.flight) {
            const chosenFlight = updateFlightTime(flightSegment.flight, flightSegment.flightTime);

            const newFlightSegments = [...flightSegments];
            const flightSegmentToUpdate = newFlightSegments[segmentNumber];
            flightSegmentToUpdate.flight = chosenFlight;

            updateFlightSegment(newFlightSegments);
        }
    }, [flightSegment.flightTime]);

    useEffect(() => {
        const newFlightSegments = [...flightSegments];
        const flightSegmentToUpdate = newFlightSegments[segmentNumber];
        flightSegmentToUpdate.connectingFlightArrivalAirports = [];
        updateFlightSegment(newFlightSegments);

        if (airline && flightSegment.arrivalAirport) {
            const handleResponse = response => {
                if (response && Array.isArray(response) && response?.length > 0) {
                    flightSegmentToUpdate.connectingFlightArrivalAirports = response;
                } else {
                    flightSegmentToUpdate.connectingFlightArrivalAirports = [];
                }
                updateFlightSegment(newFlightSegments);
            };

            airline &&
                flightSegment.departureAirport &&
                fetchAirportsFromOriginWithoutArrivalCountry(
                    airline,
                    flightSegment.arrivalAirport,
                    handleResponse,
                    setError
                );
        }
    }, [flightSegment.arrivalAirport]);

    const supportedCountriesOptions = () => {
        if (!supportedCountries || !Array.isArray(supportedCountries)) {
            return [];
        }
        if (segmentNumber === 0) {
            return mapCountriesToOptions(supportedCountries, language);
        } else {
            const connectingFlightSupportedCountries = supportedCountries.filter(sC =>
                flightSegments[segmentNumber - 1].connectingFlightArrivalAirports.some(
                    cFAA => cFAA.country_iso === sC.code
                )
            );
            return mapCountriesToOptions(connectingFlightSupportedCountries, language);
        }
    };

    const departureAirportsOptions = () => {
        if (segmentNumber === 0) {
            if (!Array.isArray(flightSegment.departureAirports)) {
                return [];
            }
            return mapAirportsToOptions(flightSegment.departureAirports);
        }
        return mapAirportsToOptions(flightSegments[segmentNumber - 1].connectingFlightDepartureAirport);
    };

    const handleAddConnectingFlight = () => {
        addFlightSegment({ departureAirport: flightSegment.arrivalAirport });
    };

    const handleRemoveConnectingFlight = () => {
        removeFlightSegment();
    };

    const connectingFlightDateTimeLimit =
        segmentNumber !== 0 && flightSegment.flights && flightSegments?.[segmentNumber - 1]?.flight?.arrivalFlightTime
            ? flightSegments?.[segmentNumber - 1]?.flight?.arrivalFlightTime
            : undefined;

    const showAddConnectingFlight =
        flightSegments.length === segmentNumber + 1 &&
        segmentNumber < 2 &&
        flightSegment.connectingFlightArrivalAirports.length > 0 &&
        flightSegment.destinationCountry &&
        flightSegment.departureAirport &&
        flightSegment.arrivalAirport &&
        flightSegment.flightNumber &&
        flightSegment.flightDate &&
        flightSegment.flight &&
        (airline === flightSegment.flightNumber ? flightSegment.flightTime : true);

    return (
        <Card className="p-2 pb-0">
            <fieldset disabled={loading.submit || segmentNumber + 1 < flightSegments.length}>
                <Form.Group className="border-bottom-0" controlId="destination-country">
                    <Form.Label>
                        {localize(
                            segmentNumber === 0
                                ? "flightDetails.flight.destinationCountry.label2"
                                : "flightDetails.flight.destinationCountry.label"
                        )}
                    </Form.Label>
                    <AutoSelect
                        name="destinationCountry"
                        options={supportedCountriesOptions()}
                        availableOptionsOnly={true}
                        required
                        onChange={selectDestinationCountry}
                        placeholder={localize("flightDetails.flight.destinationCountry.defaultOptionText")}
                        requiredMessage={localize("flightDetails.flight.destinationCountry.missingMessage")}
                        clearButton={false}
                    />
                </Form.Group>
                <Form.Group className="border-bottom-0" controlId="departure-airport">
                    <Form.Label>{localize("flightDetails.flight.origin.label")}</Form.Label>
                    <AutoSelect
                        name="departureAirport"
                        options={departureAirportsOptions()}
                        availableOptionsOnly={true}
                        disabled={
                            !Array.isArray(flightSegment.departureAirports) ||
                            flightSegment.departureAirports?.length === 0 ||
                            segmentNumber > 0
                        }
                        required
                        onChange={selectDepartureAirport}
                        placeholder={localize("flightDetails.flight.origin.defaultOptionText")}
                        requiredMessage={localize("flightDetails.flight.origin.missingMessage")}
                        clearButton={false}
                    />
                </Form.Group>
                <Form.Group className="border-bottom-0" controlId="arrival-airport">
                    <Form.Label>{localize("flightDetails.flight.destination.label")}</Form.Label>
                    <AutoSelect
                        name="arrivalAirport"
                        options={
                            Array.isArray(flightSegment.arrivalAirports)
                                ? mapAirportsToOptions(flightSegment.arrivalAirports)
                                : []
                        }
                        availableOptionsOnly={true}
                        disabled={
                            !Array.isArray(flightSegment.arrivalAirports) || flightSegment.arrivalAirports?.length === 0
                        }
                        required
                        onChange={selectArrivalAirport}
                        placeholder={localize("flightDetails.flight.destination.defaultOptionText")}
                        requiredMessage={localize("flightDetails.flight.destination.missingMessage")}
                        clearButton={false}
                    />
                </Form.Group>
                <Form.Group controlId="flight-number" className={`border-bottom-0 ${getFlightNumberClassName()}`}>
                    <Form.Label>{localize("flightDetails.flight.number.label")}</Form.Label>
                    <AutoSelect
                        name="flightNumber"
                        options={flightSegment.flights && getAvailableFlights().map(f => ({ value: f, label: f }))}
                        availableOptionsOnly={true}
                        disabled={!flightSegment.arrivalAirport}
                        required
                        onChange={selectFlightNumber}
                        placeholder={localize("flightDetails.flight.number.defaultOptionText")}
                        requiredMessage={localize("flightDetails.flight.number.missingMessage")}
                        clearButton={false}
                    />
                </Form.Group>
                <FlightDateInput
                    className="border-bottom-0"
                    airline={airline}
                    flights={flightSegment.flights}
                    flightNumber={flightSegment.flightNumber}
                    departureTimeZone={getDepartureTimeZone()}
                    onChange={selectFlightDate}
                    dateTimeLimit={connectingFlightDateTimeLimit}
                />
                {airline === flightSegment.flightNumber && (
                    <Form.Group className="border-bottom-0" controlId="flight-time">
                        <Form.Label>{localize("flightDetails.flight.time.label")}</Form.Label>
                        <Form.Control type="time" required onChange={selectFlightTime} />
                        <Required message={localize("flightDetails.flight.time.missingMessage")} />
                    </Form.Group>
                )}
                {flightSegment.flight && (
                    <FlightInfo
                        flight={flightSegment.flight}
                        departureAirport={flightSegment.departureAirport}
                        arrivalAirport={flightSegment.arrivalAirport}
                    />
                )}
                {showAddConnectingFlight && (
                    <IconButton onClick={handleAddConnectingFlight} iconType="add">
                        {localize("flightDetails.flight.addConnectingFlight.label")}
                    </IconButton>
                )}
                {segmentNumber > 0 && segmentNumber + 1 === flightSegments.length && (
                    <IconButton onClick={handleRemoveConnectingFlight} iconType="remove">
                        {localize("flightDetails.flight.removeConnectingFlight.label")}
                    </IconButton>
                )}
            </fieldset>
        </Card>
    );
};

export default FlightSegmentForm;
