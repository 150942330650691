import React, { useContext, useState } from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router";
import CredentialContext from "../../config/CredentialContext";
import { doNothing } from "../../utils/dataProcessing";
import { useCruiseLine, useLocalize, useQuery } from "../../utils/hooks";
import FormPage from "../_components/FormPage";
import ValidationForm from "../_components/ValidationForm";
import LoadingWrapper from "../_components/Wrappers/LoadingWrapper";
import UnapprovedPoliciesWrapper from '../_components/Wrappers/PrivacyPolicyWrapper/UnapprovedPoliciesWrapper';
import CustomBookingField from "./CustomBookingField";

const CarnivalCruiseRetrieveBooking = ({ onNext }) => {
    const { loadingAny, globalConfig } = useContext(CredentialContext);
    const { localize } = useLocalize();
    const query = useQuery();
    const history = useHistory();
    const { policies: defaultPolicies } = useCruiseLine();
    const {
        policies = defaultPolicies,
        bookingFields = [{ name: "bookingNumber" }, { name: "lastName" }],
    } = globalConfig;
    const [values, setValues] = useState({});

    const onValueChange = field => event => setValues(v => ({ ...v, [field]: event.target.value?.trimStart() }));

    //TODO: Extract and make extendable for additional cases
    const cleanString = str => str?.replace(/’/g, "'");

    const onSubmit = () => {
        bookingFields.forEach(({ name }) => query.set(name, cleanString(values[name]?.trim())));
        history.replace(`?${query.toString()}`);
        onNext();
    };

    return (
        <UnapprovedPoliciesWrapper policies={policies}>
            <LoadingWrapper fullPage isLoading={loadingAny("config")}>
                <FormPage header={localize("carnivalcruise.retrievebooking.title")}>
                    <ValidationForm loadingProp="booking" handleSubmit={onSubmit}>
                        {bookingFields.map(f => (
                            <CustomBookingField
                                key={f.name}
                                type={f.type || "text"}
                                field={f}
                                maxLength={f.maxLength || '30'}
                                value={values[f.name]}
                                onChange={onValueChange(f.name)}
                            />
                        ))}
                        <LoadingWrapper>
                            <Button type="submit" className="w-100 mt-4">
                                {localize("carnivalcruise.retrievebooking.submit.label")}
                            </Button>
                        </LoadingWrapper>
                    </ValidationForm>
                </FormPage>
            </LoadingWrapper>
        </UnapprovedPoliciesWrapper>
    );
};

CarnivalCruiseRetrieveBooking.defaultProps = {
    onNext: doNothing,
};

export default CarnivalCruiseRetrieveBooking;
