import { useQuery } from "./index";

const useCompanion = () => {

    const query = useQuery();

    const companionId = query.get("companionId");

    return { companionId };
};

export default useCompanion;