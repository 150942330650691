import DateInput from "../_components/DateInput";
import FlightDateSelect from "./FlightDateSelect";
import { Form } from "react-bootstrap";
import { useLocalize } from "../../utils/hooks";
import { getBegginingOfCurrentYear, getEndOfNextYear } from "../../utils/dateTimeUtils";

const FlightDateInput = ({
    className = "",
    airline,
    flights,
    flightNumber,
    departureTimeZone,
    onChange,
    dateTimeLimit,
    errorMessage,
}) => {
    const { localize } = useLocalize();

    if (airline === flightNumber) {
        return (
            <Form.Group className={className}>
                <DateInput
                    label={localize("flightDetails.flight.date.label")}
                    type="futureOnly"
                    allowToday
                    min={getBegginingOfCurrentYear()}
                    max={getEndOfNextYear()}
                    dateSelected={value => onChange({ target: { value } })}
                    errorMessage={errorMessage}
                />
            </Form.Group>
        );
    }
    return (
        <FlightDateSelect
            {...{ flights, flightNumber, departureTimeZone, onChange, errorMessage, className, dateTimeLimit }}
        />
    );
};

export default FlightDateInput;
