import { Form } from "react-bootstrap";
import React from "react";
import { useLocalize } from "../../utils/hooks";

const Required = ({ message }) => {
    const { localize } = useLocalize();

    return <Form.Control.Feedback type="invalid">{message || localize("global.required")}</Form.Control.Feedback>;
};

export default Required;