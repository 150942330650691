import React, { useContext, useEffect, useState } from "react";
import moment from "moment-timezone";
import { DateTime } from "luxon";
import { Button, Form } from "react-bootstrap";
import CredentialContext from "../../../config/CredentialContext";
import { postAcceptance, postReturnJson } from "../../../utils/dataFetching";
import { doNothing, processDataFromCapServer } from "../../../utils/dataProcessing";
import { getBegginingOf1900, getEndOCurrentYear } from "../../../utils/dateTimeUtils";
import { useCruiseLine, useLanguage, useLocalize, useQuery, useUnapprovedPolicies } from "../../../utils/hooks";
import { getBaseUrl } from "../../../utils/textUtils";
import { getThreeLeterCodeWithTwoLetterCode } from '../../../utils/countryUtils';
import DateInput from "../../_components/DateInput";
import FormPage from "../../_components/FormPage";
import MarkdownText from "../../_components/MarkdownText";
import LoadingWrapper from "../../_components/Wrappers/LoadingWrapper";
import CustomSwitch from "../../_components/CustomSwitch";
import CountrySelect from "../../_components/CountrySelect";
import { CARNIVAL_HEALTH, CARNIVAL_PRIVACY } from "../../_components/Wrappers/PrivacyPolicyWrapper/constants";

const AGE_LIMIT = 16;

const CarnivalCruiseEditGuest = ({ guest, onSave, cruiseInfo, firstPassanger, backToList }) => {
    const {
        loading,
        loadingAny,
        updateLoading,
        setError,
        userId,
        submissionHref,
        setProcessInstance,
        credentialTypeHref,
        processInstance = {},
        globalConfig,
        appVersion,
    } = useContext(CredentialContext);

    const { localize } = useLocalize();
    const { localizeField, localizeFieldOrDefault } = useLanguage();
    const query = useQuery();
    const { cruiseLineCode } = useCruiseLine();
    const {
        disableGuestEdit,
        countryOfResidenceLabel,
        guestHeader,
        guestText,
        guestNote,
        addMainUserLabel,
        addCompanionFutureUseLabel,
        addCompanionInfoConsentLabel,
        addCompanionGuardianConsentLabel,
    } = globalConfig;
    const { generalPolicies, getGeneralPolicies } = useUnapprovedPolicies();

    const [validated, setValidated] = useState(false);
    const [birthDate, setBirthDate] = useState(guest.birthDate);
    const [primaryPassenger, setPrimaryPassenger] = useState(false);
    const [isCompanionUnderAge, setIsCompanionUnderAge] = useState(false);
    const [companionInfoConsent, setCompanionInfoConsent] = useState(false);
    const [guardianConsent, setGuardianConsent] = useState(false);
    const [futureUse, setFutureUse] = useState(false);
    const [nationality, setNationality] = useState();
    const [countryOfResidence, setCountryOfResidence] = useState();

    const havePrimaryPassanger = !!processInstance?.userData?.userSpecificData[userId];
    const haveCruiseDetails = !!processInstance?.userData?.sharedData?.cruiseDetails;

    const isUnderAge = dateTime => {
        const now = DateTime.local();
        const diff = now.diff(DateTime.fromISO(dateTime), "years");

        return diff.years < AGE_LIMIT;
    };

    const selectBirthDate = value => {
        const bd = moment(value).utcOffset(0, true).format("YYYY-MM-DD");

        setBirthDate(bd);
        setIsCompanionUnderAge(isUnderAge(value));
    };

    const selectNationality = value => setNationality(value);
    const selectCountryOfResidence = value => setCountryOfResidence(value);

    const passportCountry = getThreeLeterCodeWithTwoLetterCode(nationality) || "USA";

    const {
        shipCode,
        bookingId: bookingNumber,
        sailDate: embarkationDate,
        shipName,
        voyageNumber,
        durationDays,
        inPortTesting,
        cutoffTime,
        destinationCountry,
        visaRequired = false,
        ...otherCruiseInfo
    } = cruiseInfo;

    const getCompanion = () => {
        const referenceTime = processInstance?.userData?.sharedData?.cruiseDetails?.embarkationDate || new Date();

        return {
            firstName: guest.firstName,
            lastName: guest.lastName,
            ...(!futureUse ? { expiringDtm: DateTime.fromISO(referenceTime).plus({ days: 1 }).ts } : {}),
            privateInfo: {
                extendedAttributes: {
                    dateOfBirth: birthDate,
                    nationality: nationality || "US",
                    passportCountry,
                    countryOfResidence,
                    userSequenceNumber: guest.sequenceNumber,
                    userPatientId: guest.patientId,
                },
            },
        };
    };

    const getCruiseDetails = passengerId => {
        const sharedData = {
            appVersion,
            cruiseDetails: {
                embarkationDate,
                bookingNumber,
                cruiseLineCode,
                shipCode,
                shipName,
                voyageNumber,
                passType: "Carnival",
                bookingLastName: query.get("lastName"),
                env: query.get("env"),
                durationDays,
                inPortTesting,
                cutoffTime,
                destinationCountry,
                visaRequired,
                purpose: "TOURIST",
                ...otherCruiseInfo,
            },
        };
        const userSpecificData = {
            [passengerId]: {
                firstName: guest.firstName,
                lastName: guest.lastName,
                dateOfBirth: birthDate,
                nationality: nationality || "US",
                passportCountry,
                countryOfResidence,
                userSequenceNumber: guest.sequenceNumber,
                userPatientId: guest.patientId,
            },
        };

        return {
            userData: {
                sharedData,
                userSpecificData,
            },
        };
    };

    const setPassenger = passengerId => {
        processInstance.userData.userSpecificData[passengerId] = {
            firstName: guest.firstName,
            lastName: guest.lastName,
            dateOfBirth: birthDate,
            nationality: nationality || "US",
            passportCountry,
            countryOfResidence,
            userSequenceNumber: guest.sequenceNumber,
            userPatientId: guest.patientId,
        };
    };

    const handleCapResponse = response => {
        setProcessInstance(response);
        processDataFromCapServer(setError)(response);
        onSave({
            ...guest,
            firstName: guest.firstName,
            lastName: guest.lastName,
            birthDate,
            added: true,
        });
        updateLoading("submit", false);
    };

    const getCreateUrl = () => `${submissionHref}/processInstances/persons/${userId}`;
    const getUpdateUrl = () => `${submissionHref}/processInstances/${processInstance.id}/persons/${userId}`;
    const getCompanionsUrl = () => `${getBaseUrl(credentialTypeHref)}/api/persons/${userId}/companions`;
    const approvalsUrl = getBaseUrl(credentialTypeHref) + "/api/persons/" + userId + "/approvals";

    const savePrimaryPassenger = () => {
        if (haveCruiseDetails) {
            setPassenger(userId);
            return postReturnJson(getUpdateUrl(), processInstance, handleCapResponse, setError);
        }

        const cruiseDetails = getCruiseDetails(userId);
        return postReturnJson(getCreateUrl(), cruiseDetails, handleCapResponse, setError);
    };

    const saveFirstPassenger = () => {
        const addCompanionToProcessInstance = ({ id }) => {
            const cruiseDetails = getCruiseDetails(id);
            companionInfoConsent && postAcceptance(approvalsUrl, createConsent(id), doNothing, setError);
            guardianConsent && postAcceptance(approvalsUrl, createGuardianConsent(id), doNothing, setError);
            postReturnJson(getCreateUrl(), cruiseDetails, handleCapResponse, setError);
        };

        const companion = getCompanion();
        postReturnJson(getCompanionsUrl(), companion, addCompanionToProcessInstance, setError);
    };

    const saveCompanionPassenger = () => {
        const addCompanionToProcessInstance = ({ id }) => {
            companionInfoConsent && postAcceptance(approvalsUrl, createConsent(id), doNothing, setError);
            guardianConsent && postAcceptance(approvalsUrl, createGuardianConsent(id), doNothing, setError);
            setPassenger(id);
            postReturnJson(getUpdateUrl(), processInstance, handleCapResponse, setError);
        };

        const companion = getCompanion();
        postReturnJson(getCompanionsUrl(), companion, addCompanionToProcessInstance, setError);
    };

    const onSubmit = event => {
        event.preventDefault();

        if (!event.currentTarget.checkValidity()) {
            setValidated(true);
            return;
        }

        updateLoading("submit", true);

        if (primaryPassenger) {
            return savePrimaryPassenger();
        }

        if (!firstPassanger) {
            return saveFirstPassenger();
        }

        return saveCompanionPassenger();
    };

    const selectMainUser = () => {
        setPrimaryPassenger(!primaryPassenger);
        setIsCompanionUnderAge(false);
        setCompanionInfoConsent(false);
        setGuardianConsent(false);
        setFutureUse(false);
        selectBirthDate(birthDate);
    };

    useEffect(() => {
        getGeneralPolicies([CARNIVAL_PRIVACY, CARNIVAL_HEALTH]);
    }, []);

    const getPolicyDetails = () => {
        const carnivalPrivacyConsent = generalPolicies?.find(gP => gP?.name === CARNIVAL_PRIVACY) || {};
        const carnivalHealthConsent = generalPolicies?.find(gP => gP?.name === CARNIVAL_HEALTH) || {};
        return { privacyConsent: carnivalPrivacyConsent, healthConsent: carnivalHealthConsent };
    };

    const getPrivacyPolicyUrl = () => getPolicyDetails()?.privacyConsent?.url || localize("privacyPolicy.url");
    const getHealthConsentUrl = () => getPolicyDetails()?.healthConsent?.url;

    const createConsent = id => ({
        approvals: [
            {
                id: getPolicyDetails()?.privacyConsent?.name || "Trip Privacy Policy",
                provider: getPolicyDetails()?.privacyConsent?.provider || "trip-service",
                url: getPrivacyPolicyUrl() + "?companionId=" + id,
            },
        ],
    });

    const createGuardianConsent = id => ({
        approvals: [
            {
                id: getPolicyDetails()?.healthConsent?.name || "Health Consent",
                provider: getPolicyDetails()?.healthConsent?.provider || "trip-service",
                url: getHealthConsentUrl() + "?companionId=" + id,
            },
        ],
    });

    const url = `<a rel="noreferrer" target="_blank" href="${getHealthConsentUrl()}">${
        getPolicyDetails()?.healthConsent?.linkText || localize("addCompanion.guardianConsent.link")
    }</a>`;

    const onCheckFutureUse = () => {
        setFutureUse(!futureUse);
    };

    const onCheckInfoConsent = () => {
        setCompanionInfoConsent(!companionInfoConsent);
    };

    const onCheckGuardianConsent = () => {
        setGuardianConsent(!guardianConsent);
    };

    const submitDisabled = () => {
        if (isCompanionUnderAge && guardianConsent && companionInfoConsent) {
            return false;
        }

        if (!isCompanionUnderAge && companionInfoConsent) {
            return false;
        }

        return !primaryPassenger;
    };

    return (
        <LoadingWrapper fullPage isLoading={loadingAny("config", "consents") || !processInstance}>
            <FormPage header={localizeFieldOrDefault(guestHeader, localize("carnivalcruise.addguest.title"))}>
                <Form noValidate validated={validated} className="w-100" onSubmit={onSubmit}>
                    <Form.Group>
                        <MarkdownText className="pb-2">
                            {localizeFieldOrDefault(guestText, localize("carnivalcruise.addguest.text"))}
                        </MarkdownText>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{localize("carnivalcruise.addguest.firstAndMiddleName")}</Form.Label>
                        <Form.Control value={guest.firstName} type="text" minLength="1" maxLength="30" disabled />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{localize("carnivalcruise.addguest.lastname")}</Form.Label>
                        <Form.Control value={guest.lastName} type="text" minLength="1" maxLength="30" disabled />
                    </Form.Group>
                    <Form.Group>
                        <DateInput
                            type="pastOnly"
                            customClass="pb-3"
                            label={localize("carnivalcruise.addguest.dateofbirth")}
                            dateSelected={selectBirthDate}
                            value={birthDate}
                            min={getBegginingOf1900()}
                            max={getEndOCurrentYear()}
                            disabled={disableGuestEdit}
                        />
                        {guestNote && <Form.Text>{localizeField(guestNote)}</Form.Text>}
                    </Form.Group>
                    <CountrySelect
                        type="nationality"
                        value=""
                        onChange={selectNationality}
                        customLabel={localize("flightDetails.flight.passportCountry2.label")}
                    />
                    <CountrySelect
                        value=""
                        onChange={selectCountryOfResidence}
                        type="countryOfResidence"
                        customLabel={
                            countryOfResidenceLabel
                                ? localizeFieldOrDefault(
                                      countryOfResidenceLabel,
                                      localize("flightDetails.flight.countryOfResidence.label")
                                  )
                                : ""
                        }
                    />
                    {!havePrimaryPassanger && !isCompanionUnderAge && (
                        <div className="align-items-center text-center">
                            <div className="w-100">
                                <CustomSwitch
                                    control="carnevalMainUser"
                                    switchLabel={localizeFieldOrDefault(
                                        addMainUserLabel,
                                        localize("carnivalcruise.addguest.addMainUser.label")
                                    )}
                                    onChecked={selectMainUser}
                                />
                            </div>
                        </div>
                    )}
                    {!primaryPassenger && (
                        <>
                            <CustomSwitch
                                control="futureUseSwitch"
                                switchName="futureUse"
                                label={localizeFieldOrDefault(
                                    addCompanionFutureUseLabel,
                                    localize("addCompanion.futureUse.label")
                                )}
                                switchLabel={localize("addCompanion.futureUse.switch.label")}
                                onChecked={onCheckFutureUse}
                            />
                            <CustomSwitch
                                control="companionInfoSwitch"
                                switchName="companionInfoConsent"
                                label={localizeFieldOrDefault(
                                    addCompanionInfoConsentLabel,
                                    localize("addCompanion.companionInfoConsent.label")
                                )}
                                onChecked={onCheckInfoConsent}
                            />
                            {isCompanionUnderAge && (
                                <CustomSwitch
                                    control="guardianSwitch"
                                    switchName="guardianConsent"
                                    label={localizeFieldOrDefault(
                                        addCompanionGuardianConsentLabel,
                                        localize("addCompanion.guardianConsent.label", { url })
                                    )}
                                    onChecked={onCheckGuardianConsent}
                                />
                            )}
                        </>
                    )}
                    <LoadingWrapper row isLoading={loading.submit}>
                        <Button type="submit" disabled={submitDisabled()} className="w-100 my-3">
                            {localize("carnivalcruise.addguest.submit.label")}
                        </Button>
                        <Button onClick={backToList} className="w-100 my-3">
                            {localize("carnivalcruise.addguest.backToList.label")}
                        </Button>
                    </LoadingWrapper>
                </Form>
            </FormPage>
        </LoadingWrapper>
    );
};

export default CarnivalCruiseEditGuest;
