import React, { useContext } from "react";
import { DateTime } from "luxon";
import { useIntl } from "react-intl";
import { Modal, Button } from "react-bootstrap";
import CredentialContext from "../../config/CredentialContext";
import { useLocalize } from "../../utils/hooks";
import { InfoIcon } from "../../icons/InfoIcon";
import LoadingWrapper from "../_components/Wrappers/LoadingWrapper";
import { isNonEmptyString } from "../../utils/textUtils";
import { doNothing } from '../../utils/dataProcessing';

const InterlineConfirmationModal = ({
    onShowModal,
    onConfirmSubmit,
    onCloseModal,
    bookingRef,
    firstName,
    middleName,
    lastName,
    birthDate,
}) => {
    const { loading } = useContext(CredentialContext);

    const { localize } = useLocalize();
    const intl = useIntl();

    return (
        <Modal show={onShowModal} onHide={loading.submit ? doNothing : onCloseModal} centered className="px-3">
            <Modal.Body>
                <div className="text-center pt-2">
                    <InfoIcon size="50px" />
                </div>
                <div className="text-center h5 py-3">
                    {localize("addInterlineFlightDetails.interlineModalConfirmation.title")}
                </div>
                <div className="px-2">
                    {bookingRef && (
                        <div className="pb-2">
                            {localize("flightDetails.flight.bookingRefNumber.label")}:{" "}
                            <span className="font-weight-bold">{bookingRef}</span>
                        </div>
                    )}
                    <div className="pb-2">
                        {localize("addInterlineFlightDetails.interlineModalInfo.firstName")}:{" "}
                        <span className="font-weight-bold">{firstName}</span>
                    </div>
                    {isNonEmptyString(middleName) && (
                        <div className="pb-2">
                            {localize("addInterlineFlightDetails.interlineModalInfo.middleName")}:{" "}
                            <span className="font-weight-bold">{middleName}</span>
                        </div>
                    )}
                    <div className="pb-2">
                        {localize("addInterlineFlightDetails.interlineModalInfo.lastName")}:{" "}
                        <span className="font-weight-bold">{lastName}</span>
                    </div>
                    <div className="pb-2">
                        {localize("addInterlineFlightDetails.interlineModalInfo.dateOfBirth")}:{" "}
                        <span className="font-weight-bold">
                            {DateTime.fromISO(birthDate, { setZone: true })
                                .setLocale(intl.locale)
                                .toFormat("dd MMM yyyy")}
                        </span>
                    </div>
                </div>
                <div className="px-2 pt-4 pb-2">
                    <Button type="button" className="w-100 mb-3" onClick={onConfirmSubmit} disabled={loading.submit}>
                        <LoadingWrapper isLoading={loading.submit} size="sm">
                            {localize("global.submit")}
                        </LoadingWrapper>
                    </Button>
                    <Button
                        type="button"
                        className="w-100 btn-secondary"
                        onClick={onCloseModal}
                        disabled={loading.submit}
                    >
                        {localize("global.back")}
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default InterlineConfirmationModal;
