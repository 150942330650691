import React, { useContext, useEffect, useState } from "react";
import CredentialContext from '../../../../config/CredentialContext';
import UnnapprovedPolicy from './UnapprovedPolicy';
import LoadingWrapper from '../LoadingWrapper';
import { useUnapprovedPolicies } from '../../../../utils/hooks';
import { postAcceptance } from '../../../../utils/dataFetching';
import { doNothing } from '../../../../utils/dataProcessing';
import { getBaseUrl } from '../../../../utils/textUtils';

const UnapprovedPoliciesWrapper = ({ children, policies }) => {
    const { credentialTypeHref, userId, loading, setError } = useContext(CredentialContext);
    const { unapprovedPolicies, getUnapprovedPolicies } = useUnapprovedPolicies();

    const [policiesAccepted, setPoliciesAccepted] = useState(false);
    const [isPoliciesAcceptedLoading, setIsPoliciesAcceptedLoading] = useState(false);

    useEffect(() => {
        if (!loading.config && policies && Array.isArray(policies) && policies.length > 0) {
            getUnapprovedPolicies(policies);
        }
    }, [loading.config, policies]);

    const acceptPrivacyPolicy = async () => {
        setIsPoliciesAcceptedLoading(true);
        const approvedPoliciesSubmission = unapprovedPolicies?.map(uP => {
            return {
                id: uP?.name,
                provider: uP?.provider,
                url: uP?.url
            }
        });

        const consents = {
            approvals: approvedPoliciesSubmission
        }

        const consentUrl = getBaseUrl(credentialTypeHref) + "/api/persons/" + userId + "/approvals";

        await postAcceptance(consentUrl, consents, doNothing, setError);
        setIsPoliciesAcceptedLoading(false);
        setPoliciesAccepted(true);
        window.scrollTo(0, 0);
    };

    if ((!loading.config && !policies) || unapprovedPolicies?.length === 0 || policiesAccepted) return <>{children}</>;

    return (
      <LoadingWrapper fullPage isLoading={loading.consents || loading.config || isPoliciesAcceptedLoading || !unapprovedPolicies}>
          <UnnapprovedPolicy
              policies={policies}
              unapprovedPolicies={unapprovedPolicies || []}
              onAccept={acceptPrivacyPolicy}
          />
      </LoadingWrapper>
  );
};

export default UnapprovedPoliciesWrapper;
