import { worldCountries } from "../config/world-countries";
import { MOCKED_ARRIVAL_DATA } from "./constants";

const MOCKED_DATA = MOCKED_ARRIVAL_DATA;

export const mapAirlinesToOptions = allAirlines =>
    allAirlines.filter(a => !a.embeddedOnly).map(a => ({
        value: a.iataCode,
        label: a.name.replace("(", "").replace(")", "") + " - " + a.iataCode,
    }));
export const mapAirportsToOptions = airports =>
    airports.map(a => ({
        value: a.iata_code,
        label: a.iata_code + " - " + a.name.replace("(", "").replace(")", ""),
    }));

export const mapCountriesToOptions = (countries, language) => {
    return worldCountries
        .filter(worldCountry => countries.some(c => worldCountry.code === c.code))
        .map(countryWithTranslation => ({
            value: countryWithTranslation.code,
            label: countryWithTranslation.name[language] || countryWithTranslation.name.en,
        }));
};

export const generateCapFlightPayload = (
    flight,
    departureAirport,
    arrivalAirport,
    originCountry,
    userId,
    firstName,
    lastName,
    userEmail,
    nationality,
    birthDate,
    bookingRef,
    env,
    appVersion
) => {
    const { departureFlightTime, arrivalFlightTime, airlineCode, flightNumber, flightDate } = flight;
    return {
        userData: {
            sharedData: {
                appVersion,
                flightDetails: {
                    departureDateTime: departureFlightTime,
                    arrivalDateTime: arrivalFlightTime || departureFlightTime,
                    departureDate: flightDate,
                    originCountry,
                    flightNumber,
                    airlineCode,
                    bookingRef,
                    origin: departureAirport,
                    destination: arrivalAirport,
                    env,
                },
            },
            userSpecificData: {
                [userId]: {
                    firstName,
                    lastName,
                    dateOfBirth: birthDate.split("T")[0],
                    nationality,
                },
            },
        },
    };
};

export const generateCapCruisePayload = (
    cruiseLineCode,
    bookingNumber,
    embarkationDate,
    userId,
    firstName,
    lastName,
    nationality,
    birthDate,
    appVersion,
    passportCountry,
    countryOfResidence
) => {
    return {
        userData: {
            sharedData: {
                appVersion,
                cruiseDetails: {
                    cruiseLineCode,
                    bookingNumber,
                    embarkationDate: embarkationDate.split("T")[0],
                },
            },
            userSpecificData: {
                [userId]: {
                    firstName,
                    lastName,
                    dateOfBirth: birthDate.split("T")[0],
                    nationality,
                    passportCountry,
                    countryOfResidence
                },
            },
        },
    };
};

export const generateCapEventPayload = (
    eventCode,
    referenceNumber,
    userId,
    firstName,
    lastName,
    birthDate,
    nationality,
    appVersion
) => {
    let eventDetails = { eventCode: eventCode };

    if (referenceNumber && referenceNumber !== "") {
        eventDetails["referenceNumber"] = referenceNumber;
    }

    return {
        userData: {
            sharedData: {
                appVersion,
                eventDetails,
            },
            userSpecificData: {
                [userId]: {
                    firstName,
                    lastName,
                    dateOfBirth: birthDate.split("T")[0],
                    nationality,
                },
            },
        },
    };
};

export const isArrivalsFlight = details => {
    if (!details) {
        return false;
    }
    const { destination, flightNumber, origin } = details;
    return (
        destination === MOCKED_DATA.airportCode &&
        flightNumber === MOCKED_DATA.flightNo &&
        origin === MOCKED_DATA.airportCode
    );
};

export const validateInterlineTrip = response => {
    const { valid = true, invalidReason = null } = response;

    if (!valid && invalidReason) {
        return [invalidReason];
    }

    return [];
};

export const validateFligthBlocksResponse = response => {
    const { flightBlocks } = response;
    const fligthsResponse = flightBlocks.reduce((acc, block) => {
        const { flightSegments, id } = block;

        const unsuportedFlightSegments = flightSegments.filter(segment => segment.hasOwnProperty("invalidReason"));
        const segments =
            unsuportedFlightSegments.length === 0
                ? flightSegments
                : flightSegments.map(s => ({ ...s, unsupported: true }));

        acc.push({ id, flightSegments: segments });
        return acc;
    }, []);

    return {
        ...response,
        flightBlocks: fligthsResponse,
    };
};

export const getFlightDetails = (processInstance, blockId, segmentId) => {
    if (!processInstance || !blockId || !segmentId) return null;

    const interlineFlightDetails = processInstance?.userData?.sharedData?.interlineFlightDetails;

    const flightBlocks = interlineFlightDetails?.flightBlocks;

    const flightBlock = flightBlocks?.find(fB => fB?.id === blockId);

    const flightSegment = flightBlock?.flightSegments?.find(fS => fS?.id === segmentId);

    return { interlineFlightDetails, flightBlocks, flightBlock, flightSegment };
};

export const updateFlightSegment = (processInstance, blockId, segmentId, updates = []) => {
    const flightBlocks = processInstance.userData.sharedData.interlineFlightDetails.flightBlocks;

    if (Array.isArray(flightBlocks)) {
        flightBlocks.forEach(fB => {
            if (fB.id === blockId) {
                fB?.flightSegments?.forEach(fS => {
                    if (fS?.id === segmentId) {
                        fS[updates[0]] = updates[1];
                    }
                });
            }
        });
    }
};


