import { CUSTOM_BOOKING_RESPONSE, USE_CUSTOM_BOOKING_RESPONSE } from "../../pages/Navigation/constants";
import { getLocal } from "./utils";

const carnevalCruiseGuests = {
    "bookingId": "4GQFF5",
    "bookingStatus": null,
    "shipCode": "HZ",
    "shipName": "CARNIVAL HORIZON",
    "sailDate": "2022-01-16",
    "durationDays": 6,
    "voyageNumber": "HZ01162022006",
    "inPortTesting": "Y",
    "cutoffTime": "060000",
    "destinationCountry": "RS",
    "visaRequired": true,
    "guests": [
        {
            "sequenceNumber": "1",
            "firstName": "SAM",
            "lastName": "ADAM",
            "genderCode": "M",
            "ageAmount": 36,
            "ageCode": "Y",
            "birthDate": "1985-11-12",
            "patientId": "4GQFF5-1"
        },
        {
            "sequenceNumber": "2",
            "firstName": "SUE",
            "lastName": "ADAM",
            "genderCode": "F",
            "ageAmount": 31,
            "ageCode": "Y",
            "birthDate": "1990-11-11",
            "patientId": "4GQFF5-2"
        },
        {
            "sequenceNumber": "3",
            "firstName": "YALE",
            "lastName": "ADAM",
            "genderCode": "M",
            "ageAmount": 9,
            "ageCode": "Y",
            "birthDate": "2012-08-13",
            "patientId": "4GQFF5-3"
        },
        {
            "sequenceNumber": "4",
            "firstName": "JAN",
            "lastName": "ADAM",
            "genderCode": "M",
            "ageAmount": 6,
            "ageCode": "Y",
            "birthDate": "2015-05-21",
            "patientId": "4GQFF5-4"
        },
        {
            "sequenceNumber": "5",
            "firstName": "RON",
            "lastName": "ADAM",
            "genderCode": "M",
            "ageAmount": 16,
            "ageCode": "M",
            "birthDate": "2020-08-25",
            "patientId": "4GQFF5-5"
        }
    ]
}

const bookingLookupErrors = {
    "20": {
        "code": 20,
        "text": "No such booking found"
    },
    "21": {
        "code": 21,
        "text": "Your voyage is not setup for VeriFLY registration. Please visit your cruise line’s website to complete all health questionnaire surveys required for this booking."
    },
    "26": {
        "code": 26,
        "text": "Unfortunately we are unable to make a connection to the Guest Lookup service at this time, please check back later."
    },
    "30": {
        "code": 30,
        "text": "Some other server error"
    },
}


export const carnevalGuests = (req, res, ctx) => {
    const bookingNumber = req.url.searchParams.get("bookingNumber");
    if (bookingLookupErrors.hasOwnProperty(bookingNumber))
        return res(ctx.status(404), ctx.json(bookingLookupErrors[bookingNumber]));
    const response = getLocal(USE_CUSTOM_BOOKING_RESPONSE) ? getLocal(CUSTOM_BOOKING_RESPONSE) : carnevalCruiseGuests;
    return res(ctx.status(200), ctx.json(response));
};
