import React, { useContext, useRef, useState } from "react";
import { DateTime } from "luxon";
import { REDIRECT } from "../../utils/hooks/useCap";
import FormPage from "../_components/FormPage";
import { Button, Form, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import Required from "../_components/Required";
import DateInput from "../_components/DateInput";
import moment from "moment-timezone";
import CountrySelect from "../_components/CountrySelect";
import CredentialContext from "../../config/CredentialContext";
import ValidationForm from "../_components/ValidationForm";
import LoadingWrapper from "../_components/Wrappers/LoadingWrapper";
import { generateCapCruisePayload } from "../../utils/flightUtils";
import { CRUISE_CONSENT } from "../_components/Wrappers/PrivacyPolicyWrapper/constants";
import UnapprovedPoliciesWrapper from "../_components/Wrappers/PrivacyPolicyWrapper/UnapprovedPoliciesWrapper";
import { useCap, useCruiseLine, useLanguage, useLocalize, useUser } from "../../utils/hooks";
import {
    getBegginingOf1900,
    getBegginingOfCurrentYear,
    getBegginingOfLastYear,
    getEndOCurrentYear,
    getEndOfNextYear,
} from "../../utils/dateTimeUtils";
import { getThreeLeterCodeWithTwoLetterCode } from '../../utils/countryUtils';

const CapCruiseDetails = () => {
    const {
        loading,
        updateLoading,
        setError,
        userId,
        firstName: appFirstName,
        lastName: appLastName,
        globalConfig,
        appVersion,
    } = useContext(CredentialContext);
    const { createProcessInstance } = useCap(REDIRECT.MANAGE_TRIP);
    const firstNameRef = useRef();
    const lastNameRef = useRef();
    const { localize } = useLocalize();
    const { localizeField, localizeFieldOrDefault } = useLanguage();
    const { cruiseLineCode: cruiseLineCodeDefault } = useCruiseLine();
    const { glideBirthDate, glideNationality, glideCountryOfResidence, checkAndUpdateUser } = useUser(userId, setError);

    const policies = globalConfig.policies || CRUISE_CONSENT;
    const bookingNumberInput = globalConfig?.bookingFields?.bookingNumber;
    const cruiseLineCode = globalConfig?.bookingFields?.cruiseLineCode?.value || cruiseLineCodeDefault;
    const embarkationDaysInPast = globalConfig?.allowedEmbarkationDaysInPast;
    const countryOfResidenceLabel = globalConfig?.countryOfResidenceLabel;

    const embarkationDateType = embarkationDaysInPast && typeof embarkationDaysInPast === "number" ? "" : "futureOnly";
    const embarkationMinDate =
        embarkationDaysInPast && typeof embarkationDaysInPast === "number"
            ? getBegginingOfLastYear()
            : getBegginingOfCurrentYear();
    const embarkationValidMinDate =
        embarkationDaysInPast && typeof embarkationDaysInPast === "number"
            ? DateTime.now().startOf("day").minus({ days: embarkationDaysInPast }).toISODate()
            : "";
    const embarkationOutOfRangeMessage =
        embarkationDaysInPast && typeof embarkationDaysInPast === "number"
            ? localize("cruiseDetails.embarkationDate.outOfRange.invalidMessage", { days: embarkationDaysInPast })
            : "";

    const [firstName, setFirstName] = useState(appFirstName);
    const [lastName, setLastName] = useState(appLastName);
    const [birthDate, setBirthDate] = useState();
    const [nationality, setNationality] = useState();
    const [countryOfResidence, setCountryOfResidence] = useState();

    const [bookingNumber, setBookingNumber] = useState();
    const [embarkationDate, setEmbarkationDate] = useState();
    const [embarkationDateErrorMessage, setEmbarkationDateErrorMessage] = useState("");
    const [birthDateErrorMessage, setBirthDateErrorMessage] = useState("");

    const selectBookingNumber = e => setBookingNumber(e.target.value);
    const selectEmbarkationDate = e => {
        setEmbarkationDate(moment(e).format());
        setEmbarkationDateErrorMessage("");
    };
    const selectBirthDate = value => {
        if (value) {
            setBirthDate(moment(value).utcOffset(0, true).format());
            setBirthDateErrorMessage("");
        }
    };
    const selectFirstName = e => setFirstName(e.target.value?.trim());
    const selectLastName = e => setLastName(e.target.value?.trim());

    const selectCountryOfResidence = value => setCountryOfResidence(value);

    const preValidate = () => {
        if (!birthDate) {
            setBirthDateErrorMessage(localize("flightDetails.traveler.dateOfBirth.invalidMessage"));
        }
    };

    const passportCountry = getThreeLeterCodeWithTwoLetterCode(nationality);

    const handleSubmit = () => {
        updateLoading("submit", true);
        const cruiseDetails = generateCapCruisePayload(
            cruiseLineCode,
            bookingNumber,
            embarkationDate,
            userId,
            firstName,
            lastName,
            nationality,
            birthDate,
            appVersion,
            passportCountry,
            countryOfResidence
        );
        checkAndUpdateUser({
            birthDate,
            nationality,
            passportCountry,
            countryOfResidence,
        });
        createProcessInstance(cruiseDetails);
    };

    return (
        <UnapprovedPoliciesWrapper policies={policies}>
            <FormPage header={localize("cruiseDetails.header")}>
                <ValidationForm handleSubmit={handleSubmit} preValidate={preValidate}>
                    <fieldset>
                        <Form.Group>
                            <p>{localize("cruiseDetails.note")}</p>
                        </Form.Group>
                    </fieldset>
                    <fieldset>
                        <legend>{localize("flightDetails.trip.legend")}</legend>
                        <Form.Group controlId="booking-number">
                            <Form.Label>
                                {localizeField(bookingNumberInput?.label) ||
                                    localize("cruiseDetails.bookingNumber.label")}
                            </Form.Label>
                            <Form.Control
                                type="text"
                                required
                                pattern={
                                    bookingNumberInput?.pattern || "^(4[1-9][0-9]{5}|[5-7][0-9]{6}|8000000|9190000)$"
                                }
                                onChange={selectBookingNumber}
                            />
                            <Required
                                message={
                                    bookingNumber
                                        ? localizeField(bookingNumberInput?.invalidMessage)
                                            ? localizeField(bookingNumberInput?.invalidMessage)
                                            : localize(`cruiseDetails.bookingNumber.invalidMessage`)
                                        : localizeField(bookingNumberInput?.missingMessage)
                                        ? localizeField(bookingNumberInput?.missingMessage)
                                        : localize(`cruiseDetails.bookingNumber.missingMessage`)
                                }
                            />
                        </Form.Group>
                        <Form.Group>
                            <DateInput
                                type={embarkationDateType}
                                label={localize("cruiseDetails.embarkationDate.label")}
                                dateSelected={selectEmbarkationDate}
                                errorMessage={embarkationDateErrorMessage}
                                min={embarkationMinDate}
                                max={getEndOfNextYear()}
                                minDate={embarkationValidMinDate}
                                outOfRangeMessage={embarkationOutOfRangeMessage}
                            />
                        </Form.Group>
                    </fieldset>
                    <fieldset>
                        <legend>{localize("flightDetails.traveler.legend")}</legend>
                        <Form.Group controlId="first-name">
                            <Form.Label>
                                <FormattedMessage id="flightDetails.traveler.firstAndMiddleName.label" />
                            </Form.Label>
                            <Form.Control
                                ref={firstNameRef}
                                type="text"
                                defaultValue={appFirstName}
                                pattern="(\p{L}\p{M}*)+([ -](\p{L}\p{M}*)+)?"
                                minLength="1"
                                maxLength="30"
                                onChange={selectFirstName}
                                disabled
                                required
                            />
                            <Form.Text className="text-muted">
                                {localize("flightDetails.traveler.firstName.additionalText")}
                            </Form.Text>
                            <Required message={localize("flightDetails.traveler.firstName.invalidMessage")} />
                        </Form.Group>
                        <Form.Group controlId="last-name">
                            <Form.Label>
                                <FormattedMessage id="flightDetails.traveler.lastName.label" />
                            </Form.Label>
                            <Form.Control
                                ref={lastNameRef}
                                type="text"
                                defaultValue={appLastName}
                                pattern="(\p{L}\p{M}*)+([ -](\p{L}\p{M}*)+)?"
                                minLength="1"
                                maxLength="30"
                                onChange={selectLastName}
                                disabled
                                required
                            />
                            <Form.Text className="text-muted">
                                {localize("flightDetails.traveler.firstName.additionalText")}
                            </Form.Text>
                            <Required message={localize("flightDetails.traveler.lastName.invalidMessage")} />
                        </Form.Group>
                        <CountrySelect
                            value={glideNationality}
                            onChange={setNationality}
                            customLabel={localize("flightDetails.flight.passportCountry2.label")}
                        />
                        <CountrySelect
                            value={glideCountryOfResidence}
                            onChange={selectCountryOfResidence}
                            type="countryOfResidence"
                            customLabel={
                                countryOfResidenceLabel
                                    ? localizeFieldOrDefault(
                                          countryOfResidenceLabel,
                                          localize("flightDetails.flight.countryOfResidence.label")
                                      )
                                    : ""
                            }
                        />
                        <Form.Group>
                            <DateInput
                                type="pastOnly"
                                label={localize("flightDetails.traveler.dateOfBirth.label")}
                                dateSelected={selectBirthDate}
                                errorMessage={birthDateErrorMessage}
                                value={glideBirthDate}
                                min={getBegginingOf1900()}
                                max={getEndOCurrentYear()}
                            />
                        </Form.Group>
                    </fieldset>
                    <Row className="justify-content-center">
                        <LoadingWrapper isLoading={loading.submit}>
                            <Button type="submit" className="w-100 mx-3">
                                <FormattedMessage id="global.submit" />
                            </Button>
                        </LoadingWrapper>
                    </Row>
                </ValidationForm>
            </FormPage>
        </UnapprovedPoliciesWrapper>
    );
};

export default CapCruiseDetails;
