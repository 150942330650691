import React, { useContext, useReducer, useState, useEffect } from "react";
import { DateTime } from "luxon";
import { Button, Form, Row } from "react-bootstrap";
import Required from "../_components/Required";
import LoadingWrapper from "../_components/Wrappers/LoadingWrapper";
import UnapprovedPoliciesWrapper from '../_components/Wrappers/PrivacyPolicyWrapper/UnapprovedPoliciesWrapper';
import { INITIAL_CONSENT } from "../_components/Wrappers/PrivacyPolicyWrapper/constants";
import { mapAirlinesToOptions } from "../../utils/flightUtils";
import CredentialContext from "../../config/CredentialContext";
import { useLanguage, useLocalize, useUser } from "../../utils/hooks";
import InterlineFlightsDetalisNoPnrLookup from "./InterlineFlightsDetalisNoPnrLookup";
import InterlineFlightsDetalisPnrLookup from "./InterlineFlightsDetalisPnrLookup.js";
import { fetchAllAirlines } from "../../utils/dataFetching";
import { airlineActions, personalActions } from "./constants";
import InfoModal from "../_components/InfoModal";
import { nameRegex, splitFirstName } from "../../utils/textUtils";
import { getBegginingOf1900, getEndOCurrentYear } from "../../utils/dateTimeUtils";
import DateInput from "../_components/DateInput";
import CountrySelect from "../_components/CountrySelect";
import ValidationForm from "../_components/ValidationForm";
import FormPage from "../_components/FormPage";

const initialAirlineDetails = {
    airlines: [],
    airline: undefined,
};
const airlineReducer = (state, { type, payload }) => {
    switch (type) {
        case airlineActions.SET_AIRLINES:
            return { ...state, airlines: payload };
        case airlineActions.SET_AIRLINE:
            return { ...state, airline: payload };
        default:
            throw new Error();
    }
};

const personalReducer = (state, { type, payload }) => {
    switch (type) {
        case personalActions.SET_FIRST_NAME:
            return { ...state, firstName: payload };
        case personalActions.SET_MIDDLE_NAME:
            return { ...state, middleName: payload };
        case personalActions.SET_LAST_NAME:
            return { ...state, lastName: payload };
        case personalActions.SET_BIRTH_DATE:
            return { ...state, birthDate: payload };
        case personalActions.SET_NATIONALITY:
            return { ...state, nationality: payload };
        case personalActions.SET_COUNTRY_OF_RESIDENCE:
            return { ...state, countryOfResidence: payload };
        default:
            throw new Error();
    }
};

const AddInterlineFlightDetails = () => {
    const { userId, firstName: appFirstName, lastName: appLastName, globalConfig, loading, setError } = useContext(
        CredentialContext
    );
    const {
        user,
        glideBirthDate,
        glideNationality,
        checkAndUpdateUser,
        glideCountryOfResidence,
        isUserDataBeingFetched
    } = useUser(userId, setError);
    const { localize } = useLocalize();
    const { localizeField } = useLanguage();

    const flightModalInfo = globalConfig?.flightModalInfo;
    const airlineNoPNRmessage = globalConfig?.airlineNoPNRmessage;

    const [isAirlinesLoading, setIsAirlinesLoading] = useState(true);
    const [showHeaderInfoModal, setShowHeaderInfoModal] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const openHeaderInfoModal = () => setShowHeaderInfoModal(true);
    const closeHeaderInfoModal = () => setShowHeaderInfoModal(false);
    const openConfirmationModal = () => {
        setShowConfirmationModal(true);
    };
    const closeConfirmationModal = () => {
        setShowConfirmationModal(false);
    };
    const confirmationModalConfig = {
        showConfirmationModal,
        closeConfirmationModal,
    };

    const [airlineDetails, dispatchAirlineAction] = useReducer(airlineReducer, initialAirlineDetails);
    const { airlines, airline } = airlineDetails;

    const { extractedFirstName, extractedMiddleName } = splitFirstName(appFirstName);

    const initialPersonalDetails = {
        firstName: extractedFirstName,
        middleName: extractedMiddleName,
        lastName: appLastName,
        birthDate: undefined,
        nationality: undefined,
        countryOfResidence: undefined
    };
    const [personalDetails, dispatchPersonalAction] = useReducer(personalReducer, initialPersonalDetails);

    const selectFirstName = e =>
        dispatchPersonalAction({ type: personalActions.SET_FIRST_NAME, payload: e.target.value?.trim() });
    const selectMiddleName = e =>
        dispatchPersonalAction({ type: personalActions.SET_MIDDLE_NAME, payload: e.target.value?.trim() });
    const selectLastName = e =>
        dispatchPersonalAction({ type: personalActions.SET_LAST_NAME, payload: e.target.value?.trim() });
    const selectBirthDate = value => {
        const date = DateTime.fromISO(value).setZone("UTC", { keepLocalTime: true }).toISO();
        dispatchPersonalAction({ type: personalActions.SET_BIRTH_DATE, payload: date });
    };
    const selectNationality = value =>
        dispatchPersonalAction({ type: personalActions.SET_NATIONALITY, payload: value });
    const selectCountryOfResidence = value =>
        dispatchPersonalAction({ type: personalActions.SET_COUNTRY_OF_RESIDENCE, payload: value });

    const selectAirline = event => {
        const { value } = event.target;
        dispatchAirlineAction({ type: airlineActions.SET_AIRLINE, payload: value });
    };

    useEffect(() => {
        const handleResponse = response => {
            dispatchAirlineAction({ type: airlineActions.SET_AIRLINES, payload: response.content });
            setIsAirlinesLoading(false);
        };

        fetchAllAirlines(handleResponse, setError);
    }, []);

    const { extractedFirstName: extractedUserFirstName, extractedMiddleName: extractedUserMiddleName } = splitFirstName(user?.firstName);

    useEffect(() => {
        if (user) {
            dispatchPersonalAction({ type: personalActions.SET_FIRST_NAME, payload: extractedUserFirstName });
            dispatchPersonalAction({ type: personalActions.SET_MIDDLE_NAME, payload: extractedUserMiddleName });
            dispatchPersonalAction({ type: personalActions.SET_LAST_NAME, payload: user.lastName });
        }
    }, [user]);

    const selectedAirline = airlines?.find(a => a.iataCode === airline);

    const renderInterlineForm = () => {
        if (!selectedAirline) {
            return null;
        }
        if (!selectedAirline.pnrLookupEnabled) {
            return (
                <InterlineFlightsDetalisNoPnrLookup
                    airline={airline}
                    personalDetails={personalDetails}
                    confirmationModalConfig={confirmationModalConfig}
                    checkAndUpdateUser={checkAndUpdateUser}
                />
            );
        }
        return (
            <InterlineFlightsDetalisPnrLookup
                airline={airline}
                airlineName={selectedAirline.name}
                personalDetails={personalDetails}
                confirmationModalConfig={confirmationModalConfig}
                checkAndUpdateUser={checkAndUpdateUser}
            />
        );
    };

    const submitDisabled = loading.submit || !airline;

    return (
        <>
            <InfoModal
                show={showHeaderInfoModal}
                close={closeHeaderInfoModal}
                message={flightModalInfo ? localizeField(flightModalInfo) : localize("flightDetails.note")}
            />
            <LoadingWrapper
                fullPage
                isLoading={isAirlinesLoading || isUserDataBeingFetched}
                message={localize("global.spinner.slowNetwork.message")}
                messageDelay={5000}
            >
                <UnapprovedPoliciesWrapper policies={INITIAL_CONSENT}>
                    <FormPage header={localize("flightDetails.header2")} showInfo={openHeaderInfoModal}>
                        <ValidationForm
                            handleSubmit={openConfirmationModal}
                            loadingProp="submit"
                            deferLoadingToModal={true}
                        >
                            <fieldset disabled={loading.submit}>
                                <Form.Group className="border-bottom-0" controlId="airline">
                                    <Form.Label>{localize("flightDetails.flight.airline")}</Form.Label>
                                    <Form.Control
                                        as="select"
                                        defaultValue=""
                                        name="airline"
                                        required
                                        onChange={selectAirline}
                                    >
                                        <option value="" disabled hidden>
                                            {localize("flightDetails.flight.airline.placeholder")}
                                        </option>
                                        {mapAirlinesToOptions(airlines).map((a, i) => (
                                            <option key={i} value={a.value}>
                                                {a.label}
                                            </option>
                                        ))}
                                    </Form.Control>
                                    {selectedAirline && !selectedAirline.pnrLookupEnabled && (
                                        <Form.Text>
                                            {airlineNoPNRmessage
                                                ? localizeField(airlineNoPNRmessage)
                                                : localize("flightDetails.flight.airline.additionalText")}
                                        </Form.Text>
                                    )}
                                    <Required message={localize("flightDetails.flight.airline.missingMessage")} />
                                </Form.Group>
                            </fieldset>
                            {renderInterlineForm()}
                            <fieldset disabled={loading.submit}>
                                <legend>{localize("flightDetails.primaryPassenger.legend")}</legend>
                                <Form.Group className="border-bottom-0" controlId="first-name">
                                    <Form.Label>{localize("flightDetails.traveler.firstName.label")}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        defaultValue={extractedUserFirstName}
                                        pattern={nameRegex}
                                        minLength="1"
                                        maxLength="30"
                                        onChange={selectFirstName}
                                        required
                                    />
                                    <Form.Text className="text-muted text-justify">
                                        {localize("flightDetails.traveler.firstName.note")}
                                    </Form.Text>
                                    <Required message={localize("flightDetails.traveler.firstName.invalidMessage")} />
                                </Form.Group>
                                <Form.Group className="border-bottom-0" controlId="middle-name">
                                    <Form.Label>{localize("flightDetails.traveler.middleName.label")}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        defaultValue={extractedUserMiddleName}
                                        pattern={nameRegex}
                                        minLength="1"
                                        maxLength="30"
                                        onChange={selectMiddleName}
                                    />
                                    <Form.Text className="text-muted text-justify">
                                        {localize("flightDetails.traveler.middleName.note")}
                                    </Form.Text>
                                    <Required message={localize("flightDetails.traveler.middleName.invalidMessage")} />
                                </Form.Group>
                                <Form.Group className="border-bottom-0" controlId="last-name">
                                    <Form.Label>{localize("flightDetails.traveler.lastName.label")}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        defaultValue={user?.lastName}
                                        pattern={nameRegex}
                                        minLength="1"
                                        maxLength="30"
                                        onChange={selectLastName}
                                        required
                                    />
                                    <Form.Text className="text-muted text-justify">
                                        {localize("flightDetails.traveler.lastName.note")}
                                    </Form.Text>
                                    <Required message={localize("flightDetails.traveler.lastName.invalidMessage")} />
                                </Form.Group>
                                <Form.Group className="border-bottom-0">
                                    <DateInput
                                        type="pastOnly"
                                        label={localize("flightDetails.traveler.dateOfBirth.label")}
                                        dateSelected={selectBirthDate}
                                        value={glideBirthDate}
                                        min={getBegginingOf1900()}
                                        max={getEndOCurrentYear()}
                                    />
                                </Form.Group>
                                <CountrySelect
                                    value={glideNationality}
                                    onChange={selectNationality}
                                    type="nationality"
                                    className="border-bottom-0"
                                    customLabel={localize("flightDetails.flight.passportCountry2.label")}
                                />
                                <CountrySelect
                                    value={glideCountryOfResidence}
                                    onChange={selectCountryOfResidence}
                                    type="countryOfResidence"
                                    className="border-bottom-0"
                                />
                            </fieldset>
                            <Row className="justify-content-center">
                                <Button className="w-100 mx-3" type="submit" disabled={submitDisabled}>
                                    <LoadingWrapper isLoading={loading.submit} row>
                                        {localize("global.submit")}
                                    </LoadingWrapper>
                                </Button>
                            </Row>
                        </ValidationForm>
                    </FormPage>
                </UnapprovedPoliciesWrapper>
            </LoadingWrapper>
        </>
    );
};

export default AddInterlineFlightDetails;
