import { Form } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { resolveObjectPath } from "../../../utils/formUtils";
import { useLanguage } from "../../../utils/hooks";

const CustomRadioButtonGroup = ({ input, isComplete, selectedValue }) => {
    const { name, label, options, required, validValues, missingMessage, invalidMessage } = input;

    const { register, errors } = useFormContext();
    const getError = resolveObjectPath(errors);

    const { localizeField } = useLanguage();

    const isChecked = val =>
        val === selectedValue ||
        (selectedValue === true && val.toLowerCase() === "yes") ||
        (selectedValue === false && val.toLowerCase() === "no");

    return (
        <Form.Group>
            <Form.Label>{localizeField(label)}</Form.Label>
            {options.map((option, index) => (
                <div key={index} className="form-check form-check-inline mr-5 my-2">
                    <input
                        id={`${name}-${option.value}`}
                        className="form-check-input"
                        ref={register({
                            required,
                            validate: val => validValues.includes(val),
                        })}
                        type="radio"
                        name={name}
                        value={option.value}
                        disabled={isComplete}
                        checked={isComplete ? isChecked(option.value) : undefined}
                    />
                    <label htmlFor={`${name}-${option.value}`} className="form-check-label">
                        {localizeField(option.label)}
                    </label>
                </div>
            ))}
            {getError(name)?.type === "required" && (
                <Form.Text className="text-required">{localizeField(missingMessage)}</Form.Text>
            )}
            {errors[name]?.type === "validate" && (
                <Form.Text className="text-required">{localizeField(invalidMessage)}</Form.Text>
            )}
        </Form.Group>
    );
};

export default CustomRadioButtonGroup;
