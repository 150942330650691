import { USE_PREMIER_REVIEW_KEY } from "../../pages/Navigation/constants";
import {
    BEFORE_YOU_GO_TO_THE_AIRPORT_STEP_NAME, COMPANION_PHOTO_STEP_NAME,
    COVID_TEST_STEP_NAME,
    isCovidTestStep,
    isVaccinationStep,
    QUESTIONNAIRE_STEP_NAME,
    TRAVEL_ATTESTATION_STEP_NAME,
    TRAVEL_AUTHORIZATION_STEP_NAME,
    VACCINATION_STEP_NAME
} from "../../utils/steps";
import { DateTime } from "luxon";
import { PRESENTATION_TYPES, REVIEW_STATUS, STATUS_FOR } from "../../utils/constants";
import { getLocal } from "./utils";

const completeStep = (data, stepId, stepName, predicate) => {
    console.log("Completing step %s", stepName);
    const findStepByName = s => s.name === stepName;
    const findStep = predicate || findStepByName;
    const step = data.instructions.userProcesses[stepId].steps.find(findStep);
    if (step) {
        step.complete = true;
    }
    return data;
};

const setAvailable = (data, stepId, stepName, predicate, value) => {
    console.log("Processing step %s", stepName);
    const findStepByName = s => s.name === stepName;
    const findStep = predicate || findStepByName;
    const step = data.instructions.userProcesses[stepId].steps.find(findStep);
    if (step) {
        step.available = value;
    }
    return data;
};

const setPending = (data, userId, fsd, stepName) => {
    const arrayName = STATUS_FOR[stepName];
    console.log("Setting review status %s for %s", REVIEW_STATUS.PENDING, arrayName);
    for (const item of data.userData.userSpecificData[userId].flightSpecificData[fsd][arrayName]) {
        item.submitted = DateTime.utc().toISO();
        item.review = {
            presentation: PRESENTATION_TYPES.PAPER,
            submitted: DateTime.utc().toString(),
            status: REVIEW_STATUS.PENDING,
        };
    }
    return data;
};

const setAccepted = (data, userId, fsd, stepName) => {
    const arrayName = STATUS_FOR[stepName];
    console.log("Setting review status %s for %s", REVIEW_STATUS.ACCEPTED, arrayName);
    if(!data.userData.userSpecificData[userId].flightSpecificData[fsd][arrayName]) {
        data.userData.userSpecificData[userId].flightSpecificData[fsd][arrayName] = [{}]
    }
    for (const item of data.userData.userSpecificData[userId].flightSpecificData[fsd][arrayName]) {
        item.submitted = DateTime.utc().toISO();
        item.review = {
            presentation: PRESENTATION_TYPES.DIGITAL_UPLOAD,
            submitted: DateTime.utc().toString(),
            status: REVIEW_STATUS.ACCEPTED,
        };
    }
    const stepId = `${fsd}:${userId}`;
    const otherStepName = stepName === COVID_TEST_STEP_NAME ? VACCINATION_STEP_NAME : COVID_TEST_STEP_NAME;
    data = setAvailable(data, stepId, otherStepName, null,false);
    data = completeStep(data, stepId, COVID_TEST_STEP_NAME, isCovidTestStep);
    data = completeStep(data, stepId, VACCINATION_STEP_NAME, isVaccinationStep);
    return data;
}

const processTravelAuthRequest = (data, userId, fsd) => {
    data.userData.userSpecificData[userId].flightSpecificData[fsd].travelAuthorizations = [{
        auditData: {rawData: "1234abcd"}
    }]
    delete(data.userData.userSpecificData[userId].travelAuthorizationRequests)
    return data;
}

export const setInterlineReview = (data, userId, fsd, stepName, accepted) => {
    const status = accepted ? REVIEW_STATUS.ACCEPTED : REVIEW_STATUS.REJECTED;
    const arrayName = STATUS_FOR[stepName];
    for (const item of data.userData.userSpecificData[userId].flightSpecificData[fsd][arrayName]) {
        item.review = { ...item.review, completed: DateTime.utc().toString(), status };
    }
    const stepId = `${fsd}:${userId}`;
    data = completeStep(data, stepId, stepName);
    return data;
};

export const processInterlineSteps = (data) => {
    const {
        userData: { userSpecificData },
    } = data;
    console.log("Processing instance");
    for (const userId in userSpecificData) {
        console.log("Processing steps for user %s", userId);
        for (const fsd in userSpecificData[userId].flightSpecificData ) {
            console.log("Processing steps for segment %s", fsd);
            const segmentSpecificData = userSpecificData[userId].flightSpecificData[fsd];

            const stepId = `${fsd}:${userId}`

            // COMPANION_PHOTO_STEP
            if (segmentSpecificData.photo?.completed) {
                data = completeStep(data, stepId, COMPANION_PHOTO_STEP_NAME);
            }

            // BEFORE_YOU_GO_TO_THE_AIRPORT_STEP
            if (segmentSpecificData.checklist?.completed) {
                data = completeStep(data, stepId, BEFORE_YOU_GO_TO_THE_AIRPORT_STEP_NAME);
            }

            // ATTESTATION
            if (segmentSpecificData.attestation?.completed) {
                data = completeStep(data, stepId, TRAVEL_ATTESTATION_STEP_NAME);
            }

            // TRAVEL_AUTHORIZATION
            if (segmentSpecificData.travelAuthorizations?.length > 0) {
                data = completeStep(data, stepId, TRAVEL_AUTHORIZATION_STEP_NAME);
            }
            if (segmentSpecificData.travelAuthorizationRequests?.length > 0) {
                data = processTravelAuthRequest(data, userId, fsd)
            }

            // COVID_TEST
            if (segmentSpecificData.covidTests?.length > 0) {
                data = setPending(data, userId, fsd, COVID_TEST_STEP_NAME);
            }
// console.log(segmentSpecificData.visas);
//             if (segmentSpecificData.visas?.length > 0) {
//                 data = setAccepted(data, userId, fsd, "VISA");
//                 console.log(data);
//             }

            // if (segmentSpecificData.passports?.length > 0) {
            //     data = setAccepted(data, userId, fsd, COVID_TEST_STEP_NAME);
            // }

            if (segmentSpecificData.deferCovidTest) {
                data = completeStep(data, stepId, COVID_TEST_STEP_NAME);
            }

            // COVID19_VACCINE
            if (segmentSpecificData.covidVaccines?.length > 0) {
                data = setPending(data, userId, fsd, VACCINATION_STEP_NAME);
            }

            // DIGITAL_CERTIFICATE
            const healthPasses = segmentSpecificData.healthPasses;
            if (healthPasses?.filter(hp => !hp.processed).length > 0) {
                data.userData.userSpecificData[userId].flightSpecificData[fsd].healthPasses = healthPasses.map(
                    hp => ({ ...hp, processed: true }));
                data = setAccepted(data, userId, fsd, COVID_TEST_STEP_NAME);
            }

            // QUESTIONNAIRE
            const questionnaires = segmentSpecificData.questionnaires;
            if (questionnaires?.filter(hp => !hp.processed).length > 0) {
                data = completeStep(data, stepId, QUESTIONNAIRE_STEP_NAME);
            }

            // QUESTIONNAIRE
            if (questionnaires?.filter(hp => hp.type === "HQ0009").length > 0) {
                data = completeStep(data, stepId, "POST_DEPARTURE_QUESTIONNAIRE");
            }

            // QUESTIONNAIRE
            if (questionnaires?.filter(hp => hp.type === "HQ0012").length > 0) {
                data = completeStep(data, stepId, "VACCINATION_QUESTIONNAIRE");
            }

            // PAYMENT
            if (segmentSpecificData.checkForPayment && getLocal(USE_PREMIER_REVIEW_KEY)) {
                data.userData.userSpecificData[userId].paymentInfo = {
                    "PREMIUM_REVIEW_SERVICE_GROUP": {
                        "USD": {
                            "stripePriceId": "price_1K4U5UCjzFTdzQ0pX9kfiufg",
                            "amount": 1499
                        }
                    },
                    "PREMIUM_REVIEW_SERVICE_SINGLE_TRAVELER": {
                        "USD": {
                            "stripePriceId": "price_1K4U5ECjzFTdzQ0pSEpP8zHB",
                            "amount": 899
                        }
                    }
                };
            }
        }
    }
    return data;
};
