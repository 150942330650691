import { createGlobalStyle } from "styled-components";

export const colors = {
    global: {
        background: ({ theme }) => theme.global.background,
        text: ({ theme }) => theme.global.text,
        accent: ({ theme }) => theme.global.accent,
        container: ({ theme }) => theme.global.container,
        transparent: ({ theme }) => theme.global.transparent,
    },
    forms: {
        background: ({ theme }) => theme.forms.background,
        bgSolid: ({ theme }) => theme.forms.backgroundSolid,
        disabledBg: ({ theme }) => theme.forms.disabledBackground,
        border: ({ theme }) => theme.forms.border,
        borderInput: ({ theme }) => theme.forms.borderInput,
        shadowInput: ({ theme }) => theme.forms.shadowInput,
        text: ({ theme }) => theme.forms.text,
        disabledText: ({ theme }) => theme.forms.disabledText,
        shadow: ({ theme }) => theme.forms.shadow,
        invalid: ({ theme }) => theme.forms.invalid,
        invalidIcon: ({ theme }) => theme.forms.invalidIcon,
        invalidShadow: ({ theme }) => theme.forms.invalidShadow,
        valid: ({ theme }) => theme.forms.valid,
        validShadow: ({ theme }) => theme.forms.validShadow,
        validIcon: ({ theme }) => theme.forms.validIcon,
        info: ({ theme }) => theme.forms.info,
        secondaryInfo: ({ theme }) => theme.forms.secondaryInfo,
        buttonInfo: ({ theme }) => theme.forms.buttonInfo,
        pending: ({ theme }) => theme.forms.pending,
        optional: ({ theme }) => theme.forms.optional,
        muted: ({ theme }) => theme.forms.mutedText,
        textAccented: ({ theme }) => theme.forms.textAccented,
        kebabMenu: ({ theme }) => theme.forms.kebabMenu,
        credentialStep: ({ theme }) => theme.forms.credentialStep,
        timePicker: ({ theme }) => theme.forms.timePicker,
        clearIcon: ({ theme }) => theme.forms.clearIcon,
        fieldOpacity: ({ theme }) => theme.forms.fieldOpacity,
        option: ({ theme }) => theme.forms.option,
    },
    buttons: {
        primary: {
            background: ({ theme }) => theme.buttons.primary.background,
            text: ({ theme }) => theme.buttons.primary.text,
            shadow: ({ theme }) => theme.buttons.primary.shadow,
        },
        secondary: {
            background: ({ theme }) => theme.buttons.secondary.background,
            text: ({ theme }) => theme.buttons.secondary.text,
            shadow: ({ theme }) => theme.buttons.secondary.shadow,
        },
        success: {
            background: ({ theme }) => theme.buttons.success.background,
            text: ({ theme }) => theme.buttons.success.text,
            shadow: ({ theme }) => theme.buttons.success.shadow,
        },
        light: {
            background: ({ theme }) => theme.buttons.light.background,
            text: ({ theme }) => theme.buttons.light.text,
            shadow: ({ theme }) => theme.buttons.light.shadow,
        },
        danger: {
            background: ({ theme }) => theme.buttons.danger.background,
            text: ({ theme }) => theme.buttons.danger.text,
            shadow: ({ theme }) => theme.buttons.danger.shadow,
        },
        toggle: {
            background: ({ theme }) => theme.buttons.toggle.background,
            text: ({ theme }) => theme.buttons.toggle.text,
            border: ({ theme }) => theme.buttons.toggle.border,
        },
        outlinePrimary: {
            background: ({ theme }) => theme.buttons.outlinePrimary.background,
            text: ({ theme }) => theme.buttons.outlinePrimary.text,
            border: ({ theme }) => theme.buttons.outlinePrimary.border,
        },
        outlineSecondary: {
            background: ({ theme }) => theme.buttons.outlineSecondary.background,
            text: ({ theme }) => theme.buttons.outlineSecondary.text,
            border: ({ theme }) => theme.buttons.outlineSecondary.border,
        },
        outlineInfo: {
            background: ({ theme }) => theme.buttons.outlineInfo.background,
            text: ({ theme }) => theme.buttons.outlineInfo.text,
            border: ({ theme }) => theme.buttons.outlineInfo.border,
        },
        outlineInfoSecondary: {
            background: ({ theme }) => theme.buttons.outlineInfoSecondary.background,
            text: ({ theme }) => theme.buttons.outlineInfoSecondary.text,
            border: ({ theme }) => theme.buttons.outlineInfoSecondary.border,
        },
        outlineDanger: {
            background: ({ theme }) => theme.buttons.outlineDanger.background,
            text: ({ theme }) => theme.buttons.outlineDanger.text,
            border: ({ theme }) => theme.buttons.outlineDanger.border,
        },
    },
    alerts: {
        warning: {
            background: ({ theme }) => theme.alerts.warning.background,
            text: ({ theme }) => theme.alerts.warning.text,
            border: ({ theme }) => theme.alerts.warning.border,
        },
        danger: {
            background: ({ theme }) => theme.alerts.danger.background,
            text: ({ theme }) => theme.alerts.danger.text,
            border: ({ theme }) => theme.alerts.danger.border,
        },
    },
    stepper: {
        active: {
            text: ({ theme }) => theme.stepper.active.text,
            background: ({ theme }) => theme.stepper.active.background,
        },
        inactive: {
            text: ({ theme }) => theme.stepper.inactive.text,
            background: ({ theme }) => theme.stepper.inactive.background,
        },
    },
    switch: {
        background: ({ theme }) => theme.switch.background,
        outline: ({ theme }) => theme.switch.outline,
        checked: ({ theme }) => theme.switch.checked,
        unchecked: ({ theme }) => theme.switch.unchecked,
    },
    accordion: {
        header: {
            text: ({ theme }) => theme.accordion.header.text,
            border: ({ theme }) => theme.accordion.header.border,
        },
        body: {
            text: ({ theme }) => theme.accordion.body.text,
        },
    },
};

export const Theme = createGlobalStyle`
body {
    background: ${colors.global.background};
    color: ${colors.global.text};
    background-repeat: no-repeat;
    background-attachment: fixed;
}

a {
    color: ${colors.global.accent};
    text-decoration: none;
}

.card {
    background-color: ${colors.global.container};
}

.invalid-color-card {
    border: 1px solid ${colors.forms.invalid};
}

.valid-color-card {
    border: 1px solid ${colors.forms.valid};
}

.additional-info-box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    border-width: 1px;
    border-style: solid;
    padding: 10px 8px;
    border-radius: .25rem;
    text-align: justify;
    font-weight: normal;
    line-height: 1.5;

    p {
        font-size: 15px;
        margin-bottom: 0;
    }
}

.additional-info-box.info-border {
    border-color: ${colors.forms.info};
}

.additional-info-box.invalid-border {
    border-color: ${colors.forms.invalid};
}

.additional-info-box.invalid-border-layout {
    margin: 0 1.25rem;
}

.form-group {
    border-bottom: ${colors.forms.border};
    select,
    select>option,
    select:focus {
        background-color: ${colors.forms.bgSolid};
        color: ${colors.global.text};
    }
}

.bordered-fieldset {
    border: ${colors.forms.border};
}

.rbt {
    .dropdown-menu {
        background-color: ${colors.forms.bgSolid};
        a {
            color: ${colors.global.text};
        }
        a:hover, a:focus {
            background-color: ${colors.global.transparent};
        }
    }
    .rbt-highlight-text {
        background-color: ${colors.global.accent};
        color: ${colors.forms.bgSolid};
    }
}

.form-control,
.form-control:focus,
.custom-file-label {
    background-color: ${colors.forms.bgSolid};
    border: ${colors.forms.borderInput};
    box-shadow: ${colors.forms.shadowInput};
    color: ${colors.forms.text};
    &:disabled {
        background-color: ${colors.forms.disabledBg};
        color: ${colors.forms.disabledText};
        opacity: ${colors.forms.fieldOpacity};
        border: none;
        box-shadow: none;
        &::placeholder {
           color: ${colors.forms.disabledText};
       }
    }
    &::placeholder {
        color: ${colors.global.text};
        background-color: ${colors.global.transparent};
    }
}

input[type="time"].form-control::-webkit-calendar-picker-indicator {
    filter: ${colors.forms.timePicker};
}

.custom-file-label {
    &:after {
        background-color: ${colors.buttons.primary.background};
        border: ${colors.buttons.primary.background};
        color: ${colors.buttons.primary.text};
    }
    &:focus {
        box-shadow: ${colors.forms.shadow};
    }
}

.form-control:focus {
    box-shadow: ${colors.forms.shadow};
}

.was-validated .form-control,
.was-validated .custom-file-input {
    &:invalid,
    &:invalid:focus,
    &:invalid~.custom-file-label {
        border-color: ${colors.forms.invalid};
        background-image: url("${colors.forms.invalidIcon}");
    }
    &:valid,
    &:valid:focus,
    &:valid~.custom-file-label {
        border-color: ${colors.forms.valid};
        background-image: url("${colors.forms.validIcon}");
    }
    &:invalid:focus,
    &:invalid:focus~.custom-file-label {
        box-shadow: ${colors.forms.invalidShadow};
        border-color: ${colors.forms.invalid};
    }
    &:valid:focus,
    &:valid:focus~.custom-file-label {
        box-shadow: ${colors.forms.validShadow};
        border-color: ${colors.forms.valid};
    }
}

.was-validated .custom-file-input {
    &:invalid,
    &:invalid~.custom-file-label,
    &:valid,
    &:valid~.custom-file-label{
        background-image: none;
    }
}

.form-control.is-invalid {
    border-color: ${colors.forms.invalid};
}

.custom-control-input.is-invalid~.custom-control-label::before,
.was-validated .custom-control-input:invalid~.custom-control-label::before {
    border-color: ${colors.forms.invalid};
}

.custom-control-input.is-invalid:focus:not(:checked)~.custom-control-label::before,
.was-validated .custom-control-input:invalid:focus:not(:checked)~.custom-control-label::before {
    border-color: ${colors.forms.invalid};
}

.custom-control-input.is-invalid:focus~.custom-control-label::before,
.was-validated .custom-control-input:invalid:focus~.custom-control-label::before {
    box-shadow: none;
}

.svg-icon{
    .error-icon{
        fill: ${colors.forms.invalid};
    }

    .info-visa-icon {
        fill: ${colors.global.accent}
    }

    .checked-icon{
        fill: ${colors.forms.valid};
    }

    .checked-bg{
        fill: ${colors.global.transparent};
    }

    .pending-icon{
        fill: ${colors.forms.pending};
    }

    .info-icon{
        fill: ${colors.forms.info};
    }

    .secondary-info-icon {
        fill: ${colors.forms.secondaryInfo};
    }

    .secondary-info-icon-stroke {
        stroke: ${colors.forms.secondaryInfo};
        fill: ${colors.global.transparent};
    }

    .info-button-icon {
        fill: ${colors.forms.buttonInfo};
    }

    .ballot-box-checked{
        stroke: ${colors.buttons.primary.text};
    }

    .ballot-box-unchecked{
        stroke: ${colors.buttons.toggle.text};
    }

    .premier-icon-fill {
        fill: ${colors.global.text};
    }

    .premier-icon-stroke {
        stroke:  ${colors.global.text};
    }

    .remove-icon-fill {
        fill: ${colors.forms.invalid};
    }

    .remove-icon-stroke {
        stroke: white;
    }

    .add-icon-stroke {
        stroke: ${colors.global.accent};
        fill: transparent;
    }
}

.alert.alert-warning {
    color: ${colors.alerts.warning.text};
    background-color: ${colors.alerts.warning.background};
    border: ${colors.alerts.warning.border};
}

.alert.alert-danger {
    color: ${colors.alerts.danger.text};
    background-color: ${colors.alerts.danger.background};
    border: ${colors.alerts.danger.border};
}

.custom-switch-wrapper {
    background-color: ${colors.global.container};
    border: ${colors.forms.border};
    a {
        color: ${colors.global.accent}
    }
    .custom-control {
        background-color: ${colors.global.transparent};
    }
    .custom-control-input:checked~.custom-control-label::before {
        border: ${colors.buttons.primary.background};
        background-color: ${colors.buttons.primary.background};
    }
}

.custom-control-input.is-valid:checked~.custom-control-label::before,
.was-validated .custom-control-input:valid:checked~.custom-control-label::before{
    border: ${colors.forms.valid};
    background-color: ${colors.forms.valid};
}

[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
      background: ${colors.global.accent};
}

[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    border: 1px solid ${colors.forms.radioButtonBorder};
}
.btn {
    &.btn-primary,
    &.btn-primary:focus,
    &.btn-primary:not(.disabled):active {
        background-color: ${colors.buttons.primary.background};
        border-color: ${colors.buttons.primary.background};
        color: ${colors.buttons.primary.text};
    }
    &.btn-primary:focus {
        box-shadow: ${colors.buttons.primary.shadow};
    }
    &.btn-secondary,
    &.btn-secondary:focus,
    &.btn-secondary:not(.disabled):active {
        background-color: ${colors.buttons.secondary.background};
        border-color: ${colors.buttons.secondary.background};
        color: ${colors.buttons.secondary.text};
    }
    &.btn-secondary:focus {
        box-shadow: ${colors.buttons.secondary.shadow};
    }
    &.btn-light,
    &.btn-light:focus,
    &.btn-light:not(.disabled):active {
        background-color: ${colors.buttons.light.background};
        border-color: ${colors.buttons.light.background};
        color: ${colors.buttons.light.text};
    }
    &.btn-light:focus {
        box-shadow: ${colors.buttons.light.shadow};
    }
    &.btn-success,
    &.btn-success:focus,
    &.btn-success:not(.disabled):active {
        background-color: ${colors.buttons.success.background};
        border-color: ${colors.buttons.success.background};
        color: ${colors.buttons.success.text};
    }
    &.btn-success:focus {
        box-shadow: ${colors.buttons.success.shadow};
    }
    &.btn-danger,
    &.btn-danger:focus,
    &.btn-danger:not(.disabled):active {
        background-color: ${colors.buttons.danger.background};
        border-color: ${colors.buttons.danger.background};
        color: ${colors.buttons.danger.text};
    }
    &.btn-danger:focus {
        box-shadow: ${colors.buttons.danger.shadow};
    }
    &.btn-option,
    &.btn-option:focus {
    background-color: ${colors.forms.option} !important;
    color: ${colors.global.text} !important;
    border: ${colors.forms.border} !important;
    }
    &.btn-status,
    &.btn-status:focus {
    background-color: ${colors.forms.option} !important;
    color: ${colors.global.text} !important;
    border-style: solid !important;
    border-width: 1px !important;
    }
    &.btn-status.btn-status--no-status {
        border: ${colors.forms.border};
    }
    &.btn-toggle,
    &.btn-toggle:focus {
    background-color: ${colors.buttons.toggle.background} !important;
    color: ${colors.buttons.toggle.text} !important;
    border: ${colors.buttons.toggle.border} !important;
        .ballot-box-checked{
            display: none;
        }
    }
    &.active.btn-toggle {
    background-color: ${colors.buttons.primary.background} !important;
    border-color: ${colors.buttons.primary.background} !important;
    color: ${colors.buttons.primary.text} !important;
        .ballot-box-checked{
            display: initial !important;
        }
    .ballot-box-unchecked{
            display: none;
        }
    }
    &.btn-outline-primary,
    &.btn-outline-primary:focus {
    background-color: ${colors.buttons.outlinePrimary.background} !important;
    color: ${colors.buttons.outlinePrimary.text} !important;
    border: 2px solid ${colors.buttons.outlinePrimary.border} !important;
    }
    &.btn-outline-secondary,
    &.btn-outline-secondary:focus {
    background-color: ${colors.buttons.outlineSecondary.background} !important;
    color: ${colors.buttons.outlineSecondary.text} !important;
    border: 2px solid ${colors.buttons.outlineSecondary.border} !important;
    }
    &.btn-outline-info,
    &.btn-outline-info:focus {
    background-color: ${colors.buttons.outlineInfo.background} !important;
    color: ${colors.buttons.outlineInfo.text} !important;
    border: 2px solid ${colors.buttons.outlineInfo.border} !important;
    }
    &.btn-outline-info-secondary,
    &.btn-outline-info-secondary:focus {
    background-color: ${colors.buttons.outlineInfoSecondary.background} !important;
    color: ${colors.buttons.outlineInfoSecondary.text} !important;
    border: 2px solid ${colors.buttons.outlineInfoSecondary.border} !important;
    }
    &.btn-outline-danger,
    &.btn-outline-danger:focus {
    background-color: ${colors.buttons.outlineDanger.background} !important;
    color: ${colors.buttons.outlineDanger.text} !important;
    border: 2px solid ${colors.buttons.outlineDanger.border} !important;
    }
}

div.kebab-menu {
    .menu-content {
        background-color: ${colors.forms.bgSolid};
    }
    .dot {
        background-color: ${colors.forms.kebabMenu};
    }
}

.list-group-item {
    background-color: ${colors.global.transparent};
}

.trip-summary,
.passenger-link,
.border-bottom-default,
.card-text {
    border: none;
    border-bottom: ${colors.forms.border};
}

.credential-box {
    border-top: ${colors.forms.border};
    color: ${colors.forms.text};
    .credential-step {
        color: ${colors.forms.credentialStep};
    }
}

.modal-content {
    background-color: ${colors.forms.bgSolid};
}

.manage-checklist{
    color: ${colors.global.accent};
}

.rbt-aux .rbt-close{
    color: ${colors.global.text};
}

.test-details-wrapper {
    background: ${colors.global.background};
}

[data-reach-combobox-popover] {
    background: ${colors.forms.bgSolid};
}

.travel-auth-document-status.valid {
    border: 1px solid ${colors.forms.valid};
    color: ${colors.forms.valid};
}

.travel-auth-document-status.invalid {
    border: 1px solid ${colors.forms.invalid};
    color: ${colors.forms.invalid};
}

.border-required {
    border-color: ${colors.forms.invalid}!important;
}

.border-optional {
    border-color: ${colors.forms.optional} !important;
}

.border-complete {
    border-color: ${colors.forms.valid} !important;
}

.border-pending {
    border-color: ${colors.forms.pending} !important;
}

.border-rejected {
    border-color: ${colors.forms.invalid} !important;
}

.text-required,
.invalid-feedback,
.text-rejected {
    color: ${colors.forms.invalid};
}

.text-optional {
    color: ${colors.forms.optional};
}

.text-complete {
    color: ${colors.forms.valid};
}

.text-pending {
    color: ${colors.forms.pending};
}

.text-normal{
    color: ${colors.global.text};
}

.text-muted {
    color: ${colors.forms.muted} !important;
}

.text-accented {
    color: ${colors.global.accent};
}

.text-info {
    color: ${colors.forms.info} !important;
}

.stepper {
    text-align: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    gap: 5px;
    margin: 15px 5px;
    .stepper__element {
        width: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        flex: 1;
        font-weight: bold;
        .element__circle {
            width: 32px;
            min-height: auto !important;
            height: 32px;
            border-radius: 50%;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .element__circle:disabled {
            opacity: 1;
        }
        .element__circle.element__circle--active {
            color: ${colors.stepper.active.text};
            background: ${colors.stepper.active.background};
        }
        .element__circle.element__circle--inactive {
            color: ${colors.stepper.inactive.text};
            background: ${colors.stepper.inactive.background};
        }
        .element__text {
            font-size: 10px;
        }
    }
    .stepper__divider {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 30px;
        width: 50px;
        border-top: 2px solid ${colors.stepper.inactive.background};
    }
}

.accented_info {
    font-size: small;
    text-align: right;
    
    a {
        color: ${colors.global.accent};
        font-weight: bold;
        &:hover,
        &:active,
        &:visited {
            text-decoration: none;
        }
    }
}

.document_validation_modal {
    font-size: 13px;
    text-align: justify;

    h1, h2, h3, h4, h5, h6 {
        text-align: center;
        font-size: 18px;
    }

    ul {
        padding-left: 20px;
    }

    &.info_headings {
        h1, h2, h3, h4, h5, h6 {
            color: ${colors.forms.secondaryInfo}
        }
    }

    &.warning_headings {
        h1, h2, h3, h4, h5, h6 {
            color: ${colors.forms.invalid}
        }
    }
}

.labeled_switch {
    display: flex;
    align-items: flex-start;
    gap: 4px;

    input {
        appearance: none;
        min-width: 64px;
        min-height: 24px;
        margin: 0;

        position: relative;
        background-color: ${colors.switch.background};
        border-radius: 32px;
        border: 1px solid ${colors.switch.unchecked};

        cursor: pointer;
        user-select: none;
        transition: background-color 0.3s,
                    border-color 0.3s;

        &:checked {
            background-color: ${colors.switch.background};
            border-color: ${colors.switch.checked};

            &::before {
                left: unset;
                transform: translateX(calc(100% + 24px));
            }

            &::after {  
                content: attr(data-on);
                left: 0.4em;
                right: unset;
            }
        }

        &::before {
            content: '';
            position: absolute;
            height: 18px;
            width: 18px;
            top: 2px;
            left: 2px;
            bottom: 2px;
            background-color: ${colors.switch.unchecked};
            transition: all 0.3s;
            border-radius: 50%;
            cursor: pointer;
            z-index: 1;
        }

        &::after {
            content: attr(data-off);
            color: ${colors.switch.checked};
            display: block;
            position: absolute;
            font-size: 10px;
            font-weight: bold;
            line-height: 1;
            top: 0.6em;
            right: 0.4em;
            bottom: 0.5em;
        }

        &:focus {
            box-shadow: 0 0 0 0.2rem ${colors.switch.outline};
        }
    }

    &-labels {
        display: flex;
        flex-direction: column;
        gap: 4px;

        span {
            cursor: pointer;
            line-height: 1.4;
            font-size: 16px;
            user-select: none;
        }

        p {
            font-size: 12px;
            letter-spacing: 0.4px;
            line-height: 1.3;
            margin: 0;
            color: ${colors.switch.background};
        }
    }
}

.document_validation {
    border-top: ${colors.forms.border};
    color: ${colors.forms.text};
    .document_validation__step {
        color: ${colors.forms.credentialStep};
    }
    .document_validation__step--optional {
        color: ${colors.forms.disabledBg}
    }
}

.cibt-info_title {
    font-size: 16px;
    font-weight: 600;
    text-align: start;
    color: ${colors.forms.textAccented}
}

.accordion_container {
    color: ${colors.accordion.body.text};
    font-size: 14px;

    .accordion_card {
        border-bottom: 1px solid rgba(0,0,0,.125) !important;
        margin-bottom: 8px;
        border-radius: 4px !important;

        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 5px;
            padding: 16px 8px;
            font-size: 16px;
            color: ${colors.accordion.header.text};

            & > p {
                margin-bottom: 0;
                text-align: left;
            }

            & > svg {
                width: 12px;
                height: 12px;
            }

            &--active {
                border-bottom: 1px solid ${colors.accordion.header.border};
            }
        }

        &__body {
            padding: 16px 8px;

            & > ul {
                padding-left: 20px;
                margin-bottom: 0;
            }
        }
    }
}

.loadingSubmissionWrapper_container {
    width: 100vw;
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;

    & > .container__image {
        width: 35%;
        max-width: 150px;
    }

    & > .container__text {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        margin-bottom: 0;
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        color: ${colors.forms.valid};
        animation: blinker 2s linear infinite;

        @keyframes blinker {
            50% {
                opacity: 0;
            }
        }
    }
}
`;
