import { DateTime } from "luxon";
import useUserDataPath from "./useUserDataPath";
import { REVIEW_STATUS } from "../constants";

const useVisaReview = processInstance => {
    const { getUserDataPath } = useUserDataPath(processInstance);
    const userData = getUserDataPath();

    const getReviewData = type => {
        const typeReviews = userData?.[type];
        const typeReviewExists = typeReviews?.length > 0;
        // In case more than one passport/visa is allowed per segment, grab the latest.
        const activeTypeReview = typeReviewExists
            ? typeReviews?.find((typeReview, _, array) => {
                  return DateTime.fromISO(typeReview.submitted).equals(
                      DateTime.max(...array.map(tr => DateTime.fromISO(tr.submitted)))
                  );
              })
            : undefined;
        const activeTypeReviewStatus = typeReviewExists
            ? activeTypeReview?.review?.status || REVIEW_STATUS.PENDING
            : undefined;
        const activeTypeReviewDate = activeTypeReview?.submitted;
        const activeTypeReviewNote = activeTypeReview?.review?.note?.message || "";
        return {
            typeReviewExists,
            activeTypeReview,
            activeTypeReviewStatus,
            activeTypeReviewDate,
            activeTypeReviewNote,
        };
    };

    const {
        typeReviewExists: passportReviewExists,
        activeTypeReview: activePassportReview,
        activeTypeReviewStatus: activePassportReviewStatus,
        activeTypeReviewDate: activePassportReviewDate,
        activeTypeReviewNote: activePassportReviewNote,
    } = getReviewData("passports");

    const {
        typeReviewExists: visaReviewExists,
        activeTypeReview: activeVisaReview,
        activeTypeReviewStatus: activeVisaReviewStatus,
        activeTypeReviewDate: activeVisaReviewDate,
        activeTypeReviewNote: activeVisaReviewNote,
    } = getReviewData("visas");

    return {
        passportReviewExists,
        activePassportReview,
        activePassportReviewStatus,
        activePassportReviewDate,
        activePassportReviewNote,
        visaReviewExists,
        activeVisaReview,
        activeVisaReviewStatus,
        activeVisaReviewDate,
        activeVisaReviewNote,
    };
};

export default useVisaReview;
