import { DateTime } from "luxon";
import { REVIEW_STATUS, STATUS_FOR } from "../constants";
import useUserDataPath from "./useUserDataPath";

const useCovidReview = (processInstance, companionId, stepType) => {
    const { getUserDataPath } = useUserDataPath(processInstance);
    const userData = getUserDataPath();

    const getReviewData = type => {
        const covidReviews = userData?.[type];
        const reviewExists = covidReviews?.length > 0;
        const activeReview = reviewExists
            ? covidReviews?.find((test, _, array) => {
                  return DateTime.fromISO(test.submitted).equals(
                      DateTime.max(...array.map(t => DateTime.fromISO(t.submitted)))
                  );
              })
            : undefined;
        const activeReviewStatus = reviewExists ? activeReview?.review?.status || REVIEW_STATUS.PENDING : undefined;
        const activeReviewDate = activeReview?.submitted;
        const activeReviewNote = activeReview?.review?.note?.message || "";
        return { reviewExists, activeReview, activeReviewStatus, activeReviewDate, activeReviewNote };
    };

    const deferCovidTestData = !!userData?.deferCovidTest;

    if (stepType) {
        return { ...getReviewData(STATUS_FOR[stepType]) };
    }

    const {
        reviewExists: covidTestExists,
        activeReview: activeCovidTest,
        activeReviewStatus: activeCovidTestStatus,
        activeReviewDate: activeCovidTestDate,
        activeReviewNote: activeCovidTestReviewNote,
    } = getReviewData("covidTests");

    const {
        reviewExists: covidVaccineExists,
        activeReview: activeCovidVaccine,
        activeReviewStatus: activeCovidVaccineStatus,
        activeReviewDate: activeCovidVaccineDate,
        activeReviewNote: activeCovidVaccineReviewNote,
    } = getReviewData("covidVaccines");

    return {
        covidTestExists,
        activeCovidTest,
        activeCovidTestStatus,
        activeCovidTestDate,
        activeCovidTestReviewNote,
        deferCovidTest: deferCovidTestData,
        covidVaccineExists,
        activeCovidVaccine,
        activeCovidVaccineStatus,
        activeCovidVaccineDate,
        activeCovidVaccineReviewNote,
    };
};

export default useCovidReview;
