import VikingCruiseResponse from "../mocks/VikingCruiseResponse";
import PersonResponse from "../mocks/PersonResponse";
import { personId } from "../../utils/constants";
import moment from "moment";

// GET
export const vikingGetPerson = (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(PersonResponse));
};

// POST
export const vikingPostPerson = (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({ ...PersonResponse, extendedAttributes: req.body.extendedAttributes }));
};

// POST
export const vikingPost = (req, res, ctx) => {
    const vaccinationStatus = req.url.searchParams.get("mswVaccinationStatus");
    const companionId = req.url.searchParams.get("mswCompanionId");
    const mswVaccine = req.url.searchParams.get("mswVaccine");

    const message = {
        REJECTED:
            "The COVID-19 vaccine result photographs/PDFs that were uploaded were not recognized as a valid COVID-19 vaccine result. Please review the requirements and resubmit your vaccine results.",
        ACCEPTED: "Your review has beeen accepted",
    };

    const instructionsForUser = { ...VikingCruiseResponse.instructions.userProcesses[personId] };
    const arrayOfUsers = Object.keys(req.body.userData.userSpecificData);
    const newUserProcesses = {};
    arrayOfUsers.forEach(user => {
        newUserProcesses[user] = { ...instructionsForUser, userId: user };
    });

    const newInstance = {
        ...req.body,
        instructions: { ...VikingCruiseResponse.instructions, userProcesses: newUserProcesses },
    };

    const newInstanceWithVaccinationStatus = mswVaccine
        ? {
              ...req.body,
              userData: {
                  ...req.body.userData,
                  userSpecificData: {
                      ...req.body.userData.userSpecificData,
                      [companionId]: {
                          ...req.body.userData.userSpecificData[companionId],
                          covidVaccines: [
                              {
                                  ...req.body.userData.userSpecificData[companionId].covidVaccines?.[0],
                                  submitted: moment().format("YYYY-MM-DD"),
                                  review: {
                                      status: vaccinationStatus,
                                      note: {
                                          message: message[vaccinationStatus],
                                      },
                                  },
                              },
                          ],
                      },
                  },
              },
              instructions: { ...VikingCruiseResponse.instructions, userProcesses: newUserProcesses },
          }
        : {};

    const response = mswVaccine
        ? { ...VikingCruiseResponse, ...newInstanceWithVaccinationStatus }
        : { ...VikingCruiseResponse, ...newInstance };
    localStorage.setItem("processInstanceResponse", JSON.stringify(response));

    return res(ctx.status(201), ctx.json(response));
};

// GET
export const vikingGet = (req, res, ctx) => {
    const response = localStorage.getItem("processInstanceResponse");
    return res(ctx.status(200), ctx.json(JSON.parse(response)));
};
