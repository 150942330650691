export const camelToSentenceCase = text => {
    const split = text.replace( /([A-Z])/g, " $1" );
    return split.charAt(0).toUpperCase() + split.slice(1);
}

export const getPosition = (string, subString, index) => string.split(subString, index).join(subString).length;

const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d][a-z\\d-]*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator

// eslint-disable-next-line no-control-regex
const removeNewLine = (str) => str.replace(new RegExp('\r?\n','g'), ' ')

export const getFragmentFromStringIfUrl = string => {
    if (!!pattern.test(string)) {
        const parts = string.split("/");
        return parts[parts.length - 1];
    }
    return  removeNewLine(string); //Nikola D to check
}

export const getBaseUrl = string => {
    const parts = string.split("/");
    return parts[0] + "//" + parts[2];
}

export const capitalize = string => string.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())

export const truncate = (original, length) => {
    const str = original.toString();
    return str.length <= length ? str : str.substring(0, length - 3) + "...";
}

export const getFileExtensionOfBase64Data = (file) => typeof file === 'string' && file.substring("data:image/".length, file.indexOf(";base64"));
export const getBeginningOfBase64Data = (file) => typeof file === 'string' && file.split(";base64,")[1];
export const getDataTypeOfBase64Data = (file) => typeof file === 'string' && file.split(";base64,")[0].split(':')[1];

export const nameRegex = "[^0-9_!¡?÷?¿/\\\\+=@#$%ˆ&*(){}|~<>;:[\\]\\s]{1,}([ ][^0-9_!¡?÷?¿/\\\\+=@#$%ˆ&*(){}|~<>;:[\\]\\s]{1,})*";

export const getStringFromArrayOfStrings = (arrayOfStrings, separator = ',') => {
    if (Array.isArray(arrayOfStrings) && arrayOfStrings.length > 0) {
        return arrayOfStrings.join(separator);
    }
    return '';
};

export const constructPolicyText = policy => {
    if (policy?.hasOwnProperty("text") && policy?.hasOwnProperty("url") && policy?.hasOwnProperty("linkText")) {
        const linkSubtitute = `<a rel="noreferrer" target="_blank" href="${policy.url}">${policy.linkText}</a>`;
        return policy.text.replace(/{([^}]+)}/, linkSubtitute);
    }

    if (policy?.hasOwnProperty("text")) {
        return policy.text;
    }
    return '';
};

export const isNonEmptyString = (enteredString) => {
    if (typeof enteredString === 'string' && enteredString.trim()) {
        return true;
    }
    return false;
}

export const splitFirstName = (firstName) => {
    if (typeof firstName === "string") {
        const trimmedFirstName = firstName.trim();
        const words = trimmedFirstName.split(" ");

        if (words.length > 1) {
            return {
                extractedFirstName: words.shift(),
                extractedMiddleName: words.join(" ")
            };
        }
        return {
            extractedFirstName: words.shift(),
            extractedMiddleName: ''
        };
    }

    return {
        extractedFirstName: '',
        extractedMiddleName: ''
    };
}

export const joinFirstAndMiddleName = (firstName, middleName) => {
    if ((!middleName || (typeof middleName === "string" && !middleName.trim())) && typeof firstName === "string") {
        return firstName.trim(); 
    }

    if (typeof firstName === "string" && isNonEmptyString(middleName)) {
        return `${firstName.trim()} ${middleName.trim()}`
    }

    if (typeof firstName === "string") {
        return firstName.trim();
    }

    return firstName;
}

