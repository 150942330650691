import React from "react";
import { Row, Modal, Button } from "react-bootstrap";
import { useLocalize } from "../../utils/hooks";
import { ErrorIcon } from '../../icons/ErrorIcon';
import { INVALID_REASON } from './constants';

const InterlineInvalidFlightModal = ({
        showModal,
        errorMessages,
        closeModal
    }) => {
    const { localize } = useLocalize();

    const getMessageText = m => localize(`addInterlineFlightDetails.interlineModalInfo.${INVALID_REASON[m]}`);

    return (
        <Modal show={showModal}
            onHide={closeModal}
            centered
            dialogClassName="border-modal"
            className="px-3">
            <Modal.Body>
                <div className="text-center py-3">
                    <ErrorIcon size="50px" />
                </div>
                <div className="px-5">
                    {errorMessages.map((message) => (
                        <div className="pb-2 text-center" key={message}>
                            {getMessageText(message)}
                        </div>
                    ))}
                </div>
                <div className="px-5 py-4">
                    <Row className="justify-content-center">
                        <Button type="submit" className="w-100 mx-3" onClick={closeModal}>
                            {localize("addInterlineFlightDetails.interlineModalInfo.button")}
                        </Button>
                    </Row>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default InterlineInvalidFlightModal;
