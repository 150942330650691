import React, { useContext, useEffect, useRef, useState } from "react";
import { DateTime } from "luxon";
import { Button, Form, Row } from "react-bootstrap";
import { useParams } from "react-router";
import { countries } from "../../config/countries";
import CredentialContext from "../../config/CredentialContext";
import { fetchAirportsFromOrigin, fetchAirportsToCountry, fetchFlights } from "../../utils/dataFetching";
import { generateCapFlightPayload, mapAirlinesToOptions, mapAirportsToOptions } from "../../utils/flightUtils";
import { useCap, useLocalize, useQuery, useUser } from "../../utils/hooks";
import { REDIRECT } from "../../utils/hooks/useCap";
import { nameRegex } from "../../utils/textUtils";
import AutoSelect from "../_components/AutoSelect";
import CountrySelect from "../_components/CountrySelect";
import DateInput from "../_components/DateInput";
import FormPage from "../_components/FormPage";
import Required from "../_components/Required";
import ValidationForm from "../_components/ValidationForm";
import { WithType } from "../_components/Wrappers/ComponentWrappers";
import LoadingWrapper from "../_components/Wrappers/LoadingWrapper";
import { INITIAL_CONSENT } from "../_components/Wrappers/PrivacyPolicyWrapper/constants";
import UnapprovedPoliciesWrapper from '../_components/Wrappers/PrivacyPolicyWrapper/UnapprovedPoliciesWrapper';
import FlightDateInput from "./FlightDateInput";
import { getBegginingOf1900, getEndOCurrentYear } from "../../utils/dateTimeUtils";
import FlightInfo from "./FlightInfo";

const FlightDetailsWithFetching = () => {
    const {
        airlines,
        loading,
        loadingAny,
        setError,
        userEmail,
        userId,
        firstName: appFirstName,
        lastName: appLastName,
        appVersion,
    } = useContext(CredentialContext);
    const { createProcessInstance } = useCap(REDIRECT.MANAGE_TRIP);

    const { localize } = useLocalize();

    const firstNameRef = useRef();
    const lastNameRef = useRef();

    const { user, glideBirthDate, glideNationality, checkAndUpdateUser } = useUser(userId, setError);
    const { country } = useParams();

    const query = useQuery();
    const env = query.get("env");

    const [flights, setFlights] = useState([]);
    const [departureAirports, setDepartureAirports] = useState([]);
    const [arrivalAirports, setArrivalAirports] = useState([]);

    const [flight, setFlight] = useState();
    const [departureAirport, setDepartureAirport] = useState();
    const [arrivalAirport, setArrivalAirport] = useState();
    const [flightNumber, setFlightNumber] = useState();
    const [flightDate, setFlightDate] = useState();
    const [flightTime, setFlightTime] = useState();
    const [airline, setAirline] = useState();
    const [showBookingRef, setShowBookingRef] = useState(true);
    const [isBookingRefRequired, setIsBookingRefRequired] = useState(false);
    const [bookingRef, setBookingRef] = useState("");
    const [nationality, setNationality] = useState();
    const [birthDate, setBirthDate] = useState();
    const [firstName, setFirstName] = useState(appFirstName);
    const [lastName, setLastName] = useState(appLastName);
    const [flightAlreadyBookedErrorMessage, setFlightAlreadyBookedErrorMessage] = useState("");
    const [dateErrorMessage, setDateErrorMessage] = useState("");

    const selectBirthDate = value => {
        if (value) {
            const date = DateTime.fromISO(value).setZone("UTC", { keepLocalTime: true }).toISO();
            setBirthDate(date);
            setDateErrorMessage("");
        }
    };

    const preValidate = () => {
        if (!birthDate) {
            setDateErrorMessage(localize("flightDetails.traveler.dateOfBirth.invalidMessage"));
        }
    };
    const handleSubmit = () => {
        const originCountry = departureAirports.find(da => da.iata_code === departureAirport)?.country_iso;
        const flightDetails = generateCapFlightPayload(
            flight,
            departureAirport,
            arrivalAirport,
            originCountry,
            userId,
            firstName,
            lastName,
            userEmail,
            nationality,
            birthDate,
            bookingRef,
            env,
            appVersion,
        );
        checkAndUpdateUser({ firstName, lastName, birthDate, nationality });
        createProcessInstance(flightDetails);
    };

    const selectAirline = value => setAirline(value.target.value);
    const selectDepartureAirport = value => departureAirport !== value && setDepartureAirport(value);
    const selectArrivalAirport = value => arrivalAirport !== value && setArrivalAirport(value);
    const selectFlightNumber = value => flightNumber !== value && setFlightNumber(value);

    const selectFlightDate = e => {
        setFlightDate(e.target.value);
        setFlightAlreadyBookedErrorMessage("");
    };
    const selectFlightTime = e => setFlightTime(e.target.value);
    const selectNationality = value => setNationality(value);
    const selectFirstName = e => setFirstName(e.target.value?.trim());
    const selectLastName = e => setLastName(e.target.value?.trim());
    const selectBookingRef = e => setBookingRef(isBookingRefRequired ? e.target.value.toUpperCase() : e.target.value);

    const getAvailableFlights = () => {
        return [...new Set(flights.map(f => f.airlineCode + (f.flightNumber || "")))];
    };

    const bookingRefPatterns = {
        EI: "((?![019Oo])[0-9a-zA-Z]){6}",
        AA: "[a-zA-Z]{6}",
    };

    const getBookingRefPattern = () => (isBookingRefRequired ? bookingRefPatterns[airline] : undefined);
    const getBookingRefInputClassName = () => (isBookingRefRequired ? "uppercase" : "");

    const getDepartureTimeZone = () =>
        departureAirports?.find(da => da.iata_code === departureAirport)?.timezone || "UTC";

    const updateFlightTime = (flightDetails, time) => {
        const [hours, minutes] = time.split(":");
        const chosenFlight = { ...flightDetails };
        const departureTz = getDepartureTimeZone();
        chosenFlight.departureFlightTime = DateTime.fromISO(chosenFlight.flightDate)
            .plus({ hours })
            .plus({ minutes })
            .setZone(departureTz, { keepLocalTime: true })
            .toISO();
        return chosenFlight;
    };

    useEffect(() => {
        setDepartureAirports([]);
        const callback = result => {
            setArrivalAirport(undefined);
            setArrivalAirports([]);
            setDepartureAirports(result);
        };
        airline && fetchAirportsToCountry(airline, countries[country].code, callback, setError);
        const airlinesWithRequiredBookingRef = ["EI", "AA"];
        const airlinesNotUsingBookingRef = ["TJ"];
        setIsBookingRefRequired(airlinesWithRequiredBookingRef.includes(airline));
        setShowBookingRef(!airlinesNotUsingBookingRef.includes(airline));
    }, [airline]);

    useEffect(() => {
        setArrivalAirports([]);
        const callback = result => {
            setArrivalAirports(result);
        };
        airline &&
            departureAirport &&
            fetchAirportsFromOrigin(airline, departureAirport, countries[country].code, callback, setError);
    }, [departureAirport]);

    useEffect(() => {
        setFlights([]);
        setFlight(undefined);
        setFlightNumber(undefined);
        setFlightDate("");
        const callback = result => {
            setFlights(result);
        };
        airline &&
            arrivalAirport &&
            departureAirport &&
            fetchFlights(airline, departureAirport, arrivalAirport, callback, setError);
    }, [arrivalAirport]);

    useEffect(() => {
        if (arrivalAirport && flightNumber && flightDate) {
            const chosenFlight = {
                ...flights.find(
                    f =>
                        f.airlineCode === flightNumber ||
                        (f.airlineCode + f.flightNumber === flightNumber && f.departureFlightTime === flightDate)
                ),
            };
            const selectedDate = DateTime.fromISO(flightDate).toISODate();
            chosenFlight.flightDate = chosenFlight.flightDate || selectedDate;
            chosenFlight.flightNumber = chosenFlight.flightNumber || arrivalAirport;
            setFlight(flightTime ? updateFlightTime(chosenFlight, flightTime) : chosenFlight);
        }
    }, [arrivalAirport, flightNumber, flightDate]);

    useEffect(() => {
        if (flightTime && flight) {
            const chosenFlight = updateFlightTime(flight, flightTime);
            setFlight(chosenFlight);
        }
    }, [flightTime]);

    useEffect(() => {
        if (user) {
            setFirstName(user.firstName);
            setLastName(user.lastName);
        }
    }, [user]);

    const getFlightNumberClassName = () => (airline === "TJ" ? "d-none" : "");

    return (
        <LoadingWrapper
            fullPage
            isLoading={loadingAny("airlines")}
            message={localize("global.spinner.slowNetwork.message")}
            messageDelay={5000}
        >
            <UnapprovedPoliciesWrapper policies={INITIAL_CONSENT}>
                <FormPage header={localize("flightDetails.header")}>
                    <ValidationForm handleSubmit={handleSubmit} preValidate={preValidate}>
                        <fieldset>
                            <Form.Group>
                                <p>
                                    <strong>{localize("flightDetails.note")}</strong>
                                </p>
                            </Form.Group>
                        </fieldset>
                        <fieldset>
                            <legend>{localize("flightDetails.flight.legend")}</legend>
                            <Form.Group controlId="airline">
                                <Form.Label>{localize("flightDetails.flight.airline")}</Form.Label>
                                <Form.Control as="select" defaultValue="" required onChange={selectAirline}>
                                    <option value="" disabled hidden>
                                        {localize("flightDetails.flight.airline.placeholder")}{" "}
                                    </option>
                                    {mapAirlinesToOptions(airlines).map((a, i) => (
                                        <option key={i} value={a.value}>
                                            {a.label}
                                        </option>
                                    ))}
                                </Form.Control>
                                <Required message={localize("flightDetails.flight.airline.missingMessage")} />
                            </Form.Group>
                            <Form.Group controlId="departure-airport">
                                <Form.Label>{localize("flightDetails.flight.origin.label")}</Form.Label>
                                <AutoSelect
                                    name="departureAirport"
                                    options={departureAirports && mapAirportsToOptions(departureAirports)}
                                    availableOptionsOnly={true}
                                    disabled={departureAirports && departureAirports.length === 0}
                                    required
                                    onChange={selectDepartureAirport}
                                    placeholder={localize("flightDetails.flight.origin.defaultOptionText")}
                                    requiredMessage={localize("flightDetails.flight.origin.missingMessage")}
                                />
                            </Form.Group>
                            <Form.Group controlId="arrival-airport">
                                <Form.Label>{localize("flightDetails.flight.destination.label")}</Form.Label>
                                <AutoSelect
                                    name="arrivalAirport"
                                    options={arrivalAirports && mapAirportsToOptions(arrivalAirports)}
                                    availableOptionsOnly={true}
                                    disabled={arrivalAirports && arrivalAirports.length === 0}
                                    required
                                    onChange={selectArrivalAirport}
                                    placeholder={localize("flightDetails.flight.destination.defaultOptionText")}
                                    requiredMessage={localize("flightDetails.flight.destination.missingMessage")}
                                />
                            </Form.Group>
                            <Form.Group controlId="flight-number" className={getFlightNumberClassName()}>
                                <Form.Label>{localize("flightDetails.flight.number.label")}</Form.Label>
                                <AutoSelect
                                    name="flightNumber"
                                    options={flights && getAvailableFlights().map(f => ({ value: f, label: f }))}
                                    availableOptionsOnly={true}
                                    disabled={!arrivalAirport}
                                    required
                                    onChange={selectFlightNumber}
                                    placeholder={localize("flightDetails.flight.number.defaultOptionText")}
                                    requiredMessage={localize("flightDetails.flight.number.missingMessage")}
                                />
                            </Form.Group>
                            <FlightDateInput
                                airline={airline}
                                flights={flights}
                                flightNumber={flightNumber}
                                departureTimeZone={getDepartureTimeZone()}
                                onChange={selectFlightDate}
                                errorMessage={flightAlreadyBookedErrorMessage}
                            />
                            {airline === flightNumber && (
                                <Form.Group controlId="flight-time">
                                    <Form.Label>{localize("flightDetails.flight.time.label")}</Form.Label>
                                    <Form.Control type="time" required onChange={selectFlightTime} />
                                    <Required message={localize("flightDetails.flight.time.missingMessage")} />
                                </Form.Group>
                            )}
                            {showBookingRef && (
                                <Form.Group controlId="booking-reference">
                                    <Form.Label>{localize("flightDetails.flight.bookingRefNumber.label")}</Form.Label>
                                    <Form.Control
                                        className={getBookingRefInputClassName()}
                                        required={isBookingRefRequired}
                                        onChange={selectBookingRef}
                                        pattern={getBookingRefPattern()}
                                        maxLength="20"
                                    />
                                    <Form.Text className="text-muted">
                                        {localize("flightDetails.flight.bookingRefNumber.additionalText")}
                                    </Form.Text>
                                    {isBookingRefRequired && (
                                        <Required
                                            message={localize("flightDetails.flight.bookingRefNumber.missingMessage")}
                                        />
                                    )}
                                </Form.Group>
                            )}
                        </fieldset>
                        <fieldset>
                            <legend>{localize("flightDetails.traveler.legend")}</legend>
                            <Form.Group controlId="first-name">
                                <Form.Label>{localize("flightDetails.traveler.firstName.label")}</Form.Label>
                                <Form.Control
                                    ref={firstNameRef}
                                    type="text"
                                    defaultValue={user?.firstName}
                                    pattern={nameRegex}
                                    minLength="2"
                                    maxLength="30"
                                    onChange={selectFirstName}
                                    required
                                />
                                <Form.Text className="text-muted">
                                    {localize("flightDetails.traveler.firstName.note")}
                                </Form.Text>
                                <Required message={localize("flightDetails.traveler.firstName.invalidMessage")} />
                            </Form.Group>
                            <Form.Group controlId="last-name">
                                <Form.Label>{localize("flightDetails.traveler.lastName.label")}</Form.Label>
                                <Form.Control
                                    ref={lastNameRef}
                                    type="text"
                                    defaultValue={user?.lastName}
                                    pattern={nameRegex}
                                    minLength="2"
                                    maxLength="30"
                                    onChange={selectLastName}
                                    required
                                />
                                <Form.Text className="text-muted">
                                    {localize("flightDetails.traveler.lastName.note")}
                                </Form.Text>
                                <Required message={localize("flightDetails.traveler.lastName.invalidMessage")} />
                            </Form.Group>
                            <Form.Group>
                                <DateInput
                                    type="pastOnly"
                                    label={localize("flightDetails.traveler.dateOfBirth.label")}
                                    dateSelected={selectBirthDate}
                                    errorMessage={dateErrorMessage}
                                    value={glideBirthDate}
                                    min={getBegginingOf1900()}
                                    max={getEndOCurrentYear()}
                                />
                            </Form.Group>
                            <CountrySelect type="countryOfResidence" value={glideNationality} onChange={selectNationality} />
                        </fieldset>
                        {flight && (
                            <FlightInfo
                                flight={flight}
                                departureAirport={departureAirport}
                                arrivalAirport={arrivalAirport}
                            />
                        )}
                        <Row className="justify-content-center">
                            <LoadingWrapper isLoading={loading.submit}>
                                <Button type="submit" className="w-100 mx-3">
                                    {localize("global.submit")}
                                </Button>
                            </LoadingWrapper>
                        </Row>
                    </ValidationForm>
                </FormPage>
            </UnapprovedPoliciesWrapper>
        </LoadingWrapper>
    );
};

export default WithType(FlightDetailsWithFetching, "Flight Details");
