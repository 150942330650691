import {useState} from "react";

const KebabMenu = ({menu}) => {

    const [showMenu, setShowMenu] = useState(false);
    const closeMenu = () => {
        setShowMenu(false);
        document.removeEventListener("click", closeMenu);
    }
    const openMenu = e => {
        e.preventDefault();
        e.stopPropagation();
        setShowMenu(true);
        document.addEventListener("click", closeMenu);
    }

    return (
        <>
            <div onClick={openMenu} className="kebab-menu">
                <div className="dot" />
                <div className="dot" />
                <div className="dot" />
                {
                    showMenu &&
                    <div className="menu-content">
                        {menu()}
                    </div>
                }
            </div>
        </>
    );
};

export default KebabMenu;
