import { useState, useEffect, useContext } from "react";
import CredentialContext from "../../config/CredentialContext";
import { getStep, isDocumentValidation, isStepName } from "../steps";
import useQuery from "./useQuery";

const useDocumentValidation = () => {
    const { processInstance } = useContext(CredentialContext);
    const query = useQuery();
    const stepId = query.get("stepId");
    const referencedUnder = query.get("referencedUnder");

    const [subSteps, setSubSteps] = useState([]);

    const documentValidationStep = getStep(
        processInstance,
        stepId,
        referencedUnder ? isStepName(referencedUnder) : isDocumentValidation
    );
    const documentValidationStepParameters = documentValidationStep?.view?.parameters;

    const getStepByReference = s => getStep(processInstance, stepId, isStepName(s.referenceStepName));

    useEffect(() => {
        const documentValidationSubSteps = documentValidationStepParameters?.subSteps;
        if (documentValidationSubSteps) {
            const referencedSteps = documentValidationSubSteps.map(getStepByReference);
            setSubSteps(referencedSteps);
        }
    }, [processInstance, stepId]);

    return {
        documentValidationStep,
        documentValidationStepParameters,
        subSteps
    };
};

export default useDocumentValidation;
