import { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router";
import CredentialContext from "../../config/CredentialContext";
import { getStep, isHealthReview, isStepName } from "../steps";
import useQuery from "./useQuery";

const useHealthReview = () => {
    const { processInstance } = useContext(CredentialContext);
    const query = useQuery();
    const stepId = query.get("stepId");
    const history = useHistory();

    const [subSteps, setSubSteps] = useState([]);

    const HealthReviewStep = getStep(processInstance, stepId, isHealthReview);

    const getStepByReference = s => getStep(processInstance, stepId, isStepName(s.referenceStepName));

    useEffect(() => {
        const StepSubSteps = HealthReviewStep?.view?.parameters?.subSteps;
        if (StepSubSteps) {
            const referencedSteps = StepSubSteps.map(getStepByReference);
            setSubSteps(referencedSteps);
        }
    }, [processInstance, stepId]);

    const isAnySubStepOptional = subSteps?.map(sS => sS?.optional)?.reduce((prev, curr) => prev || curr, false);
    const areSubStepsComplete = subSteps?.map(sS => sS?.complete)?.reduce((prev, curr) => prev && curr, true);

    const redirectToSubSteps = () => {
        query.set("stepName", HealthReviewStep?.name);
        query.delete("healthReview");
        query.delete("reviewProcess");
        history.replace(`/addhealthreview/?${query.toString()}`);
    };

    const intialStepperConfig = !isAnySubStepOptional
        ? [
              {
                  visible: false,
                  text: "healthReview.stepper.text.1",
                  active: false,
                  complete: false,
                  action: redirectToSubSteps,
              },
              {
                  visible: true,
                  text: "healthReview.stepper.text.2",
                  active: true,
                  complete: false,
                  action: () => {},
              },
              {
                  visible: true,
                  text: "healthReview.stepper.text.3",
                  active: false,
                  complete: false,
                  action: () => {},
              },
          ]
        : [
              {
                  visible: true,
                  text: "healthReview.stepper.text.1",
                  active: true,
                  complete: false,
                  action: redirectToSubSteps,
              },
              {
                  visible: true,
                  text: "healthReview.stepper.text.2",
                  active: false,
                  complete: false,
                  action: () => {},
              },
              {
                  visible: true,
                  text: "healthReview.stepper.text.3",
                  active: false,
                  complete: false,
                  action: () => {},
              },
          ];

    const subStepStepperConfig = () => {
        const newConfig = [...intialStepperConfig];
        newConfig[0].active = false;
        newConfig[0].complete = true;
        newConfig[1].active = true;
        return newConfig;
    };

    const optionStepperConfig = stepName => {
        const stepPathName = stepName?.includes("VACCINE") ? "healthreviewvaccine" : "healthreviewtest";

        const newConfig = subStepStepperConfig();
        newConfig[1].active = false;
        newConfig[1].complete = true;
        newConfig[1].action = () => {
            query.delete("healthReview");
            query.delete("reviewProcess");
            history.replace(`/${stepPathName}/?${query.toString()}`);
        };
        newConfig[2].active = true;
        return newConfig;
    };

    const redirectToChecklist = () => {
        query.delete("toDate");
        query.delete("fromDate");
        query.delete("stepName");
        query.delete("healthReview");
        query.delete("reviewProcess");
        history.replace(`/tripchecklist?${query.toString()}`);
    };

    return {
        subSteps,
        HealthReviewStep,
        allSubStepsRequired: !isAnySubStepOptional,
        areSubStepsComplete,
        intialStepperConfig,
        subStepStepperConfig,
        optionStepperConfig,
        redirectToSubSteps,
        redirectToChecklist,
    };
};

export default useHealthReview;
