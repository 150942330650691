import React from "react";
import { Link } from "react-router-dom";
import { Button, Row } from "react-bootstrap";
import { useLocalize, useQuery } from "../../../utils/hooks";
import AirplaneHoriIcon from "../../../icons/AirplaneHoriIcon";
import useFlightBlock from "./ManageInterlineTripBlock/hooks/useFlightBlock";

const FlightBlockItem = ({ blockId, companionId }) => {
    const { localize } = useLocalize();
    const query = useQuery();

    const { getBlockStatus, getAirports, unsupported } = useFlightBlock(blockId, companionId);
    const { startAirportName, startAirportCode, endAirportName, endAirportCode } = getAirports();

    const linkUrl = () => {
        query.set("companionId", companionId);
        return `/manageinterlinetripblock/${blockId}?${query.toString()}`
    };

    return (
        <div className={`interline-flight-block interline-block m-1 ${getBlockStatus()}`}>
            {!unsupported &&
                <>
                    <Row className="d-flex flex-row justify-content-end px-4 pt-2 pb-2">
                        <div className={`flight-block-status text-2 ${getBlockStatus()}`}>
                            {localize(`manageInterlineTrip.flightSegment.status.${getBlockStatus()}`)}
                        </div>
                    </Row>
                    <div className="border-bottom-default mx-2 mb-3"/>
                </>
            }
            <Row className="d-flex flex-row justify-content-between px-4 py-4 align-items-center">
                <div className="column interline-flight-block-airport-right text-break">
                    <div>{localize("global.from")}</div>
                    <div className="text-muted form-text m-0">{startAirportName}</div>
                    <div className="h3 font-weight-bold m-0">{startAirportCode}</div>
                </div>
                <AirplaneHoriIcon className="interline-flight-block-arrow"/>
                <div className="column interline-flight-block-airport-left text-break">
                    <div>{localize("global.to")}</div>
                    <div className="text-muted form-text m-0">{endAirportName}</div>
                    <div className="h3 font-weight-bold m-0">{endAirportCode}</div>
                </div>
            </Row>
            {!unsupported
                ? <Link to={linkUrl()}>
                    <Button className="interline-flight-block-view-trevel">
                        {localize("manageInterlineTrip.flightBlock.link")}
                    </Button>
                </Link>
                : <Button className="interline-flight-block-view-trevel">
                    {localize("manageInterlineTrip.flightBlock.unsupported")}
                  </Button>
            }
        </div>
    );
};

export default FlightBlockItem;
