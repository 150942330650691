import moment from "moment-timezone";
import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { useIntl } from "react-intl";
import Required from "./Required";

export const PAST_ONLY = "pastOnly";
export const FUTURE_ONLY = "futureOnly";

const DateInput = ({
    dateSelected,
    errorMessage,
    onErrorMessage,
    missingMessage,
    outOfRangeMessage,
    label,
    value,
    type,
    allowToday,
    min,
    max,
    minDate,
    maxDate,
    disabled,
    customClass,
    note,
}) => {
    const intl = useIntl();
    const localize = id => intl.formatMessage({ id });

    const [internalErrorMessage, setInternalErrorMessage] = useState("");

    const monthRef = useRef();
    const dayRef = useRef();
    const yearRef = useRef();

    const defaultErrorMessage = localize("date.dateInvalid.errorMessage");

    const generateNumberOptions = (start, end) => {
        const arr = [];

        for (let i = start; i <= end; i++) {
            arr.push(i);
        }
        return arr;
    };

    const validateDate = date => {
        if (!date.isValid()) {
            onErrorMessage && onErrorMessage(missingMessage || defaultErrorMessage);
            dateInvalid(missingMessage || defaultErrorMessage);
        } else if (!isInRange(date)) {
            onErrorMessage && onErrorMessage(localize(`date.${type}.errorMessage`));
            dateInvalid(localize(`date.${type}.errorMessage`));
        } else if (!isInMinMaxRange(date)) {
            onErrorMessage && onErrorMessage(outOfRangeMessage || defaultErrorMessage);
            dateInvalid(outOfRangeMessage || defaultErrorMessage);
        } else {
            onErrorMessage && onErrorMessage("");
            dateValid();
        }
    };

    const selectIfValid = date => {
        if (date.isValid()) {
            dateSelected(date.toISOString());
        }
    };

    const selectDate = () => {
        const date = monthRef.current.value + "/" + dayRef.current.value + "/" + yearRef.current.value;
        const formatInputDate = moment(date, "MMM/D/YYYY", true);
        validateDate(formatInputDate);
        selectIfValid(formatInputDate);
    };

    const dateIsBefore = date => {
        const yesterday = moment().subtract(1, "day");
        const diff = date.diff(yesterday, "days");
        return diff <= 0;
    };

    const dateIsAfter = date => {
        const startOfMinDay = moment()
            .add(allowToday ? 0 : 1, "day")
            .startOf("day");
        return date.isSame(startOfMinDay) || date.isAfter(startOfMinDay);
    };

    const isInRange = date =>
        !type || (type === PAST_ONLY && dateIsBefore(date)) || (type === FUTURE_ONLY && dateIsAfter(date));

    const isInMinMaxRange = date =>
        (!maxDate || !date.isAfter(moment(maxDate))) && (!minDate || !date.isBefore(moment(minDate)));

    const dateValid = () => {
        monthRef.current.setCustomValidity(errorMessage || "");
        dayRef.current.setCustomValidity(errorMessage || "");
        yearRef.current.setCustomValidity(errorMessage || "");
        setInternalErrorMessage(errorMessage || "");
    };

    const dateInvalid = (message = defaultErrorMessage) => {
        monthRef.current.setCustomValidity(message);
        dayRef.current.setCustomValidity(message);
        yearRef.current.setCustomValidity(message);
        setInternalErrorMessage(message);
    };

    useEffect(() => {
        if (value && moment(value).isValid() && min && max) {
            const dateValue = moment(value);
            const month = dateValue.format("MMM");
            const day = dateValue.date().toString();
            const year = dateValue.year().toString();

            if (monthRef.current.value !== month || dayRef.current.value !== day || yearRef.current.value !== year) {
                monthRef.current.value = month;
                dayRef.current.value = day;
                yearRef.current.value = year;
                selectDate();
            }
        } else dateInvalid();
    }, [value]);

    useEffect(() => {
        if (errorMessage) {
            dateInvalid(errorMessage);
        } else {
            dateValid();
        }
    }, [errorMessage]);

    return (
        <div className="birth-date">
            <Form.Group controlId="birth-date" className={customClass + " no-border mb-0"}>
                <Form.Label>{label}</Form.Label>
                <div className="d-flex flex-wrap justify-content-between">
                    <Form.Control
                        className="flex-basis-32"
                        as="select"
                        name="month"
                        defaultValue=""
                        placeholder="MM"
                        required
                        disabled={disabled}
                        ref={monthRef}
                        onChange={selectDate}
                    >
                        <option value="" disabled hidden>
                            MM
                        </option>
                        {generateNumberOptions(moment(min).month(), moment(max).month()).map((month, i) => (
                            <option key={i} value={moment.monthsShort(month)}>
                                {localize("dates.month." + moment.monthsShort(month))}
                            </option>
                        ))}
                    </Form.Control>
                    <Form.Control
                        className="flex-basis-32"
                        as="select"
                        name="day"
                        defaultValue=""
                        placeholder="DD"
                        required
                        disabled={disabled}
                        ref={dayRef}
                        onChange={selectDate}
                    >
                        <option value="" disabled hidden>
                            DD
                        </option>
                        {generateNumberOptions(moment(min).date(), moment(max).date()).map((day, i) => (
                            <option key={i} value={day}>
                                {day}
                            </option>
                        ))}
                    </Form.Control>
                    <Form.Control
                        className="flex-basis-32"
                        as="select"
                        name="year"
                        defaultValue=""
                        placeholder="YYYY"
                        required
                        disabled={disabled}
                        ref={yearRef}
                        onChange={selectDate}
                    >
                        <option value="" disabled hidden>
                            YYYY
                        </option>
                        {generateNumberOptions(moment(min).year(), moment(max).year())
                            .reverse()
                            .map((year, i) => (
                                <option key={i} value={year}>
                                    {year}
                                </option>
                            ))}
                    </Form.Control>
                    {note && <Form.Text className="text-muted text-justify">{note}</Form.Text>}
                    <Required className="flex-basis-100" message={internalErrorMessage} />
                </div>
            </Form.Group>
        </div>
    );
};

export default DateInput;
