import React from "react";
import { Row } from "react-bootstrap";
import { DateTime } from "luxon";
import { useLanguage, useTimeFormat } from "../../../../utils/hooks";
import ArrowRightIcon from "../../../../icons/ArrowRightIcon";

const FlightDetailsForSummary = ({ details }) => {
    const { origin, destination, flightNumber, departureDateTime } = details;
    const { locale } = useLanguage();
    const { simpleTimeFormat } = useTimeFormat();

    const date = DateTime.fromISO(departureDateTime, { setZone: true })
                    .setLocale(locale)
                    .toFormat("dd MMM yyyy");
    const time = DateTime.fromISO(departureDateTime, { setZone: true })
                    .toLocaleString({ ...simpleTimeFormat, locale });


    return (
        <div className="flight-info">
            <Row className="d-flex flex-row justify-content-between px-2">
                <div className="">{date}</div>
                <div className="">{time}</div>
            </Row>
            <Row className="d-flex flex-row justify-content-between px-2">
                <div className="interline-flight-block-airport-right text-break">
                    {origin}
                </div>
                <ArrowRightIcon className="interline-flight-block-arrow"/>
                <div className="interline-flight-block-airport-left text-break">
                    {destination}
                </div>
            </Row>
            <Row className="d-flex flex-row justify-content-between px-2">
                <div className="font-weight-bold">Flight {flightNumber}</div>
            </Row>
        </div>
    );
};

export default FlightDetailsForSummary;
