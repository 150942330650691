import { useFormContext } from "react-hook-form";
import { Form } from "react-bootstrap";
import React from "react";
import { resolveObjectPath } from "../../../utils/formUtils";
import { useLanguage } from "../../../utils/hooks";

const CustomPhoneInput = ({ input, isComplete }) => {
    const {
        name,
        label,
        required,
        pattern,
        minLength,
        maxLength,
        missingMessage,
        invalidMessage,
        invalidLengthMessage,
        note,
    } = input;

    const { register, errors } = useFormContext();

    const { localizeField } = useLanguage();

    const getErrorMessage = type => {
        switch (type) {
            case "required":
                return missingMessage;
            case "pattern":
                return invalidMessage;
            case "minLength":
            case "maxLength":
                return invalidLengthMessage || invalidMessage;
            default:
                return missingMessage;
        }
    };

    const getLocalizedErrorMessage = type => localizeField(getErrorMessage(type));
    const getError = resolveObjectPath(errors);

    return (
        <Form.Group>
            <Form.Label htmlFor={name}>{localizeField(label)}</Form.Label>
            <Form.Control
                id={name}
                type="tel"
                name={name}
                disabled={isComplete}
                ref={register({
                    required,
                    minLength,
                    maxLength,
                    ...(pattern ? { pattern: new RegExp(pattern) } : {}),
                    validate: val => (required ? !!val?.trim() : true),
                })}
            />
            {note && <Form.Text className="text-muted">{localizeField(note)}</Form.Text>}
            {getError(name) && (
                <Form.Text className="text-required">{getLocalizedErrorMessage(getError(name).type)}</Form.Text>
            )}
        </Form.Group>
    );
};

export default CustomPhoneInput;
