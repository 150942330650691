/**
 * Used to maintain a queue of promises and call them sequentially
 * to avoid race conditions.
 * The reason for this is that every request needs a bearer token which
 * is being provided asynchronously by the mobile app. The race condition
 * happens when a request is fired before the previous one finishes.
 * In order to avoid this, the request promises are kept in a queue
 * and fired sequentially, one at a time.
 */
export class RequestQueue {
    static queue = [];
    static workingOnPromise = false;

    static enqueue(promise) {
        return new Promise((resolve, reject) => {
            this.queue.push({
                promise,
                resolve,
                reject,
            });
            this.dequeue();
        });
    }

    static dequeue() {
        if (this.workingOnPromise) {
            return false;
        }
        const item = this.queue.shift();
        if (!item) {
            return false;
        }
        try {
            this.workingOnPromise = true;
            item.promise()
                .then(value => {
                    this.workingOnPromise = false;
                    item.resolve(value);
                    this.dequeue();
                })
                .catch(err => {
                    this.workingOnPromise = false;
                    item.reject(err);
                    this.dequeue();
                });
        } catch (err) {
            this.workingOnPromise = false;
            item.reject(err);
            this.dequeue();
        }
        return true;
    }
}

export const requestToken = () => RequestQueue.enqueue(() => window.getBearerToken());
