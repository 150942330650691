import {
    SINGLE_TESTING_CENTERS_REQUEST,
    SINGLE_TESTING_CENTERS_SUCCESS,
    SINGLE_TESTING_CENTERS_FAIL,
} from "../constants/singleTestingCenterConstants";

export const singleTestingCenterReducer = (state = { testingCenters: [] }, action) => {
    switch (action.type) {
        case SINGLE_TESTING_CENTERS_REQUEST:
            return { loading: true, details: [] };
        case SINGLE_TESTING_CENTERS_SUCCESS:
            return { loading: false, details: action.payload };
        case SINGLE_TESTING_CENTERS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};
