import { DateTime } from "luxon";

export const flightBlock = (carrierCode = "AA", bookingNumber) => {
    const today = DateTime.local();
    const date1 = today.plus({ days: 1 }).toISODate();
    const date2 = today.plus({ days: 2 }).toISODate();
    const date3 = today.plus({ days: 3 }).toISODate();
    const date4 = today.plus({ days: 4 }).toISODate();

    return {
        bookingIdentifier: bookingNumber,
        carrierCode,
        visaRequired: true,
        internalVisaCheck: true,
        flightBlocks: [
            {
                id: "fb0",
                flightSegments: [
                    {
                        id: "fs0",
                        carrierCode,
                        flightNumber: "1052",
                        operatingCarrierCode: "AA", // These 2 fields are only required if the operating carrier is
                        operatingFlightNumber: "321", // different from the carrier of the airline managing the PNR
                        operatedAsText: "American Airlines (AA321)",
                        countryEntryStatus: "ENTERING",
                        regional: false,
                        departure: {
                            airportName: "Charles de Gaulle International Airport",
                            airportCode: "DUB",
                            countryCode: "IRL",
                            offsetDateTime: `${date1}T11:30:00Z`,
                        },
                        arrival: {
                            airportName: "St Gallen Altenrhein Airport London Heathrow Airport",
                            airportCode: "ACH",
                            countryCode: "CH",
                            offsetDateTime: `${date1}T13:30:00Z`,
                        }
                    },
                    {
                        id: "fs1",
                        carrierCode,
                        flightNumber: "120",
                        regional: true,
                        departure: {
                            airportName: "St Gallen Altenrhein Airport",
                            airportCode: "ACH",
                            countryCode: "CH",
                            offsetDateTime: `${date1}T14:55:00Z`,
                        },
                        arrival: {
                            airportName: "Adana Airport",
                            airportCode: "ADA",
                            countryCode: "TR",
                            offsetDateTime: `${date1}T17:30:00Z`,
                        },
                        lengthOfStayDays: null,
                    },
                    {
                        id: "fs2",
                        carrierCode,
                        flightNumber: "150",
                        regional: false,
                        departure: {
                            airportName: "Adana Airport",
                            airportCode: "ADA",
                            countryCode: "TR",
                            offsetDateTime: `${date1}T19:55:00Z`,
                        },
                        arrival: {
                            airportName: "London Heathrow",
                            airportCode: "LHR",
                            countryCode: "UK",
                            offsetDateTime: `${date2}T00:05:00Z`,
                        },
                        lengthOfStayDays: 5,
                    },
                ],
            },
            {
                id: "fb1",
                notAvailableUntil: "2022-12-12T07:30:00Z",
                flightSegments: [
                    {
                        id: "fs0",
                        carrierCode,
                        flightNumber: "150",
                        arrival: {
                            airportName: "Adana Airport",
                            airportCode: "ADA",
                            countryCode: "TR",
                            offsetDateTime: `${date3}T19:55:00Z`,
                        },
                        departure: {
                            airportName: "London Heathrow",
                            airportCode: "LHR",
                            countryCode: "UK",
                            offsetDateTime: `${date4}T00:05:00Z`,
                        },
                    },
                    {
                        id: "fs1",
                        carrierCode,
                        flightNumber: "120",
                        departure: {
                            airportName: "Adana Airport",
                            airportCode: "ADA",
                            countryCode: "TR",
                            offsetDateTime: `${date3}T17:30:00Z`,
                        },
                        arrival: {
                            airportName: "St Gallen Altenrhein Airport",
                            airportCode: "ACH",
                            countryCode: "CH",
                            offsetDateTime: `${date3}T14:55:00Z`,
                        },
                    },
                    {
                        id: "fs2",
                        carrierCode,
                        flightNumber: "105",
                        departure: {
                            airportName: "St Gallen Altenrhein Airport",
                            airportCode: "ACH",
                            countryCode: "CH",
                            offsetDateTime: `${date3}T13:30:00Z`,
                        },
                        arrival: {
                            airportName: "Dublin Airport",
                            airportCode: "DUB",
                            countryCode: "IRL",
                            offsetDateTime: `${date3}T11:30:00Z`,
                        },
                    },
                ],
            },
        ],
    };
};

const steps = [
    {
        name: "POST_DEPARTURE_QUESTIONNAIRE",
        notBeforeDtm: "2021-12-17T00:00:00.000+0000",
        notAfterDtm: "2022-01-15T00:00:00.000+0000",
        available: true,
        expired: false,
        optional: false,
        complete: true,
        displayName: "Post booking questionnaire",
        displayDescription: "Complete the Post booking questionnaire",
        displayInstructions: "Complete the Post booking questionnaire",
        view: {
            type: "INTERNAL_BOOTSTRAP",
            reference: "postDepartureQuestionnaireView",
            view: "/addquestionnaire",
            parameters: {
                type: "HQ0009",
                inputs: [
                    {
                        name: "PHS60Q",
                        type: "radio",
                        label: {
                            values: {
                                en:
                                    "4. Are you, or will you be fully vaccinated with a WHO or FDA authorized COVID-19 vaccine at least 14 days before boarding? (Fully vaccinated is you received the final dose of a vaccine series at least 14 days before embarkation.)",
                            },
                        },
                        options: [
                            {
                                label: {
                                    values: {
                                        en: "Yes",
                                    },
                                },
                                value: "PHS04V",
                            },
                            {
                                label: {
                                    values: {
                                        en:
                                            "No – I am not fully vaccinated, but I have a Company issued COVID-19 Vaccination Exemption Letter.",
                                    },
                                },
                                value: "PHS05V",
                            },
                            {
                                label: {
                                    values: {
                                        en:
                                            "No - I am not fully vaccinated, and I do not have a Company issued COVID-19 Vaccination Exemption Letter.",
                                    },
                                },
                                value: "PHS06V",
                            },
                        ],
                        required: true,
                        validValues: ["PHS04V", "PHS05V"],
                        defaultValue: "",
                        invalidMessage: {
                            values: {
                                en: "You must confirm in order to continue.",
                            },
                        },
                        missingMessage: {
                            values: {
                                en: "Please select a value.",
                            },
                        },
                        convertToBoolean: true,
                    },
                ],
                bodyText: {
                    values: {
                        en:
                            "<p>Non vaccinated passengers must present a Certificate of Travel, this statement shall be presented to the transport companies before boarding and to the border control authorities. </p><p>This form is available at <a href='https://www.interieur.gouv.fr/Actualites/L-actu-du-Ministere/Certificate-of-international-travel'>here</a></p><p> Please confirm below that you have a printed copy of this form, that it is accurate and also signed.</p>",
                    },
                },
            },
        },
        hidden: false,
    },
    {
        name: "COVID19_VACCINE",
        notBeforeDtm: "2021-12-17T00:00:00.000+0000",
        notAfterDtm: "2022-01-15T00:00:00.000+0000",
        available: true,
        expired: false,
        optional: false,
        complete: true,
        displayName: "COVID-19 Vaccination Review",
        displayDescription: "Click to submit your vaccination certificate for review.",
        displayInstructions: "Provide your vaccination certificate for review",
        view: {
            type: "INTERNAL_BOOTSTRAP",
            reference: "covidVaccineView",
            view: "/addcovidvaccinationreview",
            parameters: {
                status: {
                    values: {
                        en: {
                            label: "Your COVID-19 Vaccination Review",
                        },
                        es: {
                            label: "Su revisión de vacunación contra COVID-19",
                        },
                        ja: {
                            label: "あなたのCOVID-19ワクチン接種レビュー",
                        },
                    },
                },
                options: [
                    {
                        view: "/adddigitalcertificate",
                        label: {
                            values: {
                                en:
                                    "Do you have a Digital Vaccine Certificate?<br/><br/>Select this option for what to do.",
                            },
                        },
                        linkText: {
                            values: {
                                en: "Digital Vaccine Certificate",
                            },
                        },
                        uploadText: {
                            values: {
                                en:
                                    "Select Browse to locate your Digital Vaccine Certificate, select the document or image then submit for validation.",
                            },
                        },
                        displayName: {
                            values: {
                                en: "Digital Vaccine Certificate",
                            },
                        },
                        uploadTitle: {
                            values: {
                                en: "Digital Vaccine Certificate",
                            },
                        },
                        uploadButtonText: {
                            values: {
                                en: "Upload",
                            },
                        },
                        additionalInfoLink: {
                            url: "/supporteddigitalcerts",
                            text: {
                                values: {
                                    en: "Supported Digital Certificates",
                                },
                            },
                        },
                        qrCodeInstructions: {
                            values: {
                                en:
                                    "Use the **Scan QR code** button below if:<br>- The certificate in printed format<br/>- The certificate is stored on another device<br/>In both cases the QR code needs to be visible.",
                            },
                        },
                        uploadInstructions: {
                            values: {
                                en:
                                    "Use the **Upload** button below if:<br/>- The certificate is stored locally on this device.<br>- The certificate is stored within another application on this device, if the certificate cannot be exported, take a screenshot of the certificate and upload the image. <br/>Please make sure that the certificate QR code is visible.<br/>_Follow instructions from your device provider on how to take a screenshot_",
                            },
                        },
                        displayInstructions: {
                            values: {
                                en:
                                    "<p>Please choose from the options below to validate your Digital Vaccine Certificate.</p>",
                            },
                        },
                    },
                    {
                        view: "/addvaccinationdoses/cap:carnival",
                        label: {
                            values: {
                                en:
                                    "Does your vaccination card or certificate not contain a QR code? <br/>Is the digital vaccine certificate one that we do not currently support?<br/><br/> Please upload it for a manual review.<br/> <br/>We will review it promptly and let you know the result, click the button below to begin the process.",
                            },
                        },
                        linkText: {
                            values: {
                                en: "Manual Review",
                                ja: "検査結果のマニュアル登録",
                            },
                        },
                        displayName: {
                            values: {
                                en: "Manual Review",
                                ja: "検査結果のマニュアル登録",
                            },
                        },
                    },
                ],
                bodyText: {
                    values: {
                        en:
                            "Please complete the details below and upload an image of your vaccination certificate for validation.</p>",
                        es:
                            "Complete los detalles a continuación y cargue una imagen de su certificado de vacunación para su validación.</p>",
                        ja: "以下の詳細を記入し、確認のためにワクチン接種証明書の画像をアップロードしてください。",
                    },
                },
                vaccineType: {
                    values: {
                        en: {
                            label: "Vaccine Type",
                            placeholder: "Select Vaccine Type",
                            missingMessage: "Please select a vaccine type.",
                        },
                        es: {
                            label: "Tipo de vacuna",
                            placeholder: "Seleccione el tipo de vacuna",
                            missingMessage: "Seleccione un tipo de vacuna.",
                        },
                        ja: {
                            label: "ワクチンの種類",
                            placeholder: "ワクチンの種類を選択する",
                            missingMessage: "ワクチンの種類を選択してください",
                        },
                    },
                },
                vaccineDoses: [
                    {
                        values: {
                            en: {
                                name: "First dose",
                                dateLabel: "First dose date",
                            },
                            es: {
                                name: "First dose",
                                dateLabel: "First dose date",
                            },
                            ja: {
                                name: "First dose",
                                dateLabel: "First dose date",
                            },
                        },
                    },
                    {
                        values: {
                            en: {
                                name: "Second dose",
                                dateLabel: "Second dose date",
                            },
                            es: {
                                name: "Second dose",
                                dateLabel: "Second dose date",
                            },
                            ja: {
                                name: "Second dose",
                                dateLabel: "Second dose date",
                            },
                        },
                    },
                    {
                        values: {
                            en: {
                                name: "Booster",
                                dateLabel: "Booster date",
                                switchLabel: "I've received a booster vaccine",
                            },
                            es: {
                                name: "Booster",
                                dateLabel: "Booster date",
                                switchLabel: "I've received a booster vaccine",
                            },
                            ja: {
                                name: "Booster",
                                dateLabel: "Booster date",
                                switchLabel: "I've received a booster vaccine",
                            },
                        },
                        useSwitch: true,
                    },
                ],
                vaccineTypes: [
                    {
                        name: "Pfizer-BioNTech (Comirnaty)",
                        numDoses: 2,
                        fullyVaccinatedAfterDays: 14,
                    },
                    {
                        name: "Moderna/Spikevax (Moderna)",
                        numDoses: 2,
                        fullyVaccinatedAfterDays: 14,
                    },
                    {
                        name: "Johnson & Johnson (Janssen)",
                        numDoses: 1,
                        fullyVaccinatedAfterDays: 14,
                    },
                    {
                        name: "AstraZeneca/Oxford (Covishield, Vaxzevria)",
                        numDoses: 2,
                        fullyVaccinatedAfterDays: 14,
                    },
                    {
                        name: "Sinopharm (Hayat-Vax)",
                        numDoses: 2,
                        fullyVaccinatedAfterDays: 14,
                    },
                    {
                        name: "Sinovac",
                        numDoses: 2,
                        fullyVaccinatedAfterDays: 14,
                    },
                ],
                vaccineCardInfo: {
                    values: {
                        en: {
                            label: "This information will be reviewed to ensure its validity",
                        },
                        es: {
                            label: "Esta información será revisada para asegurar su validez.",
                        },
                        ja: {
                            label: "この情報は、その有効性を確認するためにレビューされます",
                        },
                    },
                },
                submittedMessage: {
                    values: {
                        en: {
                            label:
                                "Thank you, your vaccination results have been submitted for review. We will reply to you once the review is complete",
                        },
                        es: {
                            label:
                                "Thank you, your vaccination results have been submitted for review. We will reply to you once the review is complete.",
                        },
                        ja: {
                            label:
                                "ありがとうございます。予防接種の結果は審査のために提出されました。レビューが完了したら返信します",
                        },
                    },
                },
                fullyVaccinatedInfo: {
                    values: {
                        en: {
                            label:
                                "<small>Note:<br />You are considered fully vaccinated:  <br />- 14 days after a second dose in a 2-dose series, such as the Pfizer, AstraZeneca, or Moderna/Spikevax vaccines, or <br />- 22 days after a single-dose vaccine, such as Johnson & Johnson's Janssen vaccine<p>If you don't meet these requirements, you are <strong>NOT</strong> fully vaccinated</p></small>",
                        },
                        es: {
                            label:
                                "<small>Se le considera completamente vacunado: <br />- 14 días después de su segunda dosis en una serie de 2 dosis, como las vacunas Pfizer, AstraZeneca, o Moderna/Spikevax vaccines, o  <br />- 22 días después una vacuna de dosis única, como la vacuna Janssen de Johnson & Johnson <p> Si no cumple con estos requisitos, NO está completamente vacunado</p></small>",
                        },
                        ja: {
                            label:
                                "<small>あなたは完全にワクチン接種されていると見なされます:   <br />- 次の2回投与シリーズの2回目の投与から14日後 (Pfizer, AstraZeneca, or Moderna/Spikevax vaccines, または  <br />- 次のような単回ワクチンの22日後 Johnson & Johnson's Janssen vaccine<p>これらの要件を満たしていない場合は、完全に予防接種を受けていません</p></small>",
                        },
                    },
                },
                vaccineAdministered: {
                    values: {
                        en: {
                            label: "Date of Final Vaccine Administered",
                            missingMessage: "Please enter a valid date",
                            outOfRangeMessage: "You must be fully vaccinated {days} days before your trip",
                            dateInFutureMessage: "The Date of Final Vaccine Administered cannot be in the future",
                        },
                        es: {
                            label: "Fecha de la última vacuna administrada",
                            missingMessage: "Por favor introduzca una fecha valida.",
                            outOfRangeMessage:
                                "La vacunación final debe administrarse al menos {days} días antes de la salida.",
                            dateInFutureMessage: "La fecha de la última vacuna administrada no puede ser futura",
                        },
                        ja: {
                            label: "最初のワクチン投与日",
                            missingMessage: "有効な日付を入力してください",
                            outOfRangeMessage: "最初の投与日は最後の投与日より前でなければなりません",
                            dateInFutureMessage: "最終ワクチンの投与日を将来にすることはできません",
                        },
                    },
                },
                firstDoseAdministered: {
                    values: {
                        en: {
                            label: "Date of First Vaccine Administered",
                            missingMessage: "Please enter a valid date",
                            outOfRangeMessage: "First dose date must be before last dose date",
                            dateInFutureMessage: "The Date of First Vaccine Administered cannot be in the future",
                        },
                        es: {
                            label: "Fecha de la primera vacuna administrada",
                            missingMessage: "Por favor introduzca una fecha valida.",
                            outOfRangeMessage:
                                "La fecha de la primera dosis debe ser anterior a la fecha de la última dosis",
                            dateInFutureMessage: "La fecha de la primera vacuna administrada no puede ser futura",
                        },
                        ja: {
                            label: "最初のワクチン投与日",
                            missingMessage: "有効な日付を入力してください",
                            outOfRangeMessage: "最初の投与日は最後の投与日より前でなければなりません",
                            dateInFutureMessage: "最初のワクチン接種日は将来的にはできません",
                        },
                    },
                },
                useVaccinationCountry: false,
                acceptedVaccineCountries: [
                    {
                        values: {
                            en: "UK - NHS",
                        },
                        countryCode: "GB",
                        displayDateOfBirth: true,
                    },
                    {
                        values: {
                            en: "EU Digital COVID Certificate (EUDCC)",
                        },
                        countryCode: "EU",
                        displayDateOfBirth: true,
                    },
                    {
                        values: {
                            en: "United States CDC/FDA approved",
                        },
                        countryCode: "US",
                        displayDateOfBirth: true,
                    },
                ],
                supportedDigitalCertsInfo: {
                    displayName: {
                        values: {
                            en: "Supported Digital Certificates",
                        },
                    },
                    displayInstructions: {
                        values: {
                            en:
                                "The following is a list of all the Digital Vaccine Certificates that we currently support. We apologize for the inconvenience if your Digital Vaccine Certificate is not currently supported.  We are actively working to add more vaccine providers to our network each week.",
                        },
                    },
                    supportedDigitalCerts: [
                        {
                            name: "UK - NHS",
                        },
                        {
                            name: "EU Digital COVID Certificate (EUDCC)",
                        },
                        {
                            name: "SMART Health Cards (CVS, NYS Excelsior, US States - California and Louisiana)",
                        },
                    ],
                },
                countryAdministeredVaccine: {
                    values: {
                        en: {
                            label: "Country which Administered Vaccine",
                            placeholder: "Select Country",
                            missingMessage: "Please select country of vaccination",
                        },
                        es: {
                            label: "País que administró la vacuna",
                            placeholder: "Seleccionar país",
                            missingMessage: "Seleccione el país de vacunación",
                        },
                        ja: {
                            label: "ワクチンを投与した国",
                            placeholder: "Select Country",
                            missingMessage: "予防接種の国を選択してください",
                        },
                    },
                },
                vaccineCheckboxConfirmation: {
                    values: {
                        en: {
                            label: "I confirm that I am fully vaccinated",
                        },
                        es: {
                            label: "Confirmo que estoy completamente vacunada",
                        },
                        ja: {
                            label: "完全にワクチン接種されていることを確認します",
                        },
                    },
                },
            },
        },
        jsonLogicExpression:
            '{"some":[{"var":"credentials"},{"and":[{"==":[{"var":"subject"},"VN7B4GP3ZTP57JAJJFKVHQ6TQI"]},{"==":[{"var":"type"},"https://glide-ci-develop-glide.identityx-build.com/api/credentialProviders/LKXLQJYGVIKFEH22AH253ESPJI/credentialTypes/Z7K2SUQKLDHWBKLX7P6IIQX7YQ"]},{"or":[{"and":[{"==":[{"var":"claims.vaccineType"},"Moderna/Spikevax"]},{"<=":[{"var":"notBeforeDtm"},1641081600]}]},{"and":[{"==":[{"var":"claims.vaccineType"},"Pfizer-BioNtech"]},{"<=":[{"var":"notBeforeDtm"},1641081600]}]},{"and":[{"==":[{"var":"claims.vaccineType"},"Johnson & Johnson/Janssen"]},{"<=":[{"var":"notBeforeDtm"},1641081600]}]},{"and":[{"==":[{"var":"claims.vaccineType"},"Oxford-AstraZeneca"]},{"<=":[{"var":"notBeforeDtm"},1641081600]}]}]}]}]}',
        hidden: false,
    },
    {
        name: "COVID_TEST",
        notBeforeDtm: "2022-01-13T00:00:00.000+0000",
        notAfterDtm: "2022-01-16T00:00:00.000+0000",
        available: true,
        expired: false,
        optional: false,
        complete: true,
        displayName: "COVID-19 Test Review",
        displayDescription: "Provide the results of your test for review",
        displayInstructions:
            "Answer the questions about your COVID-19 test in the online form. All the information required on this form is found on your COVID-19 test.\nTake a photo of your exam results and upload the image or PDF.\n\nVeriFLY only supports the passenger attestation option for individuals who have received a negative pre-departure test result for COVID-19 and that the test was a viral test that was conducted on a specimen collected during the 3 calendar days preceding the event.\n\nPassengers opting for one of the other three attestation options will need to print and complete the disclosure and provide them during the check-in process at the airport.\n\nWe apologize for this inconvenience and are actively working to incorporate all options.\n\n_Please make sure the image is legible._",
        view: {
            type: "INTERNAL_BOOTSTRAP",
            reference: "covidTestView",
            view: "/addcovidreview",
            parameters: {
                options: [
                    {
                        view: "/addtestcenterqrcode",
                        label: {
                            values: {
                                en:
                                    "VeriFLY has partnered with Test Providers that will issue your test results directly to VeriFLY.",
                            },
                        },
                        linkText: {
                            values: {
                                en: "Scan QR",
                            },
                        },
                        displayName: {
                            values: {
                                en: "Partner Test Center",
                            },
                        },
                        additionalInfoLink: {
                            url: "/partnertestcenters",
                            text: {
                                values: {
                                    en: "VIEW PARTNER TEST PROVIDERS",
                                    ja: "連携対象の医療機関を見る",
                                },
                            },
                        },
                        displayInstructions: {
                            values: {
                                en: "Instructions for the partner test center flow",
                            },
                        },
                    },
                    {
                        view: "/addcovidtestresults/cap:events",
                        label: {
                            values: {
                                en:
                                    "If you already have your test result, you can upload it for review. Please click the button below to begin the manual upload process.",
                            },
                        },
                        linkText: {
                            values: {
                                en: "Manual Upload",
                                ja: "写真をアップロードする",
                            },
                        },
                        displayName: {
                            values: {
                                en: "Manual Review",
                                ja: "検査結果のマニュアル登録",
                            },
                        },
                    },
                ],
                covidTestTypes: [
                    {
                        name: "PCR",
                        testWithinDays: 3,
                    },
                    {
                        name: "ANTIGEN",
                        testWithinDays: 3,
                    },
                    {
                        name: "NAAT",
                        testWithinDays: 3,
                    },
                    {
                        name: "LAMP",
                        testWithinDays: 3,
                    },
                    {
                        name: "TMA",
                        testWithinDays: 3,
                    },
                ],
                partnerTestCentersInfo: {
                    displayName: {
                        values: {
                            en: "PARTNER TEST PROVIDERS",
                        },
                    },
                    partnerTestCenters: [
                        {
                            name: "Collinson Group",
                        },
                        {
                            name: "Cerballiance",
                        },
                    ],
                    displayInstructions: {
                        values: {
                            en: "The following is a list of the available testing partners.",
                        },
                    },
                },
            },
        },
        hidden: false,
    },
    {
        name: "VACCINATION_QUESTIONNAIRE",
        notBeforeDtm: "2022-01-11T00:00:00.000+0000",
        notAfterDtm: "2022-01-16T00:00:00.000+0000",
        available: false,
        expired: false,
        optional: false,
        complete: true,
        displayName: "Pre-Embarkation Questionnaire",
        displayDescription: "Complete the Pre-Embarkation Questionnaire",
        displayInstructions: "Complete the Pre-Embarkation Questionnaire",
        view: {
            type: "INTERNAL_BOOTSTRAP",
            reference: "vaccinationQuestionnaireView",
            view: "/addquestionnaire",
            parameters: {
                type: "HQ0012",
                inputs: [
                    {
                        name: "PHS51Q",
                        type: "toggle",
                        label: {
                            values: {
                                en:
                                    "1A. In the 14 days before boarding, have you had any one or more of the following symptoms: cough, shortness of breath, difficulty breathing, fevers, chills, new loss of taste, new loss of smell?",
                            },
                        },
                        required: true,
                        validValues: ["no"],
                        defaultValue: "",
                        invalidMessage: {
                            values: {
                                en: "You must confirm in order to continue.",
                            },
                        },
                        missingMessage: {
                            values: {
                                en: "Please select a value.",
                            },
                        },
                        convertToBoolean: true,
                    },
                    {
                        name: "PHS52Q",
                        type: "toggle",
                        label: {
                            values: {
                                en:
                                    "1B. In the 14 days before boarding, have you had any two or more of the following symptoms: congested or runny nose, sore throat, muscle or body aches, extreme tiredness, headache, vomitting, diarrhea?",
                            },
                        },
                        required: true,
                        validValues: ["no"],
                        defaultValue: "",
                        invalidMessage: {
                            values: {
                                en: "You must confirm in order to continue.",
                            },
                        },
                        missingMessage: {
                            values: {
                                en: "Please select a value.",
                            },
                        },
                        convertToBoolean: true,
                    },
                    {
                        name: "PHS53Q",
                        type: "toggle",
                        label: {
                            values: {
                                en: "2. In the 10 days before boarding, have you tested positive for COVID-19?",
                            },
                        },
                        required: true,
                        validValues: ["no"],
                        defaultValue: "",
                        invalidMessage: {
                            values: {
                                en: "You must confirm in order to continue.",
                            },
                        },
                        missingMessage: {
                            values: {
                                en: "Please select a value.",
                            },
                        },
                        convertToBoolean: true,
                    },
                    {
                        name: "PHS54Q",
                        type: "toggle",
                        label: {
                            values: {
                                en:
                                    "3. In the 14 days before boarding, were you in contact with a person who had confirmed COVID-19 or COVID-19",
                            },
                        },
                        required: true,
                        validValues: ["no"],
                        defaultValue: "",
                        invalidMessage: {
                            values: {
                                en: "You must confirm in order to continue.",
                            },
                        },
                        missingMessage: {
                            values: {
                                en: "Please select a value.",
                            },
                        },
                        convertToBoolean: true,
                    },
                ],
                bodyText: {
                    values: {
                        en:
                            "<p>Non vaccinated passengers must present a Certificate of Travel, this statement shall be presented to the transport companies before boarding and to the border control authorities. </p><p>This form is available at <a href='https://www.interieur.gouv.fr/Actualites/L-actu-du-Ministere/Certificate-of-international-travel'>here</a></p><p> Please confirm below that you have a printed copy of this form, that it is accurate and also signed.</p>",
                    },
                },
            },
        },
        hidden: false,
    },
    {
        name: "VISA",
        notBeforeDtm: "2022-02-11T12:11:22.476+0000",
        notAfterDtm: "2022-02-11T14:11:22.476+0000",
        available: true,
        expired: false,
        optional: false,
        complete: true,
        displayName: "Proof of Visa",
        displayDescription: "You are required to have Visa related documentation",
        displayInstructions:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        view: {
            type: "INTERNAL_BOOTSTRAP",
            reference: "visaView",
            view: "/addvisa",
            parameters: {
                items: [
                    {
                        name: "itemName",
                        label: {
                            values: {
                                en: "Label of the additional item",
                            },
                        },
                    },
                    {
                        name: "itemName2",
                        label: {
                            values: {
                                en: "Label of the additional item 2",
                            },
                        },
                    },
                ],
            },
        },
    },
    {
        name: "VISA",
        notBeforeDtm: "2022-02-11T12:11:22.476+0000",
        notAfterDtm: "2022-02-11T14:11:22.476+0000",
        available: true,
        expired: false,
        optional: false,
        complete: true,
        displayName: "Proof of Visa",
        displayDescription: "You are required to have Visa related documentation",
        displayInstructions:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        view: {
            type: "INTERNAL_BOOTSTRAP",
            reference: "visaView",
            view: "/addvisa",
            parameters: {
                options: [
                    {
                        available: true,
                        complete: false,
                        type: "passports",
                        view: "/addpassportdetails",
                        displayName: {
                            values: {
                                en: "Provide passport details",
                            },
                        },
                        displayInstructions: {
                            values: {
                                en: "Lorem ipsum dolor sit amet....",
                            },
                        },
                    },
                    {
                        available: true,
                        complete: false,
                        view: "/addvisadetails",
                        type: "visas",
                        displayName: {
                            values: {
                                en: "Provide Visa details",
                            },
                        },
                        displayInstructions: {
                            values: {
                                en: "Lorem ipsum dolor sit amet....",
                            },
                        },
                    },
                ],
            },
        },
        hidden: false,
    },
];

const FlightResponseInterline = (carrierCode = "AA", bookingNumber) => {
    return {
        id: "VTKBGBU662YFEDL56JK72RMZ6M",
        personId: "VN7B4GP3ZTP57JAJJFKVHQ6TQI",
        status: "ACTIVE",
        createdDtm: "2022-01-11T09:25:10.221+0000",
        lastUpdatedDtm: "2022-01-11T09:25:37.670+0000",
        expiredDtm: "2022-01-17T00:00:00.000+0000",
        instructions: {
            allowCompanions: true,
            maxCompanions: 9,
            userProcesses: {
                "fb0:fs0:6IHQ55CULDJ247MQAFOT7SRACU": {
                    steps: steps,
                },
                "fb0:fs1:6IHQ55CULDJ247MQAFOT7SRACU": {
                    steps: steps,
                },
                "fb0:fs2:6IHQ55CULDJ247MQAFOT7SRACU": {
                    steps: steps,
                },
                "fb1:fs0:6IHQ55CULDJ247MQAFOT7SRACU": {
                    steps: steps,
                },
                "fb1:fs1:6IHQ55CULDJ247MQAFOT7SRACU": {
                    steps: steps,
                },
                "fb1:fs2:6IHQ55CULDJ247MQAFOT7SRACU": {
                    steps: steps,
                },
            },
        },
        userData: {
            sharedData: {
                interlineFlightDetails: { ...flightBlock(carrierCode, bookingNumber) },
            },
            userSpecificData: {
                "6IHQ55CULDJ247MQAFOT7SRACU": {
                    firstName: "JAN",
                    lastName: "ADAM",
                    dateOfBirth: "2015-05-21",
                    nationality: "US",
                    payments: [],
                    paymentRequests: [],
                    personWellnessId: "502f054e-75cd-48f9-86fa-3ba5c458f6d3",
                    userSequenceNumber: "4",
                    userPatientId: "4GQFF5-4",
                    flightSpecificData: {
                        "fb0:fs0": {
                            countryEntryStatus: "TRANSITING",
                            covidTests: [],
                            covidVaccines: [],
                            healthPasses: [],
                            llcVaccines: [],
                            questionnaires: [],
                            questionnaireResponses: {},
                            travelAuthorizationRequests: [],
                            travelAuthorizations: [],
                        },
                        "fb0:fs1": {
                            countryEntryStatus: "ENTERING",
                            covidTests: [],
                            covidVaccines: [],
                            healthPasses: [],
                            llcVaccines: [],
                            questionnaires: [],
                            questionnaireResponses: {},
                            travelAuthorizationRequests: [],
                            travelAuthorizations: [],
                        },
                        "fb0:fs2": {
                            countryEntryStatus: "ENTERING",
                            covidTests: [],
                            covidVaccines: [],
                            healthPasses: [],
                            llcVaccines: [],
                            questionnaires: [],
                            questionnaireResponses: {},
                            travelAuthorizationRequests: [],
                            travelAuthorizations: [],
                        },
                        "fb1:fs0": {
                            countryEntryStatus: "TRANSITING",
                            covidTests: [],
                            covidVaccines: [],
                            healthPasses: [],
                            llcVaccines: [],
                            questionnaires: [],
                            questionnaireResponses: {},
                            travelAuthorizationRequests: [],
                            travelAuthorizations: [],
                        },
                        "fb1:fs1": {
                            countryEntryStatus: "ENTERING",
                            covidTests: [],
                            covidVaccines: [],
                            healthPasses: [],
                            llcVaccines: [],
                            questionnaires: [],
                            questionnaireResponses: {},
                            travelAuthorizationRequests: [],
                            travelAuthorizations: [],
                        },
                        "fb1:fs2": {
                            countryEntryStatus: "ENTERING",
                            covidTests: [],
                            covidVaccines: [],
                            healthPasses: [],
                            llcVaccines: [],
                            questionnaires: [],
                            questionnaireResponses: {},
                            travelAuthorizationRequests: [],
                            travelAuthorizations: [],
                        },
                    },
                },
            },
        },
    };
};

export const flightSpecificData = {
    countryEntryStatus: "ENTERING",
    covidTests: [],
    covidVaccines: [],
    healthPasses: [],
    llcVaccines: [],
    questionnaires: [],
    questionnaireResponses: {},
    travelAuthorizationRequests: [],
    travelAuthorizations: [],
    visaRequirements: {
        "checkRequired": true,
        // checkRequired: false,
        // "status": "TRUE",
        status: "CONDITIONAL",
        additionalInfo: {
            healthEntry: [
                {
                    header: "Entry Restrictions",
                    content:
                        "<ul><li>There are no COVID-19 related entry restrictions at this time. However, travellers should hold the relevant travel documents to enter the country</li></ul>",
                },
                {
                    header: "Entry & Health Requirements",
                    content:
                        "<ul><li>There is no vaccination requirement at this time.</li><li>There is no quarantine requirement at this time.</li><li>There is no testing requirement at this time.</li><li>All travellers must present a proof of medical or travel insurance with COVID-19 coverage and with a minimum coverage of 10,000 USD</li></ul>",
                },
                {
                    header: "In-country Experience",
                    content:
                        "<ul><li>Face masks are mandatory in public spaces.</li><li>Most offices, factories, restaurants, cafes and stores are open with social distancing measures in place.</li><li>A COVID-Pass is required for some domestic activities.</li></ul>",
                },
                {
                    header: "Transit Restrictions",
                    content:
                        "<ul><li>There are no COVID-19 related transit restrictions at this time. However, travellers should hold the relevant travel documents for their transit and final destinations</li></ul>",
                },
                {
                    header: "Re-Entry Requirements",
                    content:
                        '<ul><li>All travellers, aged 2 years and older, returning to the United States by air must provide a Passenger Disclosure and Attestation form. This form will be provided to them by their airline at check-in. </li><li>All air passengers returning to the United States from China, Hong Kong or Macau must hold a negative test. Accepted tests are PCR tests or antigen tests issued no more than 2 days before departure from China, Hong Kong or Macau</li><li>Other re-entry requirements for travellers returning to the United States vary between states. For up-to-date public health measures, check the state or local health department of your planned destination. This information can be found <a href="https://www.usa.gov/state-health" target="blank">here</a></li></ul>',
                },
            ],
        },
    },
    /* externalDocumentStatusChecks: {
        brazilianEVisa: {
            id: "kjshdfkjsh",
            inputs: {
                referenceNumber: "123456",
            },
            outputs: {
                visaNumber: "slkdjflksdj",
                issueDate: "01 April 2023",
                expiryDate: "01 April 2024",
                dateOfBirth: "22 April 1978",
                visaType: "TOURIST",
                passportNumber: "LT0043533",
            },
            status: "PENDING | COMPLETE",
        },
    }, */
    passports: [
        // {
        //   "id": "PMZJ3JZ63K6YT2SZXVJ3TSBONQ",
        //   "country": "USA",
        //   "expiryDate": "2043-09-09",
        //   "number": "1233456",
        //   "s3Objects": [{}],
        //   "submitted": "2022-06-30T13:36:02.525253Z",
        //   "new": false,
        //    "review": {
        //     "completed": "2022-06-30T13:45:43.896709Z",
        //     "status": "REJECTED",
        //     "note": {
        //       "code": 0,
        //       "message": "Your test results have been rejected because...  "
        //     },
        //     "camundaProcessInstanceId": "ae6ea1fb-f879-11ec-9c95-b226b8c9f44c",
        //     "s3ReviewSummaryLocation": "review_summaries_v2/2022-06-30_13",
        //     "passportId": "PMZJ3JZ63K6YT2SZXVJ3TSBONQ"
        //   }
        // }
    ],
    visas: [
        //  {
        //  "id": "LYDAH6TV6Q3FIQ723DSQRKWG34",
        //  "s3Objects": [{}],
        //  "purpose": "TOURIST",
        //  "issuanceCountry": "Usa",
        //  "type": "TOURIST",
        //  "number": "23554f",
        //  "startDate": "2015-08-08",
        //  "endDate": "2044-09-09",
        //  "submitted": "2022-06-30T13:36:44.126485Z",
        //  "review": {
        //    "completed": "2022-06-30T13:45:43.896709Z",
        //    "status": "REJECTED",
        //    "note": {
        //      "code": 0,
        //      "message": "Your test results have been rejected because...  "
        //    },
        //    "camundaProcessInstanceId": "ae6ea1fb-f879-11ec-9c95-b226b8c9f44c",
        //    "s3ReviewSummaryLocation": "review_summaries_v2/2022-06-30_13",
        //    "passportId": "PMZJ3JZ63K6YT2SZXVJ3TSBONQ"
        //  }
        // }
    ],
    submittedDocuments: {
        /* passport: {
            submissionStatus: "REJECTED",
            submissions: [
                {
                    id: "LYDAH6TV6Q3FIQ723DSQRKWG34",
                    stepName: "PROOF_OF_PASSPORT",
                    s3Objects: [
                        {
                            extension: "jpeg|pdf|png",
                            key: "5aeacda3-6286-415a-af0c-b414aa68cd00",
                        },
                    ],
                    captureInfo: {
                        timeTakenMillis: 4357,
                        numberOfTries: 1,
                    },
                    formData: {},
                    fieldsToMatch: [],
                    submitted: "2022-05-22T07:34:35.993816Z",
                    review: {
                        completed: "2022-05-22T07:39:22.760282Z",
                        status: "REJECTED",
                        note: {
                            code: 10000,
                            message: "Your <b>document</b><br> submissions are invalid because...",
                        },
                        relatedDocIds: ["{corresponding marriage license ID}"],
                        camundaProcessInstanceId: "...",
                        s3ReviewSummaryLocation: "...",
                    },
                },
            ],
        }, */
        /* visa: {
            submissionStatus: "REJECTED",
            submissions: [
                {
                    id: "LYDAH6TV6Q3FIQ723DSQRKWG34",
                    stepName: "VISA",
                    s3Objects: [
                        {
                            extension: "jpeg|pdf|png",
                            key: "5aeacda3-6286-415a-af0c-b414aa68cd00",
                        },
                    ],
                    captureInfo: {
                        timeTakenMillis: 4357,
                        numberOfTries: 1,
                    },
                    formData: {},
                    fieldsToMatch: [],
                    submitted: "2022-05-21T07:34:35.993816Z",
                    review: {
                        completed: "2022-05-22T07:39:22.760282Z",
                        status: "REJECTED",
                        note: {
                            code: 10006,
                            message: "Your document <i>submissions</i> are invalid because...",
                        },
                        relatedDocIds: ["{corresponding marriage license ID}"],
                        camundaProcessInstanceId: "...",
                        s3ReviewSummaryLocation: "...",
                    },
                },
                // {
                //     id: "{set by CAP}",
                //     stepName: "SUBMIT_BRAZILIAN_EVISA_CHECK",
                //     submitted: "2023-04-22T07:34:35.993816Z",
                //     docValidationData: {
                //         documentMetadata: {
                //             subType: "brazilian eVisa",
                //             type: "visa",
                //             certifyingAuthority: "Brazil",
                //         },
                //     },
                //     externalDocumentCheckInputs: {
                //         referenceNumber: "123456",
                //     },
                // },
                // {
                //     id: "{set by CAP}",
                //     stepName: "SUBMIT_BRAZILIAN_EVISA_CHECK",
                //     submitted: "2023-05-22T07:34:35.993816Z",
                //     review: {
                //         status: "ACCEPTED",
                //     },
                //     docValidationData: {
                //         documentMetadata: {
                //             subType: "brazilian eVisa",
                //             type: "visa",
                //             certifyingAuthority: "Brazil",
                //         },
                //         documentFields: [
                //             {
                //                 name: "Date of Birth",
                //                 value: "1973-07-13",
                //                 type: "Date",
                //             },
                //             {
                //                 name: "Issue Date",
                //                 value: "2023-09-27",
                //                 type: "Date",
                //             },
                //             {
                //                 name: "Expiry Date",
                //                 value: "2024-09-27",
                //                 type: "Date",
                //             },
                //             {
                //                 name: "Visa Number",
                //                 value: "GF161616",
                //                 type: "String",
                //             },
                //             {
                //                 name: "Visa Type",
                //                 value: "TOURIST",
                //                 type: "String",
                //             },
                //             {
                //                 name: "Passport Number",
                //                 value: "PU1233456",
                //                 type: "String",
                //             },
                //         ],
                //     },
                //     externalDocumentCheckInputs: {
                //         referenceNumber: "123456",
                //     },
                // },
            ],
        }, */
        /* photoIdentification: {
          submissionStatus: "PENDING",
          submissions: [
              {
                  id: "LYDAH6TV6Q3FIQ723DSQRKWG34",
                  stepName: "PHOTO_IDENTIFICATION",
                  s3Objects: [
                      {
                          extension: "jpeg|pdf|png",
                          key: "5aeacda3-6286-415a-af0c-b414aa68cd00",
                      },
                  ],
                  captureInfo: {
                      timeTakenMillis: 4357,
                      numberOfTries: 1,
                  },
                  formData: {
                      expiryDate: "2027-06-17",
                  },
                  fieldsToMatch: [
                      {
                          name: "First Name",
                          type: "String",
                          value: "John",
                      },
                      {
                          name: "Last Name",
                          type: "String",
                          value: "Doesn't",
                      },
                      {
                          name: "Date Of Birth",
                          type: "Date",
                          value: "1979-08-14",
                      },
                  ],
                  submitted: "2022-05-22T07:34:35.993816Z",
                  review: {
                      completed: "2022-05-22T07:39:22.760282Z",
                      status: "PENDING",
                      note: {
                          code: 0,
                          message: "Your document submissions are invalid because...",
                      },
                      relatedDocIds: ["{corresponding marriage license ID}"],
                      camundaProcessInstanceId: "...",
                      s3ReviewSummaryLocation: "...",
                  },
              },
          ],
      }, */
        /* birthCertificate: {
          submissionStatus: "PENDING",
          submissions: [
            {
                id: "LYDAH6TV6Q3FIQ723DSQRKWG34",
                stepName: "BIRTH_CERTIFICATE",
                s3Objects: [
                    {
                        extension: "jpeg|pdf|png",
                        key: "5aeacda3-6286-415a-af0c-b414aa68cd00",
                    },
                    {
                     extension: "jpeg|pdf|png",
                     key: "5aeacda3-6286-415a-af0c-b414aa68cd00",
                 },
                ],
                captureInfo: {
                    timeTakenMillis: 4357,
                    numberOfTries: 1,
                },
                formData: {
                    expiryDate: "2027-06-17",
                },
                fieldsToMatch: [
                    {
                        name: "First Name",
                        type: "String",
                        value: "John",
                    },
                    {
                        name: "Last Name",
                        type: "String",
                        value: "Doesn't",
                    },
                    {
                        name: "Date Of Birth",
                        type: "Date",
                        value: "1979-08-14",
                    },
                ],
                submitted: "2022-05-22T07:34:35.993816Z",
                review: {
                    completed: "2022-05-22T07:39:22.760282Z",
                    status: "PENDING",
                    note: {
                        code: 0,
                        message: "Your document submissions are INVALID because...",
                    },
                    relatedDocIds: ["{corresponding marriage license ID}"],
                    camundaProcessInstanceId: "...",
                    s3ReviewSummaryLocation: "...",
                },
            }
        ],
      }, */
        /* nameChange: {
         submissionStatus: "REJECTED",
         submissions: [
           {
               id: "LYDAH6TV6Q3FIQ723DSQRKWG34",
               stepName: "BIRTH_CERTIFICATE",
               s3Objects: [
                   {
                       extension: "jpeg|pdf|png",
                       key: "5aeacda3-6286-415a-af0c-b414aa68cd00",
                   },
               ],
               captureInfo: {
                   timeTakenMillis: 4357,
                   numberOfTries: 1,
               },
               formData: {
                   expiryDate: "2027-06-17",
               },
               fieldsToMatch: [
                   {
                       name: "First Name",
                       type: "String",
                       value: "John",
                   },
                   {
                       name: "Last Name",
                       type: "String",
                       value: "Doesn't",
                   },
                   {
                       name: "Date Of Birth",
                       type: "Date",
                       value: "1979-08-14",
                   },
               ],
               submitted: "2022-05-22T07:34:35.993816Z",
               review: {
                   completed: "2022-05-22T07:39:22.760282Z",
                   status: "REJECTED",
                   note: {
                       code: 0,
                       message: "Your document submissions are invalid because...",
                   },
                   relatedDocIds: ["{corresponding marriage license ID}"],
                   camundaProcessInstanceId: "...",
                   s3ReviewSummaryLocation: "...",
               },
           }
       ],
     }, */
        /* genericDocument: {
      submissionStatus: "REJECTED",
         submissions: [
           {
               id: "LYDAH6TV6Q3FIQ723DSQRKWG34",
               stepName: "GENERIC_DOCUMENT",
               s3Objects: [
                   {
                       extension: "jpeg|pdf|png",
                       key: "5aeacda3-6286-415a-af0c-b414aa68cd00",
                   },
               ],
               captureInfo: {
                   timeTakenMillis: 4357,
                   numberOfTries: 1,
               },
               formData: {
                   expiryDate: "2027-06-17",
               },
               fieldsToMatch: [
                   {
                       name: "First Name",
                       type: "String",
                       value: "John",
                   },
                   {
                       name: "Last Name",
                       type: "String",
                       value: "Doesn't",
                   },
                   {
                       name: "Date Of Birth",
                       type: "Date",
                       value: "1979-08-14",
                   },
               ],
               submitted: "2022-05-22T07:34:35.993816Z",
               review: {
                   completed: "2022-05-22T07:39:22.760282Z",
                   status: "REJECTED",
                   note: {
                       code: 0,
                       message: "Your document submissions are invalid because...",
                   },
                   relatedDocIds: ["{corresponding marriage license ID}"],
                   camundaProcessInstanceId: "...",
                   s3ReviewSummaryLocation: "...",
               },
           }
       ],
     } */
    },
    documentSubmissionStatus: "ACCEPTED",
    parameters: {
        something: false
    }
};

export default FlightResponseInterline;
