import React, { useEffect, useRef } from "react";
import { DateTime } from "luxon";
import { Form } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useLocalize } from "../../utils/hooks";
import Required from "../_components/Required";

const FlightDateSelect = ({
    flights,
    flightNumber,
    departureTimeZone,
    onChange,
    errorMessage,
    className = "",
    dateTimeLimit,
}) => {
    const flightDateRef = useRef();

    const intl = useIntl();
    const { localize } = useLocalize();
    const getAirportLocalDate = time => {
        const dateTime = DateTime.fromISO(time);
        const zonedDateTime = dateTime.setZone(departureTimeZone);
        return zonedDateTime.toLocaleString({ ...DateTime.DATE_MED, locale: intl.locale });
    };

    useEffect(() => {
        if (flightDateRef.current) {
            flightDateRef.current.value = "";
        }
    }, [flightNumber]);

    const flightDateOptions = () => {
        if (dateTimeLimit) {
            return flights
                .filter(f => f.airlineCode + (f.flight || f.flightNumber) === flightNumber)
                .map(f => f.departureFlightTime)
                .filter(dFt => {
                    return DateTime.fromISO(dFt) >= DateTime.fromISO(dateTimeLimit);
                });
        } else {
            return flights
                .filter(f => f.airlineCode + (f.flight || f.flightNumber) === flightNumber)
                .map(f => f.departureFlightTime);
        }
    };

    return (
        <Form.Group className={className} controlId="flight-date">
            <Form.Label>{localize("flightDetails.flight.date.label")}</Form.Label>
            <Form.Control
                as="select"
                defaultValue=""
                required
                isInvalid={!!errorMessage}
                disabled={!flightNumber}
                onChange={onChange}
                ref={flightDateRef}
            >
                <option value="" disabled hidden>
                    {localize(
                        flightDateOptions().length === 0 && flightNumber
                            ? "flightDetails.flight.date.noOptionText"
                            : "flightDetails.flight.date.defaultOptionText"
                    )}
                </option>
                {flightDateOptions().map((fDo, index) => (
                    <option key={index} value={fDo}>
                        {getAirportLocalDate(fDo)}
                    </option>
                ))}
            </Form.Control>
            <Required message={errorMessage || localize("flightDetails.flight.date.missingMessage")} />
        </Form.Group>
    );
};

export default FlightDateSelect;
