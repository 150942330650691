import { credentialTypeHref } from "../constants";
import useQuery from "./useQuery";
import { useEffect, useState } from "react";
import { getBaseUrl } from "../textUtils";
import { getReturnJson, postReturnJson } from "../dataFetching";
import { worldCountries } from "../../config/world-countries";
import useLanguage from "./useLanguage";

const useUser = (personId, onError, isMainUserPage = true) => {
    const query = useQuery();
    const { language } = useLanguage();

    const [user, setUser] = useState();
    const [isUserDataBeingFetched, setIsUserDataBeingFetched] = useState(false);
    const [glideBirthDate, setGlideBirthDate] = useState();
    const [glideNationality, setGlideNationality] = useState();
    const [glideNationalityCode, setGlideNationalityCode] = useState();
    const [glidePassportCountry, setGlidePassportCountry] = useState();
    const [glidePassportCountryCode, setGlidePassportCountryCode] = useState();
    const [glideCountryOfResidence, setGlideCountryOfResidence] = useState();
    const [glideCountryOfResidenceCode, setGlideCountryOfResidenceCode] = useState();
    const [glideReferenceNumbers, setGlideReferenceNumbers] = useState([]);

    const getGlideUrl = () => {
        const cth = process.env.NODE_ENV !== "production" ? credentialTypeHref : query.get("credentialTypeHref");
        return `${getBaseUrl(cth)}/api/persons/${personId}`;
    };

    const updateUser = updatedData => {
        const glideUrl = getGlideUrl();
        postReturnJson(glideUrl, updatedData, setUser, onError);
    };

    const checkAndUpdateUser = ({
        firstName,
        lastName,
        birthDate,
        nationality,
        passportCountry,
        countryOfResidence,
        reference,
    }) => {
        const updatedUser = { ...user };
        let shouldUpdateUser = false; 

        updatedUser.extendedAttributes = updatedUser.extendedAttributes || {};
        if (firstName && firstName !== user?.firstName) {
            updatedUser.firstName = firstName;
            shouldUpdateUser = true;
        }
        if (lastName && lastName !== user?.lastName) {
            updatedUser.lastName = lastName;
            shouldUpdateUser = true;
        }
        if (nationality && nationality !== glideNationalityCode) {
            updatedUser.extendedAttributes.nationality = nationality;
            shouldUpdateUser = true;
        }
        if (!countryOfResidence && !glideCountryOfResidenceCode && glidePassportCountryCode) {
            updatedUser.extendedAttributes.countryOfResidence = glidePassportCountryCode;
            shouldUpdateUser = true;
        }
        if (countryOfResidence && countryOfResidence !== glideCountryOfResidenceCode) {
            updatedUser.extendedAttributes.countryOfResidence = countryOfResidence;
            shouldUpdateUser = true;
        }
        if (!passportCountry && !glidePassportCountryCode && glideCountryOfResidenceCode) {
            updatedUser.extendedAttributes.passportCountry = glideCountryOfResidenceCode;
            shouldUpdateUser = true;
        }
        if (passportCountry && passportCountry !== glidePassportCountryCode) {
            updatedUser.extendedAttributes.passportCountry = passportCountry;
            shouldUpdateUser = true;
        };
        if (birthDate) {
            const datePart = birthDate.split("T")[0];
            if (datePart !== glideBirthDate) {
                updatedUser.extendedAttributes.dateOfBirth = datePart;
                shouldUpdateUser = true;
            }
        }
        if (reference && !updatedUser.extendedAttributes?.referenceNumbers?.includes(reference)) {
            if (updatedUser.extendedAttributes?.referenceNumbers){
                updatedUser.extendedAttributes.referenceNumbers.push(reference);
            } else {
                updatedUser.extendedAttributes.referenceNumbers = [reference];
            }
            shouldUpdateUser = true;
        }

        if (shouldUpdateUser) {
            updateUser(updatedUser);
        }
    };

    const addUserSpecificData = values => {
        values.userData = {
            ...values.userData,
            userSpecificData: {
                [personId]: {
                    firstName: user?.firstName,
                    lastName: user?.lastName,
                    dateOfBirth: glideBirthDate?.split("T")[0],
                    nationality: glideNationality,
                    passportCountry: glidePassportCountry,
                    countryOfResidence: glideCountryOfResidence
                },
            },
        };
        return values;
    }

    useEffect(() => {
        if (isMainUserPage) {
            const glideUrl = getGlideUrl();
            setIsUserDataBeingFetched(true);
    
            const handleResponse = response => {
                setIsUserDataBeingFetched(false);
                setUser(response);
    
                setGlideBirthDate(response?.extendedAttributes?.dateOfBirth);
    
                const existingNationalityCode = response?.extendedAttributes?.nationality;
                setGlideNationalityCode(existingNationalityCode);
                setGlideNationality(worldCountries.find(c => c.code === existingNationalityCode)?.name[language]);
    
                const existingCountryOfResidenceCode = response?.extendedAttributes?.countryOfResidence;
                setGlideCountryOfResidenceCode(existingCountryOfResidenceCode);
                setGlideCountryOfResidence(worldCountries.find(c => c.alpha3 === existingCountryOfResidenceCode)?.name[language]);
    
                const existingPassportCountryCode = response?.extendedAttributes?.passportCountry;
                setGlidePassportCountryCode(existingPassportCountryCode);
                setGlidePassportCountry(worldCountries.find(c => c.alpha3 === existingPassportCountryCode)?.name[language]);
    
                setGlideReferenceNumbers(response?.extendedAttributes?.referenceNumbers);
            };
            getReturnJson(glideUrl, null, handleResponse, onError);
        } 
    }, [personId, isMainUserPage]);

    return {
        user,
        glideBirthDate,
        glideNationality,
        glideNationalityCode,
        glidePassportCountry,
        glidePassportCountryCode,
        glideCountryOfResidence,
        glideCountryOfResidenceCode,
        glideReferenceNumbers,
        checkAndUpdateUser,
        addUserSpecificData,
        isUserDataBeingFetched
    };
};

export default useUser;
