import { rest } from "msw";
import { localHost } from "./constants";
import { returnOkResponse } from "../responseResolvers/utils";

const emptyItemsResponse = { items: [] };

export const legacyHandlers = [
    rest.get(`${localHost}/api/me/trips`, returnOkResponse(emptyItemsResponse)),
    rest.get(`${localHost}/api/me/covid-test-results`, returnOkResponse(emptyItemsResponse)),
];
