import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import CredentialContext from "../../config/CredentialContext";
import { getReturnJson, postReturnJson } from "../dataFetching";
import { processDataFromCapServer } from "../dataProcessing";
import { useQuery } from "./index";

export const REDIRECT = {
    MANAGE_TRIP: "MANAGE_TRIP",
    MANAGE_INTERLINE_TRIP: "MANAGE_INTERLINE_TRIP",
    MANAGE_NON_INTERLINE_TRIP: "MANAGE_NON_INTERLINE_TRIP",
    BACK: "BACK",
    NONE: "NONE",
};

const useCap = (redirect = REDIRECT.BACK, loadingProp = "submit", customErrorHandler = null) => {
    const { processInstance, setProcessInstance, setError, userId, submissionHref, updateLoading } = useContext(
        CredentialContext
    );

    const errorHandler = customErrorHandler || setError;
    const history = useHistory();
    const query = useQuery();

    const [updateProcessInstance, setUpdateProcessInstance] = useState();
    const [createProcessInstance, setCreateProcessInstance] = useState();

    const handleRedirect = id => {
        if (typeof redirect === "number") {
            history.go(redirect);
        } else {
            switch (redirect) {
                case REDIRECT.BACK: {
                    history.goBack();
                    break;
                }
                case REDIRECT.MANAGE_TRIP: {
                    history.replace("/managetrip/" + history.location.search + "&extId=" + id);
                    break;
                }
                case REDIRECT.MANAGE_INTERLINE_TRIP: {
                    history.replace("/manageinterlinetrip/" + history.location.search + "&extId=" + id);
                    break;
                }
                case REDIRECT.MANAGE_NON_INTERLINE_TRIP: {
                    history.replace("/managenoninterlinetrip/" + history.location.search + "&extId=" + id);
                    break;
                }
                case REDIRECT.NONE:
                default:
                    break;
            }
        }
    };

    const fetchProcessInstance = () => {
        const processInstanceId = query.get("extId") || query.get("correlationId");
        const instanceUrl = submissionHref + "/processInstances/" + processInstanceId + "/persons/" + userId;
        const handleResponse = response => {
            setProcessInstance(response);
            processDataFromCapServer(errorHandler)(response);
        }
        getReturnJson(instanceUrl, null, handleResponse, errorHandler);
    }

    const processSubmissionResponse = onSuccess => response => {
        setProcessInstance(response);
        const processInstanceId = processDataFromCapServer(errorHandler)(response);
        updateLoading(loadingProp, false);
        onSuccess && onSuccess(response);
        handleRedirect(processInstanceId);
    };

    const sendRequest = url => (payload, onSuccess) => postReturnJson(url, payload, processSubmissionResponse(onSuccess), errorHandler);

    const getUpdateUrl = () => submissionHref + "/processInstances/" + processInstance.id + "/persons/" + userId;
    const getCreationUrl = () =>
        process.env.NODE_ENV !== "production"
            ? "/api/me/trips"
            : submissionHref + "/processInstances/persons/" + userId;

    useEffect(() => {
        if (processInstance) {
            setUpdateProcessInstance(() => sendRequest(getUpdateUrl()));
        }
    }, [processInstance]);

    useEffect(() => {
        if (submissionHref && userId) {
            setCreateProcessInstance(() => sendRequest(getCreationUrl()));
        }
    },[submissionHref, userId])

    return { fetchProcessInstance, updateProcessInstance, createProcessInstance };
};

export default useCap;
