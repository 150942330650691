import { useContext } from "react";
import { useHistory } from "react-router";
import CredentialContext from "../../config/CredentialContext";
import { getReturnJson, postReturnJson } from "../dataFetching";
import { processDataFromCapServer } from "../dataProcessing";
import { useCompanion, useQuery } from "./index";
import { REDIRECT } from "./useCap";

const useCapHandler = () => {

    const { processInstance, setProcessInstance, setError, userId, submissionHref, updateLoading } = useContext(
        CredentialContext
    );

    const history = useHistory();
    const query = useQuery();
    const { companionId } = useCompanion();

    const fetchProcessInstance = () => {
        const processInstanceId = query.get("extId") || query.get("correlationId");
        const instanceUrl = submissionHref + "/processInstances/" + processInstanceId + "/persons/" + userId;
        const handleResponse = response => {
            setProcessInstance(response);
            processDataFromCapServer(setError)(response);
        }
        getReturnJson(instanceUrl, null, handleResponse, setError);
    }

    const getUpdateUrl = () => submissionHref + "/processInstances/" + processInstance.id + "/persons/" + userId;
    const getCreationUrl = () =>
        process.env.NODE_ENV !== "production"
            ? "/api/me/trips"
            : submissionHref + "/processInstances/persons/" + userId;

    const updateData = (key, value) => {
        key && (processInstance.userData.userSpecificData[companionId][key] = value);
        return processInstance;
    }
    const addToArray = (key, value) => {
        if(key) {
            const array = [...processInstance.userData.userSpecificData[companionId][key]] || [];
            processInstance.userData.userSpecificData[companionId][key] = [...array, value];
        }
        return processInstance;
    }

    class CapHandler {
        redirectBehavior = REDIRECT.BACK;
        loadingProp = "submit";

        handleRedirect = (id) => {
            if (typeof this.redirectBehavior === "number") {
                history.go(this.redirectBehavior);
            } else {
                switch (this.redirectBehavior) {
                    case REDIRECT.BACK: {
                        history.goBack();
                        break;
                    }
                    case REDIRECT.MANAGE_TRIP: {
                        history.replace("/managetrip/" + history.location.search + "&extId=" + id);
                        break;
                    }
                    case REDIRECT.NONE:
                    default:
                        break;
                }
            }
        };
        processSubmissionResponse = onSuccess => response => {
            setProcessInstance(response);
            const processInstanceId = processDataFromCapServer(setError)(response);
            updateLoading(this.loadingProp, false);
            onSuccess && onSuccess(response);
            this.handleRedirect(processInstanceId);
        };
        fetchProcessInstance = fetchProcessInstance;
        sendRequest = url => (payload, onSuccess) => postReturnJson(url, payload, this.processSubmissionResponse(onSuccess), setError);
        updateProcessInstance = () => this.sendRequest(getUpdateUrl())(this.payload);
        createProcessInstance = this.sendRequest(getCreationUrl());

        setLoadingProp = value => {
            this.loadingProp = value;
            return this;
        }
        setRedirectBehavior = value => {
            this.redirectBehavior = value;
            return this;
        }
        payload = {};
        updateCompanionData = (key, value) => {
            this.payload = updateData(key,value);
            return this;
        }
        addToArray = (key, value) => {
            this.payload = addToArray(key,value);
            return this;
        }
        // updateProcessInstance = () => this.updateProcessInstance(this.payload);

    }

    return { capHandler: new CapHandler() }
}

export default useCapHandler;