import { Button, Modal } from "react-bootstrap";
import { ErrorIcon } from "../../icons/ErrorIcon";
import { InfoIcon } from "../../icons/InfoIcon";
import { useLocalize } from "../../utils/hooks";
import MarkdownText from "./MarkdownText";

const InfoModal = ({ message, show, close, type = "info" }) => {
    const { localize } = useLocalize();

    const renderIcon = () => {
        switch (type) {
            case "warning":
                return <ErrorIcon size="40px" />;
            default:
                return <InfoIcon size="40px" colorClass="secondary-info-icon" />;
        }
    };

    return (
        <Modal show={show} onHide={close} centered className="px-3">
            <Modal.Body
                className={`document_validation_modal ${type === "info" ? "info_headings" : "warning_headings"}`}
            >
                <div className="text-center mb-2">{renderIcon()}</div>
                <MarkdownText className="text-justify py-2">{message}</MarkdownText>
                <div className="py-1">
                    <Button type="button" className="w-100 btn-secondary" onClick={close}>
                        {localize("global.close")}
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default InfoModal;
