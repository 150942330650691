/* A custom hook that is used to display error messages. */
import { useLocalize } from "./index";
import { tryStringifyOrDefault } from "../formUtils";

const ERROR_TYPES = {
    DANGER: "danger",
    WARNING: "warning",
};

/* A map of error codes to error types. */
const ERROR_TYPES_BY_CODE = {
    600: ERROR_TYPES.WARNING,
    602: ERROR_TYPES.WARNING,
    617: ERROR_TYPES.WARNING,
};

/* A custom hook that is used to display error messages. */
const useError = error => {
    const { localize, localizeOrDefault } = useLocalize();

    const defaultMessage = localize("global.error.defaultMessage");
    const getFallbackMessage = err => {
        if (typeof err === "string") return err;
        if (!err || err === {} || err === "{}") return defaultMessage;
        if (err?.message) return err.message;
        if (err?.text) return err.text;
        return tryStringifyOrDefault(error, defaultMessage);
    };
    const getErrorMessage = err => {
        const fallbackMessage = getFallbackMessage(err);
        if (err?.code) return localizeOrDefault(`global.error.${err.code}.message`, null, fallbackMessage);
        return fallbackMessage;
    };

    const defaultTitle = localize("global.error.error");
    const getErrorTitle = err => {
        if (err?.code) return localizeOrDefault(`global.error.${err.code}.title`, defaultTitle);
        if (err?.title) return err.title;
        return defaultTitle;
    };

    const getErrorType = err => {
        if (err?.type) return err.type;
        if (err?.code) return ERROR_TYPES_BY_CODE[err.code] || ERROR_TYPES.DANGER;
        return ERROR_TYPES.DANGER;
    };

    return { title: getErrorTitle(error), message: getErrorMessage(error), type: getErrorType(error) };
};

export default useError;
