import React from "react";
import { closeForm } from "../../../utils/dataProcessing";
import ErrorMessage from "../ErrorMessage";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, error: error };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({ hasError: true, error: error });
    }

    render() {
        if (this.state.hasError) {
            return <ErrorMessage error={this.state.error} close={closeForm} />;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
