import React, { useContext, useEffect, useReducer, useState } from "react";
import { DateTime } from "luxon";
import moment from "moment-timezone";
import { Form } from "react-bootstrap";
import CredentialContext from "../../config/CredentialContext";
import { useCap, useLanguage, useLocalize, useQuery } from "../../utils/hooks";
import { fetchSupportedCountries } from "../../utils/dataFetching";
import { getThreeLeterCodeWithTwoLetterCode } from '../../utils/countryUtils';
import { REDIRECT } from "../../utils/hooks/useCap";
import Required from "../_components/Required";
import InterlineConfirmationModal from "./InterlineConfirmationModal";
import InterlineInvalidFlightModal from "./InterlineInvalidFlightModal";
import { bookingRefPatterns, bookingRefRules, flightActions } from "./constants";
import FlightSegmentForm from "./components/FlightSegmentForm";
import { InfoIcon } from "../../icons/InfoIcon";
import InfoModal from "../_components/InfoModal";
import { joinFirstAndMiddleName } from '../../utils/textUtils';

const FLIGHT_SEGMENT_UNDER_HOURS = 24;
const DEFAULT_MINCONNECTIONTIME_IN_HOURS = 0.5;

const initialFlightSegment = {
    destinationCountry: undefined,
    departureAirports: [],
    departureAirport: undefined,
    arrivalAirports: [],
    arrivalAirport: undefined,
    flights: [],
    flight: undefined,
    flightNumber: undefined,
    flightDate: undefined,
    flightTime: undefined,
    connectingFlightArrivalAirports: [],
    connectingFlightDepartureAirport: [],
};

const initialFlightDetails = {
    bookingRef: "",
    supportedCountries: [],
    flightSegments: [{ ...initialFlightSegment }],
};

const flightReducer = (state, { type, payload }) => {
    switch (type) {
        case flightActions.SET_BOOKING_REF:
            return { ...state, bookingRef: payload };
        case flightActions.SET_SUPPORTED_COUNTRIES:
            return { ...state, supportedCountries: payload };
        case flightActions.SET_FLIGHT_SEGMENTS:
            return { ...state, flightSegments: payload };
        case flightActions.RESET_FLIGHT_DETAILS:
            return initialFlightDetails;
        case flightActions.ADD_FLIGHT_SEGMENT:
            return {
                ...state,
                flightSegments: state.flightSegments.concat({
                    ...initialFlightSegment,
                    departureAirports: payload.departureAirports,
                    departureAirport: payload.departureAirport,
                }),
            };
        case flightActions.REMOVE_FLIGHT_SEGMENT:
            return {
                ...state,
                flightSegments: state.flightSegments.slice(0, state.flightSegments.length - 1),
            };
        default:
            throw new Error();
    }
};

const InterlineFlightsDetalisNoPnrLookup = ({
    airline,
    personalDetails,
    confirmationModalConfig,
    checkAndUpdateUser
}) => {
    const { firstName, middleName, lastName, birthDate, nationality, countryOfResidence } = personalDetails;
    const { showConfirmationModal, closeConfirmationModal } = confirmationModalConfig;

    const [errorMessages, setErrorMessages] = useState([]);
    const [showInvalidReasonModal, setShowInvalidReasonModal] = useState(false);
    const [showFlightInfoModal, setShowFlightInfoModal] = useState(false);
    const [visaRequired, setVisaRequired] = useState(false);

    const { userId, appVersion, globalConfig, loading, updateLoading, setError } = useContext(CredentialContext);
    const { localize } = useLocalize();
    const { localizeField } = useLanguage();
    const query = useQuery();
    const env = query.get("env");
    const connectingFlightModalInfo = globalConfig?.connectingFlightModalInfo;

    const isBookingRefRequired = bookingRefRules.NO_PNR.AIRLINES_WITH_REQUIRED_BOOKING_REF.includes(airline);
    const showBookingRef = !bookingRefRules.NO_PNR.AIRLINES_NOT_USING_BOOKING_REF.includes(airline);
    const getBookingRefInputClassName = isBookingRefRequired ? "uppercase" : "";
    const getBookingRefPattern = isBookingRefRequired ? bookingRefPatterns[airline] : undefined;

    const [flightDetails, dispatchFlightAction] = useReducer(flightReducer, initialFlightDetails);
    const { bookingRef, supportedCountries, flightSegments } = flightDetails;

    const selectBookingRef = event =>
        dispatchFlightAction({
            type: flightActions.SET_BOOKING_REF,
            payload: isBookingRefRequired ? event.target.value.toUpperCase() : event.target.value,
        });

    const updateFlightSegment = payload => {
        dispatchFlightAction({ type: flightActions.SET_FLIGHT_SEGMENTS, payload });
    };
    const addFlightSegment = payload => {
        dispatchFlightAction({ type: flightActions.ADD_FLIGHT_SEGMENT, payload });
    };
    const removeFlightSegment = () => {
        dispatchFlightAction({ type: flightActions.REMOVE_FLIGHT_SEGMENT });
    };
    const flightActionsConfig = {
        updateFlightSegment,
        addFlightSegment,
        removeFlightSegment,
    };

    useEffect(() => {
        dispatchFlightAction({ type: flightActions.RESET_FLIGHT_DETAILS });

        const handleResponse = result => {
            dispatchFlightAction({ type: flightActions.SET_SUPPORTED_COUNTRIES, payload: result });
        };

        fetchSupportedCountries(handleResponse, airline, setError);
    }, [airline]);

    useEffect(() => {
        const isVisaRequired = flightSegments
            .map(fS => {
                const isVisaRequired = supportedCountries?.find(sC => sC.code === fS.destinationCountry)?.visaRequired;
                return !!isVisaRequired;
            })
            .reduce((prev, curr) => prev || curr, false);

        setVisaRequired(isVisaRequired);
    }, [flightSegments]);

    const capErrorHandler = e => {
        const { code } = e;
        const codeErrors = ["700"];

        const messages = code && codeErrors.includes(code.toString()) ? [e.code.toString()] : ["defaultMessage"];

        setErrorMessages(messages);
        setShowInvalidReasonModal(true);
        updateLoading("submit", false);
    };

    const { createProcessInstance } = useCap(REDIRECT.MANAGE_INTERLINE_TRIP, "submit", capErrorHandler);

    const flightBlockData = () => {
        const blocksArray = [];

        flightSegments.forEach((currentFlightSegment, index, flightSegmentsArray) => {
            if (index === 0) {
                blocksArray.push([currentFlightSegment]);
            } else {
                const previousFlightSegment = flightSegmentsArray[index - 1];

                const DTarrivalFlightTime = DateTime.fromISO(previousFlightSegment?.flight?.arrivalFlightTime);
                const DTdepartureFlightTime = DateTime.fromISO(currentFlightSegment?.flight?.departureFlightTime);

                const timeDiffBetweenSegments = DTdepartureFlightTime.diff(DTarrivalFlightTime, "hours")?.hours;

                const getConnectionTime = () => {
                    const currentDepartureAirport = currentFlightSegment.departureAirports?.find(
                        dA => dA.iata_code === currentFlightSegment.departureAirport
                    );

                    try {
                        if (currentDepartureAirport?.minConnectionTime) {
                            return moment.duration(currentDepartureAirport?.minConnectionTime).asHours();
                        } else {
                            return DEFAULT_MINCONNECTIONTIME_IN_HOURS;
                        }
                    } catch {
                        return DEFAULT_MINCONNECTIONTIME_IN_HOURS;
                    }
                };

                if (
                    timeDiffBetweenSegments < FLIGHT_SEGMENT_UNDER_HOURS &&
                    previousFlightSegment?.arrivalAirport === currentFlightSegment?.departureAirport &&
                    currentFlightSegment?.arrivalAirport !== previousFlightSegment.departureAirport &&
                    timeDiffBetweenSegments >= getConnectionTime()
                ) {
                    blocksArray[blocksArray.length - 1].push(currentFlightSegment);
                } else {
                    blocksArray.push([currentFlightSegment]);
                }
            }
        });

        return blocksArray;
    };

    const handleSubmit = () => {
        updateLoading("submit", true);

        const flightSegmentData = (flightSegment, index) => {
            const departure = flightSegment.departureAirports.find(
                dA => dA.iata_code === flightSegment.departureAirport
            );
            const arrival = flightSegment.arrivalAirports.find(dA => dA.iata_code === flightSegment.arrivalAirport);

            return {
                id: `fs${index}`,
                carrierCode: flightSegment.flight.airlineCode,
                flightNumber: flightSegment.flight.flightNumber,
                departure: {
                    airportName: departure.name,
                    airportCode: departure.iata_code,
                    countryCode: departure.country_iso,
                    offsetDateTime: flightSegment.flight.departureFlightTime,
                },
                arrival: {
                    airportName: arrival.name,
                    airportCode: arrival.iata_code,
                    countryCode: arrival.country_iso,
                    offsetDateTime: flightSegment.flight.arrivalFlightTime || flightSegment.flight.departureFlightTime,
                },
            };
        };

        const passportCountry = getThreeLeterCodeWithTwoLetterCode(nationality);

        const assembledFirstName = joinFirstAndMiddleName(firstName, middleName);

        const flightDetails = {
            userData: {
                sharedData: {
                    appVersion,
                    interlineFlightDetails: {
                        nonPnr: true,
                        bookingIdentifier: bookingRef,
                        carrierCode: flightSegments[0].flight.airlineCode,
                        visaRequired,
                        env,
                        flightBlocks: flightBlockData().map((fB, index) => {
                            return {
                                id: `fb${index}`,
                                flightSegments: fB?.map((fS, index) => flightSegmentData(fS, index)),
                            };
                        }),
                    },
                },
                userSpecificData: {
                    [userId]: {
                        firstName: assembledFirstName,
                        lastName,
                        dateOfBirth: birthDate.split("T")[0],
                        nationality,
                        passportCountry,
                        countryOfResidence
                    },
                },
            },
        };
        checkAndUpdateUser({
            firstName: assembledFirstName,
            lastName,
            birthDate,
            nationality,
            passportCountry,
            countryOfResidence
        });

        createProcessInstance(flightDetails);
        closeConfirmationModal();
    };

    const closeInvalidReasonModal = () => {
        setShowInvalidReasonModal(false);
    };

    const openFlightInfoModal = () => {
        setShowFlightInfoModal(true);
    };
    const closeFlightInfoModal = () => {
        setShowFlightInfoModal(false);
    };

    return (
        <>
            <InterlineInvalidFlightModal
                showModal={showInvalidReasonModal}
                errorMessages={errorMessages}
                closeModal={closeInvalidReasonModal}
            />
            <InterlineConfirmationModal
                onShowModal={showConfirmationModal}
                onConfirmSubmit={handleSubmit}
                bookingRef={bookingRef}
                firstName={firstName}
                middleName={middleName}
                lastName={lastName}
                birthDate={birthDate}
                onCloseModal={closeConfirmationModal}
            />
            <InfoModal
                show={showFlightInfoModal}
                close={closeFlightInfoModal}
                message={localizeField(connectingFlightModalInfo)}
            />
            <fieldset disabled={loading.submit}>
                {showBookingRef && (
                    <Form.Group className="border-bottom-0" controlId="booking-reference">
                        <Form.Label>{localize("flightDetails.flight.bookingRefNumber.label")}</Form.Label>
                        <Form.Control
                            className={getBookingRefInputClassName}
                            onChange={selectBookingRef}
                            value={bookingRef}
                            maxLength="20"
                            pattern={getBookingRefPattern}
                            required={isBookingRefRequired}
                        />
                        <Form.Text className="text-muted">
                            {localize("flightDetails.flight.bookingRefNumber.additionalText")}
                        </Form.Text>
                        {isBookingRefRequired && (
                            <Required message={localize("flightDetails.flight.bookingRefNumber.missingMessage")} />
                        )}
                    </Form.Group>
                )}
            </fieldset>
            <legend className="flight_details_legend">
                <h6 className={`legend_title ${connectingFlightModalInfo ? "legend_title--offset" : ""}`}>
                    {localize("flightDetails.flight.legend")}
                </h6>
                {connectingFlightModalInfo && (
                    <div className="legend_icon_container" onClick={openFlightInfoModal}>
                        <InfoIcon size="24px" />
                    </div>
                )}
            </legend>
            {flightSegments.map((fS, index) => (
                <FlightSegmentForm
                    key={index}
                    airline={airline}
                    supportedCountries={supportedCountries}
                    segmentNumber={index}
                    flightSegments={flightSegments}
                    flightSegment={fS}
                    flightActionsConfig={flightActionsConfig}
                />
            ))}
        </>
    );
};

export default InterlineFlightsDetalisNoPnrLookup;
