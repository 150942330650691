import React from "react";
import { Button, Form } from "react-bootstrap";
import { useLocalize } from "../../../utils/hooks";

const CarnevalCruiseGuestListItem = ({ lastName, firstName, birthDate, onOpen, item, added }) => {
    const { localize } = useLocalize();

    const onClick = () => {
        onOpen(item);
    };

    return (
        <Form.Group>
            <div className="row">
                <div className={`col-6 ${added ? "text-secondary" : ""}`}>
                    <div className="h6 font-weight-bold text-truncate">{`${firstName} ${lastName}`}</div>
                    <div>{birthDate}</div>
                </div>
                <div className="col-6">
                    <Button
                        type="submit"
                        disabled={added}
                        className="w-100 text-nowrap text-truncate"
                        onClick={onClick}
                    >
                        {localize(
                            added ? "carnivalcruise.guest.addedbutton.label" : "carnivalcruise.guest.addbutton.label"
                        )}
                    </Button>
                </div>
            </div>
        </Form.Group>
    );
};

CarnevalCruiseGuestListItem.defaultProps  = {
    lastName: "",
    firstName: "",
    birthDate: "",
    item: {},
    onOpen: () => {},
    added: false
}

export default CarnevalCruiseGuestListItem;
