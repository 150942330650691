import { v4 as uuidv4 } from "uuid";

export const processDataFromCapServer = (handleError, closeWebView = false) => data => {
    try {
        if (data.credentialActions && data.credentialActions.length > 0)
            window.formHandler.appHandleCapResponse(JSON.stringify(data.credentialActions));
        if (closeWebView)
            closeForm();
        else
            return data.id;
    } catch (e) {
        handleError && handleError(e);
    }
};

export const toBase64 = file => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        const fileType = file.type.split("/")[1];
        const extension = fileType === "jpeg" ? "jpg" : fileType;
        const { id, name } = file;
        reader.onload = () => resolve({id, name, extension, data: reader.result.replace(/^data:.+;base64,/, '')});
        reader.onerror = error => reject(error);
    });
};

export const encodeFiles = async (inputFiles, maxNumberOfFiles = 5, encode = true) => {
    let errorMessageId = "";
    const files = inputFiles;

    if (files.length > maxNumberOfFiles) {
        errorMessageId = "covidTest.uploadResults.maxFiles";
    }

    const previews = [];
    const filePromises = [];

    Array.from(files).forEach(file => {
        const sizeLimit = 10485760;
        const extension = file.type.split("/")[1];
        const allowedExtensions = ["jpg", "jpeg", "png", "pdf"];
        if (!allowedExtensions.includes(extension)) {
            errorMessageId = "covidTest.uploadResults.missingMessage";
        }
        if (file.size > sizeLimit) {
            errorMessageId = "covidTest.uploadResults.maxSize";
        }
        const link = URL.createObjectURL(file);
        const id = uuidv4();
        file.id = id;
        previews.push({
            id,
            value: link,
            type: extension,
            name: file.name,
        });
        encode && filePromises.push(toBase64(file));

        URL.revokeObjectURL(file);
    });

    const filesData = encode ? await Promise.all(filePromises) : files;

    return ({filesData, previews, errorMessageId});
};

export const dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, {type:mime});
}

export const decodeJwt = token => {
    if (!token) {
        return null;
    }
    const base64 = token.split(".")[1];
    // const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    // const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
    //     return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    // }).join(''));
    // const jsonPayload = decodeURIComponent(atob(base64));
    const jsonPayload = atob(base64);

    return JSON.parse(jsonPayload);
}

export const closeForm = () => window.formHandler.appCloseWebView(null);

/**
 * To be used when a callback parameter is explicitly required,
 * but no callback is actually necessary.
 * Using this explicitly states the intent that no action is to be performed,
 * as well as preventing linter errors from using empty arrow functions.
 */
export const doNothing = () => {
    // Do nothing
}

export function parseIfJSON (value) {
    try {
        return JSON.parse(value);
    } catch {
        return value;
    }
}