import { submissionHref, credentialTypeHref, medicalHref, language, personId,  tripHost } from "../../utils/constants";

export const APPROVALS_KEY = "approvals";
export const CUSTOM_INSTRUCTIONS_KEY = "customUserInstructions";
export const USE_CUSTOM_INSTRUCTIONS_KEY = "useCustomUserInstructions";
export const CUSTOM_FORM_CONFIG_KEY = "customFormConfig";
export const USE_CUSTOM_FORM_CONFIG_KEY = "useCustomFormConfig";
export const PI_KEY = "flights";
export const INTERLINE_KEY = "interlineFlights";
export const USE_PREMIER_REVIEW_KEY = "usePremierReview";
export const USE_DARK_THEME = "useDarkTheme";
export const USE_CUSTOM_BOOKING_RESPONSE = "useCustomBookingResponse";
export const CUSTOM_BOOKING_RESPONSE = "customBookingResponse";

export const flightsUrl = (user, theme) =>
    `/addflightdetails/cap/usa?email=${user.email}&firstName=${user.firstName}&lastName=${user.lastName}&language=${language}-US&personId=${personId}&submissionHref=${submissionHref}&credentialTypeHref=${credentialTypeHref}&medicalHref=${medicalHref}&theme=${theme}&tripServerHostUrl=${tripHost}&analyticsEnabled=false`;
export const interlineFlightsUrl = (user, theme) =>
    `/addinterlineflightdetails?email=${user.email}&firstName=${user.firstName}&lastName=${user.lastName}&language=${language}-US&personId=${personId}&submissionHref=${submissionHref}&credentialTypeHref=${credentialTypeHref}&medicalHref=${medicalHref}&theme=${theme}&tripServerHostUrl=${tripHost}&analyticsEnabled=false`;
export const flightsSwrUrl = (user, theme) =>
    `/addswrflightdetails/cap/usa?email=${user.email}&firstName=${user.firstName}&lastName=${user.lastName}&language=${language}-US&personId=${personId}&submissionHref=${submissionHref}&credentialTypeHref=${credentialTypeHref}&medicalHref=${medicalHref}&theme=${theme}&tripServerHostUrl=${tripHost}&analyticsEnabled=false`;
export const vikingUrl = (user, theme) =>
    `/addcruisedetails/viking?email=${user.email}&firstName=${user.firstName}&lastName=${user.lastName}&language=${language}-US&personId=${personId}&submissionHref=${submissionHref}&credentialTypeHref=${credentialTypeHref}&medicalHref=${medicalHref}&theme=${theme}&tripServerHostUrl=${tripHost}&analyticsEnabled=false`;
export const carnivalUrl = (user, theme) =>
    `/addcruisedetails/CCL?email=${user.email}&firstName=${user.firstName}&lastName=${user.lastName}&language=${language}-US&personId=${personId}&submissionHref=${submissionHref}&credentialTypeHref=${credentialTypeHref}&medicalHref=${medicalHref}&theme=${theme}&tripServerHostUrl=${tripHost}&analyticsEnabled=false`;
export const halUrl = (user, theme) =>
    `/addcruisedetails/CCL?cruiseLineCode=hal&email=${user.email}&firstName=${user.firstName}&lastName=${user.lastName}&language=${language}-US&personId=${personId}&submissionHref=${submissionHref}&credentialTypeHref=${credentialTypeHref}&medicalHref=${medicalHref}&theme=${theme}&tripServerHostUrl=${tripHost}&analyticsEnabled=false`;
export const configurableUrl = (user, theme) =>
    `/create/cruiseDetails?cruiseLineCode=hal&email=${user.email}&firstName=${user.firstName}&lastName=${user.lastName}&language=${language}-US&personId=${personId}&submissionHref=${submissionHref}&credentialTypeHref=${credentialTypeHref}&medicalHref=${medicalHref}&theme=${theme}&tripServerHostUrl=${tripHost}&analyticsEnabled=false`;
export const manageTripUrl = (user, id, theme) =>
    `/managetrip/?email=${user.email}&firstName=${user.firstName}&lastName=${user.lastName}&language=${language}-US&personId=${personId}&submissionHref=${submissionHref}&credentialTypeHref=${credentialTypeHref}&medicalHref=${medicalHref}&theme=${theme}&extId=${id}&tripServerHostUrl=${tripHost}&analyticsEnabled=false`;
export const manageInterlineTripUrl = (user, id, theme) =>
    `/manageinterlinetrip/?email=${user.email}&firstName=${user.firstName}&lastName=${user.lastName}&language=${language}-US&personId=${personId}&submissionHref=${submissionHref}&credentialTypeHref=${credentialTypeHref}&medicalHref=${medicalHref}&theme=${theme}&extId=${id}&tripServerHostUrl=${tripHost}&analyticsEnabled=false`;
export const eventTripUrl = (user, theme) =>
    `addevent?email=${user.email}&firstName=${user.firstName}&lastName=${user.lastName}&language=${language}-US&personId=${personId}&submissionHref=${submissionHref}&credentialTypeHref=${credentialTypeHref}&medicalHref=${medicalHref}&theme=${theme}&eventCode=amnhealthcare&eventDetailsHeader=AMN%20Healthcare&eventDetailsLegend=AMN%20Healthcare&policies=amnPrivacyConsent,amnHealthCheckConsent,amnStorageConsent&showReferenceNumber=true&referenceNumberLabel=Email%20Address&eventDetailsNote=Innovative%20staffing%20and%20total%20talent%20solutions&tripServerHostUrl=${tripHost}&analyticsEnabled=false`;
