import { rest } from "msw";
import { vikingGetPerson, vikingPost, vikingPostPerson } from "../responseResolvers/vikingCruise";
import { submissionHref } from "../../utils/constants";
import { localHost, baseUrl } from "./constants";

export const cruiseHandlers = [
    /* Viking Cruise */
    // GET /addcruisedetails/viking/baseUrl/api/persons/:personId
    rest.get(`${localHost}/addcruisedetails/viking/${baseUrl}/api/persons/:personId`, vikingGetPerson),

    // POST /addcruisedetails/viking/baseUrl/api/persons/:personId
    rest.post(`${localHost}/addcruisedetails/viking/${baseUrl}/api/persons/:personId`, vikingPostPerson),

    // POST /addcruisedetails/viking/submissionHref/processInstances/persons/:personId
    rest.post(`${localHost}/addcruisedetails/viking/${submissionHref}/processInstances/persons/:personId`, vikingPost),

    // POST /managetrip/viking/submissionHref/processInstances/persons/:personId
    rest.post(`${localHost}/managetrip/${submissionHref}/processInstances/:id/persons/:personId`, vikingPost),

    // // GET /submissionHref/processInstances/:id/persons/:personId
    // rest.get(`${localHost}/${submissionHref}/processInstances/:id/persons/:personId`, vikingGet),
    //
    // // GET /managetrip/submissionHref/processInstances/:id/persons/:personId
    // rest.get(`${localHost}/managetrip/${submissionHref}/processInstances/:id/persons/:personId`, vikingGet),
    //
    // // GET /tripchecklist/submissionHref/processInstances/:id/persons/:personId`
    // rest.get(`${localHost}/tripchecklist/${submissionHref}/processInstances/:id/persons/:personId`, vikingGet),
    //
    // // POST /addvaccination/cap/submissionHref/processInstances/:id/persons/:personId
    // rest.post(`${localHost}/addvaccination/cap/${submissionHref}/processInstances/:id/persons/:personId`, vikingPost),
    //
    // // POST /submissionHref/processInstances/:id/persons/:personId
    // rest.post(`${localHost}/${submissionHref}/processInstances/:id/persons/:personId`, vikingPost),
    //
];
