import React, { useContext, useEffect, useState } from "react";
import AddSavedCompanionForm from "./AddSavedCompanionForm";
import FormPage from "../_components/FormPage";
import AddCompanions from "./AddCompanions";
import CredentialContext from "../../config/CredentialContext";
import { Button, Modal } from "react-bootstrap";
import { getReturnJson, postReturnJson } from "../../utils/dataFetching";
import { getBaseUrl } from "../../utils/textUtils";
import { closeForm, processDataFromCapServer } from "../../utils/dataProcessing";
import { useLocalize, useLanguage, useQuery, useTheme, useCap, useHeaderTitle, useUser } from "../../utils/hooks";
import LoadingWrapper from "../_components/Wrappers/LoadingWrapper";
import PassengerList from "./PassengerList";
import TripSummary from "../_components/TripSummary/TripSummary";
import CompanionCountryOfResidenceModal from './CompanionCountryOfResidenceModal';
import { REDIRECT } from '../../utils/hooks/useCap';

export default function ManageTrip() {
    const {
        submissionHref,
        userId,
        credentialTypeHref,
        processInstance,
        loading,
        setError
    } = useContext(
        CredentialContext
    );
    const { fetchProcessInstance, updateProcessInstance } = useCap(REDIRECT.NONE);
    const { checkAndUpdateUser, isUserDataBeingFetched } = useUser(userId, setError);

    const query = useQuery();
    const theme = useTheme(query.get("theme"));

    const { localize } = useLocalize();
    const { localizeField } = useLanguage();

    const [savedCompanions, setSavedCompanions] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [showModalForCountryOfResidence, setShowModalForCountryOfResidence] = useState(false);

    const closeModal = () => setShowModal(false);

    const shouldDisableAddCompanion = () => {
        if (processInstance) {
            const maxPassengers = (processInstance.instructions?.maxCompanions ?? 9) + 1;
            const passengerCount = Object.keys(processInstance.userData.userSpecificData).length;
            return passengerCount >= maxPassengers;
        } else {
            return false;
        }
    };

    const allowCompanions = processInstance?.instructions?.allowCompanions ?? false;

    useEffect(() => {
        fetchProcessInstance();

        const getCompanionsUrl = getBaseUrl(credentialTypeHref) + "/api/persons/" + userId + "/companions";
        getReturnJson(getCompanionsUrl, null, setSavedCompanions, () =>
            setError("Error fetching companions from" + getCompanionsUrl)
        );
    }, []);

    const mainUserData = processInstance?.userData?.userSpecificData?.[userId];

    const mainUserCountryOfResidenceSubmit = async (enteredCountryOfResidence) => {
        await checkAndUpdateUser({ countryOfResidence: enteredCountryOfResidence })

        mainUserData.countryOfResidence = enteredCountryOfResidence;

        await updateProcessInstance(processInstance);
    };

    useEffect(() => {
        if (mainUserData && !mainUserData?.countryOfResidence) {
            setShowModalForCountryOfResidence(true);
        }
    }, [mainUserData]);

    const cancelTrip = () => {
        setShowModal(false);
        const processInstanceId = query.get("extId");
        const cancellationUrl =
            submissionHref + "/processInstances/" + processInstanceId + "/persons/" + userId + "/cancel";
        postReturnJson(cancellationUrl, processInstance, processDataFromCapServer(setError, true), setError);
    };

    const showCancelConfirmation = () => {
        const { eventDetails } = processInstance?.userData.sharedData;
        const messageId = eventDetails
            ? "manageTrip.cancelEvent.confirmMessage"
            : "manageTrip.cancelTrip.confirmMessage";
        setModalMessage(localize(messageId));
        setShowModal(true);
    };

    const getModalBgColor = () => {
        return { backgroundColor: theme.forms.backgroundSolid };
    };

    const Menu = () => {
        const { eventDetails } = processInstance?.userData.sharedData;
        const messageId = eventDetails ? "global.cancel" : "global.cancelTrip";

        return <div onClick={showCancelConfirmation}>{localize(messageId)}</div>;
    };

    const ConfirmationModal = ({ title, message }) => {
        return (
            <Modal show={showModal} onHide={closeModal}>
                {title && (
                    <Modal.Header style={getModalBgColor()}>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                )}
                <Modal.Body style={getModalBgColor()}>
                    <p>{message}</p>
                </Modal.Body>
                <Modal.Footer style={getModalBgColor()}>
                    <Button variant="secondary" onClick={closeModal}>
                        {localize("global.cancel")}
                    </Button>
                    <Button variant="primary" onClick={cancelTrip}>
                        {localize("global.confirm")}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };

    return (
        <LoadingWrapper fullPage isLoading={!processInstance || isUserDataBeingFetched || loading.submit}>
            <div className="manage-trip">
                <CompanionCountryOfResidenceModal
                    onShowModal={showModalForCountryOfResidence}
                    firstName={mainUserData?.firstName}
                    lastName={mainUserData?.lastName}
                    onCloseModal={setShowModalForCountryOfResidence}
                    handleSubmit={mainUserCountryOfResidenceSubmit}
                    exitApp={closeForm}
                    userType="main"
                    loadingProp="submit"
                />
                <ConfirmationModal message={modalMessage} />
                <FormPage header={useHeaderTitle()} headerMenu={Menu}>
                    <TripSummary />
                    <PassengerList />
                    {allowCompanions && (
                        <>
                            <AddCompanions
                                disabled={shouldDisableAddCompanion()}
                                addCompanionText={localizeField(processInstance?.instructions?.companionText?.body) || localize("manageTrip.addCompanion.text")}
                            />
                            {shouldDisableAddCompanion() && <p className="pt-3 mb-0 text-90 font-weight-bold">{localize("manageTrip.addCompanion.maximumNumber.text")}</p>}
                        </>
                    )}
                </FormPage>
                {allowCompanions && (
                    <FormPage>
                        <AddSavedCompanionForm companions={savedCompanions} disabled={shouldDisableAddCompanion()} />
                    </FormPage>
                )}
            </div>
        </LoadingWrapper>
    );
}
