import { useIntl } from "react-intl";

const DEFAULT_MESSAGE = "No translation found for id ";
const useLocalize = () => {
    const intl = useIntl();
    const messageExists = id => !!intl.messages[id];

    const localize = (id, values) => {
        if (messageExists(id)) {
            return intl.formatMessage({ id }, values);
        }
        return DEFAULT_MESSAGE + id;
    };

    const localizeOrDefault = (id, values, defaultValue) => {
        if (messageExists(id)) {
            return intl.formatMessage({ id }, values);
        }
        return defaultValue;
    };

    const localizeOrEmpty = (id, values) => localizeOrDefault(id, values, "");

    return { localize, localizeOrDefault, localizeOrEmpty };
};

export default useLocalize;
