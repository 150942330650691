import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import CustomRadioButtonGroup from "./CustomRadioButtonGroup";
import CustomSwitchInput from "./CustomSwitchInput";
import CustomSelect from "./CustomSelect";
import CustomCountrySelect from './CustomCountrySelect';
import CustomToggle from "./CustomToggle";
import CustomDateInput from "./CustomDateInput";
import CustomNote from "./CustomNote";
import CustomNumberInput from "./CustomNumberInput";
import CustomEmailInput from './CustomEmailInput';
import CustomPhoneInput from './CustomPhoneInput';
import CustomTextInput from "./CustomTextInput";

const CustomInput = ({ input, isComplete, selectedValue, details = {} }) => {
    const { watch } = useFormContext();

    const { type, hideIf, showIf } = input;

    const [hide, setHide] = useState(false);
    const [show, setShow] = useState(true);

    useEffect(() => {
        if (hideIf && hideIf.length > 0) {
            const formValues = watch(hideIf.map(p => p.field));
            setHide(hideIf.map(p => p.values.includes(formValues[p.field])).reduce((acc, curr) => acc && curr, true));
        }
    }, [hideIf, watch]);

    useEffect(() => {
        if (showIf && showIf.length > 0) {
            const formValues = watch(showIf.map(p => p.field));
            setShow(showIf.map(p => p.values.includes(formValues[p.field])).reduce((acc, curr) => acc && curr, true));
        }
    }, [showIf, watch]);

    if (hide || !show) return null;

    const inputProps = { input, isComplete, selectedValue };

    switch (type) {
        case "radio":
            return <CustomRadioButtonGroup {...inputProps} />;
        case "switch":
            return <CustomSwitchInput {...inputProps} />;
        case "select":
            return <CustomSelect {...inputProps} />;
        case "countrySelect":
            return <CustomCountrySelect details={details} {...inputProps} />;
        case "toggle":
            return <CustomToggle {...inputProps} />;
        case "date":
            return <CustomDateInput {...inputProps} />;
        case "note":
            return <CustomNote input={input} />;
        case "number":
            return <CustomNumberInput {...inputProps} />;
        case "email":
            return <CustomEmailInput {...inputProps} />;
        case "phone":
            return <CustomPhoneInput {...inputProps} />;
        case "text":
        default:
            return <CustomTextInput {...inputProps} />;
    }
};

export default CustomInput;
