import { rest } from "msw";
import { approvalsPost } from "../responseResolvers/approvals";
import { policyUrlsGet, unapprovedPoliciesGet } from "../responseResolvers/policyUrl";
import { baseUrl, localHost } from "./constants";

export const approvalsHandlers = [
    /* Approvals */
    /* // POST baseUrl/api/me/approvals
    rest.post(`${localHost}/api/me/approvals`, approvalsPost), */

    // POST baseUrl/api/persons/:personId/approvals
    rest.post(`${baseUrl}/api/persons/:personId/approvals`, approvalsPost),

    /* Policy Url */
    // GET /api/me/approvals/policy-url
    rest.get(`${localHost}/api/me/approvals/policy-url`, policyUrlsGet),
    // GET /api/me/approvals/policy-url/unapproved?
    rest.get(`${localHost}/api/me/approvals/policy-url/unapproved`, unapprovedPoliciesGet),
];
