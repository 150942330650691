import React from "react";

const CrossedEyeIcon = ({ customClass, colorClass, width, height }) => {
    return (
        <svg
            className={`svg-icon ${customClass}`}
            width={width}
            height={height}
            viewBox="0 0 28 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                className={colorClass}
                d="M8.76399 13.2587L3.61599 8.11066C1.58932 10.32 0.821323 12.5333 0.80799 12.5787L0.666656 13L0.806657 13.4213C0.83599 13.5107 3.89466 22.3333 14.072 22.3333C15.3107 22.3333 16.4387 22.1973 17.4747 21.9693L13.8133 18.308C12.495 18.2434 11.2479 17.6906 10.3146 16.7574C9.38134 15.8241 8.82862 14.577 8.76399 13.2587ZM14.072 3.66666C11.5987 3.66666 9.57199 4.20533 7.88266 4.99733L2.94266 0.0573273L1.05732 1.94266L25.0573 25.9427L26.9427 24.0573L22.5453 19.66C26.0627 17.056 27.3173 13.4773 27.336 13.4213L27.476 13L27.336 12.5787C27.3067 12.4893 24.2493 3.66666 14.072 3.66666ZM16.6133 13.728C16.8627 12.8253 16.6507 11.8093 15.9573 11.1147C15.264 10.42 14.2467 10.2093 13.344 10.4587L11.3333 8.44799C12.1573 7.9413 13.1047 7.671 14.072 7.66666C17.0133 7.66666 19.4053 10.0587 19.4053 13C19.4014 13.9671 19.1305 14.9143 18.6227 15.7373L16.6133 13.728Z"
            />
        </svg>
    );
};

CrossedEyeIcon.defaultProps = {
    customClass: "",
    colorClass: "premier-icon-fill",
    width: "28",
    height: "26",
};

export default CrossedEyeIcon;
