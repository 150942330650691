const CloseIcon = ({ size, className }) => (
    <svg
        width={size}
        height={size}
        className={`svg-icon ${className}`}
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path className="premier-icon-fill" d="M11.875 4.00625L10.9938 3.125L7.5 6.61875L4.00625 3.125L3.125 4.00625L6.61875 7.5L3.125 10.9938L4.00625 11.875L7.5 8.38125L10.9938 11.875L11.875 10.9938L8.38125 7.5L11.875 4.00625Z" />
    </svg>
);

CloseIcon.defaultProps = {
    size: '15',
    className: ''
}

export default CloseIcon;
