import {
    TESTING_CENTERS_REQUEST,
    TESTING_CENTERS_SUCCESS,
    TESTING_CENTERS_FAIL,
} from "../constants/testeingCentersConstants";

export const testingCentersReducer = (state = { testingCenters: [] }, action) => {
    switch (action.type) {
        case TESTING_CENTERS_REQUEST:
            return { loading: true, testingCenters: [] };
        case TESTING_CENTERS_SUCCESS:
            return { loading: false, testingCenters: action.payload };
        case TESTING_CENTERS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};
