import { useContext } from "react";
import CredentialContext from "../../config/CredentialContext";
import { postReturnJson } from "../dataFetching";
import { putToS3 } from "../putToS3";
import { getBaseUrl } from "../textUtils";
import { useCompanion } from "./index";
import { doNothing } from "../dataProcessing";

const useS3 = () => {

    const { setError, submissionHref } = useContext(CredentialContext);
    const { companionId } = useCompanion();

    const getPresignedUrls = async files => {
        const contentTypes = files.map(file => ({contentType: file.type}));
        const url = `${getBaseUrl(submissionHref)}/api/persons/${companionId}/presignedUrls`
        return postReturnJson(url, contentTypes, doNothing, setError);
    }

    const getExtension = file => {
        const extension = file.type.split("/")[1];
        return extension === "jpeg" ? "jpg" : extension;
    }

    const uploadFilesToS3 = async files => {
        let promises = [];
        const urls = await getPresignedUrls(files);
        files.forEach((file, i) => {
            const res = putToS3(file, urls[i].preSignedUrl)
            promises.push(res);
        })
        await Promise.all(promises);
        return files.map((file, i) => ({extension: getExtension(file), key: urls[i].s3key}));
    }

    return { uploadFilesToS3 };
}

export default useS3;