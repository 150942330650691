import React from "react";
import CustomSpinner from "../CustomSpinner";

const LoadingWrapper = ({ children, isLoading, fullPage, ...otherProps }) => {
    if (isLoading) return <CustomSpinner container={fullPage} {...otherProps} />;

    return <>{children}</>;
};

export default LoadingWrapper;
