const PersonResponse = {
    id: "AA5MYKHUN2MFJOUDILSW2XEINI",
    tenant: {
        id: "default",
        name: "default",
        notificationEmail: "support@daon.com",
        status: "ACTIVE",
        idTokenInfo: {
            issuer: "https://engidx-glide-develop.identityx-build.com/system",
            audience: "https://engidx-glide-develop.identityx-build.com/system",
            timeToLive: 1800,
        },
        createdDtm: "2020-08-04T15:21:08.959+0000",
        lastUpdatedDtm: "2020-08-04T15:21:08.959+0000",
        href: "https://engidx-glide-develop.identityx-build.com/api/tenants/default",
    },
    email: "ecb75224@cuoly.com",
    firstName: "Nikola",
    lastName: "Dragic",
    status: "ACTIVE",
    idxId: "QTAzCMCyALE-9PI2IO2Bn00qKw",
    idxUserId: "glide:64fb63f6-c6ed-450e-98f3-ce21d8201a3c",
    idxTenant: "seamlesstravel",
    idxInstanceId: "default",
    createdDtm: "2021-07-05T11:18:36.963+0000",
    lastUpdatedDtm: "2021-07-05T11:21:01.241+0000",
    region: {
        id: "default",
        name: "default",
        description: "This is the default region",
        locales: ["en-US"],
        approvals: {
            privacyPolicy: {
                name: "Privacy Policy",
                description: "The privacy policy for Glide",
                url: "https://engidx-glide-develop.identityx-build.com/privacy_policy_20210304.html",
                mandatory: true,
            },
            termsAndConditions: {
                name: "Terms and Conditions",
                description: "The terms and conditions for Glide",
                url: "https://engidx-glide-develop.identityx-build.com/terms_of_service_20200901.html",
                mandatory: true,
            },
        },
        status: "ACTIVE",
        createdDtm: "2020-08-04T15:21:05.000+0000",
        href: "https://engidx-glide-develop.identityx-build.com/api/regions/default",
    },
    info: {
        approvals: [
            {
                id: "Privacy Policy",
                provider: "SYSTEM",
                url: "https://engidx-glide-develop.identityx-build.com/privacy_policy_20210304.html",
                createdDtm: "2021-07-05T11:18:36.963+0000",
            },
            {
                id: "Terms and Conditions",
                provider: "SYSTEM",
                url: "https://engidx-glide-develop.identityx-build.com/terms_of_service_20200901.html",
                createdDtm: "2021-07-05T11:18:36.963+0000",
            },
            {
                id: "Trip Privacy Policy",
                provider: "trip-service",
                url: "https://myverifly.com/policies/2021-02-16/privacy/privacy.html",
                createdDtm: "2021-07-05T11:20:31.697+0000",
            },
            {
                id: "Covid Consent",
                provider: "trip-service",
                url: "https://myverifly.com/policies/2021-02-16/covid/privacy.html",
                createdDtm: "2021-07-05T11:20:31.697+0000",
            },
            {
                id: "Data Storage Consent",
                provider: "trip-service",
                url: "https://myverifly.com/policies/2021-02-16/storage/privacy.html",
                createdDtm: "2021-07-05T11:20:31.697+0000",
            },
        ],
        registeredApiMap: {},
    },
    aliases: {},
    extendedAttributes: {
        dateOfBirth: "1977-07-07",
        nationality: "RS",
        // passportCountry: "SRB",
        // countryOfResidence: "SRB",
        referenceNumbers: ["eventcode1:bookingnumber", "eventcode2:email@daon.com", "eventcode3:12345678", "eventcode4:referencenumber123"]
    },
    href: "https://engidx-glide-develop.identityx-build.com/api/persons/AA5MYKHUN2MFJOUDILSW2XEINI",
};

export default PersonResponse;