import { useIntl } from "react-intl";
import { Col, Row } from "react-bootstrap";
import React from "react";

const EventSummary = () => {
    const intl = useIntl();
    const localize = id => intl.formatMessage({ id });

    return (
        <Row>
            <Col className="pl-0 col-12">{localize("eventDetails.veriflyEvents.label")}</Col>
        </Row>
    );
};

export default EventSummary;
