import React from "react";
import FormPage from "./FormPage";
import MarkdownText from "./MarkdownText";
import { Button, Row } from "react-bootstrap";
import { useError, useLocalize } from "../../utils/hooks";

const ErrorMessage = ({ error, close }) => {
    const { localize } = useLocalize();

    const { title, message, type } = useError(error);

    return (
        <FormPage title={title} cardTitleClassName="text-center">
            <div className={`alert alert-${type}`}>
                <MarkdownText>{message}</MarkdownText>
            </div>
            <Row className="justify-content-center">
                <Button variant="secondary" className="w-em-8" onClick={close}>
                    {localize("global.ok")}
                </Button>
            </Row>
        </FormPage>
    );
};

export default ErrorMessage;
