import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { worldCountries } from "../../config/world-countries";
import Required from "./Required";
import { Typeahead } from "react-bootstrap-typeahead";
import useLanguage from "../../utils/hooks/useLanguage";

const NATIONALITY = "nationality";
const DEPARTURE_COUNTRY = "departureCountry";
const VISA_ISSUANCE_COUNTRY = "visaIssuanceCountry";
const USING_ALPHA2 = [NATIONALITY, DEPARTURE_COUNTRY];

export default function CountrySelect({
    onChange,
    value,
    defaultValue,
    errors,
    disabled,
    type = NATIONALITY,
    customLabel,
    className = ""
}) {
    const intl = useIntl();
    const localize = id => intl.formatMessage({ id });
    const { language } = useLanguage();

    const [inputFocused, setInputFocused] = useState(false);

    const getCountryName = c => c.name[language] || c.name.en;

    const sortedCountries = [
        worldCountries[0],
        worldCountries[1],
        worldCountries[2],
        ...worldCountries.slice(3).sort((a, b) => (getCountryName(a) < getCountryName(b) ? -1 : 1)),
    ];

    const options = sortedCountries.map(c => ({
        value: USING_ALPHA2.includes(type) ? c.code : c.alpha3,
        label: getCountryName(c).replace(" (", ", ").replace(")", ""),
    }));
    const pattern = worldCountries.map(c => getCountryName(c).replace(" (", ", ").replace(")", "")).join("|");

    const handleChange = o => o[0]?.value && onChange && onChange(o[0].value);
    const onBlur = e => {
        const val = options.find(o => o.label === e.target.value)?.value;
        onChange && onChange(val);
    };

    const focusInput = () => setInputFocused(true);

    useEffect(() => {
        if (value) {
            const code = options.find(o => o.label === value)?.value;
            code && onChange && onChange(code);
        }
    }, [value]);

    const localizationType =
        type === VISA_ISSUANCE_COUNTRY
            ? "visa.visaDetails.documentDetails.visaIssuanceCountry"
            : `flightDetails.flight.${type}`;

    return (
        <>
            <Form.Label>{customLabel ? customLabel : <FormattedMessage id={`${localizationType}.label`} />}</Form.Label>
            {inputFocused || !value ? (
                <Form.Group className={className} controlId={type}>
                    <Typeahead
                        id={type}
                        disabled={disabled}
                        defaultInputValue={value}
                        onChange={handleChange}
                        onBlur={onBlur}
                        placeholder={localize("countrySelect.defaultOptionText")}
                        options={options}
                        autoFocus={!!value}
                        paginationText={localize("countrySelect.paginationText")}
                        renderInput={({ inputRef, referenceElementRef, ...inputProps }) => (
                            <>
                                <Form.Control
                                    required
                                    defaultValue={defaultValue}
                                    pattern={pattern}
                                    {...inputProps}
                                    ref={input => {
                                        inputRef(input);
                                        referenceElementRef(input);
                                    }}
                                    isInvalid={errors && errors[type]}
                                />
                                <Required message={localize(`${localizationType}.missingMessage`)} />
                            </>
                        )}
                    />
                </Form.Group>
            ) : (
                <Form.Group className={className} controlId={`${type}-placeholder`}>
                    <Form.Control
                        required
                        defaultValue={value}
                        pattern={pattern}
                        isInvalid={errors && errors[type]}
                        placeholder={localize("countrySelect.defaultOptionText")}
                        onFocus={() => focusInput()}
                    />
                </Form.Group>
            )}
        </>
    );
}
