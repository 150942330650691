const ComponentWrapper = Component => {
    return ({ show, children, ...otherProps }) => {
        if (show) {
            return <Component {...otherProps} />;
        }
        return <>{children}</>;
    };
};

export default ComponentWrapper;

