import { personId, correlationId } from "../../utils/constants";

const VikingCruiseResponse = {
    id: correlationId,
    // personId: "GHK3VBFPK4NQ3S3FNOISAN5WRA",
    personId: personId,
    status: "ACTIVE",
    userData: {
        sharedData: {
            cruiseDetails: {
                embarkationDate: "2021-08-06",
                bookingNumber: "1234567",
                cruiseLineCode: "viking",
            },
        },
        userSpecificData: {
            [personId]: {
                firstName: "Nikola",
                lastName: "Dragic",
                dateOfBirth: "1960-08-15",
                nationality: "US",
            },
        },
    },
    credentialActions: [],
    createdDtm: "2021-06-08T12:56:43.641+0000",
    lastUpdatedDtm: "2021-06-08T12:56:44.092+0000",
    instructions: {
        allowCompanions: true,
        maxCompanions: 9,
        userProcesses: {
            [personId]: {
                processSection: "Viking_Cruises_for_all_users",
                userId: personId,
                displayName: "Your Trip for all passengers",
                displayDescription: "This will guide you through making a trip.",
                displayInstructions: "Select the steps below to complete each task required for your trip.",
                steps: [
                    {
                        name: "COVID19_VACCINE",
                        notBeforeDtm: "2021-07-07T00:00:00.000+0000",
                        notAfterDtm: "2021-08-04T00:00:00.000+0000",
                        available: true,
                        expired: false,
                        optional: false,
                        complete: false,
                        displayName: "COVID-19 Vaccination Review",
                        displayDescription: "Click to submit your vaccination certificate for review or check status.",
                        displayInstructions: "Provide your vaccination certificate for review",
                        view: {
                            type: "INTERNAL_BOOTSTRAP",
                            reference: "covidVaccineView",
                            view: "/addvaccination/cap:viking",
                            parameters: {
                                status: {
                                    values: {
                                        en: {
                                            label: "Your COVID-19 Vaccination Review",
                                        },
                                        es: {
                                            label: "Su revisión de vacunación contra COVID-19",
                                        },
                                        ja: {
                                            label: "あなたのCOVID-19ワクチン接種レビュー",
                                        },
                                    },
                                },
                                bodyText: {
                                    values: {
                                        en:
                                            "Please complete the details below and upload an image of your vaccination certificate for validation.</p>",
                                        es:
                                            "Complete los detalles a continuación y cargue una imagen de su certificado de vacunación para su validación.</p>",
                                        ja:
                                            "以下の詳細を記入し、確認のためにワクチン接種証明書の画像をアップロードしてください。",
                                    },
                                },
                                vaccineType: {
                                    values: {
                                        en: {
                                            label: "Vaccine Type",
                                            placeholder: "Select Vaccine Type",
                                            missingMessage: "Please select a vaccine type.",
                                        },
                                        es: {
                                            label: "Tipo de vacuna",
                                            placeholder: "Seleccione el tipo de vacuna",
                                            missingMessage: "Seleccione un tipo de vacuna.",
                                        },
                                        ja: {
                                            label: "ワクチンの種類",
                                            placeholder: "ワクチンの種類を選択する",
                                            missingMessage: "ワクチンの種類を選択してください",
                                        },
                                    },
                                },
                                vaccineTypes: [
                                    {
                                        name: "Pfizer-BioNTech (Comirnaty)",
                                        numDoses: 2,
                                        fullyVaccinatedAfterDays: 14,
                                    },
                                    {
                                        name: "Moderna (Moderna)",
                                        numDoses: 2,
                                        fullyVaccinatedAfterDays: 14,
                                    },
                                    {
                                        name: "Johnson & Johnson (Janssen)",
                                        numDoses: 1,
                                        fullyVaccinatedAfterDays: 14,
                                    },
                                    {
                                        name: "AstraZeneca/Oxford (Covishield, Vaxzevria)",
                                        numDoses: 2,
                                        fullyVaccinatedAfterDays: 14,
                                    },
                                    {
                                        name: "Sinopharm (Hayat-Vax)",
                                        numDoses: 2,
                                        fullyVaccinatedAfterDays: 14,
                                    },
                                ],
                                vaccineCardInfo: {
                                    values: {
                                        en: {
                                            label: "This information will be reviewed to ensure its validity",
                                        },
                                        es: {
                                            label: "Esta información será revisada para asegurar su validez.",
                                        },
                                        ja: {
                                            label: "この情報は、その有効性を確認するためにレビューされます",
                                        },
                                    },
                                },
                                submittedMessage: {
                                    values: {
                                        en: {
                                            label:
                                                "Thank you, your vaccination results have been submitted for review. We will reply to you once the review is complete",
                                        },
                                        es: {
                                            label:
                                                "Thank you, your vaccination results have been submitted for review. We will reply to you once the review is complete.",
                                        },
                                        ja: {
                                            label:
                                                "ありがとうございます。予防接種の結果は審査のために提出されました。レビューが完了したら返信します",
                                        },
                                    },
                                },
                                fullyVaccinatedInfo: {
                                    values: {
                                        en: {
                                            label:
                                                "<small>Note:<br />You are considered fully vaccinated:  <br />- 14 days after a second dose in a 2-dose series, such as the Pfizer or Moderna vaccines, or <br />- 14 days after a single-dose vaccine, such as Johnson & Johnson’s Janssen vaccine<p>If you don’t meet these requirements, you are <strong>NOT</strong> fully vaccinated</p></small>",
                                        },
                                        es: {
                                            label:
                                                "<small>Se le considera completamente vacunado: <br />- 14 días después de su segunda dosis en una serie de 2 dosis, como las vacunas Pfizer o Moderna, o  <br />- 14 días después una vacuna de dosis única, como la vacuna Janssen de Johnson & Johnson <p> Si no cumple con estos requisitos, NO está completamente vacunado</p></small>",
                                        },
                                        ja: {
                                            label:
                                                "<small>あなたは完全にワクチン接種されていると見なされます:   <br />- 次の2回投与シリーズの2回目の投与から14日後 (Pfizer or Moderna vaccines, または  <br />- 次のような単回ワクチンの14日後 Johnson & Johnson’s Janssen vaccine<p>これらの要件を満たしていない場合は、完全に予防接種を受けていません</p></small>",
                                        },
                                    },
                                },
                                vaccineAdministered: {
                                    values: {
                                        en: {
                                            label: "Date of Final Vaccine Administered",
                                            missingMessage: "Please enter a valid date",
                                            outOfRangeMessage:
                                                "You must be fully vaccinated {days} days before your trip",
                                            dateInFutureMessage:
                                                "The Date of Final Vaccine Administered cannot be in the future",
                                        },
                                        es: {
                                            label: "Fecha de la última vacuna administrada",
                                            missingMessage: "Por favor introduzca una fecha valida.",
                                            outOfRangeMessage:
                                                "La vacunación final debe administrarse al menos {days} días antes de la salida.",
                                        },
                                        ja: {
                                            label: "最終ワクチン投与日",
                                            missingMessage: "有効な日付を入力してください",
                                            outOfRangeMessage:
                                                "最終ワクチン接種は、出発の少なくとも{days}日前に投与する必要があります",
                                        },
                                    },
                                },
                                useVaccinationCountry: false,
                                acceptedVaccineCountries: [
                                    {
                                        values: {
                                            en: "United States",
                                            es: "Estados Unidos",
                                            ja: "アメリカ",
                                        },
                                        countryCode: "US",
                                        displayDateOfBirth: true,
                                    },
                                    {
                                        values: {
                                            en: "Canada",
                                        },
                                        countryCode: "CA",
                                        displayDateOfBirth: true,
                                    },
                                    {
                                        values: {
                                            en: "United Kingdom",
                                        },
                                        countryCode: "GB",
                                        displayDateOfBirth: true,
                                    },
                                    {
                                        values: {
                                            en: "Australia",
                                        },
                                        countryCode: "AU",
                                        displayDateOfBirth: true,
                                    },
                                    {
                                        values: {
                                            en: "New Zealand",
                                        },
                                        countryCode: "NZ",
                                        displayDateOfBirth: true,
                                    },
                                ],
                                countryAdministeredVaccine: {
                                    values: {
                                        en: {
                                            label: "Country which Administered Vaccine",
                                            placeholder: "Select Country",
                                            missingMessage: "Please select country of vaccination",
                                        },
                                        es: {
                                            label: "País que administró la vacuna",
                                            placeholder: "Seleccionar país",
                                            missingMessage: "Seleccione el país de vacunación",
                                        },
                                        ja: {
                                            label: "ワクチンを投与した国",
                                            placeholder: "Select Country",
                                            missingMessage: "予防接種の国を選択してください",
                                        },
                                    },
                                },
                                vaccineCheckboxConfirmation: {
                                    values: {
                                        en: {
                                            label: "I confirm that i am fully vaccinated",
                                        },
                                        es: {
                                            label: "Confirmo que estoy completamente vacunada",
                                        },
                                        ja: {
                                            label: "完全にワクチン接種されていることを確認します",
                                        },
                                    },
                                },
                            },
                        },
                    },
                ],
            },
        },
    },
};

export default VikingCruiseResponse;
