export const AddIcon = ({ size, customClass, colorClass = "add-icon-stroke" }) => (
    <svg
        className={"svg-icon " + (customClass ? customClass : "")}
        viewBox="0 0 24 24"
        width={size ? size : "24px"}
        height={size ? size : "24px"}
    >
        <g>
            <circle className={colorClass} cx="12" cy="12" r="11" strokeWidth="2" />
            <path className={colorClass} d="M6 12L18 12" strokeWidth="2" />
            <path className={colorClass} d="M12 6L12 18" strokeWidth="2" />
        </g>
    </svg>
);
