import { DateTime } from "luxon";
import en from "./../../lang/en.json";
import es from "./../../lang/es.json";
import ja from "../../lang/ja.json";
import fr from "../../lang/fr.json";
import useQuery from "./useQuery";

const useLanguage = () => {
    const languageQuery = useQuery().get("language");

    const getLocalizeField = language => field => field?.values?.[language] || field?.values?.en || "";
    const getLocalizeFieldOrDefault = language => (field, def) => field?.values?.[language] || field?.values?.en || def;

    const getLocalizedDate = locale => dateTime =>
        DateTime.fromISO(dateTime).setLocale(locale).toLocaleString({
            weekday: "long",
            month: "short",
            day: "2-digit",
            year: "numeric",
        });

    const getCustomLocalizedDate = locale => (dateTime, weekday, month, day, year) =>
        DateTime.fromISO(dateTime).setLocale(locale).toLocaleString({
            weekday,
            month,
            day,
            year,
        });

    const getLocalizedTime = locale => dateTime =>
        DateTime.fromISO(dateTime).setLocale(locale).toLocaleString({
            hour: "2-digit",
            minute: "2-digit",
            hourCycle: "h23",
        });

    const getLocalizations = locale => ({
        locale,
        localizeField: getLocalizeField(locale.substring(0, 2)),
        localizeFieldOrDefault: getLocalizeFieldOrDefault(locale.substring(0, 2)),
        localizeDate: getLocalizedDate(locale),
        localizeCustomDate: getCustomLocalizedDate(locale),
        localizeTime: getLocalizedTime(locale),
    });

    const languageCode = languageQuery ? languageQuery.substring(0, 2) : "";

    const parsedLocale = languageQuery ? languageQuery.split("@")[0] : "";

    switch (languageCode) {
        case "ja":
        case "jp":
            return { language: "ja", translations: { ...en, ...ja }, ...getLocalizations("ja-JP") };
        case "es":
            return { language: "es", translations: { ...en, ...es }, ...getLocalizations("es-ES") };
        case "fr":
            return { language: "fr", translations: { ...en, ...fr }, ...getLocalizations("fr-FR") };
        case "en":
            return { language: "en", translations: en, ...getLocalizations(parsedLocale) };
        default:
            return { language: "en", translations: en, ...getLocalizations("en-US") };
    }
};

export default useLanguage;
