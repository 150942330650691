import { DateTime } from "luxon/build/cjs-browser/luxon";
import React from "react";
import { Form } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import { resolveObjectPath } from "../../../utils/formUtils";
import { useLanguage } from "../../../utils/hooks";
import DateInput, { FUTURE_ONLY, PAST_ONLY } from "../DateInput";

const CustomDateInput = ({ input, isComplete, selectedValue }) => {
    const { name, label, required, min, max, dateType, allowToday, missingMessage, invalidMessage, note } = input;

    const { errors, control } = useFormContext();

    const { localizeField } = useLanguage();

    const dateIsBefore = date => {
        const maxDay = DateTime.now()
            .minus({ days: allowToday ? 0 : 1 })
            .startOf("day");
        return date <= maxDay;
    };

    const dateIsAfter = date => {
        const minDay = DateTime.now()
            .plus({ days: allowToday ? 0 : 1 })
            .startOf("day");
        return date >= minDay;
    };

    const checkDate = {
        [PAST_ONLY]: dateIsBefore,
        [FUTURE_ONLY]: dateIsAfter,
    };

    const validate = date => !dateType || (checkDate[dateType] && checkDate[dateType](DateTime.fromISO(date)));

    const getError = resolveObjectPath(errors);
    const getErrorMessage = errorType => localizeField(errorType === "required" ? missingMessage : invalidMessage);

    return (
        <Form.Group>
            <Controller
                id={name}
                control={control}
                name={name}
                rules={{ required, validate }}
                defaultValue=""
                render={({ onChange }) => (
                    <DateInput
                        label={localizeField(label)}
                        errorMessage={errors[name] && getErrorMessage(errors[name].type)}
                        dateSelected={onChange}
                        min={min}
                        max={max}
                        type={dateType}
                        allowToday={allowToday}
                        disabled={isComplete}
                        value={selectedValue}
                    />
                )}
            />
            {note && <Form.Text className="text-muted">{localizeField(note)}</Form.Text>}
            {getError(name) && <Form.Text className="text-required">{getErrorMessage(getError(name).type)}</Form.Text>}
        </Form.Group>
    );
};

export default CustomDateInput;
