import { useContext, useEffect, useState } from "react";
import CredentialContext from "../../config/CredentialContext";
import { getReturnJson, putReturnJson } from "../dataFetching";
import { getBaseUrl } from "../textUtils";

const useCompanionData = isCompanionPage => {
    const { userId, credentialTypeHref, setError } = useContext(CredentialContext);

    const [fetchedCompanions, setFetchedCompanions] = useState([]);
    const [isFetchingCompanions, setIsFetchingCompanions] = useState(false);
    const [isUpdatingCompanion, setIsUpdatingCompanion] = useState(false);

    const updateCompanion = (companionData, companionId) => {
        const updateCompanionUrl = `${getBaseUrl(credentialTypeHref)}/api/persons/${userId}/companions/${companionId}`;

        setIsUpdatingCompanion(true);

        const handleResponse = () => {
            setIsUpdatingCompanion(false);
        };

        putReturnJson(updateCompanionUrl, companionData, handleResponse, setError);
    };

    const checkAndUpdateCompanion = (
        companionId,
        { firstName, lastName, birthDate, nationality, passportCountry, countryOfResidence }
    ) => {
        let shouldUpdateCompanion = false;
        if (!companionId || !Array.isArray(fetchedCompanions) || !fetchedCompanions.length) {
            return;
        }

        const companion = fetchedCompanions.find(fC => fC.id === companionId);

        if (typeof companion !== "object") {
            return;
        }

        const updatedCompanion = { ...companion };

        updatedCompanion.privateInfo.extendedAttributes = updatedCompanion.privateInfo.extendedAttributes || {};

        if (firstName && firstName !== companion.firstName) {
            updatedCompanion.firstName = firstName;
            shouldUpdateCompanion = true;
        }
        if (lastName && lastName !== companion.lastName) {
            updatedCompanion.lastName = lastName;
            shouldUpdateCompanion = true;
        }
        if (birthDate) {
            const datePart = birthDate?.split("T")[0];

            if (datePart !== updatedCompanion.privateInfo.extendedAttributes.dateOfBirth) {
                updatedCompanion.privateInfo.extendedAttributes.dateOfBirth = datePart;
                shouldUpdateCompanion = true;
            }
        }
        if (nationality && nationality !== updatedCompanion.privateInfo.extendedAttributes.nationality) {
            updatedCompanion.privateInfo.extendedAttributes.nationality = nationality;
            shouldUpdateCompanion = true;
        }
        if (passportCountry && passportCountry !== updatedCompanion.privateInfo.extendedAttributes.passportCountry) {
            updatedCompanion.privateInfo.extendedAttributes.passportCountry = passportCountry;
            shouldUpdateCompanion = true;
        }
        if (
            countryOfResidence &&
            countryOfResidence !== updatedCompanion.privateInfo.extendedAttributes.countryOfResidence
        ) {
            updatedCompanion.privateInfo.extendedAttributes.countryOfResidence = countryOfResidence;
            shouldUpdateCompanion = true;
        }

        if (shouldUpdateCompanion) {
            updateCompanion(updatedCompanion, companionId);
        }
    };

    useEffect(() => {
        if (isCompanionPage) {
            const getCompanionsUrl = `${getBaseUrl(credentialTypeHref)}/api/persons/${userId}/companions`;

            setIsFetchingCompanions(true);

            const handleResponse = response => {
                setFetchedCompanions(response);
                setIsFetchingCompanions(false);
            };

            getReturnJson(getCompanionsUrl, null, handleResponse, setError);
        }
    }, [userId, isCompanionPage]);

    return {
        fetchedCompanions,
        isFetchingCompanions,
        isUpdatingCompanion,
        checkAndUpdateCompanion,
    };
};

export default useCompanionData;
