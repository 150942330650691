import { FETCH_API_KEY_REQUEST, FETCH_API_KEY_SUCCESS, FETCH_API_KEY_FAIL } from "../constants/fetchApiKeyConstants";

export const fetchApiKeyReducer = (state = { key: "" }, action) => {
    switch (action.type) {
        case FETCH_API_KEY_REQUEST:
            return { loading: true, key: "" };
        case FETCH_API_KEY_SUCCESS:
            return { loading: false, key: action.payload };
        case FETCH_API_KEY_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};
