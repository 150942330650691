import React from "react";
import { isArrivalsFlight } from "../../../utils/flightUtils";
import ArrivalDetailsForSummary from "./FlightSummaries/ArrivalDetailsForSummary";
import FlightDetailsForSummary from "./FlightSummaries/FlightDetailsForSummary";

const FlightSummary = ({ flightDetails }) => {

    return (
        isArrivalsFlight(flightDetails) ?
            <ArrivalDetailsForSummary details={flightDetails} /> :
            <FlightDetailsForSummary details={flightDetails} />
    );
};

export default FlightSummary;