import { useContext } from "react";
import { useParams } from "react-router";
import CredentialContext from "../../config/CredentialContext";
import { CARNIVAL_CONSENT } from "../../pages/_components/Wrappers/PrivacyPolicyWrapper/constants";
import { getReturnJson } from "../dataFetching";
import { useLocalize, useQuery } from "./index";

const useCruiseLine = () => {
    const { localize, localizeOrDefault } = useLocalize();
    const { cruiseFormType } = useParams();
    const { globalConfig } = useContext(CredentialContext);
    const {
        bookingFields = [{ name: "bookingNumber" }, { name: "lastName" }],
        tripServerHostUrl: configTripHost = "",
        bookingUrl: configBookingUrl = "",
    } = globalConfig;
    const query = useQuery();
    const env = query.get("env");
    const cruiseLineCode = query.get("cruiseLineCode") || cruiseFormType;
    const tripHost = query.get("tripServerHostUrl") || configTripHost;
    const bookingUrl = query.get("bookingUrl") || configBookingUrl || `${tripHost}/api/carnival/bookingDetails`;

    const policies = CARNIVAL_CONSENT[cruiseLineCode?.toUpperCase()];

    const fetchCarnivalBookingDetails = (processResponse, setError) => {
        const params = {
            env,
            cruiseLineCode,
            bookingNumber: query.get("bookingNumber"),
            lastName: query.get("lastName"),
        };
        getReturnJson(bookingUrl, params, processResponse, setError);
    };

    const fetchCustomBookingDetails = (processResponse, setError) => {
        const queryFields = query.getAll("bookingFields");
        const fields = queryFields?.length > 0 ? queryFields : bookingFields.map(bf => bf.name);
        const params = fields.reduce((acc, curr) => ({ ...acc, [curr]: query.get(curr) }), { env, cruiseLineCode });
        getReturnJson(bookingUrl, params, processResponse, setError);
    };

    const fetchCarnivalBookingDetailsFromProcessInstance = (processInstance, processResponse, setError) => {
        const {
            bookingLastName,
            bookingNumber,
            cruiseLineCode: bookingCruiseLineCode,
        } = processInstance.userData.sharedData.cruiseDetails;
        const params = {
            env,
            cruiseLineCode: bookingCruiseLineCode,
            bookingNumber,
            lastName: bookingLastName,
        };
        getReturnJson(bookingUrl, params, processResponse, setError);
    };

    const defaultError = {
        title: localize("global.error.error"),
        message: localize("global.error.defaultMessage"),
    };
    const prefix = "carnivalcruise.retrievebooking.error";
    const getError = (code, prop) => {
        const defaultErrorElement = defaultError[prop];
        return localizeOrDefault(`${prefix}.${code}.${prop}`, null, defaultErrorElement);
    };
    const getBookingError = code => ({ title: getError(code, "title"), message: getError(code, "message") });

    const getBookingErrorContent = error => {
        if (error?.code) {
            return getBookingError(error.code);
        } else if (error?.message && !error.message?.includes("Invalid Group Id")) {
            return { title: "", message: error.message };
        } else if (error?.text && !error.text?.includes("Invalid Group Id")) {
            return { title: "", message: error.text };
        } else {
            return { title: "", message: "" };
        }
    };

    return {
        cruiseLineCode: cruiseLineCode || cruiseFormType,
        bookingUrl,
        policies,
        bookingFields,
        fetchCarnivalBookingDetails,
        fetchCustomBookingDetails,
        fetchCarnivalBookingDetailsFromProcessInstance,
        getBookingError,
        getBookingErrorContent
    };
};

export default useCruiseLine;
