import { Card } from "react-bootstrap";
import React from "react";
import { useIntl } from "react-intl";
import { DateTime } from "luxon";
import { useTimeFormat } from "../../utils/hooks";

const getTimeString = time => {
    const minutes = time % 100;
    const hours = (time - minutes) / 100;
    return `${hours < 10 ? "0" : ""}${hours}:${minutes < 10 ? "0" : ""}${minutes}`;
};

const ContentRow = props => <div className="d-flex flex-row justify-content-between">{props.children}</div>;

const FlightInfo = ({ flight, departureAirport, arrivalAirport }) => {
    const intl = useIntl();
    const withDefault = (value = "-") => func => args => (!args ? value : func(args));
    const { simpleTimeFormat } = useTimeFormat();

    const localizeDate = time =>
        DateTime.fromISO(time, { setZone: true }).toLocaleString({ ...DateTime.DATE_MED, locale: intl.locale });

    const getAirportLocalTime = time => DateTime.fromISO(time, { setZone: true }).toLocaleString(simpleTimeFormat);

    return (
        <Card className="rounded-card p-3">
            <ContentRow>
                <h4>{flight.originCode || departureAirport}</h4>
                <h4>&#9992;</h4>
                <h4>{flight.destinationCode || arrivalAirport}</h4>
            </ContentRow>
            <ContentRow className="justify-content-between">
                <span>{intl.formatMessage({ id: "global.departing" })}</span>
                <span>{intl.formatMessage({ id: "global.arriving" })}</span>
            </ContentRow>
            <ContentRow>
                <span>{withDefault()(localizeDate)(flight.departureFlightTime)}</span>
                <span>{withDefault()(localizeDate)(flight.arrivalFlightTime)}</span>
            </ContentRow>
            <ContentRow>
                <span>
                    {flight.depTime
                        ? getTimeString(flight.depTime)
                        : withDefault()(getAirportLocalTime)(flight.departureFlightTime)}
                </span>
                <span>
                    {flight.arrTime
                        ? getTimeString(flight.arrTime)
                        : withDefault()(getAirportLocalTime)(flight.arrivalFlightTime)}
                </span>
            </ContentRow>
        </Card>
    );
};

export default FlightInfo;
