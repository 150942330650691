import React from "react";
import { useQuery } from "../../utils/hooks";

import ManageTrip from './ManageTrip';
import ManageCruiseTrip from './ManageCruiseTrip';

const CARNIVAL = 'carnival';

export default function ManageTripRouter() {
    const query = useQuery();
    const passType = query.get("passType");

    return passType === CARNIVAL ? <ManageCruiseTrip /> : <ManageTrip />
};
