import {  useParams } from "react-router";
import { FormattedMessage, useIntl } from "react-intl";
import React from "react";
import { Button } from "react-bootstrap";
import { countries } from "../../../config/countries";
import { closeForm } from "../../../utils/dataProcessing";

const DeprecationChecker = ({ children, isDeprecated }) => {
    const { country } = useParams();
    const intl = useIntl();
    const localize = (id, values) => intl.formatMessage({ id }, values);

    if (!isDeprecated) return <>{children}</>;

    return (
        <>
            <div className="alert alert-warning">
                <FormattedMessage id="pass.deprecated.message" values={{ country: countries[country].fullName }} />
            </div>
            <Button className="w-100 my-2" variant="primary" onClick={closeForm}>
                {localize("global.back")}
            </Button>
        </>
    );
};

export default DeprecationChecker;
