import React, { useEffect } from "react";
import CustomInput from "../_components/CustomInput/CustomInput";
import { useQuery } from "../../utils/hooks";

const EventDetailsInputs = ({
    bookingFields,
    methods,
    user,
    glideReferenceNumbers,
    glideNationality,
    glidePassportCountry,
    glideCountryOfResidence
}) => {
    const query = useQuery();

    const eventCode = query.get("eventCode");

    const getExistingReferenceNumber = () => {
        if (glideReferenceNumbers && glideReferenceNumbers.length) {
            const reference = glideReferenceNumbers.find(refNumber => refNumber.split(":")[0] === eventCode);
            return reference ? reference.split(":")[1] : "";
        }
    };

    useEffect(() => {
        if (user) {
            const firstNameInputExists = bookingFields?.find(bF => bF?.name === "firstName");
            const lastNameInputExists = bookingFields?.find(bF => bF?.name === "lastName");
            const dateOfBirthInputExists = bookingFields?.find(bF => bF?.name === "dateOfBirth");
            const referenceNumberInputExists = bookingFields?.find(bF => bF?.name === "referenceNumber");

            firstNameInputExists && methods.setValue("firstName", user.firstName);
            lastNameInputExists && methods.setValue("lastName", user.lastName);
            dateOfBirthInputExists && methods.setValue("dateOfBirth", user?.extendedAttributes?.dateOfBirth);
            referenceNumberInputExists && methods.setValue("referenceNumber", getExistingReferenceNumber());
        }
    }, [user, glideReferenceNumbers, bookingFields]);

    const userDetails = {
        glideNationality,
        glidePassportCountry,
        glideCountryOfResidence
    };

    return (
        <>
            {Array.isArray(bookingFields) &&
                bookingFields.map((bF, index) => (
                    <CustomInput key={index} input={bF} selectedValue={methods.getValues(bF.name)} details={userDetails} />
                ))}
        </>
    );
};

export default EventDetailsInputs;
