import React from "react";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Link, useLocation } from "react-router-dom";

const AddCompanions = ({ disabled, addCompanionTitle, addCompanionText }) => {
    const location = useLocation();

    return (
        <>
            <Link to={`/addcompanion${location.search}`}>
                <Button disabled={disabled} type="button" className="w-100">
                    <FormattedMessage id="manageTrip.newCompanionButton" />
                </Button>
            </Link>
            <div className="pt-3 text-80">({addCompanionText})</div>
        </>
    );
}

export default AddCompanions;