import { DateTime } from "luxon";
import { useQuery } from "./index";

const useTimeFormat = () => {

    const query = useQuery();
    const hourFormat = query.get("hourFormat");

    return {
        simpleTimeFormat: hourFormat?.toString() === "24" ? DateTime.TIME_24_SIMPLE : DateTime.TIME_SIMPLE
    }
}

export default useTimeFormat;