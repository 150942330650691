import { useFormContext } from "react-hook-form";
import { Form } from "react-bootstrap";
import React from "react";
import { resolveObjectPath } from "../../../utils/formUtils";
import { useLanguage } from "../../../utils/hooks";

const CustomNumberInput = ({ input, isComplete }) => {
    const {
        name,
        label,
        required,
        min,
        max,
        missingMessage,
        invalidMessage,
        invalidRangeMessage,
    } = input;

    const { register, errors } = useFormContext();

    const { localizeField } = useLanguage();

    const getErrorMessage = type => {
        switch (type) {
            case "required":
                return missingMessage;
            case "min":
            case "max":
                return invalidRangeMessage || invalidMessage;
            default:
                return missingMessage;
        }
    };

    const getLocalizedErrorMessage = type => localizeField(getErrorMessage(type));
    const getError = resolveObjectPath(errors);

    return (
        <Form.Group>
            <Form.Label htmlFor={name}>{localizeField(label)}</Form.Label>
            <Form.Control
                type="number"
                name={name}
                disabled={isComplete}
                ref={register({
                    required,
                    min,
                    max,
                })}
            />
            {getError(name) && (
                <Form.Text className="text-required">{getLocalizedErrorMessage(getError(name).type)}</Form.Text>
            )}
        </Form.Group>
    );
};

export default CustomNumberInput;
