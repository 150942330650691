// scrolls to first invalid element in submitted form
export const scrollToError = formEl => {
    const invalidEl = formEl?.querySelector(":invalid");
    if (invalidEl) {
        invalidEl.scrollIntoView({ block: "center" });
    }
};

export const autoHeight = element => {
    element.style.height = "auto";
    element.style.height = element.scrollHeight + 2 + "px";
};

export const autoHeightAll = selector => document.querySelectorAll(selector).forEach(el => autoHeight(el));

/**
 * Given a path and an object, return the value of the path
 * @param path - The path to resolve.
 * @param obj - The object to be traversed.
 * @returns The value of the last key in the path.
 */
export const resolvePath = (path, obj) => {
    return path.split('.').reduce((prev, curr) => {
        return prev ? prev[curr] : null
    }, obj)
};

export const resolveObjectPath = obj => path => resolvePath(path, obj)

/**
 * Given an array of objects, return a map of the objects' keys to their values
 * @param [arr=[]] - The array of objects to be mapped.
 * @param [keyProp=name] - The name of the property that contains the key.
 * @param [valueProp=value] - The property that contains the value of the option.
 * @param [fallbackProp=defaultValue] - The property to use if the valueProp is undefined.
 * @returns A map of the values of the array.
 */
export const getValueMap = (arr = [], keyProp = "name", valueProp = "value", fallbackProp = "defaultValue") => {
    return arr.reduce((acc, curr) => ({...acc, [curr[keyProp]]: curr[valueProp] || curr[fallbackProp]}), {});
}

/**
 * Given an object, a property name, and an optional default value, return the value of the property if it exists,
 * otherwise return the default value
 * @param obj - The object to check for the property.
 * @param propName - The name of the property to get.
 * @param [defaultValue=true] - The default value to return if the property doesn't exist.
 * @returns The value of the property propName of the object obj. If the object does not have the property propName, the
 * defaultValue is returned.
 */
export const getBoolPropValue = (obj, propName, defaultValue = true) => {
    return obj && propName && obj.hasOwnProperty(propName) ? Boolean(obj[propName]) : defaultValue;
}

export const shouldDisplay = obj => getBoolPropValue(obj, "display");

/**
 * It tries to parse the response as JSON, and if it fails, it returns a default message
 * @param res - The response object from the fetch call.
 * @param [defaultMessage=An error occurred. Please try again later.] - The message to return if the response is not JSON.
 * @returns A function that takes in two parameters, res and defaultMessage.
 */
export const tryResponseJson = async (res, defaultMessage = "An error occurred. Please try again later.") => {
    try {
        return await res.json();
    } catch (e) {
        return { message: defaultMessage };
    }
}

/**
 * Try to parse a string as JSON, and if it fails, return a default message.
 * @param str - The string to try to parse.
 * @param [defaultMessage=An error occurred. Please try again later.] - The message to return if the string cannot be
 * parsed.
 * @returns A function that takes two parameters, str and defaultMessage.
 */
export const tryParseOrDefault = (str, defaultMessage = "An error occurred. Please try again later.") => {
    try {
        return JSON.parse(str);
    } catch {
        return defaultMessage;
    }
}

/**
 * If you can't stringify an object, return a default message
 * @param obj - The object to try to stringify.
 * @param [defaultMessage=An error occurred. Please try again later.] - The message to return if the object cannot be
 * stringified.
 * @returns A function that takes two parameters, obj and defaultMessage.
 */
export const tryStringifyOrDefault = (obj, defaultMessage = "An error occurred. Please try again later.") => {
    try {
        return JSON.stringify(obj);
    } catch {
        return defaultMessage;
    }
}
