import { useContext, useEffect, useState } from "react";
import CredentialContext from "../../config/CredentialContext";
import useQuery from "./useQuery";
import useDocumentReview from "./useDocumentReview";
import { getSteps } from "../steps";
import { REVIEW_STATUS } from "../constants";

const useSubmittedDocuments = () => {
    const { processInstance } = useContext(CredentialContext);
    const query = useQuery();
    const stepId = query.get("stepId");
    const { getDocumentSubmissionStatus } = useDocumentReview(processInstance);

    const [documentTypeSteps, setDocumentTypeSteps] = useState([]);
    const [submitDocumentsForReviewSteps, setSubmitDocumentsForReviewSteps] = useState([]);

    const isDocumentTypeStep = step => !!step?.view?.parameters?.documentType;
    const isSubmitDocumentsForReviewStep = step => step?.view?.parameters?.submitDocumentsForReviewRequired === true;
    const isForbidDocumentValidationStep = step => isDocumentTypeStep(step) ? (step?.view?.parameters?.forbidDocumentValidation ?? false) : true;

    useEffect(() => {
        const allSteps = getSteps(processInstance, stepId);

        if (Array.isArray(allSteps) && allSteps?.length) {
            setDocumentTypeSteps(allSteps.filter(isDocumentTypeStep));
            setSubmitDocumentsForReviewSteps(allSteps.filter(isSubmitDocumentsForReviewStep));
        } else {
            setDocumentTypeSteps([]);
            setSubmitDocumentsForReviewSteps([]);
        }
    }, [processInstance, stepId]);

    const isStepNonOptional = step => !step?.optional;

    const documentTypesNonOptional = documentTypeSteps.filter(isStepNonOptional);

    const submitDocumentsForReviewStepsNonOptional = submitDocumentsForReviewSteps.filter(isStepNonOptional);

    const isLastReviewStepToSubmit = step => {
        if (isSubmitDocumentsForReviewStep(step)) {
            const otherNonOptionalReviewStepsToSubmit = submitDocumentsForReviewStepsNonOptional?.filter(
                sDFRSNO => sDFRSNO?.name !== step?.name
            );

            return otherNonOptionalReviewStepsToSubmit?.length > 0
                ? otherNonOptionalReviewStepsToSubmit?.every(
                      oNORSTS =>
                          getDocumentSubmissionStatus(oNORSTS) === REVIEW_STATUS.PENDING ||
                          getDocumentSubmissionStatus(oNORSTS) === REVIEW_STATUS.SUBMITTED ||
                          getDocumentSubmissionStatus(oNORSTS) === REVIEW_STATUS.ACCEPTED ||
                          oNORSTS?.complete === true
                  )
                : true;
        }

        return false;
    };

    // Specific to BIRTH_CERTIFICATE step as NAME_CHANGE step can be dynamically added to the process instance
    const isLastReviewStepToSubmitWithProcessInstance = (step, customProcessInstance) => {
        if (isSubmitDocumentsForReviewStep(step)) {
            const allSteps = getSteps(customProcessInstance, stepId);

            let submitDocumentsForReviewStepsNonOptionalWithProcessInstance;

            if (Array.isArray(allSteps) && allSteps?.length) {
                submitDocumentsForReviewStepsNonOptionalWithProcessInstance = allSteps
                    .filter(isSubmitDocumentsForReviewStep)
                    .filter(isStepNonOptional);
            } else {
                submitDocumentsForReviewStepsNonOptionalWithProcessInstance = [];
            }

            const otherNonOptionalReviewStepsToSubmit = submitDocumentsForReviewStepsNonOptionalWithProcessInstance?.filter(
                sDFRSNO => sDFRSNO?.name !== step?.name
            );

            return otherNonOptionalReviewStepsToSubmit?.length > 0
                ? otherNonOptionalReviewStepsToSubmit?.every(
                      oNORSTS =>
                          getDocumentSubmissionStatus(oNORSTS) === REVIEW_STATUS.PENDING ||
                          getDocumentSubmissionStatus(oNORSTS) === REVIEW_STATUS.SUBMITTED ||
                          getDocumentSubmissionStatus(oNORSTS) === REVIEW_STATUS.ACCEPTED ||
                          oNORSTS?.complete === true
                  )
                : true;
        }

        return false;
    };

    return {
        documentTypeSteps,
        documentTypesNonOptional,
        submitDocumentsForReviewSteps,
        submitDocumentsForReviewStepsNonOptional,
        isDocumentTypeStep,
        isSubmitDocumentsForReviewStep,
        isForbidDocumentValidationStep,
        isLastReviewStepToSubmit,
        isLastReviewStepToSubmitWithProcessInstance,
    };
};

export default useSubmittedDocuments;
