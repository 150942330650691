import { datadogRum } from "@datadog/browser-rum";

const urlParams = new URLSearchParams(window.location.search);
const analyticsEnabled = urlParams.get("analyticsEnabled");

const getEnv = () => {
    const { hostname } = window.location;
    const env = {
        localhost: "local",
        "glide-ci-develop-glide-web-trip.identityx-build.com": "dev",
        "glide-web-trip.myverifly-staging.com": "stage",
        "glide-web-trip.myverifly.com": "prod",
    };
    return env[hostname];
};

if (analyticsEnabled !== "false") {
    datadogRum.init({
        applicationId: "50a0d1dd-8099-4d90-bc4a-d0dbfd38ebca",
        clientToken: "pubb2538d8b11564d926af61f738c95ff38",
        site: "datadoghq.eu",
        service: "veriflywebapp",
        env: getEnv(),
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        sampleRate: 100,
        sessionReplaySampleRate: 20,
        trackInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: "mask-user-input",
    });

    datadogRum.startSessionReplayRecording();
}
