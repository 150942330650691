import React from 'react';
import { useParams } from 'react-router';
import CapCruiseDetails from './CapCruiseDetails';
import CarnivalCruise from '../CarnivalCruise';

const CRUISE = {
    viking: {
        name: 'viking',
        component: <CapCruiseDetails />
    },
    CCL: {
        name: 'CCL',
        component: <CarnivalCruise />
    }
};


const AddCruiseDetails = () => {
    const { cruiseFormType } = useParams();

    if (!CRUISE[cruiseFormType]) {
        return CRUISE.viking.component;
    }

    return CRUISE[cruiseFormType].component;
};

export default AddCruiseDetails;
