import { useContext, useState, useEffect } from "react";
import CredentialContext from "../../config/CredentialContext";
import { useQuery, useLocalize, useLanguage } from "./index";

const useHeaderTitle = () => {
    const { processInstance, globalConfig = {} } = useContext(CredentialContext);
    const query = useQuery();
    const { localize } = useLocalize();
    const { localizeField } = useLanguage();
    const { headerTitle = '' } = globalConfig;

    const [title, setTitle] = useState("");

    useEffect(() => {

        if (headerTitle) {
            return setTitle(localizeField(headerTitle));
        }

        // this can be removed when eventDetailsHeader is setted in config
        if (processInstance && processInstance.userData.sharedData.eventDetails) {
            return setTitle(query.get("eventDetailsHeader") || localize("eventDetails.header"));
        }

        return setTitle(localize("manageTrip.myTrip"));
    })

    return title;
};

export default useHeaderTitle;
