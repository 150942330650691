import { rest } from "msw";
import { addCompanionGet, addCompanionPost, addCompanionPut } from "../responseResolvers/addCompanion";
import { baseUrl, localHost } from "./constants";
import {flightGetPerson, flightPostPerson} from "../responseResolvers/flights";
import { addFeedbackPost } from '../responseResolvers/misc';

export const glidePersonHandlers = [
    /******************* Glide Person **********************/

    // GET /api/persons/:personId
    rest.get(`${baseUrl}/api/persons/:personId`, flightGetPerson),

    // POST /api/persons/:personId
    rest.post(`${baseUrl}/api/persons/:personId`, flightPostPerson),

    /******************* Glide Companions **********************/

    // GET /api/persons/:personId/companions
    rest.get(`${baseUrl}/api/persons/:personId/companions`, addCompanionGet),

    // POST /api/persons/:personId/companions
    rest.post(`${baseUrl}/api/persons/:personId/companions`, addCompanionPost),

    // PUT /api/persons/:personId/companions/:companionId
    rest.put(`${baseUrl}/api/persons/:personId/companions/:companionId`, addCompanionPut),

    /******************* Feedback *******************/
    // POST /api/persons/:personId/userFeedback
    rest.post(`${localHost}/api/persons/:personId/userFeedback`, addFeedbackPost),
];
