import React from "react";
import { Button, Modal } from "react-bootstrap";
import { InfoIcon } from "../../../../../icons/InfoIcon";
import { useLocalize } from "../../../../../utils/hooks";
import MarkdownText from "../../../../_components/MarkdownText";

const UpdateInfoModal = ({ messages = [], onShow, onClose }) => {
    const { localize } = useLocalize();

    return (
        <Modal show={onShow} onHide={onClose} centered className="px-3">
            <Modal.Body className={"document_validation_modal info_headings"}>
                <div className="text-center">
                    <InfoIcon size="40px" colorClass="secondary-info-icon" />
                </div>
                <h5 className="my-2">{localize("manageTrip.updateInfoModal.title")}</h5>
                {messages?.map((message, index) => (
                    <MarkdownText key={index} className="text-left">{message}</MarkdownText>
                ))}
                <p>{localize("manageTrip.updateInfoModal.message")}</p>
                <div className="py-1">
                    <Button type="button" className="w-100 btn-secondary" onClick={onClose}>
                        {localize("global.close")}
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default UpdateInfoModal;
