import React, { useContext } from "react";
import { Row, Modal, Button } from "react-bootstrap";
import CredentialContext from "../../../config/CredentialContext";
import { useLocalize, useCap, useUserDataPath } from "../../../utils/hooks";
import { REDIRECT } from "../../../utils/hooks/useCap";
import { ErrorIcon } from "../../../icons/ErrorIcon";

const DeleteCompanionModal = ({ showModal, closeModal, companionId }) => {
    const { localize } = useLocalize();
    const { updateProcessInstance } = useCap(REDIRECT.NONE);
    const { processInstance } = useContext(CredentialContext);
    const { deleteUserSpecificData } = useUserDataPath(processInstance);

    const removeCompanion = () => {
        const processInstanceAfterDeletion = deleteUserSpecificData(companionId);
        updateProcessInstance(processInstanceAfterDeletion);
        closeModal();
    };

    return (
        <Modal show={showModal} onHide={closeModal} centered dialogClassName="border-modal">
            <Modal.Body>
                <div className="pb-3 text-center">
                    <ErrorIcon size="50px" />
                </div>
                <div className="pb-3 px-2">
                    <div className="pb-3 px-5 text-center">
                        {localize("addInterlineFlightDetails.deleteCompanionModal.title")}
                    </div>
                    <div className="pb-3">{localize("addInterlineFlightDetails.deleteCompanionModal.text")}</div>
                </div>
                <div className="pb-3">
                    <Row className="justify-content-center">
                        <Button type="submit" className="px-5 mx-2 my-2" onClick={removeCompanion}>
                            {localize("addInterlineFlightDetails.deleteCompanionModal.button.confirm")}
                        </Button>
                        <Button type="submit" className="px-5 mx-2 my-2" onClick={closeModal}>
                            {localize("addInterlineFlightDetails.deleteCompanionModal.button.cancel")}
                        </Button>
                    </Row>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default DeleteCompanionModal;
