import React from "react";
import { Form } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import MarkdownText from "./MarkdownText";

const CustomSwitch = ({
    control,
    switchRef,
    switchName,
    switchInfo,
    switchInfoHtml,
    switchError,
    validated,
    customClass = "",
    title,
    label,
    customLabel,
    switchLabel,
    hideSwitch,
    onChecked,
    disabled,
    required,
    checked,
}) => {
    return (
        <Form.Group className="no-border">
            <Form.Control className="d-none" value={""} type="text" disabled isInvalid={validated && !!switchError} />
            <Form.Group
                controlId={control}
                className={customClass + " custom-switch-wrapper" + (disabled ? " disabled" : "")}
            >
                {label && (
                    <Form.Check.Label>
                        {title && (
                            <h5>
                                <Form.Label>{title}</Form.Label>
                            </h5>
                        )}
                        <Form.Label>
                            <MarkdownText>{label}</MarkdownText>
                        </Form.Label>
                    </Form.Check.Label>
                )}
                {customLabel && (
                    <Form.Check.Label>
                        {title && (
                            <h5>
                                <Form.Label>{title}</Form.Label>
                            </h5>
                        )}
                        <Form.Label>
                            {customLabel}
                        </Form.Label>
                    </Form.Check.Label>
                )}
                {!hideSwitch && (
                    <div className="d-flex justify-content-between align-items-start mt-2 mr-2">
                        <h6 className="mt-1">
                            <Form.Label className="text-left pr-1">{switchLabel || <FormattedMessage id="privacyPolicy.agree" />}</Form.Label>
                        </h6>
                        <Form.Check
                            ref={switchRef}
                            name={switchName}
                            type="switch"
                            id={control}
                            onChange={onChecked}
                            disabled={disabled}
                            required={required}
                            checked={checked}
                        />
                    </div>
                )}
                {switchInfo && (
                    <div className="text-muted mt-2">
                        <small>{switchInfo}</small>
                    </div>
                )}
                {switchInfoHtml && (
                    <div className="text-muted mt-2" dangerouslySetInnerHTML={{ __html: switchInfoHtml }} />
                )}
            </Form.Group>
            {switchError && <div className="invalid-feedback">{switchError}</div>}
        </Form.Group>
    );
};

export default CustomSwitch;
