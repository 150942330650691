import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { DateTime } from "luxon";
import { useFromToQuery, useCurrentTime } from "../../../utils/hooks";
import { devReturnTrue } from "../../../utils/constants";

const DateRangeChecker = ({ children }) => {
    const currentTime = useCurrentTime();
    const { from, to, isInDateRange, isDateInPast } = useFromToQuery(currentTime);
    const intl = useIntl();

    const InPast = () => (
        <div className="alert alert-warning">
            <FormattedMessage
                id="global.inPast"
                values={{
                    to: DateTime.fromISO(to).toLocaleString({ ...DateTime.DATETIME_MED, locale: intl.locale }),
                }}
            />
        </div>
    );

    const NotInDateRange = () => (
        <div className="alert alert-warning">
            <FormattedMessage
                id="global.notInRange"
                values={{
                    from: DateTime.fromISO(from).toLocaleString({ ...DateTime.DATETIME_MED, locale: intl.locale }),
                    to: DateTime.fromISO(to).toLocaleString({ ...DateTime.DATETIME_MED, locale: intl.locale }),
                }}
            />
        </div>
    );

    const isInPast = () => isDateInPast ? <InPast /> : <NotInDateRange />;

    return (
        <>
            {(isInDateRange || devReturnTrue) ? children : isInPast()}
        </>
    );
};

export default DateRangeChecker;
