const ArrowRightIcon = ({ width, height, className }) => (
    <svg
        width={width}
        height={height}
        className={`svg-icon ${className}`}
        viewBox="0 0 13 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M7.5 1L11.5 4.65093L7.5 8.30186" className="premier-icon-stroke" />
        <path d="M11.5 4.5H0" className="premier-icon-stroke" />
    </svg>
);

ArrowRightIcon.defaultProps = {
    width: '13',
    height: '9',
    className: ''
}

export default ArrowRightIcon;
