import { DateTime } from "luxon";
import { v4 as uuidv4 } from "uuid";
import { getLocal, setLocal } from "./utils";

const COMPANIONS_KEY = "companions";

const companionExample = {
    id: "6DSAWWDGFWUBKRBYLRRS4A25NU",
    firstName: "John",
    lastName: "Doe",
    href: "http://localhost:9001/api/persons/56JBQEDRINAM4VJ2VTWPEC7XCM/companions/6DSAWWDGFWUBKRBYLRRS4A25NU",
    personId: "56JBQEDRINAM4VJ2VTWPEC7XCM",
    createdDtm: "2020-12-13T23:57:36.774+0100",
    lastUpdatedDtm: "2020-12-13T23:57:36.774+0100",
    privateInfo: {
        extendedAttributes: {
            dateOfBirth: "1986-09-25",
            nationality: "US"
        },
    },
};

// GET list of companions
export const addCompanionGet = (req, res, ctx) => {
    const companions = getLocal(COMPANIONS_KEY) || [];
    return res(ctx.status(200), ctx.json([...companions, companionExample]));
};

// POST
export const addCompanionPost = (req, res, ctx) => {
    const id = uuidv4();
    const companion = {
        ...req.body,
        id,
        createdDtm: DateTime.local().toISO(),
        lastUpdatedDtm: DateTime.local().toISO(),
        personId: id,
    };
    const companions = getLocal(COMPANIONS_KEY) || [];
    companions.push(companion);
    setLocal(COMPANIONS_KEY, companions);
    return res(ctx.status(201), ctx.json(companion));
};

// PUT update a companion
export const addCompanionPut = (req, res, ctx) => {
    const updatedCompanion = {
        ...req.body,
        lastUpdatedDtm: DateTime.local().toISO()
    };

    const companions = getLocal(COMPANIONS_KEY) || [];
    
    if (companions.length === 0) {
        companions.push(updatedCompanion);
        setLocal(COMPANIONS_KEY, companions);
    } else {
        const updatedCompanions = companions.map(companion => companion.id === updatedCompanion.id ? updatedCompanion : companion);
        setLocal(COMPANIONS_KEY, updatedCompanions);
    }

    return res(ctx.status(201), ctx.json(updatedCompanion));
}

