import { DateTime } from "luxon";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import CredentialContext from "../../../../../config/CredentialContext";
import { useUserDataPath } from "../../../../../utils/hooks";

const useFlightBlock = (blockId, companionId) => {
    const { flightBlock: block = blockId } = useParams();
    const { processInstance } = useContext(CredentialContext);
    const { getUserDataPath } = useUserDataPath(processInstance);
    const user = getUserDataPath();

    const [flightSegments, setFlightSegments] = useState([]);
    const [notAvailableUntil, setNotAvailableUntil] = useState();
    const [isAvailable, setIsAvailable] = useState(true);
    const [blockComplete, setBlockComplete] = useState(false);
    const [unsupported, setUnsupported] = useState(false);

    useEffect(() => {
        if (processInstance && block) {
            const flightBlocks = processInstance.userData.sharedData.interlineFlightDetails.flightBlocks;
            const flightBlock = flightBlocks.find(b => b.id === block);
            const { flightSegments: segments, notAvailableUntil: until } = flightBlock;

            setFlightSegments(segments);
            setNotAvailableUntil(until);
        }
    }, [processInstance, block]);

    useEffect(() => {
        if (flightSegments) {
            const stepsKeys = flightSegments.map(s => `${block}:${s.id}:${companionId}`);
            const stepsComplete = [];
            const { userProcesses } = processInstance?.instructions || {};

            stepsKeys.forEach(key => {
                if (!!userProcesses[key]) {
                    if (userProcesses[key].steps.length === 0) {
                        stepsComplete.push(true);
                    } else {
                        stepsComplete.push(userProcesses[key].steps.every(curr => !!curr.complete || curr.optional));
                    }
                } else {
                    stepsComplete.push(false);
                }
            });

            const allStepsAreCompleted = stepsComplete.every(curr => !!curr);
            setBlockComplete(allStepsAreCompleted);
            setUnsupported(flightSegments.every(({ unsupported }) => !!unsupported));
        }
    }, [flightSegments]);

    useEffect(() => {
        if (notAvailableUntil) {
            setIsAvailable(DateTime.fromISO(notAvailableUntil) <= DateTime.now());
        }
    }, [notAvailableUntil]);

    const getBlockStatus = () => {
        if (unsupported) {
            return "unsupported";
        }

        if (!isAvailable) {
            return "unavailable";
        }
        if (blockComplete) {
            return "complete";
        }
        return "incomplete";
    };

    const showVisaMessage = () => {
        const blockSegments = Object.keys(user.flightSpecificData)?.filter(key => key.includes(block));
        const blockSegmentObjects = blockSegments.map(bS => user.flightSpecificData[bS]);

        const isVisaRequired = blockSegmentObjects?.some(bSO => bSO?.visaRequirements?.checkRequired);

        return isVisaRequired;
    };

    const getAirports = () => {
        const departure = flightSegments?.[0]?.departure || {};
        const arrival = flightSegments?.[flightSegments.length - 1]?.arrival || {};
        const { airportName: startAirportName, airportCode: startAirportCode } = departure;
        const { airportName: endAirportName, airportCode: endAirportCode } = arrival;
        return { startAirportName, startAirportCode, endAirportName, endAirportCode };
    };

    return {
        flightSegments,
        blockComplete,
        isAvailable,
        notAvailableUntil,
        showVisaMessage,
        getBlockStatus,
        getAirports,
        unsupported,
    };
};

export default useFlightBlock;
