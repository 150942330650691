import fetch from "./fetch";
import { tryResponseJson } from "./formUtils";
import { requestToken } from "./RequestQueue";

const urlParams = new URLSearchParams(window.location.search);
const tripHost = urlParams.get("tripServerHostUrl") || "";

const TRIPS_URL = tripHost + "/api/me/trips";
const TESTS_URL = tripHost + "/api/me/covid-test-results";
const TRAVEL_AUTH_URL = tripHost + "/api/me/travel-authorizations";
const ATTESTATIONS_URL = tripHost + "/api/me/attestations";
const AIRLINES_URL = tripHost + "/api/airlines";
const AIRPORTS_URL = tripHost + "/api/airports";
const DEPARTURE_AIRPORTS_URL = tripHost + "/api/airports/departure";
const ARRIVAL_AIRPORTS_URL = tripHost + "/api/airports/arrival";
const FLIGHTS_URL = tripHost + "/api/flights/search";
const PRIVACY_URL = tripHost + "/api/me/approvals/policy-url";
const UNAPPROVED_POLICY_URL = tripHost + "/api/me/approvals/policy-url/unapproved";
const TESTING_CENTERS_URL = tripHost + "/api/testing-centers";
const COVID_TEST_REQUIREMENTS_URL = tripHost + "/api/covid-test-requirements";
const GOOGLE_MAP_API_URL = tripHost + "/api/google-key";
const ALL_AIRLINES_URL = tripHost + "/api/airlines/all";
const SUPPORTED_COUNTRIES = tripHost + "/api/countries";
const TRIP_FLIGHT_BLOCKS_URL = airline => `${tripHost}/api/${airline}/flightBlocks`;

const doRequest = async (action, url, params, callback, onError, toJson = true) => {
    const token = await requestToken();
    let resolveResponse;
    action(url, token, params)
        .then(async response => {
            if (!toJson) {
                callback && callback(response.status);
            }
            const responseBody = await tryResponseJson(response);
            if (!response.ok) {
                onError && onError(responseBody);
            } else {
                callback && callback(responseBody);
            }
            resolveResponse(responseBody);
        })
        .catch(error => {
            onError && onError(error);
        });
    return new Promise(resolve => {
        resolveResponse = resolve;
    });
};

export const getReturnJson = (url, params, callback, onError) =>
    doRequest(fetch.get, url, params, callback, onError, true);
export const getReturnJsonOrError = (url, params, callback, onError) =>
    doRequest(fetch.get, url, params, callback, onError, true);
export const getReturnStatus = (url, callback, onError) => doRequest(fetch.get, url, null, callback, onError, false);

export const postNoBodyReturnJson = (url, callback, onError) =>
    doRequest(fetch.create, url, null, callback, onError, true);
export const postNoBodyReturnStatus = (url, callback, onError) =>
    doRequest(fetch.create, url, null, callback, onError, false);
export const postReturnJson = (url, body, callback, onError) =>
    doRequest(fetch.create, url, body, callback, onError, true);
export const postReturnStatus = (url, body, callback, onError) =>
    doRequest(fetch.create, url, body, callback, onError, false);

export const putNoBodyReturnJson = (url, callback, onError) =>
    doRequest(fetch.update, url, null, callback, onError, true);
export const putReturnJson = (url, body, callback, onError) =>
    doRequest(fetch.update, url, body, callback, onError, true);

export const deleteReturnStatus = (url, callback, onError) =>
    doRequest(fetch.remove, url, null, callback, onError, false);

export const fetchConsentUrls = (callback, onError) => getReturnJson(PRIVACY_URL, null, callback, onError);
export const fetchUnapprovedPolicies = (params, callback, onError) => getReturnJson(UNAPPROVED_POLICY_URL, params, callback, onError);
export const postAcceptance = (url, consents, callback, onError) =>
    postReturnStatus(url, consents, callback, onError);
export const fetchTrips = (country, callback, onError) =>
    getReturnJson(TRIPS_URL, { destinationCountry: country }, callback, onError);
export const fetchTests = (country, callback, onError) =>
    getReturnJson(TESTS_URL, { destinationCountry: country }, callback, onError);
export const fetchTest = (id, callback, onError) => getReturnJson(TESTS_URL + "/" + id, null, callback, onError);
export const cancelTest = (id, callback, onError) => deleteReturnStatus(TESTS_URL + "/" + id, callback, onError);
export const fetchTravelAuth = (id, callback, onError) =>
    getReturnJson(TRAVEL_AUTH_URL + "/" + id, null, callback, onError);
export const fetchAttestations = (tripId, callback, onError) =>
    getReturnJson(ATTESTATIONS_URL, { tripId: tripId }, callback, onError);
export const fetchAirlinesToCountry = (countryCode, callback, onError) =>
    getReturnJson(AIRLINES_URL, { countryCode: countryCode }, callback, onError);

export const fetchAllAirlines = (callback, onError) => getReturnJson(ALL_AIRLINES_URL, null, callback, onError);

export const fetchSupportedCountries = (callback, carrierCode, onError) =>
    getReturnJson(SUPPORTED_COUNTRIES, { supportedDestination: true, carrierCode }, callback, onError);

export const fetchAirportsToCountry = (airlineCode, countryCode, callback, onError) =>
    getReturnJson(DEPARTURE_AIRPORTS_URL, { airlineCode: airlineCode, countryCode: countryCode }, callback, onError);
export const fetchAirportsFromOrigin = (airlineCode, departureAirportCode, arrivalCountryCode, callback, onError) =>
    getReturnJson(
        ARRIVAL_AIRPORTS_URL,
        { airlineCode: airlineCode, airportCode: departureAirportCode, arrivalCountry: arrivalCountryCode },
        callback,
        onError
    );
export const fetchAirportsFromOriginWithoutArrivalCountry = (airlineCode, departureAirportCode, callback, onError) => getReturnJson(
    ARRIVAL_AIRPORTS_URL,
    { airlineCode: airlineCode, airportCode: departureAirportCode },
    callback,
    onError
);
export const fetchAllAirports = (callback, onError) => getReturnJson(AIRPORTS_URL, null, callback, onError);
export const fetchFlights = (airlineCode, departureAirportCode, arrivalAirportCode, callback, onError) =>
    getReturnJson(
        FLIGHTS_URL,
        { airlineCode: airlineCode, departureCode: departureAirportCode, arrivalCode: arrivalAirportCode },
        callback,
        onError
    );
export const fetchTestingCenters = (latitude, longitude, testTypes, range, callback, onError) =>
    getReturnJson(TESTING_CENTERS_URL, { latitude, longitude, testTypes, range }, callback, onError);
export const fetchSingleTestingCenter = (id, callback, onError) =>
    getReturnJson(`${TESTING_CENTERS_URL}/${id}`, {}, callback, onError);
export const fetchCovidTestRequirements = (callback, onError) =>
    getReturnJson(COVID_TEST_REQUIREMENTS_URL, {}, callback, onError);
export const fetchGoogleMapApiKey = (callback, onError) => getReturnJson(GOOGLE_MAP_API_URL, {}, callback, onError);

export const fetchConfig = (url, callback, onError) => {
    getReturnJson(`${url}/config`, null, callback, onError);
};

export const fetchInterlineFlight = (airline, flightDetails, callback, onError) => {
    getReturnJson(TRIP_FLIGHT_BLOCKS_URL(airline), flightDetails, callback, onError);
};
