const policyObject = {
    biometricPolicyConsentUrl: "https://myverifly.com/policies/2021-12-15/privacy/privacy.html?policy=biometric",
    carnivalCovidConsentUrl: "https://www.carnivalcorp.com/privacy-notice?covid",
    carnivalDataStoragePrivacyUrl: "https://www.carnivalcorp.com/privacy-notice?dataStorage",
    carnivalPrivacyUrl: "https://www.carnivalcorp.com/privacy-notice?privacy",
    covidConsentUrl: "https://myverifly.com/policies/2021-02-16/covid/privacy.html",
    cruiseCovidConsentUrl: "https://myverifly.com/policies/2021-06-03/viking/covid/privacy.html",
    cruiseDataStoragePrivacyUrl: "https://myverifly.com/policies/2021-06-03/viking/storage/privacy.html",
    cruisePrivacyUrl: "https://myverifly.com/policies/2021-06-03/viking/privacy/privacy.html",
    dataStorageConsentUrl: "https://myverifly.com/policies/2021-02-16/storage/privacy.html",
    privatePolicyUrl: "https://myverifly.com/policies/2021-02-16/privacy/privacy.html",
    usagParticipantCovidConsentUrl: "https://myverifly.com/policies/2021-05-24/usagym/privacy.html",
    usagParticipantPrivacyPolicyUrl: "https://myverifly.com/policies/2021-05-24/usagym/privacy.html",
    usagSpectatorCovidConsentUrl: "https://myverifly.com/policies/2021-05-24/usagym/spectator/privacy.html",
    usagSpectatorPrivacyPolicyUrl: "https://myverifly.com/policies/2021-05-24/usagym/spectator/privacy.html",
    maltaPrivacyUrl: "https://myverifly.com/policies/2021-07-28/malta/privacy/privacy.html",
    maltaCovidConsentUrl: "https://myverifly.com/policies/2021-07-28/malta/covid/privacy.html",
    maltaDataStoragePrivacyUrl: "https://myverifly.com/policies/2021-07-28/malta/storage/privacy.html",
    eventPrivacyUrl: "https://myverifly.com/policies/2021-02-16/events/privacy/privacy.html",
    eventCovidConsentUrl: "https://myverifly.com/policies/2021-02-16/events/covid/privacy.html",
    eventDataStoragePrivacyUrl: "https://myverifly.com/policies/2021-02-16/events/storage/privacy.html",
    workReturnPrivacyUrl: "https://myverifly.com/policies/2021-09-10/workreturn/privacy/privacy.html",
    workReturnCovidConsentUrl: "https://myverifly.com/policies/2021-09-10/workreturn/covid/privacy.html",
    workReturnDataStoragePrivacyUrl: "https://myverifly.com/policies/2021-09-10/workreturn/storage/privacy.html",
    halDataStoragePrivacyUrl: "https://www.hollandamerica.com/en_US/legal-privacy/privacy-policy.html",
};

// GET
export const policyUrlsGet = (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(policyObject));
};

const aprrovedPolicies = {
    mainUser: [],
};

const createPolicyObject = (policyNames = []) =>
    policyNames.map(pN => ({
        name: pN,
        version: 1,
        created: "2023-03-31T12:37:34:000Z",
        provider: "trip-service",
        title: pN,
        text:
            `<b>${pN}:</b> I consent to share my data as well as all my personal belongings with the totally inconspicuous multi-national megacoorporation which I'm sure has my best interests at heart. List of all the things I consent to can be found {link}.`,
        url: "https://myverifly.com/policies/2021-06-21/viking/privacy/privacy.html",
        linkText: "here",
    }));

export const unapprovedPoliciesGet = (req, res, ctx) => {
    const policyNames = req.url.searchParams.get("policyNames");
    const companionId = req.url.searchParams.get("companionId");
    const all = req.url.searchParams.get("all");

    const arrayOfPolicyNames = policyNames?.split(",");

    const getMockedResponse = () => {
        if (all) {
            return createPolicyObject(arrayOfPolicyNames);
        }
        else if (companionId) {
            if (Array.isArray(aprrovedPolicies[companionId])) {
                const policiesToBeApproved = arrayOfPolicyNames.filter(
                    pN => !aprrovedPolicies[companionId].some(aP => aP === pN)
                );
                // Assume policies get approved right away by user
                policiesToBeApproved.forEach(p => aprrovedPolicies[companionId].push(p));

                return createPolicyObject(policiesToBeApproved);
            } else {
                // Assume policies get approved right away by user
                aprrovedPolicies[companionId] = arrayOfPolicyNames;
                return createPolicyObject(arrayOfPolicyNames);
            }
        } else {
            const policiesToBeApproved = arrayOfPolicyNames.filter(
                pN => !aprrovedPolicies.mainUser.some(aP => aP === pN)
            );
            // Assume policies get approved right away by user
            policiesToBeApproved.forEach(p => aprrovedPolicies.mainUser.push(p));

            return createPolicyObject(policiesToBeApproved);
        }
    };

    return res(ctx.status(200), ctx.delay(), ctx.json(getMockedResponse()));
};
