import { useIntl } from "react-intl";
import { Col, Row } from "react-bootstrap";
import { DateTime } from "luxon";
import React, { useContext } from "react";
import CredentialContext from "../../../config/CredentialContext";
import { useLanguage, useLocalize, useQuery, useStep } from "../../../utils/hooks";
import { isBeforeYouGoToTheAirportStep } from "../../../utils/steps";

const CruiseSummary = ({ cruiseDetails }) => {
    const { bookingNumber, embarkationDate } = cruiseDetails;
    const { processInstance, globalConfig } = useContext(CredentialContext);
    const { referenceLabel, dateLabel } = globalConfig;
    const query = useQuery();
    const { getParameterTextValue } = useStep(processInstance, isBeforeYouGoToTheAirportStep, true);
    const { language, localizeField } = useLanguage();

    const getLabel = parameter => getParameterTextValue(parameter, language, "label");

    const intl = useIntl();
    const { localize } = useLocalize();
    const getReferenceLabel = () =>
        localizeField(referenceLabel) ||
        query.get("referenceLabel") ||
        getLabel("bookingNumber") ||
        localize("cruiseDetails.bookingNumber.label");

    const getDateLabel = () =>
        localizeField(dateLabel) ||
        query.get("dateLabel") ||
        getLabel("embarkationDate") ||
        localize("cruiseDetails.embarkationDate.label");

    return (
        <>
            <Row>
                <Col className="pl-0 col-7">{getReferenceLabel()}</Col>
                <Col className="text-right pr-0 col-5">{bookingNumber}</Col>
            </Row>
            <Row>
                <Col className="pl-0 col-7">{getDateLabel()}</Col>
                <Col className="text-right pr-0 col-5">
                    {DateTime.fromISO(embarkationDate, { setZone: true })
                        .setLocale(intl.locale)
                        .toFormat("dd MMM yyyy")}
                </Col>
            </Row>
        </>
    );
};

export default CruiseSummary;
