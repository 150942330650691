import { Card, Container } from "react-bootstrap";
import React from "react";
import KebabMenu from "../../icons/KebabMenu";
import DeprecationChecker from "./Wrappers/DeprecationChecker";
import { InfoIcon } from "../../icons/InfoIcon";

const FormPage = ({
    title,
    header,
    headerMenu,
    showInfo,
    children,
    isDeprecated,
    cardClassName = "",
    cardBodyClassName = "",
    cardTitleClassName = ""
}) => {
    return (
        <Container>
            <Card className={cardClassName}>
                {header && (
                    <Card.Header as="h5" className="">
                        {header}
                        {!!showInfo && (
                            <span className="header-info-icon" onClick={showInfo}>
                                <InfoIcon size="24px" />
                            </span>
                        )}
                    </Card.Header>
                )}
                {headerMenu && <KebabMenu menu={headerMenu} />}
                <Card.Body className={cardBodyClassName}>
                    {title && <Card.Title className={cardTitleClassName}>{title}</Card.Title>}
                    <DeprecationChecker isDeprecated={isDeprecated}>{children}</DeprecationChecker>
                </Card.Body>
            </Card>
        </Container>
    );
};

export default FormPage;
