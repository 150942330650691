import React from "react";
import CountrySelect from "../CountrySelect";
import { Controller, useFormContext } from "react-hook-form";
import { Form } from "react-bootstrap";
import { useLanguage } from "../../../utils/hooks";
import { resolveObjectPath } from "../../../utils/formUtils";

const CustomCountrySelect = ({ details, input, isComplete }) => {
    const { name, label, missingMessage, invalidMessage, note, noGlideDefaults } = input;
    const {
        glideNationality = '',
        glidePassportCountry = '',
        glideCountryOfResidence = '',
    } = details || {};

    const { control, errors } = useFormContext();
    const getError = resolveObjectPath(errors);
    const { localizeField } = useLanguage();

    const getErrorMessage = errorType => localizeField(errorType === "required" ? missingMessage : invalidMessage);

    const getInitialValue = () => {
        if (noGlideDefaults) return;

        if (name === "passportCountry") {
            return glidePassportCountry;
        }

        if (name === "countryOfResidence") {
            return glideCountryOfResidence;
        }

        return glideNationality;
    };

    return (
        <Form.Group>
            <Controller
                id={name}
                control={control}
                name={name}
                rules={{ required: true }}
                defaultValue=""
                render={({ onChange }) => (
                    <CountrySelect
                        type={name}
                        value={getInitialValue()}
                        onChange={onChange}
                        customLabel={localizeField(label)}
                        disabled={isComplete}
                        className="border-bottom-0 pb-0 mb-0"
                    />
                )}
            />
            {note && <Form.Text className="text-muted">{localizeField(note)}</Form.Text>}
            {getError(name) && <Form.Text className="text-required">{getErrorMessage(getError(name).type)}</Form.Text>}
        </Form.Group>
    );
};

export default CustomCountrySelect;
