import { useContext, useState } from "react";
import CredentialContext from "../../config/CredentialContext";
import { fetchUnapprovedPolicies } from "../dataFetching";
import { getStringFromArrayOfStrings } from "../textUtils";

const useUnapprovedPolicies = () => {
    const { updateLoading, setError } = useContext(CredentialContext);

    const [unapprovedPolicies, setUnapprovedPolicies] = useState();

    const getUnapprovedPolicies = async (consentsToCheck = [], companionId) => {
        let params;
        if (companionId) {
            params = {
                policyNames: getStringFromArrayOfStrings(consentsToCheck, ","),
                companionId,
            };
        } else {
            params = {
                policyNames: getStringFromArrayOfStrings(consentsToCheck, ","),
            };
        }

        updateLoading("consents", true);

        const handleFetchedUnapprovedPolicies = consents => {
            setUnapprovedPolicies(consents);
            updateLoading("consents", false);
        };
        const handleFetchedUnapprovedConsentsError = error => {
            updateLoading("consents", false);
            setError(error);
        };

        await fetchUnapprovedPolicies(params, handleFetchedUnapprovedPolicies, handleFetchedUnapprovedConsentsError);
    };

    const [generalPolicies, setGeneralPolicies] = useState();

    const getGeneralPolicies = async (consentsToCheck = []) => {
        const params = {
            policyNames: getStringFromArrayOfStrings(consentsToCheck, ","),
            all: true
        };

        updateLoading("consents", true);

        const handleFetchedGeneralPolicies = consents => {
            setGeneralPolicies(consents);
            updateLoading("consents", false);
        };
        const handleGeneralConsentsError = error => {
            updateLoading("consents", false);
            setError(error);
        };

        await fetchUnapprovedPolicies(params, handleFetchedGeneralPolicies, handleGeneralConsentsError);
    };

    return {
        unapprovedPolicies,
        getUnapprovedPolicies,
        generalPolicies,
        getGeneralPolicies,
    };
};

export default useUnapprovedPolicies;
