import { useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import CredentialContext from "../../config/CredentialContext";
import { scrollToError } from "../../utils/formUtils";

/**
 * Bootstrap form wrapper with internal validation state
 * @component
 * @property {function} handleSubmit Action to be performed on submitting;
 * @property {function} preValidate Optional function to be performed before setting the form validation;
 * @property {string} loadingProp Optional loading prop to be set;
 * @property {function} onValidation Optional function to be called on validation state change;
 */
const ValidationForm = ({ handleSubmit, preValidate, loadingProp = "submit", children, onValidation, deferLoadingToModal = false }) => {
    const { updateLoading } = useContext(CredentialContext);

    const [validated, setValidated] = useState(false);

    useEffect(() => {
        onValidation && onValidation(validated);
    }, [validated]);

    const onSubmit = event => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        preValidate && preValidate();

        if (form.checkValidity() === false) {
            scrollToError(form);
            setValidated(true);
        } else {
            !deferLoadingToModal && loadingProp && updateLoading(loadingProp, true);
            handleSubmit(event);
        }
    };

    return (
        <Form noValidate validated={validated} onSubmit={onSubmit}>
            {children}
        </Form>
    );
};

export default ValidationForm;
