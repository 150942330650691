import { correlationId, personId } from "../../utils/constants";

const FlightResponse = {
    id: correlationId,
    personId: personId,
    status: "ACTIVE",
    userActions: {
        /* [personId]: {
            type: "USER_FEEDBACK",
            type: "GET_ITINERARY",
        }, */
    },
    userData: {
        sharedData: {
            flightDetails: {
                departureDateTime: "2021-07-23T12:45:00-05:00",
                arrivalDateTime: "2021-07-23T17:00:00-04:00",
                departureDate: "2021-07-23",
                flightNumber: "1005",
                airlineCode: "AA",
                origin: "DFW",
                destination: "NAS",
            },
        },
        userSpecificData: {
            [personId]: {
                firstName: "Petar",
                lastName: "Dragic",
                dateOfBirth: "1960-08-15",
                nationality: "US",
            },
        },
    },
    credentialActions: [],
    createdDtm: "2021-06-08T12:56:43.641+0000",
    lastUpdatedDtm: "2021-06-08T12:56:44.092+0000",
    instructions: {
        allowCompanions: true,
        maxCompanions: 9,
        userProcesses: {
            [personId]: {
                processSection: "USA_for_users_11_and_over",
                userId: personId,
                displayName: "Trip to the USA (aged 11 and over)",
                displayDescription: "This will guide you through making a trip to the USA",
                displayInstructions:
                    "Select the *steps* **below** to <a href='#'>complete</a> each task required for your trip.",
                allStepsCompletedMessage: {
                    airlines: {
                        AA:
                            "<p>You're all set!</p><p>Go to <a href='https://aa.com'>aa.com</a> or use the American Airlines app to check in for your flight starting 24 hours before departure.</p><p>If you or your companions encounter any issues with your check-in, don't worry! Follow these simple steps to resolve it:</p><ol><li>Double-check the information you entered in the app to ensure it exactly matches your booking details. Verify your first and last name, booking number, and date of birth.</li><li>If you provided a middle name in your booking, make sure it's included in the VeriFLY app as well. You can easily add your middle name after your first name when creating a passenger.</li><li>If any information is incorrect, kindly update it and try the check-in process again.</li><li>If you need any help or have questions, you can submit a request to our support team at https://support.myverifly.com/hc/en-us/requests/new. We're here to assist you!</li></ol>",
                        defaultAirline: "Checklist complete! You’re all set!",
                        BA:
                            "<p>Checklist complete! You’re all set!</p><p>Go to <a href='https://ba.com'>ba.com</a> or use the British Airways app to check in for your flight starting 24 hours before departure.</p>",
                    },
                },
                steps: [
                    /* {
                        // "name" has to include "DOCUMENT_VALIDATION"
                        name: "DOCUMENT_VALIDATION",
                        notBeforeDtm: "2021-12-23T05:00:00.000+0000",
                        notAfterDtm: "2121-12-28T17:45:00.000+0000",
                        available: true,
                        optional: false,
                        complete: false,
                        hidden: false,
                        displayName: "Document Validation",
                        displayDescription: "Please validate the documents used for the trip.",
                        displayInstructions:
                            "<b>Let's get you verified!</b><br/>Review all of the steps below that we need in order to confirm that you're really you.",
                        view: {
                            type: "INTERNAL_BOOTSTRAP",
                            reference: "documentValidationView",
                            view: "/documentvalidation",
                            parameters: {
                                subSteps: [
                                    // {
                                    //     optional: false,
                                    //     referenceStepName: "PHOTO_IDENTIFICATION",
                                    // },
                                    // {
                                    //     optional: false,
                                    //     referenceStepName: "BIRTH_CERTIFICATE",
                                    // },
                                    // {
                                    //     optional: false,
                                    //     referenceStepName: "GENERIC_DOCUMENT",
                                    // },
                                    {
                                        optional: false,
                                        referenceStepName: "PROOF_OF_PASSPORT",
                                    },
                                    {
                                        optional: false,
                                        referenceStepName: "PROOF_OF_VISA",
                                    },
                                ],
                                // "additionalInfo" array members are rendered as a button with provided "label" that opens a modal with the "bodyText". The optional "type" property of values "warning"/"info"(defaults to "info" if not specified) affects the display.
                                additionalInfo: [
                                    {
                                        label: {
                                            values: {
                                                en: "Names on Travel Documentation",
                                            },
                                        },
                                        bodyText: {
                                            values: {
                                                en:
                                                    "<h2>Names on Travel Documentation</h2>It is important that the guest's full name (first name and last name) on the cruise and airline tickets be the same as the guest's non-expired government-issued photo I.D. they plan to use for travel identification. In the event of a different name on the cruise/airline ticket and the guest's photo I.D. as a result of a marriage, divorce or a legal name change, documentation (original or clear, legible copy) supporting this change is required (at embarkation), such as a marriage certificate, marriage license or legal name change court document. <strong>Failure to bring documentation bridging the name differences could result in denial of boarding.</strong><br />Note: For newly married or soon-to-be married brides, we strongly recommend that if the non-expired government-issued photo I.D. is in the maiden name, the cruise booking be made in the maiden name (do not include the married name); If the reservation was made in the married name, but the non-expired government-issued photo I.D. is in the maiden name, documentation (original or clear, legible copy) supporting this change is required (at embarkation), such as a marriage certificate or marriage license. Failure to bring documentation bridging the name differences could result in denial of boarding.",
                                            },
                                        },
                                        // 2 types: "info", "warning". Defaults to "info" if ommited.
                                        type: "info",
                                    },
                                ],
                            },
                        },
                    }, */
                    /* {
                        // "name" has to include "DOCUMENT_VALIDATION"
                        name: "DOCUMENT_VALIDATION_NO_PASS",
                        notBeforeDtm: "2021-12-23T05:00:00.000+0000",
                        notAfterDtm: "2121-12-28T17:45:00.000+0000",
                        available: true,
                        optional: false,
                        complete: false,
                        hidden: false,
                        displayName: "Document Validation NO_PASS",
                        displayDescription: "Please validate the documents used for the trip.",
                        displayInstructions:
                            "<b>Let's get you verified!</b><br/>Review all of the steps below that we need in order to confirm that you're really you.",
                        view: {
                            type: "INTERNAL_BOOTSTRAP",
                            reference: "documentValidationView",
                            view: "/documentvalidation",
                            parameters: {
                                subSteps: [
                                    {
                                        optional: false,
                                        referenceStepName: "PHOTO_IDENTIFICATION",
                                    },
                                    {
                                        optional: false,
                                        referenceStepName: "BIRTH_CERTIFICATE",
                                    },
                                    {
                                        optional: false,
                                        referenceStepName: "GENERIC_DOCUMENT",
                                    },
                                    {
                                        optional: false,
                                        referenceStepName: "PROOF_OF_PASSPORT",
                                    },
                                    {
                                        optional: false,
                                        referenceStepName: "PROOF_OF_VISA",
                                    },
                                ],
                                // "additionalInfo" array members are rendered as a button with provided "label" that opens a modal with the "bodyText". The optional "type" property of values "warning"/"info"(defaults to "info" if not specified) affects the display.
                                additionalInfo: [
                                    {
                                        label: {
                                            values: {
                                                en: "Names on Travel Documentation",
                                            },
                                        },
                                        bodyText: {
                                            values: {
                                                en:
                                                    "<h2>Names on Travel Documentation</h2>It is important that the guest's full name (first name and last name) on the cruise and airline tickets be the same as the guest's non-expired government-issued photo I.D. they plan to use for travel identification. In the event of a different name on the cruise/airline ticket and the guest's photo I.D. as a result of a marriage, divorce or a legal name change, documentation (original or clear, legible copy) supporting this change is required (at embarkation), such as a marriage certificate, marriage license or legal name change court document. <strong>Failure to bring documentation bridging the name differences could result in denial of boarding.</strong><br />Note: For newly married or soon-to-be married brides, we strongly recommend that if the non-expired government-issued photo I.D. is in the maiden name, the cruise booking be made in the maiden name (do not include the married name); If the reservation was made in the married name, but the non-expired government-issued photo I.D. is in the maiden name, documentation (original or clear, legible copy) supporting this change is required (at embarkation), such as a marriage certificate or marriage license. Failure to bring documentation bridging the name differences could result in denial of boarding.",
                                            },
                                        },
                                        // 2 types: "info", "warning". Defaults to "info" if ommited.
                                        type: "info",
                                    },
                                ],
                            },
                        },
                    }, */
                    {
                        name: "PROOF_OF_PASSPORT",
                        notBeforeDtm: "2023-01-24T15:00:00.000+0000",
                        notAfterDtm: "2023-07-22T16:30:00.000+0000",
                        available: true,
                        expired: false,
                        optional: false,
                        complete: false,
                        hidden: false,
                        displayName: "Passport Verification",
                        displayDescription: "Please submit a copy of the required travel documents for validation.",
                        displayInstructions:
                            "Please submit your passport for processing.<ul><li>Details on your passport will need to match your booking</li><li>Your passport will need to be valid for at least six (6) months prior to arrival in Vietnam</li><ul>",
                        view: {
                            type: "INTERNAL_BOOTSTRAP",
                            reference: "passportView",
                            view: "/addpassportdetails",
                            parameters: {
                                // "documentType" is used when submitting the document. It is the name of the property that the document is submitted under in "submittedDocuments"
                                documentType: "passport",
                                documentName: "Passport",
                                forbidDocumentValidation: false,
                                submitDocumentsForReviewRequired: true,
                                PENDING: {
                                    title: {
                                        values: {
                                        en: 'PASSPORT PENDING'
                                        }
                                    },
                                    description: {
                                        values: {
                                        en: '<p>We\'re processing your <b>Proof of Passport</b>.</p><p>We\'ll notify you here or by email when review is completed.</p><p>Please continue submitting the next step.</p>'
                                        }
                                    },
                                    displayDescription: {
                                        values: {
                                        en: 'This is coming from step.view.parameters.available.displayDescription'
                                        }
                                    }
                                },
                                REJECTED: {
                                    title: {
                                      values: {
                                        en: 'PASSPORT REJECTED!'
                                      }
                                    },
                                    description: {
                                      values: {
                                        en: '<p>Your <b>Proof of Passport</b> submission is rejected.</p><p>When reviewing your document we ran into some issues.</p>'
                                      }
                                    },
                                    resubmitText: {
                                      values: {
                                        en: '<small>If your document is unrecognized it will have to get reviewed by someone at the airport.</small>'
                                      }
                                    },
                                    displayDescription: {
                                      values: {
                                        en: 'This is coming from step.view.parameters.available.displayDescription'
                                      }
                                    }
                                },
                                options: [
                                    {
                                        type: "passports",
                                        view: "/addpassportdetails",
                                        displayName: {
                                            values: {
                                                en: "Provide Passport details",
                                            },
                                        },
                                        displayInstructions: {
                                            values: {
                                                en: "Lorem ipsum dolor sit amet....",
                                            },
                                        },
                                        optionsInstructions: {
                                            values: {
                                                en:
                                                    "Lorem ipsum dolor sit amet, <b>consectetur adipisicing<b> elit. Qui quas beatae at, dolorem porro quidem odit odio totam molestias doloremque.",
                                            },
                                        },
                                        showOptionsUploadButton: true,
                                        optionsUploadButton: {
                                            values: {
                                                en: "Upload Passport",
                                            },
                                        },
                                        showOptionsScanButton: true,
                                        optionsScanButton: {
                                            values: {
                                                en: "Scan Passport",
                                            },
                                        },
                                        formUploadButtonLabel: {
                                            values: {
                                                en: "Upload front of passport",
                                            },
                                        },
                                        formUploadButtonText: {
                                            values: {
                                                en: "Upload Document",
                                            },
                                        },
                                        formUploadButtonMissingMessage: {
                                            values: {
                                                en: "Please upload front and back side of the document",
                                            },
                                        },
                                        useMSIcheck: true,
                                        allowUploadIfMSIcheckFails: false,
                                        formLengthOfStayInput: {
                                            label: {
                                                values: {
                                                    en: "Length of Stay in Days",
                                                },
                                            },
                                            defaultValue: 0,
                                            min: 0,
                                            minMessage: {
                                                values: {
                                                    en: "Minimal valid number of days is 0.",
                                                },
                                            },
                                            max: 100,
                                            maxMessage: {
                                                values: {
                                                    en: "Maximal valid number of days is 100.",
                                                },
                                            },
                                            missingMessage: {
                                                values: {
                                                    en: "This input is required",
                                                },
                                            },
                                            note: {
                                                values: {
                                                    en:
                                                        "In order to determine whether you are required to submit VISA or not, please provide a length of stay in days.",
                                                },
                                            },
                                        },
                                    },
                                ],
                            },
                        },
                    },
                    {
                        name: "PROOF_OF_VISA",
                        notBeforeDtm: "2022-11-24T15:00:00.000+0000",
                        notAfterDtm: "2023-02-22T16:30:00.000+0000",
                        available: true,
                        expired: false,
                        optional: false,
                        complete: false,
                        // hidden: true if referenced under DOCUMENT_VALIDATION
                        // hidden: true,
                        hidden: false,
                        displayName: "Visa Verification",
                        displayDescription: "Please submit a copy of the required travel documents for validation.",
                        displayInstructions:
                            "Please submit your passport for processing.<ul><li>Details on your passport will need to match your booking</li><li>Your passport will need to be valid for at least six (6) months prior to arrival in Vietnam</li><ul>",
                        view: {
                            type: "INTERNAL_BOOTSTRAP",
                            reference: "visaView",
                            view: "/addvisadetails",
                            parameters: {
                                // "documentType" is used when submitting the document. It is the name of the property that the document is submitted under in "submittedDocuments"
                                documentType: "visa",
                                documentName: "Visa",
                                submitDocumentsForReviewRequired: true,
                                forbidDocumentValidation: true,
                                subType: "brazilian eVisa",
                                certifyingAuthority: "Brazil",
                                PENDING: {
                                    title: {
                                        values: {
                                        en: 'VISA PENDING'
                                        }
                                    },
                                    description: {
                                        values: {
                                        en: '<p>We\'re processing your <b>Proof of Visa</b>.</p><p>We\'ll notify you here or by email when review is completed.</p><p>Please continue submitting the next step.</p>'
                                        }
                                    },
                                    displayDescription: {
                                        values: {
                                        en: 'This is coming from step.view.parameters.available.displayDescription'
                                        }
                                    }
                                },
                                REJECTED: {
                                    title: {
                                      values: {
                                        en: 'VISA REJECTED!'
                                      }
                                    },
                                    description: {
                                      values: {
                                        en: '<p>Your <b>Proof of Visa</b> submission is rejected.</p><p>When reviewing your document we ran into some issues.</p>'
                                      }
                                    },
                                    resubmitText: {
                                      values: {
                                        en: '<small>If your document is unrecognized it will have to get reviewed by someone at the airport.</small>'
                                      }
                                    },
                                    displayDescription: {
                                      values: {
                                        en: 'This is coming from step.view.parameters.available.displayDescription'
                                      }
                                    }
                                },
                                options: [
                                    {
                                        available: true,
                                        complete: false,
                                        view: "/addvisadetails",
                                        type: "visas",
                                        displayName: {
                                            values: {
                                                en: "Provide Visa details",
                                            },
                                        },
                                        displayInstructions: {
                                            values: {
                                                en: "Lorem ipsum dolor sit amet....",
                                            },
                                        },
                                        useMSIcheck: true,
                                        allowUploadIfMSIcheckFails: false,
                                        showOptionsScanButton: true,
                                        showOptionsUploadButton: true,
                                        showOptionsEvisaButton: true,
                                        displayEVisaValidation: {
                                            eVisaDisplayName: {
                                                values: {
                                                    en: "Validate my E-VISA",
                                                },
                                            },
                                            eVisaDisplayInstructions: {
                                                values: {
                                                    en:
                                                        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                                                    es:
                                                        "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
                                                },
                                            },
                                        },
                                        optionsTitle: {
                                            values: {
                                                en: "Provide Visa details",
                                            },
                                        },
                                        optionsInstructions: {
                                            values: {
                                                en:
                                                    "<p>Please upload your <b>VISA, EVISA or Approval letter</b>, alternatively you can scan the MRZ bon your VISA. You should then complete the visa information form and submit your details for verification, these details will need to match that of your passport and airline booking.</p>",
                                            },
                                        },
                                        optionsEVisaButton: {
                                            values: {
                                                en: "Validate my E-VISA",
                                            },
                                        },
                                        optionsUploadButton: {
                                            values: {
                                                en: "Upload VISA Details",
                                            },
                                        },
                                        optionsScanButton: {
                                            values: {
                                                en: "Scan VISA",
                                            },
                                        },
                                    },
                                ],
                            },
                        },
                    },
                    {
                        // "name" has to include "PHOTO_IDENTIFICATION"
                        name: "PHOTO_IDENTIFICATION",
                        notBeforeDtm: "2022-01-11T12:11:22.476+0000",
                        notAfterDtm: "2022-01-11T14:11:22.476+0000",
                        available: true,
                        expired: false,
                        optional: false,
                        complete: false,
                        // hidden if referenced under DOCUMENT_VALIDATION STEP(similar to how Health Review flow is done). If is to be standolone step(outside HR) then it can be set to "hiden": "false".
                        // hidden: true,
                        hidden: false,
                        displayName: "Photo Identification Document",
                        displayDescription: "Submit your passport, driver's licence, national ID card...",
                        displayInstructions: "Choose a type of document that you would like to upload.",
                        view: {
                            type: "INTERNAL_BOOTSTRAP",
                            reference: "photoIdentificationView",
                            view: "/photoidentification",
                            parameters: {
                                // "documentType" is used when submitting the document. It is the name of the property that the document is submitted under in "submittedDocuments"
                                documentType: "photoIdentification",
                                documentName: "Photo Identification",
                                // options for user to chose from for document step
                                options: [
                                    {
                                        // "type" value can be submitted in "formData" as e.g. "optionType" property to closely indentify which option was submitted for the step.
                                        type: "driversLicence",
                                        displayName: {
                                            values: {
                                                en: "Upload Driver's Licence",
                                            },
                                        },
                                        // Optional indicator whether the option requires both sides to be photographed. If not present, "front side" photo flow will be enforced.
                                        bothSides: true,
                                        label: {
                                            captureFront: {
                                                values: {
                                                    en: "Captured Driver's Licence Front",
                                                },
                                            },
                                            captureBack: {
                                                values: {
                                                    en: "Captured Driver's Licence Back",
                                                },
                                            },
                                            summary: {
                                                values: {
                                                    en: "Driver's Licence Summary",
                                                },
                                            },
                                        },
                                        // Additonal inputs besides first name, last name and date of birth. Currently supported "expiryDate".
                                        additionalInputs: [
                                            {
                                                name: "documentNumber",
                                                label: {
                                                    values: {
                                                        en: "Document Number Label",
                                                    },
                                                },
                                                maxLength: 20,
                                                minLength: 1,
                                                pattern: "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$",
                                                note: {
                                                    values: {
                                                        en: "Document Number must match...",
                                                    },
                                                },
                                                missingMessage: {
                                                    values: {
                                                        en: "Information Missing",
                                                    },
                                                },
                                                invalidMessage: {
                                                    values: {
                                                        en: "Invalid Format",
                                                    },
                                                },
                                            },
                                            {
                                                name: "issueDate",
                                                // daysInFutureAllowed: 10,
                                                label: {
                                                    values: {
                                                        en: "Issue Date Label",
                                                    },
                                                },
                                                note: {
                                                    values: {
                                                        en: "Issue date must match...",
                                                    },
                                                },
                                                missingMessage: {
                                                    values: {
                                                        en: "Information Missing",
                                                    },
                                                },
                                            },
                                            {
                                                name: "expiryDate",
                                                // allowToday: true,
                                                label: {
                                                    values: {
                                                        en: "Expiry Date Label",
                                                    },
                                                },
                                                note: {
                                                    values: {
                                                        en: "Expiry date must match...",
                                                    },
                                                },
                                                missingMessage: {
                                                    values: {
                                                        en: "Information Missing",
                                                    },
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        type: "nationalIDCard",
                                        displayName: {
                                            values: {
                                                en: "Upload National ID Card",
                                            },
                                        },
                                        label: {
                                            capture: {
                                                values: {
                                                    en: "Captured National ID Card",
                                                },
                                            },
                                            summary: {
                                                values: {
                                                    en: "National ID Card Summary",
                                                },
                                            },
                                        },
                                    },
                                    {
                                        type: "passport",
                                        displayName: {
                                            values: {
                                                en: "Upload Passport",
                                            },
                                        },
                                        bothSides: true,
                                        label: {
                                            captureFront: {
                                                values: {
                                                    en: "Captured Passport Front",
                                                },
                                            },
                                            captureBack: {
                                                values: {
                                                    en: "Captured Passport Back",
                                                },
                                            },
                                            summary: {
                                                values: {
                                                    en: "Passport Summary",
                                                },
                                            },
                                        },
                                        additionalInputs: [{ name: "expiryDate" }],
                                    },
                                ],
                                additionalInfo: [
                                    {
                                        label: {
                                            values: {
                                                en: "See acceptable Forms of Photo IDs",
                                            },
                                        },
                                        bodyText: {
                                            values: {
                                                en:
                                                    "<h2>Acceptable Forms of Photo Identification</h2><p>A non-expired government-issued photo I.D. is required of all guests 16 years of age and older. The following are acceptable:</p><ul><li>Driver's License (a temporary Driver's License with photo is acceptable)</li><li>Driver's Permit</li><li>School/Student I.D. (acceptable for guests 16/17/18 years of age)</li><li>Government-issued identification card including a U.S. Military I.D. with photo (city/state/federal)</li><li>Government-issued Trusted Traveler Program Membership Card (NEXUS/SENTRI/FAST) - for photo identification use only</li></ul>",
                                            },
                                        },
                                        type: "info",
                                    },
                                    {
                                        label: {
                                            values: {
                                                en: "See unacceptable Forms of Photo IDs",
                                            },
                                        },
                                        bodyText: {
                                            values: {
                                                en:
                                                    "<h2>Unacceptable Forms of Photo Identification</h2><ul><li>Copies of any WHTI-Compliant document</li><li>Driver's License as the only proof</li><li>A temporary driver's license (paperwork without a photo I.D.)</li><li>Voter's Registration Card</li><li>Trusted Traveler Program Membership Card (NEXUS/SENTRI/FAST) - may be used for photo identification use only</li></ul>",
                                            },
                                        },
                                        type: "warning",
                                    },
                                ],
                            },
                        },
                    },
                    {
                        // "name" has to include "GENERIC_DOCUMENT"
                        name: "GENERIC_DOCUMENT",
                        notBeforeDtm: "2022-02-11T12:11:22.476+0000",
                        notAfterDtm: "2022-02-11T14:11:22.476+0000",
                        available: true,
                        expired: false,
                        optional: false,
                        complete: false,
                        // hidden: true,
                        hidden: false,
                        displayName: "Generic Document",
                        displayDescription: "Submit your generic upload document...",
                        displayInstructions:
                            "Please follow the visual prompts and instructions that will help guide you in order to ensure a quality image.",
                        view: {
                            type: "INTERNAL_BOOTSTRAP",
                            reference: "genericDocumentView",
                            view: "/genericdocument",
                            parameters: {
                                documentType: "genericDocument",
                                documentName: "Generic Document",
                                submitDocumentsForReviewRequired: true,
                                options: [
                                    {
                                        type: "genericDocument",
                                        displayName: {
                                            values: {
                                                en: "Upload Generic Document",
                                            },
                                        },
                                        label: {
                                            capture: {
                                                values: {
                                                    en: "Captured Generic Document",
                                                },
                                            },
                                            summary: {
                                                values: {
                                                    en: "Birth Generic Document",
                                                },
                                            },
                                        },
                                        // Additonal inputs besides first name, last name and date of birth. Currently supported "documentNumber", "issueDate", "expiryDate".
                                        additionalInputs: [
                                            /* {
                                                name: "documentNumber",
                                                label: {
                                                    values: {
                                                        en: "Document Number Label",
                                                    },
                                                },
                                                maxLength: 20,
                                                minLength: 1,
                                                pattern: "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$",
                                                note: {
                                                    values: {
                                                        en: "Document Number must match...",
                                                    },
                                                },
                                                missingMessage: {
                                                    values: {
                                                        en: "Information Missing",
                                                    },
                                                },
                                                invalidMessage: {
                                                    values: {
                                                        en: "Invalid Format",
                                                    },
                                                },
                                            }, */
                                            {
                                                name: "issueDate",
                                                // daysInFutureAllowed: 10,
                                                label: {
                                                    values: {
                                                        en: "Issue Date Label",
                                                    },
                                                },
                                                note: {
                                                    values: {
                                                        en: "Issue date must match...",
                                                    },
                                                },
                                                missingMessage: {
                                                    values: {
                                                        en: "Information Missing",
                                                    },
                                                },
                                            },
                                            {
                                                name: "expiryDate",
                                                // allowToday: false,
                                                label: {
                                                    values: {
                                                        en: "Expiry Date Label",
                                                    },
                                                },
                                                note: {
                                                    values: {
                                                        en: "Expiry date must match...",
                                                    },
                                                },
                                                missingMessage: {
                                                    values: {
                                                        en: "Information Missing",
                                                    },
                                                },
                                            },
                                        ],
                                    },
                                ],
                                additionalInfo: [
                                    {
                                        label: {
                                            values: {
                                                en: "Traveling with a Generic Document",
                                            },
                                        },
                                        bodyText: {
                                            values: {
                                                en:
                                                    "<h2>Traveling with a Generic Document</h2><p>The following are acceptable:</p><ul><li>An original or copy of a birth certificate issued by a government agency (state/county/city) or the Department of Health and Vital Statistics</li><li>A clear, legible copy (photocopy) of a birth certificate that was originally issued by a government agency (state/county/city) or the Department of Health and Vital Statistics. The copy does not need to be notarized or certified.</li><li>Birth Certificate Card</li><li>A Consular report of Birth Abroad</li><li>Internationally adopted children (under the age of 18): If the adoptive parent was not issued a birth certificate, we will accept as proof of citizenship, a Certificate of Citizenship by the U.S. and adoption paperwork. A Certificate of Citizenship is issued by the U.S. once the adoption is finalized.</li></ul><p>Birth certificates from Puerto Rico issued prior to July 1, 2010 are not valid forms of proof of citizenship and are not accepted by U.S. Customs and Border Protection. Guests from Puerto Rico either need to present a WHTI-compliant document or a government-issued photo I.D. with a validated birth certificate issued after July 1, 2010.</p>",
                                            },
                                        },
                                    },
                                ],
                            },
                        },
                    },
                    {
                        // "name" has to include "BIRTH_CERTIFICATE"
                        name: "BIRTH_CERTIFICATE",
                        notBeforeDtm: "2022-02-11T12:11:22.476+0000",
                        notAfterDtm: "2022-02-11T14:11:22.476+0000",
                        available: true,
                        expired: false,
                        optional: false,
                        complete: false,
                        // hidden: true,
                        hidden: false,
                        displayName: "Birth Certificate",
                        displayDescription: "Submit your birth certificate document...",
                        displayInstructions:
                            "Please follow the visual prompts and instructions that will help guide you in order to ensure a quality image.",
                        view: {
                            type: "INTERNAL_BOOTSTRAP",
                            reference: "birthCertificateView",
                            view: "/birthcertificate",
                            parameters: {
                                documentType: "birthCertificate",
                                documentName: "Birth Certificate",
                                submitDocumentsForReviewRequired: true,
                                options: [
                                    {
                                        type: "birthCertificate",
                                        displayName: {
                                            values: {
                                                en: "Upload Birth Certificate",
                                            },
                                        },
                                        label: {
                                            capture: {
                                                values: {
                                                    en: "Captured Birth Certificate",
                                                },
                                            },
                                            summary: {
                                                values: {
                                                    en: "Birth Certificate Summary",
                                                },
                                            },
                                        },
                                    },
                                ],
                                // If toggled, it will add a 'nameChange: true' flag under user data 'parameters' property, which can be used to add NAME_CHANGE step to procecss definition.
                                switch: {
                                    label: {
                                        values: {
                                            en:
                                                "Is your name different then what is listed on your previously submitted documents?",
                                        },
                                    },
                                    consent: {
                                        values: {
                                            en: "Name is different",
                                        },
                                    },
                                    info: {
                                        values: {
                                            en:
                                                "In case your name is different, you will be required to complete an additional step by uploading a “Name change document” in order to validate your identity.",
                                        },
                                    },
                                },
                                additionalInfo: [
                                    {
                                        label: {
                                            values: {
                                                en: "Traveling with a Birth Certificate",
                                            },
                                        },
                                        bodyText: {
                                            values: {
                                                en:
                                                    "<h2>Traveling with a Birth Certificate</h2><p>The following are acceptable:</p><ul><li>An original or copy of a birth certificate issued by a government agency (state/county/city) or the Department of Health and Vital Statistics</li><li>A clear, legible copy (photocopy) of a birth certificate that was originally issued by a government agency (state/county/city) or the Department of Health and Vital Statistics. The copy does not need to be notarized or certified.</li><li>Birth Certificate Card</li><li>A Consular report of Birth Abroad</li><li>Internationally adopted children (under the age of 18): If the adoptive parent was not issued a birth certificate, we will accept as proof of citizenship, a Certificate of Citizenship by the U.S. and adoption paperwork. A Certificate of Citizenship is issued by the U.S. once the adoption is finalized.</li></ul><p>Birth certificates from Puerto Rico issued prior to July 1, 2010 are not valid forms of proof of citizenship and are not accepted by U.S. Customs and Border Protection. Guests from Puerto Rico either need to present a WHTI-compliant document or a government-issued photo I.D. with a validated birth certificate issued after July 1, 2010.</p>",
                                            },
                                        },
                                    },
                                ],
                            },
                        },
                    },
                    {
                        // "name" has to include "NAME_CHANGE"
                        name: "NAME_CHANGE",
                        notBeforeDtm: "2022-03-11T12:11:22.476+0000",
                        notAfterDtm: "2022-03-11T14:11:22.476+0000",
                        available: true,
                        expired: false,
                        // optional: true,
                        optional: false,
                        complete: false,
                        // hidden: true,
                        hidden: false,
                        displayName: "Name Change Document",
                        displayDescription: "Submit document with proof of name change if your name has changed",
                        displayInstructions:
                            "Please follow the visual prompts and instructions that will help guide you in order to ensure a quality image.",
                        view: {
                            type: "INTERNAL_BOOTSTRAP",
                            reference: "nameChangeView",
                            view: "/namechange",
                            parameters: {
                                documentType: "nameChange",
                                documentName: "Name Change",
                                submitDocumentsForReviewRequired: true,
                                options: [
                                    {
                                        type: "marriageCertificate",
                                        displayName: {
                                            values: {
                                                en: "Upload Marriage Certificate",
                                            },
                                        },
                                        label: {
                                            capture: {
                                                values: {
                                                    en: "Captured Marriage Certificate",
                                                },
                                            },
                                            summary: {
                                                values: {
                                                    en: "Marriage Certificate Summary",
                                                },
                                            },
                                        },
                                    },
                                ],
                            },
                        },
                    },
                    {
                        name: "COMPANION_PHOTO",
                        notBeforeDtm: "2022-02-11T12:11:22.476+0000",
                        notAfterDtm: "2022-02-11T14:11:22.476+0000",
                        available: true,
                        expired: false,
                        optional: false,
                        complete: false,
                        displayName: "Companion Photo",
                        displayDescription:
                            "Whether you upload a favorite or snap a fresh one, we need a pic of your traveling companion.",
                        displayInstructions:
                            "We need a photo of your traveling companion - nothing fancy! You can either snap a pic, or upload one, but please make it passport-style: full face visible, front view, with a plain white or off-white background.",
                        view: {
                            type: "INTERNAL_BOOTSTRAP",
                            reference: "companionPhotoView",
                            view: "/addcompanionphoto",
                            parameters: {
                                bodyText: {
                                    values: {
                                        en:
                                            "<p>We need a photo of your traveling companion - nothing fancy! You can either snap a pic, or upload one, but please make it passport-style: full face visible, front view, with a plain white or off-white background.</p>",
                                        es:
                                            "<p>Necesita agregar una foto de su compañero de viaje, puede tomar una foto o cargarla. Estas fotos deben ser estilo pasaporte, mostrando la cara completa, vista frontal, con un fondo blanco liso o blanquecino.</p>",
                                        ja:
                                            "<p>新しく撮影するかお持ちの写真をアップロードしてください。写真はパスポートのように正面から顔全体が見えるように、無地の白い背景で撮影してください。</p>",
                                    },
                                },
                                photoQuality: "LOW",
                                biometric: true,
                            },
                        },
                        hidden: false,
                    },
                    {
                        name: "STEP_GROUP_HEALTH_REVIEW",
                        notBeforeDtm: "2021-04-23T05:00:00.000+0000",
                        notAfterDtm: "2121-04-28T17:45:00.000+0000",
                        available: true,
                        optional: false,
                        complete: false,
                        hidden: false,
                        displayName: "COVID-19 Review Process",
                        displayDescription: "Complete to fulfill COVID-19 travel requirements.",
                        displayInstructions:
                            "A vaccination <b>OR</b> Test is required.<br/><br/>Please select <b>ONE</b> for the options below:",
                        view: {
                            type: "INTERNAL_BOOTSTRAP",
                            reference: "healthReviewView",
                            view: "/addhealthreview",
                            parameters: {
                                subSteps: [
                                    {
                                        optional: true,
                                        referenceStepName: "COVID19_VACCINE",
                                        note: {
                                            values: {
                                                en: "OR",
                                                es: "...",
                                                ja: "...",
                                            },
                                        },
                                    },
                                    {
                                        optional: true,
                                        referenceStepName: "COVID_TEST",
                                    },
                                ],
                            },
                        },
                    },
                    {
                        name: "COVID_TEST",
                        notBeforeDtm: "2021-06-23T05:00:00.000+0000",
                        notAfterDtm: "2121-06-28T17:45:00.000+0000",
                        available: true,
                        optional: false,
                        complete: false,
                        hidden: false,
                        displayName: "COVID-19 Test Review Process",
                        displayDescription: "Complete to fulfill COVID-19 travel requirements.",
                        displayInstructions: "The following test review methods are available for this step.",
                        view: {
                            type: "INTERNAL_BOOTSTRAP",
                            reference: "covidTestView",
                            view: "/addcovidreview",
                            parameters: {
                                inputs: [
                                    {
                                        name: "samplingMethodSwitch",
                                        label: {
                                            values: {
                                                en:
                                                    "My test sampling method is Nasopharyngeal (Rhinopharyngeal) Swab/Smear, Oropharyngeal Swab/Smear, Nasopharyngeal and Oropharyngeal Swab/Smear.",
                                                es: "",
                                                ja: "",
                                            },
                                        },
                                        switchLabel: {
                                            values: {
                                                en: "I agree",
                                                es: "",
                                                ja: "",
                                            },
                                        },
                                        info: {
                                            values: {
                                                en: "Some additional info",
                                            },
                                        },
                                        type: "switch",
                                        required: true,
                                        invalidMessage: {
                                            values: {
                                                en: "You must agree in order to continue.",
                                            },
                                        },
                                    },
                                ],
                                options: [
                                    {
                                        presentationTypes: ["PAPER"],
                                        displayName: {
                                            values: {
                                                en: "Manual Upload",
                                            },
                                        },
                                        label: {
                                            values: {
                                                en:
                                                    "If you already have your *test result*, you can upload it for review. </br>Please click the <em>button</em> below to begin the manual upload process.",
                                            },
                                        },
                                        linkText: {
                                            values: {
                                                en: "Manual Upload",
                                            },
                                        },
                                        view: "/addcovidtestresults/cap:bs",
                                    },
                                    {
                                        presentationTypes: ["DIGITAL_UPLOAD"],
                                        displayName: {
                                            values: {
                                                en: "Digital Test Certificate",
                                            },
                                        },
                                        displayInstructions: {
                                            values: {
                                                en:
                                                    "<p>Please choose from the options below to validate your Digital Test Certificate.</p><p>This will be reviewed and approved based documentation provided.</p>",
                                            },
                                        },
                                        qrCodeInstructions: {
                                            values: {
                                                en: "If you have the QR code you can scan the code for validation.",
                                            },
                                        },
                                        uploadInstructions: {
                                            values: {
                                                en:
                                                    "If the Digital Certificate is on your local device, you can click here to upload it.",
                                            },
                                        },
                                        uploadButtonText: {
                                            values: {
                                                en: "Upload Certificate for Review",
                                            },
                                        },
                                        label: {
                                            values: {
                                                en:
                                                    "If you have a Digital COVID Certificate, please select the option below for further information. ",
                                            },
                                        },
                                        linkText: {
                                            values: {
                                                en: "Digital Test Certificate",
                                            },
                                        },
                                        additionalInfoLink: {
                                            url:
                                                "https://support.myverifly.com/hc/en-us/articles/4407876017298-Smart-Health-Card-Issuers",
                                            text: {
                                                values: {
                                                    en: "Supported Digital Certificates",
                                                    es: "Certificados digitales admitidos",
                                                },
                                            },
                                        },
                                        view: "/addtestdigitalcertificate",
                                    },
                                    {
                                        presentationTypes: ["DIGITAL_QR"],
                                        displayName: {
                                            values: {
                                                en: "Partner Test Center",
                                            },
                                        },
                                        displayInstructions: {
                                            values: {
                                                en: "Instructions for the partner test center flow",
                                            },
                                        },
                                        label: {
                                            values: {
                                                en:
                                                    "VeriFLY has partnered with Test Providers that will issue your test results directly to VeriFLY.",
                                            },
                                        },
                                        linkText: {
                                            values: {
                                                en: "Scan QR",
                                            },
                                        },
                                        view: "/addtestcenterqrcode",
                                        additionalInfoLink: {
                                            text: {
                                                values: {
                                                    en: "VIEW PARTNER TEST CENTERS",
                                                },
                                            },
                                            url: "/partnertestcenters",
                                        },
                                    },
                                    {
                                        presentationTypes: ["NONE"],
                                        displayName: {
                                            values: {
                                                en: "Testing at port",
                                            },
                                        },
                                        label: {
                                            values: {
                                                en:
                                                    "Have made a reservation for an ANTIGEN TEST on the day of sailing at the port?",
                                            },
                                        },
                                        linkText: {
                                            values: {
                                                en: "Testing at port",
                                            },
                                        },
                                        bodyText: {
                                            values: {
                                                en:
                                                    "Current CDC and CarnivalCruise Line protocols require vaccinated guests to receive a pre-cruise COVID-19 test (PCR or ANTIGEN) within two days of sailing.",
                                            },
                                        },
                                        switchLabel: {
                                            values: {
                                                en:
                                                    "I confirm that I have made a reservation for an ANTIGEN test on the day of sailing",
                                            },
                                        },
                                        view: "/deferredcovidtest",
                                    },
                                ],
                                partnerTestCentersInfo: {
                                    displayName: {
                                        values: {
                                            en: "Partner test centers",
                                        },
                                    },
                                    displayInstructions: {
                                        values: {
                                            en: "The following is a list of the available testing partners.",
                                        },
                                    },
                                    partnerTestCenters: [
                                        {
                                            name: "Collinson Group",
                                        },
                                        {
                                            name: "Cerballiance",
                                        },
                                    ],
                                },
                                covidTestTypes: [
                                    {
                                        name: "PCR",
                                        testWithinHours: 72,
                                        withoutTestLaboratoryName: true,
                                    },
                                    {
                                        name: "NAAT",
                                        testWithinHours: 48,
                                    },
                                    {
                                        name: "LAMP",
                                        testWithinHours: 96,
                                        withoutTestLaboratoryName: true,
                                    },
                                    {
                                        name: "TMA",
                                        testWithinHours: 480,
                                        withoutTestLaboratoryName: false,
                                    },
                                ],
                                testSamplingMethods: [
                                    {
                                        name: {
                                            values: {
                                                en: "Nasopharyngeal (Rhinopharyngeal) Swab/Smear",
                                                ja: "鼻咽頭スワブ",
                                            },
                                        },
                                        validWithTestTypes: ["NAAT"],
                                    },
                                    {
                                        name: {
                                            values: {
                                                en: "Oropharyngeal Swab/Smear",
                                                ja: "口腔咽頭スワブ",
                                            },
                                        },
                                    },
                                ],
                                covidTestBeforeArrival: true,
                                // testingLaboratory: {
                                //     body: {
                                //         values: { en: "Hawai‘i Trusted Testing Partners" },
                                //     },
                                //     title: {
                                //         values: { en: "Hawai‘i Trusted Testing Partners" },
                                //     },
                                //     testingPartners: [
                                //         {
                                //             name: "American Family Care (AFC)",
                                //             note: {
                                //                 values: {
                                //                     en: "You must use Standard State of Hawai'i TTP Results Form",
                                //                 },
                                //             },
                                //             countryCode: "US",
                                //         },
                                //         {
                                //             name: "American Samoa Department of Health",
                                //             note: {
                                //                 values: {
                                //                     en: "You must use Standard State of Hawai'i TTP Results Form",
                                //                 },
                                //             },
                                //             countryCode: "US",
                                //         },
                                //         {
                                //             name: "National Kidney Foundation of Hawai‘i’s Consortium",
                                //             note: {
                                //                 values: {
                                //                     en: "You must use Standard State of Hawai'i TTP Results Form",
                                //                 },
                                //             },
                                //             countryCode: "US-HI",
                                //         },
                                //         {
                                //             name: "Chang Gung Memorial Hospital (Chia Yi)",
                                //             note: {
                                //                 values: {
                                //                     en: "You must use Standard State of Hawai'i TTP Results Form",
                                //                 },
                                //             },
                                //             countryCode: "TW",
                                //         },
                                //     ],
                                // },
                                // trustedTestingCenters: true,
                                // showTestTime: false,
                            },
                        },
                    },
                    {
                        name: "COVID19_VACCINE",
                        notBeforeDtm: "2021-05-23T05:00:00.000+0000",
                        notAfterDtm: "2121-05-28T17:45:00.000+0000",
                        available: true,
                        optional: true,
                        complete: false,
                        hidden: false,
                        displayName: "COVID Vaccination Process",
                        displayDescription: "Provide your vaccination certificate to VeriFLY for review.",
                        displayInstructions: "The following test review methods are available for this step.",
                        view: {
                            type: "INTERNAL_BOOTSTRAP",
                            reference: "covidVaccineView",
                            view: "/addcovidvaccinationreview",
                            parameters: {
                                notSubmittingText: {
                                    values: {
                                        en: "I AM NOT submitting a vaccination certificate",
                                    },
                                },
                                optOutConfirmationText: {
                                    values: {
                                        en:
                                            "By selecting this option you are confirming that you will not be submitting your vaccination info.",
                                    },
                                },
                                optInConfirmationText: {
                                    values: {
                                        en: "By selecting this option you will revert your previous selection.",
                                    },
                                },
                                options: [
                                    {
                                        displayName: {
                                            values: {
                                                en: "Digital Vaccine Certificate",
                                            },
                                        },
                                        displayInstructions: {
                                            values: {
                                                en:
                                                    "<p>Please choose from the options below to validate your Digital Vaccine Certificate.</p><p>This will be reviewed and approved based documentation provided.</p>",
                                            },
                                        },
                                        qrCodeInstructions: {
                                            values: {
                                                en: "If you have the QR code you can scan the code for validation.",
                                            },
                                        },
                                        uploadInstructions: {
                                            values: {
                                                en:
                                                    "If the Digital Certificate is on your local device, you can click here to upload it.",
                                            },
                                        },
                                        uploadButtonText: {
                                            values: {
                                                en: "Upload Certificate for Review",
                                            },
                                        },
                                        label: {
                                            values: {
                                                en:
                                                    "If you have a Digital COVID Certificate, please select the option below for further information. ",
                                            },
                                        },
                                        linkText: {
                                            values: {
                                                en: "Digital Vaccine Certificate",
                                            },
                                        },
                                        view: "/adddigitalcertificate",
                                    },
                                    {
                                        displayName: {
                                            values: {
                                                en: "Manual Upload",
                                            },
                                        },
                                        label: {
                                            values: {
                                                en:
                                                    "If you already have your vaccination card, you can upload it for review. Please click the button below to begin the manual upload process.",
                                            },
                                        },
                                        linkText: {
                                            values: {
                                                en: "Manual Upload",
                                            },
                                        },
                                        view: "/addvaccination/cap:us",
                                    },
                                ],
                                bodyText: {
                                    values: {
                                        en:
                                            "If you have the documents to prove full vaccination then you do not have to complete mandatory hotel quarantine when you come to Ireland.  However, if even you are fully vaccinated, you still have to have a negative pre-departure PCR test and complete a period of self-quarantine at home or wherever you specified in your passenger locator form.",
                                        es:
                                            "If you have the documents to prove full vaccination then you do not have to complete mandatory hotel quarantine when you come to Ireland.  However, if even you are fully vaccinated, you still have to have a negative pre-departure PCR test and complete a period of self-quarantine at home or wherever you specified in your passenger locator form.",
                                    },
                                },
                                vaccineType: {
                                    values: {
                                        en: {
                                            label: "Vaccine Type",
                                            placeholder: "Select Vaccine Type",
                                            missingMessage: "Please select a vaccine type.",
                                        },
                                    },
                                },
                                vaccineTypes: [
                                    {
                                        name: "Pfizer-BioNtech",
                                        numDoses: 2,
                                        fullyVaccinatedAfterDays: 7,
                                        vaccinatedWithinDays: 270,
                                        boosterRequiredAfterDays: 0,
                                        boosterActiveAfterDays: 0,
                                    },
                                    {
                                        name: "Moderna",
                                        numDoses: 1,
                                        fullyVaccinatedAfterDays: 14,
                                        vaccinatedWithinMonths: 9,
                                    },
                                ],
                                vaccineCardInfo: {
                                    values: {
                                        en: {
                                            label: "This information will be reviewed to ensure its validity.",
                                        },
                                    },
                                },
                                vaccineDoses: [
                                    {
                                        values: {
                                            en: {
                                                name: "First dose",
                                                dateLabel: "First dose date",
                                            },
                                        },
                                    },
                                    {
                                        values: {
                                            en: {
                                                name: "Second dose",
                                                dateLabel: "Second dose date",
                                            },
                                        },
                                    },
                                    {
                                        useSwitch: true,
                                        values: {
                                            en: {
                                                name: "Booster",
                                                dateLabel: "Booster date",
                                                switchLabel: "I've received a booster vaccine",
                                            },
                                        },
                                    },
                                ],
                                vaccineDosesErrorMessages: {
                                    values: {
                                        en: {
                                            dateInFutureMessage: "The Date cannot be in the future",
                                            afterPreviousMessage: "The Date must be after earlier dose date",
                                            beforeFollowingMessage: "The Date must be before later dose date",
                                            outOfRangeMessage:
                                                "You must be fully vaccinated {days} days before your trip",
                                        },
                                    },
                                },
                                acceptedVaccineCountries: [
                                    {
                                        values: {
                                            en: "Ireland",
                                            es: "Irlanda",
                                            ja: "アイルランド島",
                                        },
                                        countryCode: "ie",
                                        displayDateOfBirth: false,
                                    },
                                    {
                                        values: {
                                            en: "United States",
                                            es: "United States",
                                            ja: "United States",
                                        },
                                        countryCode: "us",
                                        displayDateOfBirth: true,
                                    },
                                ],
                                vaccineDateErrorMessages: {
                                    values: {
                                        en: {
                                            missingMessage: "Please enter a valid date",
                                            outOfRangeMessage:
                                                "You must be fully vaccinated {days} days before your trip",
                                            dateInFutureMessage: "The Date cannot be in the future",
                                            afterPreviousMessage: "The Date must be after earlier dose date",
                                            vaccineExpiredMessage:
                                                "270 days have passed since the final dose in the primary vaccine series",
                                            beforeFollowingMessage: "The Date must be before later dose date",
                                            boosterRequiredMessage:
                                                "Your last dose is outside the allowed period, you are now required to have a booster dose.",
                                            insufficientDosesMessage:
                                                "You must take at least {numDoses} dose(s) to be considered fully vaccinated.",
                                        },
                                        es: {
                                            missingMessage: "Por favor introduzca una fecha valida.",
                                            outOfRangeMessage:
                                                "La vacunación final debe administrarse al menos {days} días antes de la salida.",
                                            dateInFutureMessage:
                                                "La fecha de la última vacuna administrada no puede ser futura",
                                            afterPreviousMessage: "The Date must be after earlier dose date",
                                            vaccineExpiredMessage:
                                                "Han pasado 270 días desde la última dosis de la serie de vacunas primarias",
                                            beforeFollowingMessage: "The Date must be before later dose date",
                                            boosterRequiredMessage:
                                                "Su última dosis está fuera del período asignado, ahora debe recibir una dosis de refuerzo.",
                                            insufficientDosesMessage:
                                                "Debe tomar al menos {numDoses} dosis para que se le considere completamente vacunado.",
                                        },
                                        ja: {
                                            missingMessage: "有効な日付を入力してください",
                                            outOfRangeMessage:
                                                "最後のワクチン接種日は出発日より {days} 日前でなければなりません",
                                            dateInFutureMessage: "日付は将来にすることはできません",
                                            afterPreviousMessage: "日付は以前の投与日より後でなければなりません",
                                            vaccineExpiredMessage: "最後のワクチン接種日より270日以上経過しています",
                                            beforeFollowingMessage: "日付は後の投与日より前でなければなりません",
                                            boosterRequiredMessage:
                                                "あなたの最後の投与量は許可された期間外です、あなたは今ブースター投与量を持っている必要があります。",
                                            insufficientDosesMessage:
                                                "完全にワクチン接種されたと見なされるには、少なくとも{numDoses}用量を服用する必要があります。",
                                        },
                                    },
                                },
                                countryAdministeredVaccine: {
                                    values: {
                                        en: {
                                            label: "Country which Administered Vaccine",
                                            placeholder: "Select Country",
                                            missingMessage: "Please select country of vaccination.",
                                        },
                                    },
                                },
                                vaccineAdministered: {
                                    values: {
                                        en: {
                                            label: "Dose Date of Last Vaccine",
                                            dateInFutureMessage: "The Date cannot be in the future",
                                            outOfRangeMessage:
                                                "You must be fully vaccinated {days} days before your trip",
                                        },
                                    },
                                },
                                showLlcConsent: true,
                                useS3Upload: true,
                                vaccineCheckboxConfirmation: {
                                    values: {
                                        en: {
                                            label: "I confirm that i am fully vaccinated",
                                        },
                                    },
                                },
                                inputs: [
                                    {
                                        name: "samplingText",
                                        type: "text",
                                        minLength: 3,
                                        maxLength: 10,
                                        label: {
                                            values: {
                                                en: "Some text",
                                                es:
                                                    "Confirmo que mi método de muestreo de prueba es frotis/frotis nasofaríngeo (rinofaríngeo), frotis/frotis orofaríngeo, frotis/frotis nasofaríngeo y orofaríngeo, frotis nasal",
                                                ja:
                                                    "私のテストサンプリング方法は、鼻咽頭（鼻咽頭）スワブ/スミア、中咽頭スワブ/スミア、鼻咽頭および中咽頭スワブ/スミア、鼻スワブであることを確認します。",
                                            },
                                        },
                                        note: {
                                            values: {
                                                en:
                                                    "I confirm that my test sampling method is Nasopharyngeal (Rhinopharyngeal) Swab/Smear, Oropharyngeal Swab/Smear, Nasopharyngeal and Oropharyngeal Swab/Smear, Nasal Swab",
                                                es:
                                                    "Confirmo que mi método de muestreo de prueba es frotis/frotis nasofaríngeo (rinofaríngeo), frotis/frotis orofaríngeo, frotis/frotis nasofaríngeo y orofaríngeo, frotis nasal",
                                                ja:
                                                    "私のテストサンプリング方法は、鼻咽頭（鼻咽頭）スワブ/スミア、中咽頭スワブ/スミア、鼻咽頭および中咽頭スワブ/スミア、鼻スワブであることを確認します。",
                                            },
                                        },
                                        info: {
                                            values: {
                                                en:
                                                    "<p>Japanese authorities have stated that they only accept test samples taken as either <b>'Nasopharyngeal (Swab/Smear)/Nasopharynx' or 'Saliva'</b>. A mixture of samples such as 'nasal and throat' or 'pharyngeal and nasal' or 'Nasopharyngeal and throat' is not acceptable. Nasal Swab is valid when the test method is Nucleic acid amplification test</p>",
                                                es:
                                                    "Las autoridades japonesas han declarado que solo aceptan muestras de prueba tomadas como <b> 'Nasofaríngeo (frotis / frotis) / Nasofaringe' o 'Saliva'</b>. Una mezcla de muestras como' Nasal y Garganta' o 'Faríngea y Nasal' o 'nasofaríngea y garganta' no es aceptable. El hisopo nasal es válido cuando el método de prueba es la prueba de amplificación de ácido nucleico</p>",
                                                ja:
                                                    "<p>日本の当局は、<b>「鼻咽頭（綿棒/塗抹標本）/鼻咽頭」または「唾液」</b>のいずれかとして採取された試験サンプルのみを受け入れると述べています。「鼻と喉」などのサンプルの混合物または「咽頭および鼻」または「鼻咽頭および喉」は受け入れられません。鼻スワブは、検査方法が核酸増幅検査である場合に有効です。</p>",
                                            },
                                        },
                                        required: true,
                                        switchLabel: {
                                            values: {
                                                en: "I agree",
                                                es: "Estoy de acuerdo",
                                                ja: "同意します",
                                            },
                                        },
                                    },
                                    {
                                        name: "samplingMethodSwitch",
                                        type: "switch",
                                        label: {
                                            values: {
                                                en:
                                                    "I confirm that my test sampling method is Nasopharyngeal (Rhinopharyngeal) Swab/Smear, Oropharyngeal Swab/Smear, Nasopharyngeal and Oropharyngeal Swab/Smear, Nasal Swab",
                                                es:
                                                    "Confirmo que mi método de muestreo de prueba es frotis/frotis nasofaríngeo (rinofaríngeo), frotis/frotis orofaríngeo, frotis/frotis nasofaríngeo y orofaríngeo, frotis nasal",
                                                ja:
                                                    "私のテストサンプリング方法は、鼻咽頭（鼻咽頭）スワブ/スミア、中咽頭スワブ/スミア、鼻咽頭および中咽頭スワブ/スミア、鼻スワブであることを確認します。",
                                            },
                                        },
                                        infoHtml: {
                                            values: {
                                                en:
                                                    "<p>Japanese authorities have stated that they only accept test samples taken as either <b>'Nasopharyngeal (Swab/Smear)/Nasopharynx' or 'Saliva'</b>. A mixture of samples such as 'nasal and throat' or 'pharyngeal and nasal' or 'Nasopharyngeal and throat' is not acceptable. Nasal Swab is valid when the test method is Nucleic acid amplification test</p>",
                                                es:
                                                    "Las autoridades japonesas han declarado que solo aceptan muestras de prueba tomadas como <b> 'Nasofaríngeo (frotis / frotis) / Nasofaringe' o 'Saliva'</b>. Una mezcla de muestras como' Nasal y Garganta' o 'Faríngea y Nasal' o 'nasofaríngea y garganta' no es aceptable. El hisopo nasal es válido cuando el método de prueba es la prueba de amplificación de ácido nucleico</p>",
                                                ja:
                                                    "<p>日本の当局は、<b>「鼻咽頭（綿棒/塗抹標本）/鼻咽頭」または「唾液」</b>のいずれかとして採取された試験サンプルのみを受け入れると述べています。「鼻と喉」などのサンプルの混合物または「咽頭および鼻」または「鼻咽頭および喉」は受け入れられません。鼻スワブは、検査方法が核酸増幅検査である場合に有効です。</p>",
                                            },
                                        },
                                        required: true,
                                        switchLabel: {
                                            values: {
                                                en: "I agree",
                                                es: "Estoy de acuerdo",
                                                ja: "同意します",
                                            },
                                        },
                                    },
                                ],
                            },
                        },
                    },
                    {
                        name: "QUESTIONNAIRE",
                        notBeforeDtm: "2021-05-17T05:18:00.000+0000",
                        notAfterDtm: "2022-05-19T23:50:00.000+0000",
                        available: true,
                        optional: false,
                        complete: false,
                        displayName: "Health questionnaire",
                        displayDescription: "Answer the following questions.",
                        displayInstructions: "Answer the following questions in order to acquire a valid pass.",
                        view: {
                            type: "INTERNAL_BOOTSTRAP",
                            reference: "questionnaireView",
                            view: "/addquestionnaire",
                            parameters: {
                                bodyText: {
                                    values: {
                                        en:
                                            "Please complete the details below and upload an image of your vaccination certificate for validation at least 2 hours before flight.  <p>If you do not have a <b>full</b> vaccination certificate then you will be required to provide results of a COVID Test.</p>",
                                        es:
                                            "Complete los detalles a continuación y cargue una imagen de su certificado de vacunación para su validación al menos 2 horas antes del vuelo. <p> Si no tiene un certificado de vacunación <b>completo</b>, se le pedirá que proporcione los resultados de una prueba COVID.</p>",
                                        ja:
                                            "フライトの少なくとも2時間前に、以下の詳細を記入し、検証のために予防接種証明書の画像をアップロードしてください。完全な予防接種証明書をお持ちでない場合は、COVIDテストの結果を提供する必要があります。",
                                    },
                                },
                                inputs: [
                                    {
                                        name: "futureDate",
                                        type: "date",
                                        label: {
                                            values: {
                                                en: "Select date in future",
                                            },
                                        },
                                        required: true,
                                        min: "2021-01-01",
                                        max: "2028-12-31",
                                        dateType: "futureOnly",
                                        missingMessage: {
                                            values: {
                                                en: "Please select future date",
                                            },
                                        },
                                        allowToday: true,
                                        invalidMessage: {
                                            values: {
                                                en: "Date must not be in the past",
                                            },
                                        },
                                    },
                                    {
                                        name: "pastDate",
                                        type: "date",
                                        label: {
                                            values: {
                                                en: "Select date",
                                            },
                                        },
                                        required: true,
                                        min: "1900-01-01",
                                        max: "2021-12-31",
                                        dateType: "pastOnly",
                                        missingMessage: {
                                            values: {
                                                en: "Please select past date",
                                            },
                                        },
                                        invalidMessage: {
                                            values: {
                                                en: "Date must not be in the future",
                                            },
                                        },
                                    },
                                    {
                                        name: "select",
                                        type: "select",
                                        label: {
                                            values: {
                                                en: "Select one of the available options:",
                                            },
                                        },
                                        placeholder: {
                                            values: {
                                                en: "Please select one",
                                            },
                                        },
                                        options: [
                                            {
                                                value: "opt1",
                                                label: {
                                                    values: {
                                                        en: "Option 1",
                                                    },
                                                },
                                            },
                                            {
                                                value: "opt2",
                                                label: {
                                                    values: {
                                                        en: "Option 2",
                                                    },
                                                },
                                            },
                                            {
                                                value: "opt3",
                                                label: {
                                                    values: {
                                                        en: "Option 3",
                                                    },
                                                },
                                            },
                                            {
                                                value: "opt4",
                                                label: {
                                                    values: {
                                                        en: "Option 4",
                                                    },
                                                },
                                            },
                                        ],
                                        required: true,
                                        missingMessage: {
                                            values: {
                                                en: "Please select a value.",
                                            },
                                        },
                                    },
                                    {
                                        name: "optionalNote",
                                        type: "note",
                                        customClass: "text-small",
                                        htmlText: {
                                            values: {
                                                en:
                                                    "<p>Note paragraph 1 text bla bla bla</p>Lorem **bacon** ipsum dolor <em>ham</em> amet<p></p><p>blaksldkasdlksa dcjnshjad jsdkajd <strong>djhcfdskhfkdhskfds</strong> jsdbakjdsahdlasdlsaj <a href='#'>dskhvfdslfhdusilhfljdsh</a> dkfls fdklsj fsldkfldsk ldsfkjflkj flsdl</strong></p>",
                                            },
                                        },
                                        showIf: [
                                            {
                                                field: "select",
                                                values: ["opt1", "opt2"],
                                            },
                                        ],
                                    },
                                    {
                                        name: "requiredToggle",
                                        type: "toggle",
                                        convertToBoolean: true,
                                        showIf: [
                                            {
                                                field: "select",
                                                values: ["opt1", "opt2"],
                                            },
                                        ],
                                        label: {
                                            values: {
                                                en: "Required toggle text",
                                            },
                                        },
                                        required: true,
                                        defaultValue: "",
                                        invalidMessage: {
                                            values: {
                                                en: "You must agree in order to continue.",
                                            },
                                        },
                                        missingMessage: {
                                            values: {
                                                en: "Please select a value.",
                                            },
                                        },
                                    },
                                    {
                                        name: "validationToggle",
                                        type: "toggle",
                                        label: {
                                            values: {
                                                en: "Valid option non-required toggle text",
                                            },
                                        },
                                        showIf: [
                                            {
                                                field: "select",
                                                values: ["opt1", "opt2"],
                                            },
                                        ],
                                        defaultValue: "",
                                        validValues: ["no"],
                                        invalidMessage: {
                                            values: {
                                                en: "You must agree in order to continue.",
                                            },
                                        },
                                        missingMessage: {
                                            values: {
                                                en: "Please select a value.",
                                            },
                                        },
                                    },
                                    {
                                        name: "requiredValidationToggle",
                                        type: "toggle",
                                        convertToBoolean: true,
                                        label: {
                                            values: {
                                                en: "Valid option required toggle text",
                                            },
                                        },
                                        showIf: [
                                            {
                                                field: "select",
                                                values: ["opt4"],
                                            },
                                        ],
                                        required: true,
                                        defaultValue: "",
                                        validValues: ["no"],
                                        invalidMessage: {
                                            values: {
                                                en: "You must agree in order to continue.",
                                            },
                                        },
                                        missingMessage: {
                                            values: {
                                                en: "Please select a value.",
                                            },
                                        },
                                    },
                                    {
                                        name: "nonRequiredToggle",
                                        type: "toggle",
                                        label: {
                                            values: {
                                                en: "Non required toggle text",
                                            },
                                        },
                                    },
                                    {
                                        name: "dependantToggle",
                                        type: "toggle",
                                        hideIf: [
                                            {
                                                field: "nonRequiredToggle",
                                                values: ["yes"],
                                            },
                                            {
                                                field: "requiredValidationToggle",
                                                values: ["no"],
                                            },
                                            {
                                                field: "plainText",
                                                values: ["aaa", "bbb", "ccc"],
                                            },
                                        ],
                                        label: {
                                            values: {
                                                en: "Dependant toggle text",
                                            },
                                        },
                                    },
                                    {
                                        name: "symptomsSwitch",
                                        label: {
                                            values: {
                                                en:
                                                    "I haven't experienced any of the following symptoms in the past 48 hours: fever or chills, cough, shortness of breath or difficulty breathing, fatigue, muscle or body aches, headache, new loss of taste or smell, sore throat, congestion or runny nose, nausea or vomiting, diarrhea.",
                                            },
                                        },
                                        switchLabel: {
                                            values: {
                                                en: "I agree",
                                            },
                                        },
                                        info: {
                                            values: {
                                                en: "Some additional info",
                                            },
                                        },
                                        type: "switch",
                                        required: true,
                                        invalidMessage: {
                                            values: {
                                                en: "You must agree in order to continue.",
                                            },
                                        },
                                    },
                                    {
                                        name: "contactSwitch",
                                        label: {
                                            values: {
                                                en:
                                                    "Within the past 10 days, I haven't been in close physical contact. (6 feet or closer for at least 15 minutes or more within a 24 hour period) with a person who is known to have laboratory-confirmed COVID-19 or with anyone who has any symptoms consistent with COVID-19.",
                                            },
                                        },
                                        type: "switch",
                                    },
                                    {
                                        name: "plainText",
                                        label: {
                                            values: {
                                                en: "Please input some text. vlada",
                                            },
                                        },
                                        type: "text",
                                        required: true,
                                        pattern: "^[A-Za-z]*$",
                                        minLength: 2,
                                        maxLength: 8,
                                        missingMessage: {
                                            values: {
                                                en: "This field is required.",
                                            },
                                        },
                                        invalidMessage: {
                                            values: {
                                                en: "Please enter a valid value",
                                            },
                                        },
                                        invalidLengthMessage: {
                                            values: {
                                                en: "Must be between 2 and 8 characters long.",
                                            },
                                        },
                                    },
                                    {
                                        name: "plainNumber",
                                        label: {
                                            values: {
                                                en: "Please input some text. number",
                                            },
                                        },
                                        type: "number",
                                        required: true,
                                        convertToNumber: true,
                                        min: 2,
                                        max: 8,
                                        missingMessage: {
                                            values: {
                                                en: "This field is required.",
                                            },
                                        },
                                        invalidMessage: {
                                            values: {
                                                en: "Please enter a valid value",
                                            },
                                        },
                                        invalidRangeMessage: {
                                            values: {
                                                en: "Must be between 2 and 8.",
                                            },
                                        },
                                    },
                                    {
                                        name: "symptoms",
                                        label: {
                                            values: {
                                                en:
                                                    "Have you experienced any of the following symptoms in the past 48 hours: fever or chills, cough, shortness of breath or difficulty breathing, fatigue, muscle or body aches, headache, new loss of taste or smell, sore throat, congestion or runny nose, nausea or vomiting, diarrhea?",
                                            },
                                        },
                                        type: "radio",
                                        convertToBoolean: true,
                                        options: [
                                            {
                                                value: "yes",
                                                label: {
                                                    values: {
                                                        en: "Yes",
                                                    },
                                                },
                                            },
                                            {
                                                value: "no",
                                                label: {
                                                    values: {
                                                        en: "No",
                                                    },
                                                },
                                            },
                                        ],
                                        required: true,
                                        missingMessage: {
                                            values: {
                                                en: "This field is required.",
                                            },
                                        },
                                        invalidMessage: {
                                            values: {
                                                en: "No passaran!.",
                                            },
                                        },
                                        validValues: ["no"],
                                    },
                                    // {
                                    //     name: "contact",
                                    //     label: {
                                    //         values: {
                                    //             en:
                                    //                 "Within the past 10 days, have you been in close physical contact. (6 feet or closer for at least 15 minutes or more within a 24 hour period) with a person who is known to have laboratory-confirmed COVID-19 or with anyone who has any symptoms consistent with COVID-19?",
                                    //         },
                                    //     },
                                    //     type: "radio",
                                    //     options: [
                                    //         {
                                    //             value: "yes",
                                    //             label: {
                                    //                 values: {
                                    //                     en: "Yes",
                                    //                 },
                                    //             },
                                    //         },
                                    //         {
                                    //             value: "no",
                                    //             label: {
                                    //                 values: {
                                    //                     en: "No",
                                    //                 },
                                    //             },
                                    //         },
                                    //     ],
                                    //     required: true,
                                    //     missingMessage: {
                                    //         values: {
                                    //             en: "This field is required.",
                                    //         },
                                    //     },
                                    //     invalidMessage: {
                                    //         values: {
                                    //             en: "No passaran!.",
                                    //         },
                                    //     },
                                    //     validValues: ["no"],
                                    // },
                                    // {
                                    //     name: "isolation",
                                    //     label: {
                                    //         values: {
                                    //             en:
                                    //                 "Are you isolating or quarantining because you may have been exposed to a person with COVID-19 or are worried that you may be sick with COVID-19?",
                                    //         },
                                    //     },
                                    //     type: "radio",
                                    //     options: [
                                    //         {
                                    //             value: "yes",
                                    //             label: {
                                    //                 values: {
                                    //                     en: "Yes",
                                    //                 },
                                    //             },
                                    //         },
                                    //         {
                                    //             value: "no",
                                    //             label: {
                                    //                 values: {
                                    //                     en: "No",
                                    //                 },
                                    //             },
                                    //         },
                                    //     ],
                                    //     required: true,
                                    //     missingMessage: {
                                    //         values: {
                                    //             en: "This field is required.",
                                    //         },
                                    //     },
                                    //     invalidMessage: {
                                    //         values: {
                                    //             en: "No passaran!.",
                                    //         },
                                    //     },
                                    //     validValues: ["no"],
                                    // },
                                ],
                            },
                        },
                    },
                    {
                        name: "TRAVEL_AUTHORIZATION",
                        notBeforeDtm: "2021-04-23T05:00:00.000+0000",
                        notAfterDtm: "2021-04-28T17:45:00.000+0000",
                        available: true,
                        optional: false,
                        complete: false,
                        hidden: false,
                        displayName: "Travel Authorization",
                        displayDescription: "You are required to have a Travel Authorization document.",
                        displayInstructions: "You are required to have a Travel Authorization document.",
                        view: {
                            type: "INTERNAL_BOOTSTRAP",
                            reference: "authorizationView",
                            view: "/addpreregistration/cap:bs",
                            parameters: {
                                useS3Upload: true,
                                qrCode: {
                                    values: {
                                        en: {
                                            label: "Application Reference Number",
                                            buttonText: "Scan QR code",
                                            missingMessage: "Please enter a valid reference number.",
                                        },
                                        es: {
                                            label: "Número de referencia de la solicitud",
                                            buttonText: "Escanear código QR",
                                            missingMessage: "Introduzca un número de referencia válido.",
                                        },
                                    },
                                },
                                bodyText: {
                                    values: {
                                        en:
                                            "<p>Once in possession of a negative COVID-19 RT-PCR Test, travellers can apply for their required Bahamas Travel Health Visa at <a href='https://travel.gov.bs'>travel.gov.bs</a>.</p><br /><p>Once you receive your Health Visa, please scan the QR Code in below.</p>",
                                        es:
                                            "<p>Una vez en posesión de una prueba de RT-PCR COVID-19 negativa, los viajeros pueden solicitar su Visa de salud para viajes de Bahamas en <a href='https://travel.gov.bs'> travel.gov.bs </a> . </p> <br /> <p> Una vez que reciba su visa de salud, escanee el código QR a continuación. </p>",
                                    },
                                },
                                useQrCode: true,
                                useFileUpload: true,
                                defaultLocale: "en",
                                // usePlainTextInput: true can't exist with useQrCode: true and useFileUpload: true
                                /* usePlainTextInput: true,
                                plainTextInput: {
                                    "values": {
                                        "en": {
                                            "label": "International Traveller Arrival Form Number",
                                            "missingMessage": "Please enter a valid form number"
                                        },
                                        "es": {
                                            "label": "Número de Formulario de llegada de viajero internacional",
                                            "missingMessage": "Por favor ingrese un número de formulario válido"
                                        },
                                        "ja": {
                                            "label": "海外渡航者到着票番号",
                                            "missingMessage": "有効なフォーム番号を入力してください"
                                        }
                                    }
                                },
                                checkbox: {
                                    "values": {
                                        "en": {
                                            "label": "I have completed the 'International Traveller Arrival Form' required for boarding my flight and entering XXXXX"
                                        },
                                        "es": {
                                            "label": "He completado el 'Formulario de llegada de viajero internacional' requerido para abordar mi vuelo e ingresar a XXXXX"
                                        },
                                        "ja": {
                                            "label": "フライトへの搭乗とネパールへの入国に必要な「国際旅行者到着フォーム」に記入しました"
                                        }
                                    }
                                },
                                useCheckbox: true, */
                            },
                        },
                    },
                    {
                        name: "TRAVEL_ATTESTATION",
                        notBeforeDtm: "2021-04-23T05:00:00.000+0000",
                        notAfterDtm: "2022-04-28T17:45:00.000+0000",
                        available: true,
                        optional: false,
                        complete: false,
                        hidden: false,
                        displayName: "Passenger Attestation",
                        displayDescription: "Please complete the Passenger Attestation.",
                        displayInstructions:
                            "VeriFLY only supports the passenger attestation option for individuals who have received a negative pre-departure test result for COVID-19 and that the test was a viral test that was conducted on a specimen collected during the 3 calendar days preceding the flight’s departure. Passengers opting for one of the other three attestation options will need to print and complete the disclosure and provide them during the check-in process at the airport. (https://www.cdc.gov/coronavirus/2019-ncov/travelers/testing-international-air-travelers.html). We apologize for this inconvenience and are actively working to incorporate all options.",
                        view: {
                            type: "INTERNAL_BOOTSTRAP",
                            reference: "authorizationView",
                            view: "/attestations/09201318/cap:us",
                            parameters: {
                                defaultLocale: "en",
                            },
                        },
                    },
                    {
                        name: "BEFORE_YOU_GO_TO_THE_AIRPORT",
                        notBeforeDtm: "2021-04-27T17:45:00.000+0000",
                        notAfterDtm: "2021-04-28T17:45:00.000+0000",
                        available: true,
                        optional: false,
                        complete: false,
                        hidden: false,
                        displayName: "Before you go to the airport",
                        displayDescription: "Final checklist before leaving for the airport.",
                        displayInstructions:
                            "Before you leave for the airport you must confirm that you have the required documents.",
                        view: {
                            type: "INTERNAL_BOOTSTRAP",
                            reference: "checklistView",
                            view: "/addchecklist/cap:bs",
                            parameters: {
                                items: [
                                    {
                                        name: "itemName",
                                        label: {
                                            values: {
                                                en: "Label of the additional item",
                                            },
                                        },
                                    },
                                    {
                                        name: "itemName2",
                                        label: {
                                            values: {
                                                en: "Label of the additional item 2",
                                            },
                                        },
                                    },
                                ],
                            },
                        },
                    },
                    {
                        name: "VISA",
                        notBeforeDtm: "2022-02-11T12:11:22.476+0000",
                        notAfterDtm: "2022-02-11T14:11:22.476+0000",
                        available: true,
                        expired: false,
                        optional: false,
                        complete: false,
                        displayName: "Proof of Visa",
                        displayDescription: "You are required to have Visa related documentation",
                        displayInstructions:
                            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                        view: {
                            type: "INTERNAL_BOOTSTRAP",
                            reference: "visaView",
                            view: "/addvisa",
                            parameters: {
                                // documentType: "brazilianEVisa",
                                options: [
                                    {
                                        available: true,
                                        complete: false,
                                        type: "passports",
                                        view: "/addpassportdetails",
                                        displayName: {
                                            values: {
                                                en: "Provide Passport details",
                                            },
                                        },
                                        displayInstructions: {
                                            values: {
                                                en: "Lorem ipsum dolor sit amet....",
                                            },
                                        },
                                    },
                                    {
                                        available: true,
                                        complete: false,
                                        view: "/addvisadetails",
                                        type: "visas",
                                        displayName: {
                                            values: {
                                                en: "Provide Visa details",
                                            },
                                        },
                                        displayInstructions: {
                                            values: {
                                                en: "Lorem ipsum dolor sit amet....",
                                            },
                                        },
                                        showOptionsScanButton: true,
                                        showOptionsUploadButton: true,
                                        showOptionsEvisaButton: true,
                                        displayEVisaValidation: {
                                            eVisaDisplayName: {
                                                values: {
                                                    en: "Validate my E-VISA",
                                                },
                                            },
                                            eVisaDisplayInstructions: {
                                                values: {
                                                    en:
                                                        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                                                    es:
                                                        "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
                                                },
                                            },
                                        },
                                        optionsTitle: {
                                            values: {
                                                en: "Provide Visa details",
                                            },
                                        },
                                        optionsInstructions: {
                                            values: {
                                                en:
                                                    "<p>Please upload your <b>VISA, EVISA or Approval letter</b>, alternatively you can scan the MRZ bon your VISA. You should then complete the visa information form and submit your details for verification, these details will need to match that of your passport and airline booking.</p>",
                                            },
                                        },
                                        optionsEVisaButton: {
                                            values: {
                                                en: "Validate my E-VISA",
                                            },
                                        },
                                        optionsUploadButton: {
                                            values: {
                                                en: "Upload VISA Details",
                                            },
                                        },
                                        optionsScanButton: {
                                            values: {
                                                en: "Scan VISA",
                                            },
                                        },
                                    },
                                ],
                            },
                        },
                        hidden: false,
                    },
                    {
                        name: "VISA",
                        notBeforeDtm: "2022-02-11T12:11:22.476+0000",
                        notAfterDtm: "2022-02-11T14:11:22.476+0000",
                        available: true,
                        expired: false,
                        optional: false,
                        complete: false,
                        displayName: "Proof of Visa",
                        displayDescription: "You are required to have Visa related documentation",
                        displayInstructions:
                            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                        view: {
                            type: "INTERNAL_BOOTSTRAP",
                            reference: "visaView",
                            view: "/addvisa",
                            parameters: {
                                options: [
                                    {
                                        available: true,
                                        complete: false,
                                        type: "passports",
                                        view: "/addpassportdetails",
                                        displayName: {
                                            values: {
                                                en: "Provide Passport details",
                                            },
                                        },
                                        displayInstructions: {
                                            values: {
                                                en: "Lorem ipsum dolor sit amet....",
                                            },
                                        },
                                    },
                                    // {
                                    //     available: true,
                                    //     complete: false,
                                    //     view: "/addvisadetails",
                                    //     type: "visas",
                                    //     displayName: {
                                    //         values: {
                                    //             en: "Provide Visa details",
                                    //         },
                                    //     },
                                    //     displayInstructions: {
                                    //         values: {
                                    //             en: "Lorem ipsum dolor sit amet....",
                                    //         },
                                    //     },
                                    // },
                                ],
                            },
                        },
                        hidden: false,
                    },
                ],
            },
        },
    },
};

export default FlightResponse;
