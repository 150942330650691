import { Form } from "react-bootstrap";
import { useLanguage } from "../../../utils/hooks";
import MarkdownText from "../MarkdownText";

const CustomNote = ({ input }) => {
    const { customClass, htmlText } = input;

    const { localizeField } = useLanguage();

    return (
        <Form.Group>
            <Form.Label>
                <MarkdownText className={customClass}>{localizeField(htmlText)}</MarkdownText>
            </Form.Label>
        </Form.Group>
    );
};

export default CustomNote;
