import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { ErrorIcon } from "../../icons/ErrorIcon";
import { useLocalize } from "../../utils/hooks";
import CountrySelect from "../_components/CountrySelect";
import ValidationForm from "../_components/ValidationForm";

const CompanionCountryOfResidenceModal = ({
    onShowModal,
    onCloseModal,
    exitApp,
    userType = "companion",
    firstName,
    lastName,
    handleSubmit,
    loadingProp = "submit",
}) => {
    const { localize } = useLocalize();

    const [countryOfResidence, setCountryOfResidence] = useState();

    const selectCountryOfResidence = value => setCountryOfResidence(value);

    const closeModal = () => {
        onCloseModal(false);
    };

    const handlePassportCountrySubmit = () => {
        handleSubmit(countryOfResidence);
        closeModal();
    };

    return (
        <Modal show={onShowModal} onHide={exitApp ?? closeModal} centered className="px-3">
            <Modal.Body>
                <div className="text-center pt-3">
                    <ErrorIcon size="50px" />
                </div>
                <div className="text-center h4 py-4">{localize(userType === "companion" ? "companion.modal.info" : "mainUser.modal.info")}</div>
                <div className="px-5 text-center h5">
                    <p className="pb-2">{`${firstName} ${lastName}`}</p>
                </div>
                <ValidationForm handleSubmit={handlePassportCountrySubmit} loadingProp={loadingProp}>
                    <CountrySelect value="" onChange={selectCountryOfResidence} type="countryOfResidence" />
                    <div className="pt-4 py-2">
                        <Button variant="primary" type="submit" className="w-100 mb-3" disabled={!countryOfResidence}>
                            {localize("global.submit")}
                        </Button>
                        <Button variant="secondary" type="button" className="w-100 mb-3" onClick={exitApp ?? closeModal}>
                            {localize("global.back")}
                        </Button>
                    </div>
                </ValidationForm>
            </Modal.Body>
        </Modal>
    );
};

export default CompanionCountryOfResidenceModal;
