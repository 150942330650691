import { rest } from "msw";
import {
    acceptReviews,
    airlinesGet,
    airlinesAllGet,
    airportsGet,
    arrivalsGet, config,
    departuresGet,
    flightGet,
    flightPost,
    flightsGet,
    flightUpdate,
    getSupportedCountries
} from "../responseResolvers/flights";
import { submissionHref } from "../../utils/constants";
import { localHost } from "./constants";

export const flightsHandlers = [
    //**************** Flights ****************

    // GET /api/airlines
    rest.get(`${localHost}/api/airlines`, airlinesGet),

    rest.get(`${localHost}/api/airlines/all`, airlinesAllGet),

    rest.get(`${localHost}/api/countries`, getSupportedCountries),

    // GET /api/airports
    rest.get(`${localHost}/api/airports`, airportsGet),

    // GET /api/airports/departure
    rest.get(`${localHost}/api/airports/departure`, departuresGet),

    // GET /api/airports/arrival
    rest.get(`${localHost}/api/airports/arrival`, arrivalsGet),

    // GET /api/flights/search
    rest.get(`${localHost}/api/flights/search`, flightsGet),

    // POST /api/me/trips
    rest.post(`${localHost}/api/me/trips`, flightPost),

    // GET submissionHref/processInstances/:id/persons/:personId`
    rest.get(`${submissionHref}/processInstances/:id/persons/:personId`, flightGet),

    // POST submissionHref/processInstances/:id/persons/:personId`
    rest.post(`${submissionHref}/processInstances/:id/persons/:personId`, flightUpdate),

    rest.get(`${submissionHref}/config`, config),
    rest.get(`${localHost}/null/config`, config),

    // GET /accept
    rest.get(`${localHost}/api/accept`, acceptReviews),
];
