import React, { useContext } from "react";
import { Row } from "react-bootstrap";
import { DateTime } from "luxon";
import CredentialContext from "../../../config/CredentialContext";
import ArrowRightIcon from "../../../icons/ArrowRightIcon";
import { useLanguage, useLocalize, useQuery, useTimeFormat, useUserDataPath } from "../../../utils/hooks";

const InterlineFlightDetails = ({ interlineFlightDetails }) => {
    const { processInstance } = useContext(CredentialContext);
    const { localize } = useLocalize();
    const { locale } = useLanguage();
    const { simpleTimeFormat } = useTimeFormat();
    const query = useQuery();
    const { getUserDataPath } = useUserDataPath(processInstance);

    const stepId = query.get("stepId");
    const [block, segment] = stepId.split(":");

    const userData = getUserDataPath();

    const [{flightSegments}] = interlineFlightDetails.flightBlocks.filter((b) => b.id === block);
    const [flightInfo] = flightSegments.filter((s) => s.id === segment);

    const { arrival, departure, flightNumber, carrierCode } = flightInfo;
    const { airportName: arrivalAirportName, airportCode: arrivalAirportCode, offsetDateTime } = arrival;
    const { airportName: departureAirportName, airportCode: departureAirportCode } = departure;

    const date = DateTime.fromISO(offsetDateTime, { setZone: true })
                    .setLocale(locale)
                    .toFormat("dd MMM yyyy");
    const time = DateTime.fromISO(offsetDateTime, { setZone: true })
                    .toLocaleString({ ...simpleTimeFormat, locale });

    const countryEntryStatus = userData?.countryEntryStatus || '';

    return (
        <div className="flight-info">
            <Row className="d-flex flex-row justify-content-between px-2">
                <div>{date}</div>
                <div>{time}</div>
            </Row>
            <Row className="d-flex flex-row justify-content-between px-2 align-items-center">
                <div className="interline-flight-block-airport-right text-break">
                    {`${departureAirportName} (${departureAirportCode})`}
                </div>
                <ArrowRightIcon className="interline-flight-block-arrow"/>
                <div className="interline-flight-block-airport-left text-break">
                    {`${arrivalAirportName} (${arrivalAirportCode})`}
                </div>
            </Row>
            <Row className="d-flex flex-row justify-content-between px-2">
                <div className="font-weight-bold">{localize("global.flight")} {carrierCode}{flightNumber}</div>
                <div>{localize(`manageTrip.countryEntryStatus.${countryEntryStatus}`)}</div>
            </Row>
        </div>
    )
};

export default InterlineFlightDetails;
