import { useContext } from "react";
import CredentialContext from "../../config/CredentialContext";
import { useCompanion, useQuery, useStep } from "../../utils/hooks";
import { isStepName } from "../../utils/steps";
import ConfigurableForm from "./ConfigurableForm";

const InitialForm = () => {
    const { globalConfig } = useContext(CredentialContext);

    return <ConfigurableForm config={globalConfig} action={"create"} />;
};

const StepForm = ({ stepName }) => {
    const { processInstance } = useContext(CredentialContext);
    const { companionId } = useCompanion();
    const { step } = useStep(processInstance, companionId, isStepName(stepName));

    return <ConfigurableForm config={step?.view?.parameters || {}} action={"update"} />;
};

const FormConfigurationResolver = () => {
    const query = useQuery();
    const stepName = query.get("stepName");

    return stepName ? <StepForm stepName={stepName} /> : <InitialForm />;
};

export default FormConfigurationResolver;
