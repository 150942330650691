const EyeIcon = ({ size, customClass, colorClass }) => {
    return (
        <svg
            className={`svg-icon ${customClass}`}
            width={size ? size * 1.6 : "16"}
            height={size ? size : "10"}
            viewBox="0 0 16 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                className={colorClass}
                d="M7.99984 0C4.6665 0 1.81984 2.07333 0.666504 5C1.81984 7.92667 4.6665 10 7.99984 10C11.3332 10 14.1798 7.92667 15.3332 5C14.1798 2.07333 11.3332 0 7.99984 0ZM7.99984 8.33333C6.15984 8.33333 4.6665 6.84 4.6665 5C4.6665 3.16 6.15984 1.66667 7.99984 1.66667C9.83984 1.66667 11.3332 3.16 11.3332 5C11.3332 6.84 9.83984 8.33333 7.99984 8.33333ZM7.99984 3C6.89317 3 5.99984 3.89333 5.99984 5C5.99984 6.10667 6.89317 7 7.99984 7C9.1065 7 9.99984 6.10667 9.99984 5C9.99984 3.89333 9.1065 3 7.99984 3Z"
            />
        </svg>
    );
};

EyeIcon.defaultProps = {
    size: "",
    customClass: "",
    colorClass: "info-icon",
};

export default EyeIcon;
