export const worldCountries = [
    {
        code: "US",
        name: {
            en: "United States of America",
            es: "Estados Unidos",
            ja: "アメリカ（ハワイ含む）",
        },
        alpha2: "US",
        alpha3: "USA",
        numeric: "840",
    },
    {
        code: "GB",
        name: {
            en: "United Kingdom",
            es: "Reino Unido",
            ja: "イギリス",
        },
        alpha2: "GB",
        alpha3: "GBR",
        numeric: "826",
    },
    {
        code: "IE",
        name: {
            en: "Ireland",
            es: "Irlanda",
            ja: "アイルランド",
        },
        alpha2: "IE",
        alpha3: "IRL",
        numeric: "372",
    },
    {
        code: "AF",
        name: {
            en: "Afghanistan",
            es: "Afganistán",
            ja: "アフガニスタン",
        },
        alpha2: "AF",
        alpha3: "AFG",
        numeric: "004",
    },
    {
        code: "AX",
        name: {
            en: "Åland Islands",
            es: "Åland",
            ja: "オーランド諸島",
        },
        alpha2: "AX",
        alpha3: "ALA",
        numeric: "248",
    },
    {
        code: "AL",
        name: {
            en: "Albania",
            es: "Albania",
            ja: "アルバニア",
        },
        alpha2: "AL",
        alpha3: "ALB",
        numeric: "008",
    },
    {
        code: "DZ",
        name: {
            en: "Algeria",
            es: "Argelia",
            ja: "アルジェリア",
        },
        alpha2: "DZ",
        alpha3: "DZA",
        numeric: "012",
    },
    {
        code: "AS",
        name: {
            en: "American Samoa",
            es: "Samoa Americana",
            ja: "アメリカ領サモア",
        },
        alpha2: "AS",
        alpha3: "ASM",
        numeric: "016",
    },
    {
        code: "AD",
        name: {
            en: "Andorra",
            es: "Andorra",
            ja: "アンドラ",
        },
        alpha2: "AD",
        alpha3: "AND",
        numeric: "020",
    },
    {
        code: "AO",
        name: {
            en: "Angola",
            es: "Angola",
            ja: "アンゴラ",
        },
        alpha2: "AO",
        alpha3: "AGO",
        numeric: "024",
    },
    {
        code: "AI",
        name: {
            en: "Anguilla",
            es: "Anguila",
            ja: "アンギラ",
        },
        alpha2: "AI",
        alpha3: "AIA",
        numeric: "660",
    },
    {
        code: "AQ",
        name: {
            en: "Antarctica",
            es: "Antártida",
            ja: "南極",
        },
        alpha2: "AQ",
        alpha3: "ATA",
        numeric: "010",
    },
    {
        code: "AG",
        name: {
            en: "Antigua and Barbuda",
            es: "Antigua y Barbuda",
            ja: "アンティグア・バーブーダ",
        },
        alpha2: "AG",
        alpha3: "ATG",
        numeric: "028",
    },
    {
        code: "AR",
        name: {
            en: "Argentina",
            es: "Argentina",
            ja: "アルゼンチン",
        },
        alpha2: "AR",
        alpha3: "ARG",
        numeric: "032",
    },
    {
        code: "AM",
        name: {
            en: "Armenia",
            es: "Armenia",
            ja: "アルメニア",
        },
        alpha2: "AM",
        alpha3: "ARM",
        numeric: "051",
    },
    {
        code: "AW",
        name: {
            en: "Aruba",
            es: "Aruba",
            ja: "アルバ",
        },
        alpha2: "AW",
        alpha3: "ABW",
        numeric: "533",
    },
    {
        code: "AU",
        name: {
            en: "Australia",
            es: "Australia",
            ja: "オーストラリア",
        },
        alpha2: "AU",
        alpha3: "AUS",
        numeric: "036",
    },
    {
        code: "AT",
        name: {
            en: "Austria",
            es: "Austria",
            ja: "オーストリア",
        },
        alpha2: "AT",
        alpha3: "AUT",
        numeric: "040",
    },
    {
        code: "AZ",
        name: {
            en: "Azerbaijan",
            es: "Azerbaiyán",
            ja: "アゼルバイジャン",
        },
        alpha2: "AZ",
        alpha3: "AZE",
        numeric: "031",
    },
    {
        code: "BS",
        name: {
            en: "Bahamas",
            es: "Bahamas",
            ja: "バハマ",
        },
        alpha2: "BS",
        alpha3: "BHS",
        numeric: "044",
    },
    {
        code: "BH",
        name: {
            en: "Bahrain",
            es: "Baréin",
            ja: "バーレーン",
        },
        alpha2: "BH",
        alpha3: "BHR",
        numeric: "048",
    },
    {
        code: "BD",
        name: {
            en: "Bangladesh",
            es: "Bangladés",
            ja: "バングラデシュ",
        },
        alpha2: "BD",
        alpha3: "BGD",
        numeric: "050",
    },
    {
        code: "BB",
        name: {
            en: "Barbados",
            es: "Barbados",
            ja: "バルバドス",
        },
        alpha2: "BB",
        alpha3: "BRB",
        numeric: "052",
    },
    {
        code: "BY",
        name: {
            en: "Belarus",
            es: "Bielorrusia",
            ja: "ベラルーシ",
        },
        alpha2: "BY",
        alpha3: "BLR",
        numeric: "112",
    },
    {
        code: "BE",
        name: {
            en: "Belgium",
            es: "Bélgica",
            ja: "ベルギー",
        },
        alpha2: "BE",
        alpha3: "BEL",
        numeric: "056",
    },
    {
        code: "BZ",
        name: {
            en: "Belize",
            es: "Belice",
            ja: "ベリーズ",
        },
        alpha2: "BZ",
        alpha3: "BLZ",
        numeric: "084",
    },
    {
        code: "BJ",
        name: {
            en: "Benin",
            es: "Benín",
            ja: "ベナン",
        },
        alpha2: "BJ",
        alpha3: "BEN",
        numeric: "204",
    },
    {
        code: "BM",
        name: {
            en: "Bermuda",
            es: "Bermudas",
            ja: "バミューダ",
        },
        alpha2: "BM",
        alpha3: "BMU",
        numeric: "060",
    },
    {
        code: "BT",
        name: {
            en: "Bhutan",
            es: "Bután",
            ja: "ブータン",
        },
        alpha2: "BT",
        alpha3: "BTN",
        numeric: "064",
    },
    {
        code: "BO",
        name: {
            en: "Bolivia (Plurinational State of)",
            es: "Bolivia",
            ja: "ボリビア多民族国",
        },
        alpha2: "BO",
        alpha3: "BOL",
        numeric: "068",
    },
    {
        code: "BQ",
        name: {
            en: "Bonaire, Sint Eustatius and Saba",
            es: "Bonaire, San Eustaquio y Saba",
            ja: "ボネール、シント・ユースタティウスおよびサバ",
        },
        alpha2: "BQ",
        alpha3: "BES",
        numeric: "535",
    },
    {
        code: "BA",
        name: {
            en: "Bosnia and Herzegovina",
            es: "Bosnia y Herzegovina",
            ja: "ボスニア・ヘルツェゴビナ",
        },
        alpha2: "BA",
        alpha3: "BIH",
        numeric: "070",
    },
    {
        code: "BW",
        name: {
            en: "Botswana",
            es: "Botsuana",
            ja: "ボツワナ",
        },
        alpha2: "BW",
        alpha3: "BWA",
        numeric: "072",
    },
    {
        code: "BV",
        name: {
            en: "Bouvet Island",
            es: "Isla Bouvet",
            ja: "ブーベ島",
        },
        alpha2: "BV",
        alpha3: "BVT",
        numeric: "074",
    },
    {
        code: "BR",
        name: {
            en: "Brazil",
            es: "Brasil",
            ja: "ブラジル",
        },
        alpha2: "BR",
        alpha3: "BRA",
        numeric: "076",
    },
    {
        code: "IO",
        name: {
            en: "British Indian Ocean Territory",
            es: "Territorio Británico del Océano Índico",
            ja: "イギリス領インド洋地域",
        },
        alpha2: "IO",
        alpha3: "IOT",
        numeric: "086",
    },
    {
        code: "BN",
        name: {
            en: "Brunei Darussalam",
            es: "Brunéi",
            ja: "ブルネイ・ダルサラーム",
        },
        alpha2: "BN",
        alpha3: "BRN",
        numeric: "096",
    },
    {
        code: "BG",
        name: {
            en: "Bulgaria",
            es: "Bulgaria",
            ja: "ブルガリア",
        },
        alpha2: "BG",
        alpha3: "BGR",
        numeric: "100",
    },
    {
        code: "BF",
        name: {
            en: "Burkina Faso",
            es: "Burkina Faso",
            ja: "ブルキナファソ",
        },
        alpha2: "BF",
        alpha3: "BFA",
        numeric: "854",
    },
    {
        code: "BI",
        name: {
            en: "Burundi",
            es: "Burundi",
            ja: "ブルンジ",
        },
        alpha2: "BI",
        alpha3: "BDI",
        numeric: "108",
    },
    {
        code: "CV",
        name: {
            en: "Cabo Verde",
            es: "Cabo Verde",
            ja: "カーボベルデ",
        },
        alpha2: "CV",
        alpha3: "CPV",
        numeric: "132",
    },
    {
        code: "KH",
        name: {
            en: "Cambodia",
            es: "Camboya",
            ja: "カンボジア",
        },
        alpha2: "KH",
        alpha3: "KHM",
        numeric: "116",
    },
    {
        code: "CM",
        name: {
            en: "Cameroon",
            es: "Camerún",
            ja: "カメルーン",
        },
        alpha2: "CM",
        alpha3: "CMR",
        numeric: "120",
    },
    {
        code: "CA",
        name: {
            en: "Canada",
            es: "Canadá",
            ja: "カナダ",
        },
        alpha2: "CA",
        alpha3: "CAN",
        numeric: "124",
    },
    {
        code: "KY",
        name: {
            en: "Cayman Islands",
            es: "Islas Caimán",
            ja: "ケイマン諸島",
        },
        alpha2: "KY",
        alpha3: "CYM",
        numeric: "136",
    },
    {
        code: "CF",
        name: {
            en: "Central African Republic",
            es: "República Centroafricana",
            ja: "中央アフリカ共和国",
        },
        alpha2: "CF",
        alpha3: "CAF",
        numeric: "140",
    },
    {
        code: "TD",
        name: {
            en: "Chad",
            es: "Chad",
            ja: "チャド",
        },
        alpha2: "TD",
        alpha3: "TCD",
        numeric: "148",
    },
    {
        code: "CL",
        name: {
            en: "Chile",
            es: "Chile",
            ja: "チリ",
        },
        alpha2: "CL",
        alpha3: "CHL",
        numeric: "152",
    },
    {
        code: "CN",
        name: {
            en: "China",
            es: "China",
            ja: "中華人民共和国",
        },
        alpha2: "CN",
        alpha3: "CHN",
        numeric: "156",
    },
    {
        code: "CX",
        name: {
            en: "Christmas Island",
            es: "Isla de Navidad",
            ja: "クリスマス島",
        },
        alpha2: "CX",
        alpha3: "CXR",
        numeric: "162",
    },
    {
        code: "CC",
        name: {
            en: "Cocos (Keeling) Islands",
            es: "Islas Cocos",
            ja: "ココス（キーリング）諸島",
        },
        alpha2: "CC",
        alpha3: "CCK",
        numeric: "166",
    },
    {
        code: "CO",
        name: {
            en: "Colombia",
            es: "Colombia",
            ja: "コロンビア",
        },
        alpha2: "CO",
        alpha3: "COL",
        numeric: "170",
    },
    {
        code: "KM",
        name: {
            en: "Comoros",
            es: "Comoras",
            ja: "コモロ",
        },
        alpha2: "KM",
        alpha3: "COM",
        numeric: "174",
    },
    {
        code: "CG",
        name: {
            en: "Congo",
            es: "República del Congo",
            ja: "コンゴ共和国",
        },
        alpha2: "CG",
        alpha3: "COG",
        numeric: "178",
    },
    {
        code: "CD",
        name: {
            en: "Congo, Democratic Republic of the",
            es: "República Democrática del Congo",
            ja: "コンゴ民主共和国",
        },
        alpha2: "CD",
        alpha3: "COD",
        numeric: "180",
    },
    {
        code: "CK",
        name: {
            en: "Cook Islands",
            es: "Islas Cook",
            ja: "クック諸島",
        },
        alpha2: "CK",
        alpha3: "COK",
        numeric: "184",
    },
    {
        code: "CR",
        name: {
            en: "Costa Rica",
            es: "Costa Rica",
            ja: "コスタリカ",
        },
        alpha2: "CR",
        alpha3: "CRI",
        numeric: "188",
    },
    {
        code: "CI",
        name: {
            en: "Côte d'Ivoire",
            es: "Costa de Marfil",
            ja: "コートジボワール",
        },
        alpha2: "CI",
        alpha3: "CIV",
        numeric: "384",
    },
    {
        code: "HR",
        name: {
            en: "Croatia",
            es: "Croacia",
            ja: "クロアチア",
        },
        alpha2: "HR",
        alpha3: "HRV",
        numeric: "191",
    },
    {
        code: "CU",
        name: {
            en: "Cuba",
            es: "Cuba",
            ja: "キューバ",
        },
        alpha2: "CU",
        alpha3: "CUB",
        numeric: "192",
    },
    {
        code: "CW",
        name: {
            en: "Curaçao",
            es: "Curazao",
            ja: "キュラソー",
        },
        alpha2: "CW",
        alpha3: "CUW",
        numeric: "531",
    },
    {
        code: "CY",
        name: {
            en: "Cyprus",
            es: "Chipre",
            ja: "キプロス",
        },
        alpha2: "CY",
        alpha3: "CYP",
        numeric: "196",
    },
    {
        code: "CZ",
        name: {
            en: "Czechia",
            es: "República Checa",
            ja: "チェコ",
        },
        alpha2: "CZ",
        alpha3: "CZE",
        numeric: "203",
    },
    {
        code: "DK",
        name: {
            en: "Denmark",
            es: "Dinamarca",
            ja: "デンマーク",
        },
        alpha2: "DK",
        alpha3: "DNK",
        numeric: "208",
    },
    {
        code: "DJ",
        name: {
            en: "Djibouti",
            es: "Yibuti",
            ja: "ジブチ",
        },
        alpha2: "DJ",
        alpha3: "DJI",
        numeric: "262",
    },
    {
        code: "DM",
        name: {
            en: "Dominica",
            es: "Dominica",
            ja: "ドミニカ国",
        },
        alpha2: "DM",
        alpha3: "DMA",
        numeric: "212",
    },
    {
        code: "DO",
        name: {
            en: "Dominican Republic",
            es: "República Dominicana",
            ja: "ドミニカ共和国",
        },
        alpha2: "DO",
        alpha3: "DOM",
        numeric: "214",
    },
    {
        code: "EC",
        name: {
            en: "Ecuador",
            es: "Ecuador",
            ja: "エクアドル",
        },
        alpha2: "EC",
        alpha3: "ECU",
        numeric: "218",
    },
    {
        code: "EG",
        name: {
            en: "Egypt",
            es: "Egipto",
            ja: "エジプト",
        },
        alpha2: "EG",
        alpha3: "EGY",
        numeric: "818",
    },
    {
        code: "SV",
        name: {
            en: "El Salvador",
            es: "El Salvador",
            ja: "エルサルバドル",
        },
        alpha2: "SV",
        alpha3: "SLV",
        numeric: "222",
    },
    {
        code: "GQ",
        name: {
            en: "Equatorial Guinea",
            es: "Guinea Ecuatorial",
            ja: "赤道ギニア",
        },
        alpha2: "GQ",
        alpha3: "GNQ",
        numeric: "226",
    },
    {
        code: "ER",
        name: {
            en: "Eritrea",
            es: "Eritrea",
            ja: "エリトリア",
        },
        alpha2: "ER",
        alpha3: "ERI",
        numeric: "232",
    },
    {
        code: "EE",
        name: {
            en: "Estonia",
            es: "Estonia",
            ja: "エストニア",
        },
        alpha2: "EE",
        alpha3: "EST",
        numeric: "233",
    },
    {
        code: "SZ",
        name: {
            en: "Eswatini",
            es: "Suazilandia",
            ja: "エスワティニ",
        },
        alpha2: "SZ",
        alpha3: "SWZ",
        numeric: "748",
    },
    {
        code: "ET",
        name: {
            en: "Ethiopia",
            es: "Etiopía",
            ja: "エチオピア",
        },
        alpha2: "ET",
        alpha3: "ETH",
        numeric: "231",
    },
    {
        code: "FK",
        name: {
            en: "Falkland Islands (Malvinas)",
            es: "Islas Malvinas",
            ja: "フォークランド（マルビナス）諸島",
        },
        alpha2: "FK",
        alpha3: "FLK",
        numeric: "238",
    },
    {
        code: "FO",
        name: {
            en: "Faroe Islands",
            es: "Islas Feroe",
            ja: "フェロー諸島",
        },
        alpha2: "FO",
        alpha3: "FRO",
        numeric: "234",
    },
    {
        code: "FJ",
        name: {
            en: "Fiji",
            es: "Fiyi",
            ja: "フィジー",
        },
        alpha2: "FJ",
        alpha3: "FJI",
        numeric: "242",
    },
    {
        code: "FI",
        name: {
            en: "Finland",
            es: "Finlandia",
            ja: "フィンランド",
        },
        alpha2: "FI",
        alpha3: "FIN",
        numeric: "246",
    },
    {
        code: "FR",
        name: {
            en: "France",
            es: "Francia",
            ja: "フランス",
        },
        alpha2: "FR",
        alpha3: "FRA",
        numeric: "250",
    },
    {
        code: "GF",
        name: {
            en: "French Guiana",
            es: "Guayana Francesa",
            ja: "フランス領ギアナ",
        },
        alpha2: "GF",
        alpha3: "GUF",
        numeric: "254",
    },
    {
        code: "PF",
        name: {
            en: "French Polynesia",
            es: "Polinesia Francesa",
            ja: "フランス領ポリネシア",
        },
        alpha2: "PF",
        alpha3: "PYF",
        numeric: "258",
    },
    {
        code: "TF",
        name: {
            en: "French Southern Territories",
            es: "Tierras Australes y Antárticas Francesas",
            ja: "フランス領南方・南極地域",
        },
        alpha2: "TF",
        alpha3: "ATF",
        numeric: "260",
    },
    {
        code: "GA",
        name: {
            en: "Gabon",
            es: "Gabón",
            ja: "ガボン",
        },
        alpha2: "GA",
        alpha3: "GAB",
        numeric: "266",
    },
    {
        code: "GM",
        name: {
            en: "Gambia",
            es: "Gambia",
            ja: "ガンビア",
        },
        alpha2: "GM",
        alpha3: "GMB",
        numeric: "270",
    },
    {
        code: "GE",
        name: {
            en: "Georgia",
            es: "Georgia",
            ja: "ジョージア",
        },
        alpha2: "GE",
        alpha3: "GEO",
        numeric: "268",
    },
    {
        code: "DE",
        name: {
            en: "Germany",
            es: "Alemania",
            ja: "ドイツ",
        },
        alpha2: "DE",
        alpha3: "DEU",
        numeric: "276",
    },
    {
        code: "GH",
        name: {
            en: "Ghana",
            es: "Ghana",
            ja: "ガーナ",
        },
        alpha2: "GH",
        alpha3: "GHA",
        numeric: "288",
    },
    {
        code: "GI",
        name: {
            en: "Gibraltar",
            es: "Gibraltar",
            ja: "ジブラルタル",
        },
        alpha2: "GI",
        alpha3: "GIB",
        numeric: "292",
    },
    {
        code: "GR",
        name: {
            en: "Greece",
            es: "Grecia",
            ja: "ギリシャ",
        },
        alpha2: "GR",
        alpha3: "GRC",
        numeric: "300",
    },
    {
        code: "GL",
        name: {
            en: "Greenland",
            es: "Groenlandia",
            ja: "グリーンランド",
        },
        alpha2: "GL",
        alpha3: "GRL",
        numeric: "304",
    },
    {
        code: "GD",
        name: {
            en: "Grenada",
            es: "Granada",
            ja: "グレナダ",
        },
        alpha2: "GD",
        alpha3: "GRD",
        numeric: "308",
    },
    {
        code: "GP",
        name: {
            en: "Guadeloupe",
            es: "Guadalupe",
            ja: "グアドループ",
        },
        alpha2: "GP",
        alpha3: "GLP",
        numeric: "312",
    },
    {
        code: "GU",
        name: {
            en: "Guam",
            es: "Guam",
            ja: "グアム",
        },
        alpha2: "GU",
        alpha3: "GUM",
        numeric: "316",
    },
    {
        code: "GT",
        name: {
            en: "Guatemala",
            es: "Guatemala",
            ja: "グアテマラ",
        },
        alpha2: "GT",
        alpha3: "GTM",
        numeric: "320",
    },
    {
        code: "GG",
        name: {
            en: "Guernsey",
            es: "Guernsey",
            ja: "ガーンジー",
        },
        alpha2: "GG",
        alpha3: "GGY",
        numeric: "831",
    },
    {
        code: "GN",
        name: {
            en: "Guinea",
            es: "Guinea",
            ja: "ギニア",
        },
        alpha2: "GN",
        alpha3: "GIN",
        numeric: "324",
    },
    {
        code: "GW",
        name: {
            en: "Guinea-Bissau",
            es: "Guinea-Bisáu",
            ja: "ギニアビサウ",
        },
        alpha2: "GW",
        alpha3: "GNB",
        numeric: "624",
    },
    {
        code: "GY",
        name: {
            en: "Guyana",
            es: "Guyana",
            ja: "ガイアナ",
        },
        alpha2: "GY",
        alpha3: "GUY",
        numeric: "328",
    },
    {
        code: "HT",
        name: {
            en: "Haiti",
            es: "Haití",
            ja: "ハイチ",
        },
        alpha2: "HT",
        alpha3: "HTI",
        numeric: "332",
    },
    {
        code: "HM",
        name: {
            en: "Heard Island and McDonald Islands",
            es: "Islas Heard y McDonald",
            ja: "ハード島とマクドナルド諸島",
        },
        alpha2: "HM",
        alpha3: "HMD",
        numeric: "334",
    },
    {
        code: "VA",
        name: {
            en: "Holy See",
            es: "Vaticano, Ciudad del",
            ja: "バチカン市国",
        },
        alpha2: "VA",
        alpha3: "VAT",
        numeric: "336",
    },
    {
        code: "HN",
        name: {
            en: "Honduras",
            es: "Honduras",
            ja: "ホンジュラス",
        },
        alpha2: "HN",
        alpha3: "HND",
        numeric: "340",
    },
    {
        code: "HK",
        name: {
            en: "Hong Kong",
            es: "Hong Kong",
            ja: "香港",
        },
        alpha2: "HK",
        alpha3: "HKG",
        numeric: "344",
    },
    {
        code: "HU",
        name: {
            en: "Hungary",
            es: "Hungría",
            ja: "ハンガリー",
        },
        alpha2: "HU",
        alpha3: "HUN",
        numeric: "348",
    },
    {
        code: "IS",
        name: {
            en: "Iceland",
            es: "Islandia",
            ja: "アイスランド",
        },
        alpha2: "IS",
        alpha3: "ISL",
        numeric: "352",
    },
    {
        code: "IN",
        name: {
            en: "India",
            es: "India",
            ja: "インド",
        },
        alpha2: "IN",
        alpha3: "IND",
        numeric: "356",
    },
    {
        code: "ID",
        name: {
            en: "Indonesia",
            es: "Indonesia",
            ja: "インドネシア",
        },
        alpha2: "ID",
        alpha3: "IDN",
        numeric: "360",
    },
    {
        code: "IR",
        name: {
            en: "Iran (Islamic Republic of)",
            es: "Irán",
            ja: "イラン・イスラム共和国",
        },
        alpha2: "IR",
        alpha3: "IRN",
        numeric: "364",
    },
    {
        code: "IQ",
        name: {
            en: "Iraq",
            es: "Irak",
            ja: "イラク",
        },
        alpha2: "IQ",
        alpha3: "IRQ",
        numeric: "368",
    },
    {
        code: "IM",
        name: {
            en: "Isle of Man",
            es: "Isla de Man",
            ja: "マン島",
        },
        alpha2: "IM",
        alpha3: "IMN",
        numeric: "833",
    },
    {
        code: "IL",
        name: {
            en: "Israel",
            es: "Israel",
            ja: "イスラエル",
        },
        alpha2: "IL",
        alpha3: "ISR",
        numeric: "376",
    },
    {
        code: "IT",
        name: {
            en: "Italy",
            es: "Italia",
            ja: "イタリア",
        },
        alpha2: "IT",
        alpha3: "ITA",
        numeric: "380",
    },
    {
        code: "JM",
        name: {
            en: "Jamaica",
            es: "Jamaica",
            ja: "ジャマイカ",
        },
        alpha2: "JM",
        alpha3: "JAM",
        numeric: "388",
    },
    {
        code: "JP",
        name: {
            en: "Japan",
            es: "Japón",
            ja: "日本",
        },
        alpha2: "JP",
        alpha3: "JPN",
        numeric: "392",
    },
    {
        code: "JE",
        name: {
            en: "Jersey",
            es: "Jersey",
            ja: "ジャージー",
        },
        alpha2: "JE",
        alpha3: "JEY",
        numeric: "832",
    },
    {
        code: "JO",
        name: {
            en: "Jordan",
            es: "Jordania",
            ja: "ヨルダン",
        },
        alpha2: "JO",
        alpha3: "JOR",
        numeric: "400",
    },
    {
        code: "KZ",
        name: {
            en: "Kazakhstan",
            es: "Kazajistán",
            ja: "カザフスタン",
        },
        alpha2: "KZ",
        alpha3: "KAZ",
        numeric: "398",
    },
    {
        code: "KE",
        name: {
            en: "Kenya",
            es: "Kenia",
            ja: "ケニア",
        },
        alpha2: "KE",
        alpha3: "KEN",
        numeric: "404",
    },
    {
        code: "KI",
        name: {
            en: "Kiribati",
            es: "Kiribati",
            ja: "キリバス",
        },
        alpha2: "KI",
        alpha3: "KIR",
        numeric: "296",
    },
    {
        code: "KP",
        name: {
            en: "Korea (Democratic People's Republic of)",
            es: "Corea del Norte",
            ja: "朝鮮民主主義人民共和国",
        },
        alpha2: "KP",
        alpha3: "PRK",
        numeric: "408",
    },
    {
        code: "KR",
        name: {
            en: "Korea, Republic of",
            es: "Corea del Sur",
            ja: "大韓民国",
        },
        alpha2: "KR",
        alpha3: "KOR",
        numeric: "410",
    },
    {
        code: "XK",
        name: {
            en: "Kosovo",
            es: "Kosovo",
            ja: "コソボ",
        },
        alpha2: "XK",
        alpha3: "XXK",
        numeric: "383",
    },
    {
        code: "KW",
        name: {
            en: "Kuwait",
            es: "Kuwait",
            ja: "クウェート",
        },
        alpha2: "KW",
        alpha3: "KWT",
        numeric: "414",
    },
    {
        code: "KG",
        name: {
            en: "Kyrgyzstan",
            es: "Kirguistán",
            ja: "キルギス",
        },
        alpha2: "KG",
        alpha3: "KGZ",
        numeric: "417",
    },
    {
        code: "LA",
        name: {
            en: "Lao People's Democratic Republic",
            es: "Laos",
            ja: "ラオス人民民主共和国",
        },
        alpha2: "LA",
        alpha3: "LAO",
        numeric: "418",
    },
    {
        code: "LV",
        name: {
            en: "Latvia",
            es: "Letonia",
            ja: "ラトビア",
        },
        alpha2: "LV",
        alpha3: "LVA",
        numeric: "428",
    },
    {
        code: "LB",
        name: {
            en: "Lebanon",
            es: "Líbano",
            ja: "レバノン",
        },
        alpha2: "LB",
        alpha3: "LBN",
        numeric: "422",
    },
    {
        code: "LS",
        name: {
            en: "Lesotho",
            es: "Lesoto",
            ja: "レソト",
        },
        alpha2: "LS",
        alpha3: "LSO",
        numeric: "426",
    },
    {
        code: "LR",
        name: {
            en: "Liberia",
            es: "Liberia",
            ja: "リベリア",
        },
        alpha2: "LR",
        alpha3: "LBR",
        numeric: "430",
    },
    {
        code: "LY",
        name: {
            en: "Libya",
            es: "Libia",
            ja: "リビア",
        },
        alpha2: "LY",
        alpha3: "LBY",
        numeric: "434",
    },
    {
        code: "LI",
        name: {
            en: "Liechtenstein",
            es: "Liechtenstein",
            ja: "リヒテンシュタイン",
        },
        alpha2: "LI",
        alpha3: "LIE",
        numeric: "438",
    },
    {
        code: "LT",
        name: {
            en: "Lithuania",
            es: "Lituania",
            ja: "リトアニア",
        },
        alpha2: "LT",
        alpha3: "LTU",
        numeric: "440",
    },
    {
        code: "LU",
        name: {
            en: "Luxembourg",
            es: "Luxemburgo",
            ja: "ルクセンブルク",
        },
        alpha2: "LU",
        alpha3: "LUX",
        numeric: "442",
    },
    {
        code: "MO",
        name: {
            en: "Macao",
            es: "Macao",
            ja: "マカオ",
        },
        alpha2: "MO",
        alpha3: "MAC",
        numeric: "446",
    },
    {
        code: "MG",
        name: {
            en: "Madagascar",
            es: "Madagascar",
            ja: "マダガスカル",
        },
        alpha2: "MG",
        alpha3: "MDG",
        numeric: "450",
    },
    {
        code: "MW",
        name: {
            en: "Malawi",
            es: "Malaui",
            ja: "マラウイ",
        },
        alpha2: "MW",
        alpha3: "MWI",
        numeric: "454",
    },
    {
        code: "MY",
        name: {
            en: "Malaysia",
            es: "Malasia",
            ja: "マレーシア",
        },
        alpha2: "MY",
        alpha3: "MYS",
        numeric: "458",
    },
    {
        code: "MV",
        name: {
            en: "Maldives",
            es: "Maldivas",
            ja: "モルディブ",
        },
        alpha2: "MV",
        alpha3: "MDV",
        numeric: "462",
    },
    {
        code: "ML",
        name: {
            en: "Mali",
            es: "Malí",
            ja: "マリ",
        },
        alpha2: "ML",
        alpha3: "MLI",
        numeric: "466",
    },
    {
        code: "MT",
        name: {
            en: "Malta",
            es: "Malta",
            ja: "マルタ",
        },
        alpha2: "MT",
        alpha3: "MLT",
        numeric: "470",
    },
    {
        code: "MH",
        name: {
            en: "Marshall Islands",
            es: "Islas Marshall",
            ja: "マーシャル諸島",
        },
        alpha2: "MH",
        alpha3: "MHL",
        numeric: "584",
    },
    {
        code: "MQ",
        name: {
            en: "Martinique",
            es: "Martinica",
            ja: "マルティニーク",
        },
        alpha2: "MQ",
        alpha3: "MTQ",
        numeric: "474",
    },
    {
        code: "MR",
        name: {
            en: "Mauritania",
            es: "Mauritania",
            ja: "モーリタニア",
        },
        alpha2: "MR",
        alpha3: "MRT",
        numeric: "478",
    },
    {
        code: "MU",
        name: {
            en: "Mauritius",
            es: "Mauricio",
            ja: "モーリシャス",
        },
        alpha2: "MU",
        alpha3: "MUS",
        numeric: "480",
    },
    {
        code: "YT",
        name: {
            en: "Mayotte",
            es: "Mayotte",
            ja: "マヨット",
        },
        alpha2: "YT",
        alpha3: "MYT",
        numeric: "175",
    },
    {
        code: "MX",
        name: {
            en: "Mexico",
            es: "México",
            ja: "メキシコ",
        },
        alpha2: "MX",
        alpha3: "MEX",
        numeric: "484",
    },
    {
        code: "FM",
        name: {
            en: "Micronesia (Federated States of)",
            es: "Micronesia",
            ja: "ミクロネシア連邦",
        },
        alpha2: "FM",
        alpha3: "FSM",
        numeric: "583",
    },
    {
        code: "MD",
        name: {
            en: "Moldova, Republic of",
            es: "Moldavia",
            ja: "モルドバ共和国",
        },
        alpha2: "MD",
        alpha3: "MDA",
        numeric: "498",
    },
    {
        code: "MC",
        name: {
            en: "Monaco",
            es: "Mónaco",
            ja: "モナコ",
        },
        alpha2: "MC",
        alpha3: "MCO",
        numeric: "492",
    },
    {
        code: "MN",
        name: {
            en: "Mongolia",
            es: "Mongolia",
            ja: "モンゴル",
        },
        alpha2: "MN",
        alpha3: "MNG",
        numeric: "496",
    },
    {
        code: "ME",
        name: {
            en: "Montenegro",
            es: "Montenegro",
            ja: "モンテネグロ",
        },
        alpha2: "ME",
        alpha3: "MNE",
        numeric: "499",
    },
    {
        code: "MS",
        name: {
            en: "Montserrat",
            es: "Montserrat",
            ja: "モントセラト",
        },
        alpha2: "MS",
        alpha3: "MSR",
        numeric: "500",
    },
    {
        code: "MA",
        name: {
            en: "Morocco",
            es: "Marruecos",
            ja: "モロッコ",
        },
        alpha2: "MA",
        alpha3: "MAR",
        numeric: "504",
    },
    {
        code: "MZ",
        name: {
            en: "Mozambique",
            es: "Mozambique",
            ja: "モザンビーク",
        },
        alpha2: "MZ",
        alpha3: "MOZ",
        numeric: "508",
    },
    {
        code: "MM",
        name: {
            en: "Myanmar",
            es: "Birmania",
            ja: "ミャンマー",
        },
        alpha2: "MM",
        alpha3: "MMR",
        numeric: "104",
    },
    {
        code: "NA",
        name: {
            en: "Namibia",
            es: "Namibia",
            ja: "ナミビア",
        },
        alpha2: "NA",
        alpha3: "NAM",
        numeric: "516",
    },
    {
        code: "NR",
        name: {
            en: "Nauru",
            es: "Nauru",
            ja: "ナウル",
        },
        alpha2: "NR",
        alpha3: "NRU",
        numeric: "520",
    },
    {
        code: "NP",
        name: {
            en: "Nepal",
            es: "Nepal",
            ja: "ネパール",
        },
        alpha2: "NP",
        alpha3: "NPL",
        numeric: "524",
    },
    {
        code: "NL",
        name: {
            en: "Netherlands",
            es: "Países Bajos",
            ja: "オランダ",
        },
        alpha2: "NL",
        alpha3: "NLD",
        numeric: "528",
    },
    {
        code: "NC",
        name: {
            en: "New Caledonia",
            es: "Nueva Caledonia",
            ja: "ニューカレドニア",
        },
        alpha2: "NC",
        alpha3: "NCL",
        numeric: "540",
    },
    {
        code: "NZ",
        name: {
            en: "New Zealand",
            es: "Nueva Zelanda",
            ja: "ニュージーランド",
        },
        alpha2: "NZ",
        alpha3: "NZL",
        numeric: "554",
    },
    {
        code: "NI",
        name: {
            en: "Nicaragua",
            es: "Nicaragua",
            ja: "ニカラグア",
        },
        alpha2: "NI",
        alpha3: "NIC",
        numeric: "558",
    },
    {
        code: "NE",
        name: {
            en: "Niger",
            es: "Níger",
            ja: "ニジェール",
        },
        alpha2: "NE",
        alpha3: "NER",
        numeric: "562",
    },
    {
        code: "NG",
        name: {
            en: "Nigeria",
            es: "Nigeria",
            ja: "ナイジェリア",
        },
        alpha2: "NG",
        alpha3: "NGA",
        numeric: "566",
    },
    {
        code: "NU",
        name: {
            en: "Niue",
            es: "Niue",
            ja: "ニウエ",
        },
        alpha2: "NU",
        alpha3: "NIU",
        numeric: "570",
    },
    {
        code: "NF",
        name: {
            en: "Norfolk Island",
            es: "Isla Norfolk",
            ja: "ノーフォーク島",
        },
        alpha2: "NF",
        alpha3: "NFK",
        numeric: "574",
    },
    {
        code: "MK",
        name: {
            en: "North Macedonia",
            es: "Macedonia del Norte",
            ja: "北マケドニア",
        },
        alpha2: "MK",
        alpha3: "MKD",
        numeric: "807",
    },
    {
        code: "MP",
        name: {
            en: "Northern Mariana Islands",
            es: "Islas Marianas del Norte",
            ja: "北マリアナ諸島",
        },
        alpha2: "MP",
        alpha3: "MNP",
        numeric: "580",
    },
    {
        code: "NO",
        name: {
            en: "Norway",
            es: "Noruega",
            ja: "ノルウェー",
        },
        alpha2: "NO",
        alpha3: "NOR",
        numeric: "578",
    },
    {
        code: "OM",
        name: {
            en: "Oman",
            es: "Omán",
            ja: "オマーン",
        },
        alpha2: "OM",
        alpha3: "OMN",
        numeric: "512",
    },
    {
        code: "PK",
        name: {
            en: "Pakistan",
            es: "Pakistán",
            ja: "パキスタン",
        },
        alpha2: "PK",
        alpha3: "PAK",
        numeric: "586",
    },
    {
        code: "PW",
        name: {
            en: "Palau",
            es: "Palaos",
            ja: "パラオ",
        },
        alpha2: "PW",
        alpha3: "PLW",
        numeric: "585",
    },
    {
        code: "PS",
        name: {
            en: "Palestine, State of",
            es: "Palestina",
            ja: "パレスチナ",
        },
        alpha2: "PS",
        alpha3: "PSE",
        numeric: "275",
    },
    {
        code: "PA",
        name: {
            en: "Panama",
            es: "Panamá",
            ja: "パナマ",
        },
        alpha2: "PA",
        alpha3: "PAN",
        numeric: "591",
    },
    {
        code: "PG",
        name: {
            en: "Papua New Guinea",
            es: "Papúa Nueva Guinea",
            ja: "パプアニューギニア",
        },
        alpha2: "PG",
        alpha3: "PNG",
        numeric: "598",
    },
    {
        code: "PY",
        name: {
            en: "Paraguay",
            es: "Paraguay",
            ja: "パラグアイ",
        },
        alpha2: "PY",
        alpha3: "PRY",
        numeric: "600",
    },
    {
        code: "PE",
        name: {
            en: "Peru",
            es: "Perú",
            ja: "ペルー",
        },
        alpha2: "PE",
        alpha3: "PER",
        numeric: "604",
    },
    {
        code: "PH",
        name: {
            en: "Philippines",
            es: "Filipinas",
            ja: "フィリピン",
        },
        alpha2: "PH",
        alpha3: "PHL",
        numeric: "608",
    },
    {
        code: "PN",
        name: {
            en: "Pitcairn",
            es: "Islas Pitcairn",
            ja: "ピトケアン",
        },
        alpha2: "PN",
        alpha3: "PCN",
        numeric: "612",
    },
    {
        code: "PL",
        name: {
            en: "Poland",
            es: "Polonia",
            ja: "ポーランド",
        },
        alpha2: "PL",
        alpha3: "POL",
        numeric: "616",
    },
    {
        code: "PT",
        name: {
            en: "Portugal",
            es: "Portugal",
            ja: "ポルトガル",
        },
        alpha2: "PT",
        alpha3: "PRT",
        numeric: "620",
    },
    {
        code: "PR",
        name: {
            en: "Puerto Rico",
            es: "Puerto Rico",
            ja: "プエルトリコ",
        },
        alpha2: "PR",
        alpha3: "PRI",
        numeric: "630",
    },
    {
        code: "QA",
        name: {
            en: "Qatar",
            es: "Catar",
            ja: "カタール",
        },
        alpha2: "QA",
        alpha3: "QAT",
        numeric: "634",
    },
    {
        code: "RE",
        name: {
            en: "Réunion",
            es: "Reunión",
            ja: "レユニオン",
        },
        alpha2: "RE",
        alpha3: "REU",
        numeric: "638",
    },
    {
        code: "RO",
        name: {
            en: "Romania",
            es: "Rumania",
            ja: "ルーマニア",
        },
        alpha2: "RO",
        alpha3: "ROU",
        numeric: "642",
    },
    {
        code: "RU",
        name: {
            en: "Russian Federation",
            es: "Rusia",
            ja: "ロシア連邦",
        },
        alpha2: "RU",
        alpha3: "RUS",
        numeric: "643",
    },
    {
        code: "RW",
        name: {
            en: "Rwanda",
            es: "Ruanda",
            ja: "ルワンダ",
        },
        alpha2: "RW",
        alpha3: "RWA",
        numeric: "646",
    },
    {
        code: "BL",
        name: {
            en: "Saint Barthélemy",
            es: "San Bartolomé",
            ja: "サン・バルテルミー",
        },
        alpha2: "BL",
        alpha3: "BLM",
        numeric: "652",
    },
    {
        code: "SH",
        name: {
            en: "Saint Helena, Ascension and Tristan da Cunha",
            es: "Santa Elena, Ascensión y Tristán de Acuña",
            ja: "セントヘレナ・アセンションおよびトリスタンダクーニャ",
        },
        alpha2: "SH",
        alpha3: "SHN",
        numeric: "654",
    },
    {
        code: "KN",
        name: {
            en: "Saint Kitts and Nevis",
            es: "San Cristóbal y Nieves",
            ja: "セントクリストファー・ネイビス",
        },
        alpha2: "KN",
        alpha3: "KNA",
        numeric: "659",
    },
    {
        code: "LC",
        name: {
            en: "Saint Lucia",
            es: "Santa Lucía",
            ja: "セントルシア",
        },
        alpha2: "LC",
        alpha3: "LCA",
        numeric: "662",
    },
    {
        code: "MF",
        name: {
            en: "Saint Martin (French part)",
            es: "San Martín",
            ja: "サン・マルタン（フランス領）",
        },
        alpha2: "MF",
        alpha3: "MAF",
        numeric: "663",
    },
    {
        code: "PM",
        name: {
            en: "Saint Pierre and Miquelon",
            es: "San Pedro y Miquelón",
            ja: "サンピエール島・ミクロン島",
        },
        alpha2: "PM",
        alpha3: "SPM",
        numeric: "666",
    },
    {
        code: "VC",
        name: {
            en: "Saint Vincent and the Grenadines",
            es: "San Vicente y las Granadinas",
            ja: "セントビンセントおよびグレナディーン諸島",
        },
        alpha2: "VC",
        alpha3: "VCT",
        numeric: "670",
    },
    {
        code: "WS",
        name: {
            en: "Samoa",
            es: "Samoa",
            ja: "サモア",
        },
        alpha2: "WS",
        alpha3: "WSM",
        numeric: "882",
    },
    {
        code: "SM",
        name: {
            en: "San Marino",
            es: "San Marino",
            ja: "サンマリノ",
        },
        alpha2: "SM",
        alpha3: "SMR",
        numeric: "674",
    },
    {
        code: "ST",
        name: {
            en: "Sao Tome and Principe",
            es: "Santo Tomé y Príncipe",
            ja: "サントメ・プリンシペ",
        },
        alpha2: "ST",
        alpha3: "STP",
        numeric: "678",
    },
    {
        code: "SA",
        name: {
            en: "Saudi Arabia",
            es: "Arabia Saudita",
            ja: "サウジアラビア",
        },
        alpha2: "SA",
        alpha3: "SAU",
        numeric: "682",
    },
    {
        code: "SN",
        name: {
            en: "Senegal",
            es: "Senegal",
            ja: "セネガル",
        },
        alpha2: "SN",
        alpha3: "SEN",
        numeric: "686",
    },
    {
        code: "RS",
        name: {
            en: "Serbia",
            es: "Serbia",
            ja: "セルビア",
        },
        alpha2: "RS",
        alpha3: "SRB",
        numeric: "688",
    },
    {
        code: "SC",
        name: {
            en: "Seychelles",
            es: "Seychelles",
            ja: "セーシェル",
        },
        alpha2: "SC",
        alpha3: "SYC",
        numeric: "690",
    },
    {
        code: "SL",
        name: {
            en: "Sierra Leone",
            es: "Sierra Leona",
            ja: "シエラレオネ",
        },
        alpha2: "SL",
        alpha3: "SLE",
        numeric: "694",
    },
    {
        code: "SG",
        name: {
            en: "Singapore",
            es: "Singapur",
            ja: "シンガポール",
        },
        alpha2: "SG",
        alpha3: "SGP",
        numeric: "702",
    },
    {
        code: "SX",
        name: {
            en: "Sint Maarten (Dutch part)",
            es: "San Martín",
            ja: "シント・マールテン（オランダ領）",
        },
        alpha2: "SX",
        alpha3: "SXM",
        numeric: "534",
    },
    {
        code: "SK",
        name: {
            en: "Slovakia",
            es: "Eslovaquia",
            ja: "スロバキア",
        },
        alpha2: "SK",
        alpha3: "SVK",
        numeric: "703",
    },
    {
        code: "SI",
        name: {
            en: "Slovenia",
            es: "Eslovenia",
            ja: "スロベニア",
        },
        alpha2: "SI",
        alpha3: "SVN",
        numeric: "705",
    },
    {
        code: "SB",
        name: {
            en: "Solomon Islands",
            es: "Islas Salomón",
            ja: "ソロモン諸島",
        },
        alpha2: "SB",
        alpha3: "SLB",
        numeric: "090",
    },
    {
        code: "SO",
        name: {
            en: "Somalia",
            es: "Somalia",
            ja: "ソマリア",
        },
        alpha2: "SO",
        alpha3: "SOM",
        numeric: "706",
    },
    {
        code: "ZA",
        name: {
            en: "South Africa",
            es: "Sudáfrica",
            ja: "南アフリカ",
        },
        alpha2: "ZA",
        alpha3: "ZAF",
        numeric: "710",
    },
    {
        code: "GS",
        name: {
            en: "South Georgia and the South Sandwich Islands",
            es: "Islas Georgias del Sur y Sandwich del Sur",
            ja: "サウスジョージア・サウスサンドウィッチ諸島",
        },
        alpha2: "GS",
        alpha3: "SGS",
        numeric: "239",
    },
    {
        code: "SS",
        name: {
            en: "South Sudan",
            es: "Sudán del Sur",
            ja: "南スーダン",
        },
        alpha2: "SS",
        alpha3: "SSD",
        numeric: "728",
    },
    {
        code: "ES",
        name: {
            en: "Spain",
            es: "España",
            ja: "スペイン",
        },
        alpha2: "ES",
        alpha3: "ESP",
        numeric: "724",
    },
    {
        code: "LK",
        name: {
            en: "Sri Lanka",
            es: "Sri Lanka",
            ja: "スリランカ",
        },
        alpha2: "LK",
        alpha3: "LKA",
        numeric: "144",
    },
    {
        code: "SD",
        name: {
            en: "Sudan",
            es: "Sudán",
            ja: "スーダン",
        },
        alpha2: "SD",
        alpha3: "SDN",
        numeric: "729",
    },
    {
        code: "SR",
        name: {
            en: "Suriname",
            es: "Surinam",
            ja: "スリナム",
        },
        alpha2: "SR",
        alpha3: "SUR",
        numeric: "740",
    },
    {
        code: "SJ",
        name: {
            en: "Svalbard and Jan Mayen",
            es: "Svalbard y Jan Mayen",
            ja: "スヴァールバル諸島およびヤンマイエン島",
        },
        alpha2: "SJ",
        alpha3: "SJM",
        numeric: "744",
    },
    {
        code: "SE",
        name: {
            en: "Sweden",
            es: "Suecia",
            ja: "スウェーデン",
        },
        alpha2: "SE",
        alpha3: "SWE",
        numeric: "752",
    },
    {
        code: "CH",
        name: {
            en: "Switzerland",
            es: "Suiza",
            ja: "スイス",
        },
        alpha2: "CH",
        alpha3: "CHE",
        numeric: "756",
    },
    {
        code: "SY",
        name: {
            en: "Syrian Arab Republic",
            es: "Siria",
            ja: "シリア・アラブ共和国",
        },
        alpha2: "SY",
        alpha3: "SYR",
        numeric: "760",
    },
    {
        code: "TW",
        name: {
            en: "Taiwan, Province of China",
            es: "Taiwán (República de China)",
            ja: "中国台湾省（中華民国）",
        },
        alpha2: "TW",
        alpha3: "TWN",
        numeric: "158",
    },
    {
        code: "TJ",
        name: {
            en: "Tajikistan",
            es: "Tayikistán",
            ja: "タジキスタン",
        },
        alpha2: "TJ",
        alpha3: "TJK",
        numeric: "762",
    },
    {
        code: "TZ",
        name: {
            en: "Tanzania, United Republic of",
            es: "Tanzania",
            ja: "タンザニア",
        },
        alpha2: "TZ",
        alpha3: "TZA",
        numeric: "834",
    },
    {
        code: "TH",
        name: {
            en: "Thailand",
            es: "Tailandia",
            ja: "タイ",
        },
        alpha2: "TH",
        alpha3: "THA",
        numeric: "764",
    },
    {
        code: "TL",
        name: {
            en: "Timor-Leste",
            es: "Timor Oriental",
            ja: "東ティモール",
        },
        alpha2: "TL",
        alpha3: "TLS",
        numeric: "626",
    },
    {
        code: "TG",
        name: {
            en: "Togo",
            es: "Togo",
            ja: "トーゴ",
        },
        alpha2: "TG",
        alpha3: "TGO",
        numeric: "768",
    },
    {
        code: "TK",
        name: {
            en: "Tokelau",
            es: "Tokelau",
            ja: "トケラウ",
        },
        alpha2: "TK",
        alpha3: "TKL",
        numeric: "772",
    },
    {
        code: "TO",
        name: {
            en: "Tonga",
            es: "Tonga",
            ja: "トンガ",
        },
        alpha2: "TO",
        alpha3: "TON",
        numeric: "776",
    },
    {
        code: "TT",
        name: {
            en: "Trinidad and Tobago",
            es: "Trinidad y Tobago",
            ja: "トリニダード・トバゴ",
        },
        alpha2: "TT",
        alpha3: "TTO",
        numeric: "780",
    },
    {
        code: "TN",
        name: {
            en: "Tunisia",
            es: "Túnez",
            ja: "チュニジア",
        },
        alpha2: "TN",
        alpha3: "TUN",
        numeric: "788",
    },
    {
        code: "TR",
        name: {
            en: "Turkey",
            es: "Turquía",
            ja: "トルコ",
        },
        alpha2: "TR",
        alpha3: "TUR",
        numeric: "792",
    },
    {
        code: "TM",
        name: {
            en: "Turkmenistan",
            es: "Turkmenistán",
            ja: "トルクメニスタン",
        },
        alpha2: "TM",
        alpha3: "TKM",
        numeric: "795",
    },
    {
        code: "TC",
        name: {
            en: "Turks and Caicos Islands",
            es: "Islas Turcas y Caicos",
            ja: "タークス・カイコス諸島",
        },
        alpha2: "TC",
        alpha3: "TCA",
        numeric: "796",
    },
    {
        code: "TV",
        name: {
            en: "Tuvalu",
            es: "Tuvalu",
            ja: "ツバル",
        },
        alpha2: "TV",
        alpha3: "TUV",
        numeric: "798",
    },
    {
        code: "UG",
        name: {
            en: "Uganda",
            es: "Uganda",
            ja: "ウガンダ",
        },
        alpha2: "UG",
        alpha3: "UGA",
        numeric: "800",
    },
    {
        code: "UA",
        name: {
            en: "Ukraine",
            es: "Ucrania",
            ja: "ウクライナ",
        },
        alpha2: "UA",
        alpha3: "UKR",
        numeric: "804",
    },
    {
        code: "AE",
        name: {
            en: "United Arab Emirates",
            es: "Emiratos Árabes Unidos",
            ja: "アラブ首長国連邦",
        },
        alpha2: "AE",
        alpha3: "ARE",
        numeric: "784",
    },
    {
        code: "UM",
        name: {
            en: "United States Minor Outlying Islands",
            es: "Islas Ultramarinas Menores de los Estados Unidos",
            ja: "合衆国領有小離島",
        },
        alpha2: "UM",
        alpha3: "UMI",
        numeric: "581",
    },
    {
        code: "UY",
        name: {
            en: "Uruguay",
            es: "Uruguay",
            ja: "ウルグアイ",
        },
        alpha2: "UY",
        alpha3: "URY",
        numeric: "858",
    },
    {
        code: "UZ",
        name: {
            en: "Uzbekistan",
            es: "Uzbekistán",
            ja: "ウズベキスタン",
        },
        alpha2: "UZ",
        alpha3: "UZB",
        numeric: "860",
    },
    {
        code: "VU",
        name: {
            en: "Vanuatu",
            es: "Vanuatu",
            ja: "バヌアツ",
        },
        alpha2: "VU",
        alpha3: "VUT",
        numeric: "548",
    },
    {
        code: "VE",
        name: {
            en: "Venezuela (Bolivarian Republic of)",
            es: "Venezuela",
            ja: "ベネズエラ・ボリバル共和国",
        },
        alpha2: "VE",
        alpha3: "VEN",
        numeric: "862",
    },
    {
        code: "VN",
        name: {
            en: "Vietnam",
            es: "Vietnam",
            ja: "ベトナム",
        },
        alpha2: "VN",
        alpha3: "VNM",
        numeric: "704",
    },
    {
        code: "VG",
        name: {
            en: "Virgin Islands (British)",
            es: "Islas Vírgenes Británicas",
            ja: "イギリス領ヴァージン諸島",
        },
        alpha2: "VG",
        alpha3: "VGB",
        numeric: "092",
    },
    {
        code: "VI",
        name: {
            en: "Virgin Islands (U.S.)",
            es: "Islas Vírgenes de los Estados Unidos",
            ja: "アメリカ領ヴァージン諸島",
        },
        alpha2: "VI",
        alpha3: "VIR",
        numeric: "850",
    },
    {
        code: "WF",
        name: {
            en: "Wallis and Futuna",
            es: "Wallis y Futuna",
            ja: "ウォリス・フツナ",
        },
        alpha2: "WF",
        alpha3: "WLF",
        numeric: "876",
    },
    {
        code: "EH",
        name: {
            en: "Western Sahara",
            es: "República Árabe Saharaui Democrática",
            ja: "西サハラ",
        },
        alpha2: "EH",
        alpha3: "ESH",
        numeric: "732",
    },
    {
        code: "YE",
        name: {
            en: "Yemen",
            es: "Yemen",
            ja: "イエメン",
        },
        alpha2: "YE",
        alpha3: "YEM",
        numeric: "887",
    },
    {
        code: "ZM",
        name: {
            en: "Zambia",
            es: "Zambia",
            ja: "ザンビア",
        },
        alpha2: "ZM",
        alpha3: "ZMB",
        numeric: "894",
    },
    {
        code: "ZW",
        name: {
            en: "Zimbabwe",
            es: "Zimbabue",
            ja: "ジンバブエ",
        },
        alpha2: "ZW",
        alpha3: "ZWE",
        numeric: "716",
    },
];
