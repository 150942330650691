export const getLocal = key => JSON.parse(localStorage.getItem(key));
export const setLocal = (key, val) => localStorage.setItem(key, JSON.stringify(val));
export const clearLocal = key => localStorage.removeItem(key);

export const returnOkResponse = value => (req, res, ctx) => {
    return res(ctx.delay(), ctx.status(200), ctx.json(value))
};

export const returnOkPostResponse = value => (req, res, ctx) => {
    return res(ctx.delay(), ctx.status(202), ctx.json(value))
};