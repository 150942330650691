import React from "react";
import { closeForm } from "../../../utils/dataProcessing";
import { useLocalize } from "../../../utils/hooks";
import ComponentWrapper from "../../_components/Wrappers/ComponentWrapper";
import CarnivalCruiseRetrieveBookingError from "../../CarnivalCruise/CarnivalCruiseRetrieveBookingError";

const BookingError = ({ errorCode, title, message }) => {
    const { localize } = useLocalize();
    return (
        <CarnivalCruiseRetrieveBookingError
            onNext={closeForm}
            errorCode={errorCode}
            title={title}
            message={message}
            buttonLabel={localize("global.close")}
        />
    );
};

export default ComponentWrapper(BookingError);
