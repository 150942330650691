import React, { useContext } from "react";
import { Container } from "react-bootstrap";
import CredentialContext from "../../../config/CredentialContext";
import FlightSummary from "./FlightSummary";
import CruiseSummary from "./CruiseSummary";
import EventSummary from "./EventSummary";
import InterlineFlightDetails from "./InterlineFlightDetails";
import { useCompanion, useLocalize, useLanguage } from "../../../utils/hooks";

export default function TripSummary() {
    const {
        globalConfig,
        processInstance: {
            userData: {
                sharedData: {
                    flightDetails,
                    cruiseDetails,
                    eventDetails,
                    interlineFlightDetails
                } = {},
                userSpecificData = {}
            } = {},
        } = {},
        userId
    } = useContext(CredentialContext);

    const { companionId } = useCompanion();
    const { localize } = useLocalize();
    const { localizeField } = useLanguage();

    const passenger = userSpecificData[companionId || userId];

    const renderTitle = () => {
        const { detailsTitle = '' } = globalConfig;

        if (detailsTitle) {
            return localizeField(detailsTitle);
        }

        if (passenger) {
            return `${passenger.firstName} ${passenger.lastName}`;
        }

        return localize("manageTrip.detailsTitle");
    };

    return (
        <Container className="trip-summary">
            <div className="d-flex justify-content-center">
                {renderTitle()}
            </div>
            {eventDetails && <EventSummary />}
            {flightDetails && <FlightSummary flightDetails={flightDetails} />}
            {cruiseDetails && <CruiseSummary cruiseDetails={cruiseDetails} />}
            {interlineFlightDetails && <InterlineFlightDetails interlineFlightDetails={interlineFlightDetails} />}
        </Container>
    );
}
