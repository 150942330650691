import React, { useState } from "react";
import CarnivalCruiseRetrieveBooking from './CarnivalCruiseRetrieveBooking';
import CarnivalCruiseRetrieveBookingError from './CarnivalCruiseRetrieveBookingError';
import CarnivalCruiseAddGuests from './CarnivalCruiseAddGuests/';

const STEPS = {
    CarnivalCruiseRetrieveBooking: 'CarnivalCruiseRetrieveBooking',
    CarnivalCruiseRetrieveBookingError: 'CarnivalCruiseRetrieveBookingError',
    CarnivalCruiseAddGuests: 'CarnivalCruiseAddGuests'
}

const CarnivalCruise = () => {
    const [step, setStep] = useState(STEPS.CarnivalCruiseRetrieveBooking);
    const [errorTitle, setErrorTitle] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const [errorCode, setErrorCode] = useState();

    const setAddGuests = () => {
        setStep(STEPS.CarnivalCruiseAddGuests);
    };

    const setBooking = () => {
        setStep(STEPS.CarnivalCruiseRetrieveBooking);
    };

    const setError = (title, message, code) => {
        setErrorTitle(title);
        setErrorMessage(message);
        setErrorCode(code);
        setStep(STEPS.CarnivalCruiseRetrieveBookingError);
    };

    switch (step) {
        case STEPS.CarnivalCruiseRetrieveBooking:
            return <CarnivalCruiseRetrieveBooking onNext={setAddGuests} />;
        case STEPS.CarnivalCruiseRetrieveBookingError:
            return <CarnivalCruiseRetrieveBookingError onNext={setBooking} title={errorTitle} message={errorMessage} errorCode={errorCode} />;
        case STEPS.CarnivalCruiseAddGuests:
            return <CarnivalCruiseAddGuests onError={setError} />;
        default:
            return null;
    }
};

export default CarnivalCruise;
