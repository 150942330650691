import { useEffect, useState } from "react";
import useQuery from "./useQuery";
import { DateTime } from "luxon";

const useFromToQuery = (currentTime, step) => {
    const [from, setFrom] = useState();
    const [to, setTo] = useState();
    const [isInDateRange, setIsInDateRange] = useState(true);
    const [isDateInPast, setIsDateInPast] = useState(false);
    const [isDateInFuture, setIsDateInFuture] = useState(false);

    const query = useQuery();

    useEffect(() => {
        const fromDateValue = step?.notBeforeDtm ? step?.notBeforeDtm : query.get("fromDate");
        const fromDate = fromDateValue && fromDateValue.includes(" ") ? fromDateValue.replace(" ", "+") : fromDateValue;

        const toDateValue = step?.notAfterDtm ? step?.notAfterDtm : query.get("toDate");
        const toDate = toDateValue && toDateValue.includes(" ") ? toDateValue.replace(" ", "+") : toDateValue;

        setFrom(fromDate);
        setTo(toDate);
    }, [query]);

    useEffect(() => {
        if (from && to) {
            const now = DateTime.fromISO(currentTime);
            const isInRange = DateTime.fromISO(from) <= now && DateTime.fromISO(to) >= now;
            setIsInDateRange(isInRange);

            const isInPast = DateTime.fromISO(to) <= now;
            setIsDateInPast(isInPast);

            const isInFuture = DateTime.fromISO(from) >= now;
            setIsDateInFuture(isInFuture);
        }
    }, [from, to]);

    return { from, to, isInDateRange, isDateInPast, isDateInFuture };
};

export default useFromToQuery;
