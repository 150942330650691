import React, { useContext, useState } from "react";
import { Form } from "react-bootstrap";
import CredentialContext from "../../config/CredentialContext";
import { useLocalize, useQuery, useCap } from "../../utils/hooks";
import InterlineInfoModal from "./InterlineInfoModal";
import InterlineInvalidFlightModal from "./InterlineInvalidFlightModal";
import { fetchInterlineFlight } from "../../utils/dataFetching";
import { REDIRECT } from "../../utils/hooks/useCap";
import InterlineConfirmationModal from "./InterlineConfirmationModal";
import { validateFligthBlocksResponse, validateInterlineTrip } from '../../utils/flightUtils';
import { bookingRefPatterns } from "./constants";
import { getThreeLeterCodeWithTwoLetterCode } from '../../utils/countryUtils';
import { joinFirstAndMiddleName } from '../../utils/textUtils';

const InterlineFlightsDetalisPnrLookup = ({
    airline,
    airlineName,
    personalDetails,
    confirmationModalConfig,
    checkAndUpdateUser,
}) => {
    const { firstName, middleName, lastName, birthDate, nationality, countryOfResidence } = personalDetails;
    const { showConfirmationModal, closeConfirmationModal } = confirmationModalConfig;

    const { updateLoading, userId, globalConfig, appVersion } = useContext(CredentialContext);
    const { additionalPnrLookupFields } = globalConfig;
    const { localize } = useLocalize();
    const query = useQuery();
    const env = query.get("env");

    const [bookingRef, setBookingRef] = useState();
    const [showInvalidReasonModal, setShowInvalidReasonModal] = useState(false);
    const [showFlightBlocksErrorModal, setShowFlightBlocksErrorModal] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);

    const selectBookingRef = e => setBookingRef(e.target.value.trim());

    const getBookingRefPattern = () => bookingRefPatterns[airline];
    
    const capErrorHandler = e => {
        const { code } = e;
        const codeErrors = ["508", "700"];

        const messages = code && codeErrors.includes(code.toString()) ? [e.code.toString()] : ["defaultMessage"];

        setErrorMessages(messages);
        closeConfirmationModal();
        setShowInvalidReasonModal(true);
        updateLoading("submit", false);
    };

    const { createProcessInstance } = useCap(REDIRECT.MANAGE_INTERLINE_TRIP, "submit", capErrorHandler);

    const passportCountry = getThreeLeterCodeWithTwoLetterCode(nationality);
    const assembledFirstName = joinFirstAndMiddleName(firstName, middleName);

    const createFlightInstance = (flightResponse) => {
        const flightData = {
            userData: {
                sharedData: {
                    appVersion,
                    interlineFlightDetails: {
                        carrierCode: airline,
                        env,
                        ...flightResponse,
                    },
                },
                userSpecificData: {
                    [userId]: {
                        firstName: assembledFirstName,
                        lastName,
                        dateOfBirth: birthDate.split("T")[0],
                        nationality,
                        passportCountry,
                        countryOfResidence
                    },
                },
            },
        };
        checkAndUpdateUser({
            firstName: assembledFirstName,
            lastName,
            birthDate,
            nationality,
            passportCountry,
            countryOfResidence,
        });
        createProcessInstance(flightData);
    };

    const processFlightBlocksResponse = response => {
        const messages = validateInterlineTrip(response);
        if (messages.length !== 0) {
            setErrorMessages(messages);
            setShowInvalidReasonModal(true);
            closeConfirmationModal();
            updateLoading("submit", false);
        } else {
            const flightResponse = validateFligthBlocksResponse(response);

            createFlightInstance(flightResponse);
        }
    };

    const setErrorResponse = e => {
        closeConfirmationModal();

        const { code } = e;
        const codeErrors = ["24", "283", "508"];

        if (code && codeErrors.includes(code.toString())) {
            setErrorMessages([e.code.toString()]);
            setShowInvalidReasonModal(true);
            updateLoading("submit", false);
        } else {
            setShowFlightBlocksErrorModal(true);
            updateLoading("submit", false);
        }
    };

    const handleSubmit = () => {
        updateLoading("submit", true);

        const flightDetails = {
            env,
            lastName,
            bookingNumber: bookingRef,
        };
        const additionalFields = additionalPnrLookupFields?.[airline] || [];
        if (additionalFields.includes("dateOfBirth") || additionalFields.includes("birthDate")) {
            flightDetails.dateOfBirth = birthDate.split("T")[0];
        }

        fetchInterlineFlight(airline, flightDetails, processFlightBlocksResponse, setErrorResponse);
    };

    const closeFlightBlocksErrorModal = () => {
        setShowFlightBlocksErrorModal(false);
    };

    const closeInvalidReasonModal = () => {
        setShowInvalidReasonModal(false);
    };

    return (
        <>
            <InterlineInfoModal
                showModal={showFlightBlocksErrorModal}
                bookingRef={bookingRef}
                firstName={assembledFirstName}
                lastName={lastName}
                airlineName={airlineName}
                birthDate={birthDate}
                nationality={nationality}
                countryOfResidence={countryOfResidence}
                closeModal={closeFlightBlocksErrorModal}
            />
            <InterlineInvalidFlightModal
                showModal={showInvalidReasonModal}
                errorMessages={errorMessages}
                closeModal={closeInvalidReasonModal}
            />
            <InterlineConfirmationModal
                onShowModal={showConfirmationModal}
                onConfirmSubmit={handleSubmit}
                bookingRef={bookingRef}
                firstName={firstName}
                middleName={middleName}
                lastName={lastName}
                birthDate={birthDate}
                onCloseModal={closeConfirmationModal}
            />
            <fieldset>
                <Form.Group className="border-bottom-0" controlId="booking-reference">
                    <Form.Label>{localize("addInterlineFlightDetails.flight.bookingRefNumber.label")}</Form.Label>
                    <Form.Control
                        type="text"
                        minLength="2"
                        maxLength="6"
                        onChange={selectBookingRef}
                        required
                        name="bookingRef"
                        pattern={getBookingRefPattern()}
                        placeholder="ex. JCGNHD"
                    />
                    <Form.Control.Feedback type="invalid">
                        {localize("addInterlineFlightDetails.flight.bookingRefNumber.missingMessage")}
                    </Form.Control.Feedback>
                </Form.Group>
            </fieldset>
        </>
    );
};

export default InterlineFlightsDetalisPnrLookup;
