import {
  LOCATION_REQUEST,
  LOCATION_SUCCESS,
  LOCATION_FAIL,
} from '../constants/locationConstants';

export const locationReducer = (state = { yourLocation: {} }, action) => {
  switch (action.type) {
    case LOCATION_REQUEST:
      return { loading: true, yourLocation: {} };
    case LOCATION_SUCCESS:
      return { loading: false, yourLocation: action.payload };
    case LOCATION_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
