import React from "react";
import { useItineraryChange } from '../../../utils/hooks';
import InterlineInfoModal from '../../AddInterlineFlightDetails/InterlineInfoModal';
import UpdateInfoModal from '../../ManageTrip/ManageInterlineTrip/ManageInterlineTripBlock/components/UpdateInfoModal';

const ActionModals = () => {
    const { flightUpdateModal, flightBlocksErrorModal } = useItineraryChange();

    const { flightUpdateModalData, closeUpdateInfoModal } = flightUpdateModal;
    const {
        showFlightBlocksErrorModal,
        bookingIdentifier,
        firstName,
        lastName,
        carrierCode,
        dateOfBirth,
        nationality,
        countryOfResidence,
        closeFlightBlocksErrorModal,
        cancelTrip
    } = flightBlocksErrorModal;

    return (
        <>
            <UpdateInfoModal
                messages={flightUpdateModalData}
                onShow={flightUpdateModalData?.length > 0}
                onClose={closeUpdateInfoModal}
            />
            <InterlineInfoModal
                showModal={showFlightBlocksErrorModal}
                bookingRef={bookingIdentifier}
                firstName={firstName}
                lastName={lastName}
                airlineName={carrierCode}
                birthDate={dateOfBirth}
                nationality={nationality}
                countryOfResidence={countryOfResidence}
                closeModal={closeFlightBlocksErrorModal}
                isUserActionsCheck={true}
                cancelTrip={cancelTrip}
            />
        </>
    );
};

export default ActionModals;
